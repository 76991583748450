import { APIClient } from "./apiCore";
import * as url from "./urls/Information";
import {
    POST_ARRIVE_USER_INFO,
    POST_CONTRACT_EMAIL_SEND_CHECK,
    POST_CONTRACT_NO_MEMBER_EMAIL_SEND_CHECK
} from "./urls/Information";
const api = new APIClient();


export const postInformationSendSearch = (data) => api.create(url.POST_INFORMATION_SEND_SEARCH, data)
export const postWasteSearch = (data) => api.create(url.POST_WASTE_SEARCH, data)
export const postRegistration = (data) => api.create(url.POST_WASTE_REGISTRATION, data)
export const postWasteKindSearch = (data) => api.create(url.POST_WASTE_KIND_SEARCH, data)

export const postArriveSearch = (data) => api.create(url.POST_ARRIVE_SEARCH,data)
export const postArriveDelete = (data) => api.create(url.POST_ARRIVE_DELETE,data)
export const postArriveSearchDetail = (data) => api.get(url.POST_ARRIVE_SEARCH_DETAIL,data)
export const postArriveCompanyNumberSearch = (data) => api.create(url.POST_ARRIVE_COMPANY_NUMBER_SEARCH,data)
export const postArriveUserInfo = (data) => api.create(url.POST_ARRIVE_USER_INFO,data)

export const postArriveDetailSave = (data) => api.create(url.POST_ARRIVE_DETAIL_SAVE,data)
export const postContractSearch = (data) => api.create(url.POST_CONTRACT_SEARCH,data)
export const postCoatingPreView = (data) => api.create(url.POST_CONTRACT_COATING_PRE_VIEW,data)
export const postCoatingSearch = (data) => api.create(url.POST_CONTRACT_COATING_SEARCH,data)
export const postContractInfo = (data) => api.create(url.POST_CONTRACT_INFO,data)
export const postContractNoMemberEmailSendCheck = (data) => api.create(url.POST_CONTRACT_NO_MEMBER_EMAIL_SEND_CHECK,data)

export const postContractEmailSend = (data) => api.create(url.POST_CONTRACT_EMAIL_SEND,data)
export const postCoatingCreate = (data) => api.create(url.POST_MYPAGE_COATING_CREATE,data)
export const postCoatingUpload = (data) => api.coatingUpload(url.POST_MYPAGE_COATING_UPLOAD,data)
export const postCompleteWasteSearch = (data) => api.create(url.POST_COMPLETE_WASTE_SEARCH,data)
export const postCompleteWasteDelete = (data) => api.create(url.POST_COMPLETE_WASTE_DELETE,data)
export const postCompleteWasteUpdate = (data) => api.create(url.POST_COMPLETE_WASTE_UPDATE,data)
export const postCompleteWasteSave = (data) => api.create(url.POST_COMPLETE_WASTE_SAVE,data)

export const postNoMemberCoatingCreate = (data) => api.create(url.POST_CONTRACT_NO_MEMBER_COATING_CREATE,data)
export const postNoMemberCoatingUpload = (data) => api.noMemberCoatingUpload(url.POST_CONTRACT_NO_MEMBER_COATING_UPLOAD,data)
export const postNoMemberSave = (data) => api.create(url.POST_CONTRACT_NO_MEMBER_SAVE,data)
export const postCompleteDocumentSearch = (data) => api.create(url.POST_COMPLETE_DOCUMENT_SEARCH,data)
export const postCompleteDocumentUpdate = (data) => api.create(url.POST_COMPLETE_DOCUMENT_UPDATE,data)
export const postCompleteDocumentPermitDownload = (data) => api.create(url.POST_COMPLETE_DOCUMENT_PERMIT_DOWNLOAD,data)
export const postCompleteDocumentMemberPreView = (data) => api.create(url.POST_COMPLETE_DOCUMENT_MEMBER_PRE_VIEW,data)
export const postCompleteDocumentContributionDownload= (data) => api.create(url.POST_COMPLETE_DOCUMENT_CONTRIBUTION_DOWNLOAD,data)






