import { PublicFooter } from "./base/PublicFooter";
import { PublicHeader } from "./base/PublicHeader";
import { useEffect } from "react";

export const PrivacyPolicy = () => {
    useEffect(() => {
        const body = document.querySelector('#root');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)

    }, []);
    return (
        <>
            <PublicHeader />
            <div class="container policy_doc">
                <div className="row">
                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <h1 class="privacy_heading text-center">개인정보 보호 및 이용</h1>
                        <div class="privacy_outer">
                            <ul>
                                <li>1. 회사는 “개인정보 보호법” 등 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보 수집 및 이용에 대해서는 서비스 관련 법령, 회사의 개인정보 처리방침, 회원가입 이용약관 및 서비스 이용약관이 적용됩니다.</li>
                                <li>2. 사용자는 원활한 서비스 이용을 위하여 사용자가 제공받는 서비스와 직접적으로 관련 있는 다음의 사용자에게 아래 표에서 열거한 개인정보를 공유할 수 있습니다. 회사는 서비스의 원활한 제공을 위하여 관련 법령에 따라 개인정보를 수집 ∙ 이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 개인정보 보호 및 이용에 관련된 상세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</li>
                                <img src={process.env.PUBLIC_URL + "/images/agree_register_three.png"} alt="" />
                                <li>3. 사용자는 원활한 서비스 이용을 위하여 사용자가 제공받는 서비스와 직접적으로 관련 있는 다음의 사용자에게 아래 표에서 열거한 개인정보를 공유할 수 있습니다. 회사는 서비스의 원활한 제공을 위하여 관련 법령에 따라 개인정보를 수집 ∙ 이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 개인정보 보호 및 이용에 관련된 상세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</li>
                            </ul>
                            <h6>사용자 데이터</h6>
                            <ul>
                                <li>4. 사용자는 서비스 이용과정에서 사이트에 사용자데이터를 업로드, 제공, 저장, 송부 또는 공개할 수 있습니다. 사용자 데이터에 대한 지식재산권 등의 권리는 여전히 해당 사용자에게 전적으로 귀속됩니다. 사용자는 사이트에 부정확하거나 무효 또는 완전하지 않은 정보, 기타 관련 법령 및 본 약관에서 허용되지 않는 정보를 제공하거나 입력하여서는 안 됩니다. 사용자가 사이트에 제공 또는 입력한 사용자 데이터에 대한 책임(법령 위반에 따른 벌금·과태료 등의 제재 책임을 포함하되 이에 한정되지 아니함)은 전적으로 해당 사용자가 부담하며, 회사는 이에 대해 책임을 지지 않습니다(단, 관련 경우에 회사의 고의 또는 중대한 과실이 존재하는 경우는 예외로 함).</li>
                                <li>5. 회사는 사용자가 제공받는 서비스와 직접적으로 연관이 있는 다음의 자들에 대하여, 해당 사용자의 원활한 서비스 이용을 위하여 합리적으로 요구되는 범위 내에서 해당 사용자의 사용자데이터의 일부를 공개할 수 있습니다.</li>
                                <img src={process.env.PUBLIC_URL + "/images/agree_register_four.png"} alt="" />
                                <li>6. 서비스의 회원가입 이용약관 제5조 및 제11조 제2항의 적용을 전제로, 관련 법령이 허용하는 한도 내에서 회사는 [(i) 서비스 향상 및 개선, (ii) 회사 및 회사 계열회사의 신규 제품이나 서비스 개발, 출시 및 개선, (iii) 홍보마케팅 전략 수립 및 마케팅/홍보물 제작, (iv) 고객과의 커뮤니케이션, (v) 통계 목적, (vi) 과학적 연구]를 위하여 사용자 데이터 및 개인정보를 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기 기타 유사한 행위(이하 본 항에서 ‘처리’)를 할 수 있습니다.</li>
                                <li>[필수]</li>
                                <img src={process.env.PUBLIC_URL + "/images/agree_register_three.png"} alt="" />
                                <li>[사용자 데이터]</li>
                                <img src={process.env.PUBLIC_URL + "/images/agree_register_four.png"} alt="" />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
    );
}