import React from 'react';
import DaumPostcode from 'react-daum-postcode';

const AddressSearchPopup = ({ onComplete, onClose }) => {
    return (
        <div className="popup">
            <DaumPostcode
                onComplete={onComplete} // 주소 선택 시 호출되는 함수
                autoClose={false} // 주소 선택 후 자동 닫기 여부
            />
            <button onClick={onClose}>닫기</button> {/* 팝업 닫기 버튼 */}
        </div>
    );
};

export default AddressSearchPopup;