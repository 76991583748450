import React, { useEffect, useState } from "react";
import {
    postDeliveryCompleteSearch,
    postDeliveryDeliveryDelete,
    postDeliveryDocumentMemberPreView,
    postDeliveryDocumentUpdate
} from "../../api/delivery";
import { scrollToTop } from "../../api/utils/scrollToTop";
import Modal from "react-bootstrap/Modal";
import { MobileHeader } from "../common/MobileHeader";
import { Sidebar } from "../common/Sidebar";
import { PcHeader } from "../common/PcHeader";
import { PDFDocument } from "pdf-lib";
import DeliveryCheckPreviewModal from "./modal/DeliveryCheckPreviewModal";

export const DeliveryContractCheck = () => {

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [responseData, setResponseData] = useState([])
    const [total, setTotal] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 15,
    })

    const [adminCheck, setAdminCheck] = useState(false)

    // 총 페이지 수 상태
    const [totalPages, setTotalPages] = useState(0);
    // 모든 체크박스의 상태를 관리하는 state
    const [checkedItems, setCheckedItems] = useState([]); // 선택된 항목들의 idx를 저장하는 상태
    const [isAllChecked, setIsAllChecked] = useState(false); // 전체 선택 여부를 저장하는 상태
    const [combinedPdfUrl, setCombinedPdfUrl] = useState('');

    const completeSearch = async () => {
        try {
            const response = await postDeliveryCompleteSearch(pageNation);
            if (response.code === "0000") {
                // 응답 데이터가 배열 형태로 오는지 확인하고, 없으면 빈 배열을 할당
                const resultData = response.result?.result || [];
                setResponseData(resultData);

                // resultData가 비어있다면 totalCount는 0으로 설정
                const totalCount = resultData.length > 0 ? resultData[0].totalCount : 0;
                setTotal(totalCount);

                // 페이지 수 계산 (totalCount가 0일 경우 최소 페이지 1로 설정)
                setTotalPages(Math.max(1, Math.ceil(totalCount / pageNation.rowCnt)));

                // adminCheck 값이 true인지 확인하고 설정
                if (response.result.adminCheck) {
                    setAdminCheck(true);

                }
            }
        } catch (e) {
            setShowErrorMessage(e.message);
            setShowError(true);
        }
    };

    const handleSelectAll = () => {
        if (isAllChecked) {
            // 전체 해제 시 상태 초기화
            setCheckedItems([]);
        } else {
            // 전체 선택 시 모든 idx 저장
            const allIds = responseData.map((item) => item.idx);
            setCheckedItems(allIds);
        }
        setIsAllChecked(!isAllChecked); // 전체 선택 상태 업데이트
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        completeSearch()


    }, []);

    // 페이지 번호가 변경될 때마다 데이터 검색
    useEffect(() => {
        completeSearch();
    }, [pageNation]); // pageNation이 변경될 때마다 호출

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };

    const handleItemCheck = (idx) => {

        if (checkedItems.includes(idx)) {
            // 이미 선택된 항목이면 해당 idx를 제거
            setCheckedItems(checkedItems.filter((item) => item !== idx));
        } else {
            // 선택되지 않은 항목이면 idx 추가
            setCheckedItems([...checkedItems, idx]);
        }
    };
    const handleLastCheckChange = (newValue, idx) => {
        const updatedData = responseData.map(item =>
            item.idx === idx ? { ...item, lastCheck: newValue } : item
        );

        setResponseData(updatedData);

    };

    const handleSuccessClose = () => {
        setShowSuccess(false);
        scrollToTop()
        completeSearch()
        setCheckedItems([]);
    }
    const handleOpenModal = async (item) => {
        try {
            // Modal을 열고 PDF 데이터를 가져오는 함수 호출
            const response = await postDeliveryDocumentMemberPreView(item);
            const leftPdfData = response.result.contractPdf;
            const rightPdfData = response.result.base64PdfFile1;

            if (leftPdfData && rightPdfData) {
                const combinedPdf = await combinePdfs(leftPdfData, rightPdfData);
                setCombinedPdfUrl(URL.createObjectURL(combinedPdf)); // Blob URL 생성
                setIsModalOpen(true);  // 모달 열기
            }
        } catch (e) {
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCombinedPdfUrl(null); // URL 초기화
    };

    const handlePrint = () => {
        const iframe = document.querySelector("iframe");
        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.print();
        }
    };

    const combinePdfs = async (base64Pdf1, base64Pdf2) => {
        const pdf1Blob = await createPdfBlob(base64Pdf1);
        const pdf2Blob = await createPdfBlob(base64Pdf2);

        // pdf-lib를 사용하여 PDF 문서 합치기
        const pdf1Doc = await PDFDocument.load(await pdf1Blob.arrayBuffer());
        const pdf2Doc = await PDFDocument.load(await pdf2Blob.arrayBuffer());

        const combinedPdf = await PDFDocument.create();
        const [pdf1Pages, pdf2Pages] = await Promise.all([
            combinedPdf.copyPages(pdf1Doc, pdf1Doc.getPageIndices()),
            combinedPdf.copyPages(pdf2Doc, pdf2Doc.getPageIndices()),
        ]);

        pdf1Pages.forEach((page) => combinedPdf.addPage(page));
        pdf2Pages.forEach((page) => combinedPdf.addPage(page));

        const pdfBytes = await combinedPdf.save();
        return new Blob([pdfBytes], { type: 'application/pdf' });
    };

    const createPdfBlob = (base64Data) => {
        return new Promise((resolve) => {
            const pdfData = atob(base64Data); // Base64 문자열 디코딩
            const byteNumbers = new Uint8Array(pdfData.length);
            for (let i = 0; i < pdfData.length; i++) {
                byteNumbers[i] = pdfData.charCodeAt(i);
            }
            const blob = new Blob([byteNumbers], { type: 'application/pdf' });
            resolve(blob);
        });
    };

    const handlerSave = async () => {
        try {
            if (checkedItems.length < 1) {
                setShowErrorMessage("저장할 행을 선택해주세요.")
                setShowError(true)
                return
            }
            const selectedData = responseData.filter(item => checkedItems.includes(item.idx));

            const response = await postDeliveryDocumentUpdate(selectedData);

            if (response.code === "0000") {
                setShowSuccess(true)
                setShowSuccessMessage("저장 되었습니다.")
            }
        }
        catch (e) {
            setShowErrorMessage("관리자에게 문의바랍니다.");
            setShowError(true)
        }
    };

    // 삭제 버튼 클릭 시 체크된 항목 서버로 전송
    const handleDelete = async () => {
        try {
            if (checkedItems.length < 1) {
                setShowErrorMessage("삭제할 행을 선택해주세요.")
                setShowError(true)
                return
            }

            const response = await postDeliveryDeliveryDelete({
                idx: checkedItems
            })

            if (response.code === "0000") {
                setShowSuccess("삭제 되었습니다.")
                setShowSuccess(true)
            }
        } catch (e) {
            setShowErrorMessage("삭제 실패 관리자에게 문의바랍니다.")
            setShowError(true)
        }
    };

    const handleErrorClose = () => {
        setShowError(false)

    }

    const getStartIndex = (pageNum) => {
        return (pageNum - 1) * pageNation.rowCnt;
    };

    const startIndex = getStartIndex(pageNation.pageNum);
    return (
        <React.Fragment >
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="납품관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_data">
                                <div className="card-header">
                                    납품현황
                                </div>
                                <div className="card-body">
                                    <div className="card_top_with_btns mb-13px">
                                        <h6 className="table_stats">납품현황 (<span>{total}</span> 건)</h6>
                                        {adminCheck && (
                                            <div className="d-flex">
                                                <button className="btn btn-primary"
                                                    onClick={handlerSave}
                                                >저장</button>
                                                <button className="btn btn-outline-danger ms-10px"
                                                    onClick={handleDelete}
                                                >삭제</button>
                                            </div>
                                        )}
                                    </div>
                                    <table className="table table-bordered my_table xmb-55px">
                                        <thead>
                                            <tr>
                                                {adminCheck && (
                                                    <th style={{ width: "5.61%" }} className="text-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={isAllChecked} // 전체 선택 여부에 따라 체크 상태 변화
                                                            onChange={handleSelectAll} // 전체 선택 핸들러
                                                        />
                                                    </th>
                                                )}
                                                <th style={{ width: "5.61%" }}>번호</th>
                                                <th style={{ width: "46.00%" }}>공사 명</th>
                                                <th style={{ width: "13.38%" }}>처리완료일</th>
                                                <th style={{ width: "9.22%" }}>비회원 서명여부</th>
                                                <th style={{ width: "9.31%" }}>최종완료</th>
                                                <th>문서확인</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {responseData && responseData.length > 0 ? (
                                                responseData.map((item, index) => (
                                                    <tr
                                                        key={item.idx}
                                                        className="clickable"
                                                        onClick={() => {
                                                            // item.signatureCheck가 "N"이거나 null인 경우 클릭 이벤트 차단
                                                            if (item.signatureCheck === "N" || item.signatureCheck === null) {
                                                                return;
                                                            }
                                                            handleOpenModal(item); // 클릭 시 모달 열기
                                                        }}
                                                    >
                                                        {adminCheck && (
                                                            <td className="text-center">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={checkedItems.includes(item.idx)} // 개별 체크박스 상태
                                                                    onChange={() => handleItemCheck(item.idx)} // 개별 체크박스 핸들러
                                                                />
                                                            </td>
                                                        )}
                                                        <td className="text-center">{startIndex + index + 1}</td>
                                                        <td className="text-center">{item.constructionText}</td>
                                                        <td className="text-center">{item.lastDate ? item.lastDate : "-"}</td>
                                                        <td className="text-center">{item.signatureCheck}</td>
                                                        <td className="text-center">
                                                            {adminCheck ? (
                                                                <select className="form-select"
                                                                    value={item.lastCheck}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    onChange={(e) => handleLastCheckChange(e.target.value, item.idx)} // idx를 전달
                                                                >
                                                                    <option>미완료</option>
                                                                    <option>완료</option>
                                                                </select>
                                                            ) : (
                                                                item.lastCheck
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-primary btn_tbl w-148px"
                                                                disabled={item.signatureCheck === "N" || item.signatureCheck === null}
                                                                onClick={() => handleOpenModal(item)}
                                                            >계약서 보기 / 인쇄</button>
                                                        </td>


                                                    </tr>
                                                )))
                                                : (
                                                    <tr className="no-data-message">
                                                        <td className="text-center" colSpan={7}>조회된 데이터가 없습니다.</td>
                                                    </tr>
                                                )}
                                        </tbody>
                                        <DeliveryCheckPreviewModal
                                            show={isModalOpen}
                                            handleClose={handleCloseModal}
                                            previewUrl={combinedPdfUrl}
                                            handlePrint={handlePrint}
                                        />
                                    </table>
                                    <div className="pagination_container mb-55px">
                                        <ul>
                                            {responseData.length > 0 &&
                                                <>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                            alt="First Page"
                                                            onClick={() => handlePageChange(1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                            alt="Previous Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>

                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li key={index}
                                                            onClick={() => handlePageChange(index + 1)}
                                                            className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                        >
                                                            {index + 1} {/* 페이지 번호 표시 */}
                                                        </li>
                                                    ))}

                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                            alt="Next Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum < totalPages ? pageNation.pageNum + 1 : totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                            alt="Last Page"
                                                            onClick={() => handlePageChange(totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <h5>실패</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>성공</h5>
                        <p>{showSuccessMessage}</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                    </Modal.Body>
                </Modal>
                {/*{ isModalOpen &&*/}
                {/*    <Modal className="modal_alert1" show={isModalOpen} onHide={handleIsModalClose} centered>*/}
                {/*        <Modal.Body>*/}
                {/*            <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />*/}
                {/*            <div className="card-body">*/}
                {/*                <div className="grey_box_container">*/}
                {/*                    <div className="grey_box">*/}
                {/*                        <iframe src={combinedPdfUrl} width="100%" height="800px" title="Combined PDF" />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleIsModalClose}>확인</button>*/}
                {/*        </Modal.Body>*/}
                {/*    </Modal>*/}
                {/*}*/}
            </div>
        </React.Fragment>
    );
}