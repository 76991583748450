import { APIClient } from "./apiCore";
import * as url from "./urls/Dashboard";
import {
    POST_DASH_BOARD_BOTTOM_SERACH,
    POST_DASH_BOARD_EMAIL_SEND,
    POST_DASH_BOARD_EMAIL_SEND_CHECK,
    POST_DASH_BOARD_TOTAL_COUNT
} from "./urls/Dashboard";
const api = new APIClient();

//LOGIN
export const postDashBoardEmailSend = (data) => api.create(url.POST_DASH_BOARD_EMAIL_SEND, data)
export const postDashBoardEmailSendCheck = (data) => api.create(url.POST_DASH_BOARD_EMAIL_SEND_CHECK, data)
export const postDashBoardTotalCount = (data) => api.create(url.POST_DASH_BOARD_TOTAL_COUNT, data)
export const postDashBoardBottomSearch = (data) => api.create(url.POST_DASH_BOARD_BOTTOM_SERACH, data)


