import { APIClient } from "./apiCore";
import * as url from "./urls/Mypage";
import {
    POST_MYPAGE_AUTH_SEARCH,
    POST_MYPAGE_BASE_AUTH_SEARCH,
    POST_MYPAGE_BASE_DETAIL_AUTH_SEARCH,
    POST_MYPAGE_BASE_DETAIL_SAVE,
    POST_MYPAGE_BASE_DETAIL_SEARCH,
    POST_MYPAGE_BASE_DETAILAUTH_SAVE,
    POST_MYPAGE_DRIVE_DETAIL_SAVE, POST_MYPAGE_DRIVE_DETAIL_SEARCH,
    POST_MYPAGE_DRIVE_DETAIL_SERACH, POST_MYPAGE_HANDLER_DETAIL_SAVE, POST_MYPAGE_HANDLER_DETAIL_SEARCH
} from "./urls/Mypage";
const api = new APIClient();


export const postMypageAuthSearch = (data) => api.create(url.POST_MYPAGE_AUTH_SEARCH, data)
export const postMypageBaseDetailSave = (data) => api.create(url.POST_MYPAGE_BASE_DETAIL_SAVE, data)
export const postMypageBaseDetailSearch = (data) => api.create(url.POST_MYPAGE_BASE_DETAIL_SEARCH, data)
export const postMypageBaseDetailAuthSearch = (data) => api.create(url.POST_MYPAGE_BASE_DETAIL_AUTH_SEARCH, data)
export const postMypageBaseDetailAuthSave = (data) => api.create(url.POST_MYPAGE_BASE_DETAILAUTH_SAVE, data)
export const postMypageDriveDetailSearch = (data) => api.create(url.POST_MYPAGE_DRIVE_DETAIL_SEARCH, data)
export const postMypageDriveDetailSave = (data) => api.create(url.POST_MYPAGE_DRIVE_DETAIL_SAVE, data)
export const postMypageHandlerDetailSearch = (data) => api.create(url.POST_MYPAGE_HANDLER_DETAIL_SEARCH, data)
export const postMypageHandlerDetailSave = (data) => api.create(url.POST_MYPAGE_HANDLER_DETAIL_SAVE, data)






