import { APIClient } from "./apiCore";
import * as url from "./urls/Delivery";
import {
    POST_DELIVERY_COMPLETE_SEARCH,
    POST_DELIVERY_DELIVERY_DELETE,
    POST_DELIVERY_DELIVERY_NO_MEMBER_PRE_VIEW,
    POST_DELIVERY_DOCUMENT_MEMBER_PRE_VIEW,
    POST_DELIVERY_DOCUMENT_MEMBER_PREVIEW,
    POST_DELIVERY_DOCUMENT_UPDATE,
    POST_DELIVERY_NO_MEMBER_COATING_CREATE,
    POST_DELIVERY_NO_MEMBER_COATING_UPLOAD,
    POST_DELIVERY_NO_MEMBER_EMAIL_SEARCH,
    POST_DELIVERY_NO_MEMBER_PRE_VIEW,
    POST_DELIVERY_NO_MEMBER_SAVE,
    POST_DELIVERY_REGISTRATION_BASE,
    POST_DELIVERY_REGISTRATION_SAVE,
    POST_DELIVERY_REGISTRATION_SEARCH
} from "./urls/Delivery";
const api = new APIClient();


export const postRegistrationSearch = (data) => api.create(url.POST_DELIVERY_REGISTRATION_SEARCH, data)
export const postDeliveryRegistrationSave = (data) => api.create(url.POST_DELIVERY_REGISTRATION_SAVE, data)
export const postDeliveryRegistrationBase = (data) => api.create(url.POST_DELIVERY_REGISTRATION_BASE, data)
export const postDeliveryNoMemberEmailSearch = (data) => api.create(url.POST_DELIVERY_NO_MEMBER_EMAIL_SEARCH, data)
export const postDeliveryCompleteSearch = (data) => api.create(url.POST_DELIVERY_COMPLETE_SEARCH, data)
export const postDeliveryDocumentUpdate = (data) => api.create(url.POST_DELIVERY_DOCUMENT_UPDATE, data)
export const postDeliveryDeliveryDelete = (data) => api.create(url.POST_DELIVERY_DELIVERY_DELETE, data)
export const postDeliveryNoMemberPreView = (data) => api.create(url.POST_DELIVERY_NO_MEMBER_PRE_VIEW, data)
export const postDeliveryNoMemberCoatingCreate = (data) => api.create(url.POST_DELIVERY_NO_MEMBER_COATING_CREATE, data)
export const postDeliveryNoMemberCoatingUpload = (data) => api.noMemberCoatingUpload(url.POST_DELIVERY_NO_MEMBER_COATING_UPLOAD, data)
export const postDeliveryNoMemberSave = (data) => api.create(url.POST_DELIVERY_NO_MEMBER_SAVE, data)
export const postDeliveryDocumentMemberPreView = (data) => api.create(url.POST_DELIVERY_DOCUMENT_MEMBER_PRE_VIEW, data)







