import { APIClient } from "./apiCore";
import * as url from "./urls/UrlAuth";
const api = new APIClient();

//LOGIN
export const postLogin = (data) => api.create(url.POST_LOGIN, data)
export const joinMemberIdCheck = (data) => api.create(url.JOIN_MEMBER_ID_CHECK, data)
export const joinMemberEmailCheck = (data) => api.create(url.JOIN_MEMBER_EMAIL_CHECK, data)
export const joinMemberCompanyNumberCheck = (data) => api.create(url.JOIN_MEMBER_COMPANY_NUMBER_CHECK, data)
export const joinMemberShip = (data) => api.create(url.JOIN_MEMBER_SHIP, data)
export const joinMemberShipEmailSend = (data) => api.create(url.JOIN_MEMBER_EMAIL_SEND, data)
export const findId = (data) => api.create(url.FIND_ID, data)
export const findIdEmailSend = (data) => api.create(url.FIND_ID_EMAIL_SEND, data)
export const newPassword = (data) => api.create(url.NEW_PASSWORD, data)
export const newPasswordSave = (data) => api.create(url.NEW_PASSWORD_SAVE, data)
export const fileUpload = (data) => api.updateWithFile(url.FILE_UPLOAD, data)

export const findIsPay = (data) => api.create(url.FIND_IS_PAY, data)
export const updateIsPay = (data) => api.create(url.UPDATE_IS_PAY, data)


