import React, { useEffect, useState } from "react";
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf';
import { PDFDocument } from 'pdf-lib';
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { renderToString } from "react-dom/server";
import { scrollToTop } from "../../api/utils/scrollToTop";
import {
    postCoatingPreView,
    postContractInfo,
    postNoMemberCoatingCreate,
    postNoMemberCoatingUpload,
    postNoMemberSave
} from "../../api/information";
import { MobileHeader } from "../common/MobileHeader";
import { PcHeader } from "../common/PcHeader";
import { Sidebar } from "../common/Sidebar";
import {VerifyDeliverySidebar} from "./base/VerifyDeliverySidebar";
import {
    postDeliveryNoMemberCoatingCreate,
    postDeliveryNoMemberCoatingUpload,
    postDeliveryNoMemberPreView,
    postDeliveryNoMemberSave
} from "../../api/delivery";

GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';

export const DeliveryNoMemberDetail = () => {

    const navigate = useNavigate();
    const [combinedPdfUrl, setCombinedPdfUrl] = useState('');
    const [combinedPdfUrl2, setCombinedPdfUrl2] = useState('');
    const [selectedOption, setSelectedOption] = useState('email');
    const [selectedCoatingOption, setSelectedCoatingOption] = useState('electionCoating');

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showCoating, setShowCoating] = useState("")
    const [showSuccess, setShowSuccess] = useState(false);
    const [electionCoating, setElectionCoatingReq] = useState("")

    const [companyName, setCompanyName] = useState("")
    const [complete, setComplete] = useState(false)
    const handleErrorClose = () => {
        setShowError(false)
    }

    const { idx, constructionText } = useParams();

    const [urlArr, setUrlArr] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState({});

    const [loadUserInfo, setLoadUserInfo] = useState({
        userCompanyName: "",
        userEmail: "",
        idx: idx,

    })


    const handleSuccessClose = () => {
        setShowSuccess(false);
        setShowCoating(false)
        setElectionCoatingReq("")
        setComplete(true)
        scrollToTop()
    }

    const handleCoatingClose = () => setShowCoating(false);

    const [coatingReq, setCoatingReq] = useState({
        type: "coating",
        companyName: "",
        wasteRegistrationIdx: idx,
    })

    const pdfSearch = async () => {
        try {
            const response = await postDeliveryNoMemberPreView(loadUserInfo)

            setDeliveryInfo(response.result.deliveryInfo)

            const leftPdfData = response.result.contractPdf; // 계약 PDF
            const rightPdfData = response.result.base64PdfFile1; // 드라이브 PDF

            const arr = [];

            const combinedPdf = await combinePdfs(leftPdfData, rightPdfData);
            arr.push(URL.createObjectURL(combinedPdf))
            
            // if (leftPdfData) {
            //     setCombinedPdfUrl(URL.createObjectURL(await createPdfBlob(leftPdfData))); // 왼쪽 PDF 설정
            // }

            // if (rightPdfData) {
            //     setCombinedPdfUrl2(URL.createObjectURL(await createPdfBlob(rightPdfData))); // 오른쪽 PDF 설정
            //     arr.push(URL.createObjectURL(await createPdfBlob(rightPdfData)))
            // }

            setUrlArr(arr)
        } catch (e) {
        }
    };

    const combinePdfs = async (base64Pdf1, base64Pdf2) => {
        const pdf1Blob = await createPdfBlob(base64Pdf1);
        const pdf2Blob = await createPdfBlob(base64Pdf2);

        // pdf-lib를 사용하여 PDF 문서 합치기
        const pdf1Doc = await PDFDocument.load(await pdf1Blob.arrayBuffer());
        const pdf2Doc = await PDFDocument.load(await pdf2Blob.arrayBuffer());

        const combinedPdf = await PDFDocument.create();
        const [pdf1Pages, pdf2Pages] = await Promise.all([
            combinedPdf.copyPages(pdf1Doc, pdf1Doc.getPageIndices()),
            combinedPdf.copyPages(pdf2Doc, pdf2Doc.getPageIndices()),
        ]);

        pdf1Pages.forEach((page) => combinedPdf.addPage(page));
        pdf2Pages.forEach((page) => combinedPdf.addPage(page));

        const pdfBytes = await combinedPdf.save();
        return new Blob([pdfBytes], { type: 'application/pdf' });
    };

    const createPdfBlob = (base64Data) => {
        return new Promise((resolve) => {
            const pdfData = atob(base64Data); // Base64 문자열 디코딩
            const byteNumbers = new Uint8Array(pdfData.length);
            for (let i = 0; i < pdfData.length; i++) {
                byteNumbers[i] = pdfData.charCodeAt(i);
            }
            const blob = new Blob([byteNumbers], { type: 'application/pdf' });
            resolve(blob);
        });
    };

    useEffect(() => {
        scrollToTop()
        pdfSearch(); // 컴포넌트 마운트 시 PDF 요청
    }, []);


    const handleCoatingOptionChange = (e) => {
        setSelectedCoatingOption(e.target.value);
        setCoatingReq({
            ...coatingReq,
            type: e.target.value
        });

    };

    const handleSubmit = async () => {

        if (complete) {
            setShowErrorMessage("이미 처리된 사항입니다.")
            setShowError(true)
            return
        }       
        setShowCoating(true)

    };

    const coatingCreateClick = async () => {
        try {

            const response = await postDeliveryNoMemberCoatingCreate(coatingReq)


            if (response.result.image) {
                setElectionCoatingReq(response.result.image)
                setCreateCoatingCheck(true)
                setSelectedCoatingOption("coating")
            }
        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
            setCreateCoatingCheck(false)
        }
    }

    const companyNameHandler = (e) => {
        setCompanyName(e)
        setCoatingReq({ ...coatingReq, companyName: e })
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [createCoatingCheck, setCreateCoatingCheck] = useState(false)
    const [uploadCoatingCheck, setUploadCoatingCheck] = useState(false)
    const handleFileChange = (event) => {

        const files = event?.target?.files; // 선택된 파일들

        // 파일이 선택되지 않은 경우
        if (!files || files.length === 0) {
            return;
        }

        const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링

        if (validFiles.length === files.length) {
            setSelectedFile(Array.from(files));
            const file = validFiles[0]; // 첫 번째 파일로 미리보기 생성
            const reader = new FileReader();

            reader.onloadend = () => {
                setFileUrl(reader.result); // Base64 문자열로 설정
            };
            reader.readAsDataURL(file); // 파일을 Data URL로 읽음
            setUploadCoatingCheck(true)
            setSelectedCoatingOption("upload")
        } else {
            setShowErrorMessage("이미지 파일 및 PDF만 가능합니다.")
            setShowError(true)
            setUploadCoatingCheck(false)
        }
    };

    const isValidFileType = (file) => {
        const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };

    const handleUploadClick = () => {
        document.getElementById('fileInput').click();
    };

    const coatingHandlerSubmit = async () => {

        if (coatingReq.type === "coating") {
            if (electionCoating === "") {
                if (!createCoatingCheck) {
                    setShowErrorMessage("생성된 도장이 없습니다.")
                    setShowError(true)
                    return
                }
            }
        }
        else {
            if (!uploadCoatingCheck) {
                setShowErrorMessage("업로드된 도장이 없습니다.")
                setShowError(true)
                return
            }

            try {
                const response = await postDeliveryNoMemberCoatingUpload({
                    file: selectedFile,
                    wasteRegistrationIdx: coatingReq.wasteRegistrationIdx
                })


            }
            catch (e) {
                setShowErrorMessage("업로드 도장 저장 오류입니다.")
                setShowError(true)
                setFileUrl("")
            }
        }



        try {

            const response = await postDeliveryNoMemberSave(coatingReq)
            setShowCoating(false)
            pdfSearch()
            setShowSuccess(true)
        }
        catch (e) {
            setShowError(true)
            setShowErrorMessage(e.message)
            setShowCoating(false)
            scrollToTop()
        }

    }

    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 1,
    })

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };


    return (
        <React.Fragment>

            <Modal className="modal_alert2" show={showCoating} onHide={handleCoatingClose} centered size={"lg"}>
                <Modal.Header>도장 선택</Modal.Header>
                <Modal.Body>
                    <div className="input_row">
                        <input type="text" placeholder="업체명을 입력해주세요." className="form-control"
                            onChange={(e) => companyNameHandler(e.target.value)}
                        />
                    </div>
                    <div className="stamp_container">
                        <div className="stamp_box">
                            <div className="stamp_top">
                                <h5>선택한 도장</h5>
                                <button className="btn btn-outline-secondary w-90px"
                                    onClick={coatingCreateClick}
                                >도장 생성</button>
                            </div>
                            <div className="stamp_option">
                                <label>
                                    {electionCoating ? (
                                        <img src={`data:image/png;base64,${electionCoating}`} alt="선택한 도장" style={{ width: '95px', height: '92px' }} />
                                    ) : (
                                        <img src={process.env.PUBLIC_URL + "/images/img_stamp.png"} alt="" style={{ width: '95px', height: '92px' }} />
                                    )}
                                    <input name="stamp" type="radio" className="form-input-radio"
                                        value="coating"
                                        checked={selectedCoatingOption === 'coating'}
                                        onChange={(e) => handleCoatingOptionChange(e)} />
                                </label>
                            </div>
                        </div>
                        <div className="stamp_box">
                            <div className="stamp_top">
                                <h5>전자도장</h5>
                                <button className="btn btn-outline-secondary w-100px"
                                    onClick={handleUploadClick}
                                >도장 업로드</button>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e)}
                                    style={{ display: 'none' }} // 숨김 처리
                                />
                            </div>
                            <div className="stamp_option">
                                <label>
                                    {fileUrl ? ( // 미리보기 URL이 존재하면
                                        <img src={fileUrl} alt="미리보기" style={{ width: '95px', height: '92px' }} />
                                    ) : (
                                        <img src={process.env.PUBLIC_URL + "/images/img_stamp.png"} alt="" style={{ width: '95px', height: '92px' }} />
                                    )}
                                    <input name="stamp" type="radio" className="form-input-radio"
                                        value="upload"
                                        checked={selectedCoatingOption === 'upload'}
                                        onChange={(e) => handleCoatingOptionChange(e)} />
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary btn_modal_footer"
                        onClick={coatingHandlerSubmit}
                    >선택</button>
                </Modal.Footer>
            </Modal>
            <MobileHeader />
            <div className="main-sidebar">
                <VerifyDeliverySidebar />
                <div className="main-container">
                    <PcHeader title="납품 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_data">
                                <div className="card-header">
                                    계약서 미리보기
                                </div>
                                <div className="card-body">
                                        <div className="grey_box">
                                            <iframe src={urlArr[pageNation.pageNum - 1]} width="100%" height="1000px" title="Combined PDF" />
                                        </div>
                                    <div className="pagination_container mb-55px">
                                        <ul>
                                            {urlArr.length > 0 &&
                                                // 페이지가 존재하는 경우 페이지네이션 렌더링
                                                <>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                            alt="First Page"
                                                            onClick={() => handlePageChange(1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                            alt="Previous Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    {/* 페이지 번호 배열 생성 */}
                                                    {[...Array(urlArr.length)].map((_, index) => (
                                                        <li key={index}
                                                            onClick={() => handlePageChange(index + 1)}
                                                            className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                        >
                                                            {index + 1} {/* 페이지 번호 표시 */}
                                                        </li>
                                                    ))}

                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                            alt="Next Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum < urlArr.length ? pageNation.pageNum + 1 : urlArr.length)}
                                                            className={pageNation.pageNum === urlArr.length ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                            alt="Last Page"
                                                            onClick={() => handlePageChange(urlArr.length)}
                                                            className={pageNation.pageNum === urlArr.length ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>
                                    { deliveryInfo.signatureCheck === "N" &&
                                    <div className="card_footer_btngrp_end">
                                    <button className="btn btn-primary btn_footer"
                                        onClick={handleSubmit}
                                    >전자승인진행</button>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <h5>오류</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                        </button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>제출 완료</h5>
                        <p>제출 되었습니다.</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}