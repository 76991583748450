import { PublicFooter } from "./base/PublicFooter";
import { PublicHeader } from "./base/PublicHeader";
import {useEffect} from "react";

export const Landing = () => {

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);


    return (
        <>
            <PublicHeader />
            <div className="content_main">
                <div className="content_wrapper">
                    <h3>원클릭 플랫폼 솔루션</h3>
                    <h1>
                        복잡한 절차 없이 간편하게,<br />
                        폐기물 계약부터 신고까지 한번에 해결 !
                    </h1>
                    <button
                        className="btn btn-outline-light button-home">원클릭 바로가기 <img src={process.env.PUBLIC_URL + "/images/home_btn_next.png"} alt=""
                    /></button>

                    <div className="home_boxes_wrapper">
                        <div className="home_box">
                            <img src={process.env.PUBLIC_URL + "/images/box_img1.png"} alt="" />
                            <h4>서비스 소개</h4>
                            <div>원클릭 플랫폼을 활용하여<br />
                                맞춤서비스를 도입할 수 있습니다.</div>
                        </div>
                        <div className="home_box">
                            <img src={process.env.PUBLIC_URL + "/images/box_img2.png"} alt="" />
                            <h4>요금안내</h4>
                            <div>원클릭 플랫폼에 대한<br />
                                요금을 상세히 안내합니다.</div>
                        </div>
                        <div className="home_box">
                            <img src={process.env.PUBLIC_URL + "/images/box_img3.png"} alt="" />
                            <h4>법적효력</h4>
                            <div>원클릭 플랫폼의 법적효력과<br />
                                상세한 가이드를 참고할 수 있습니다.</div>
                        </div>
                        <div className="home_box">
                            <img src={process.env.PUBLIC_URL + "/images/box_img4.png"} alt="" />
                            <h4>도입문의</h4>
                            <div>원클릭의 차별화 된 요금정책<br />
                                으로 합리적인 견적을 제공합니다.</div>
                        </div>
                    </div>
                </div>

            </div>
            <PublicFooter />
        </>
    );
}