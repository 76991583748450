import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NaverClose = () => {
    const location = useLocation(); // 현재 URL을 가져옴
    const navigate = useNavigate(); // 페이지 네비게이션을 위한 훅
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        // 쿼리 파라미터에서 'profile'을 추출
        const queryParams = new URLSearchParams(location.search);
        const profileParam = queryParams.get("profile");

        console.log(profileParam)

        if (profileParam) {
            try {
                // URL 디코딩 후 JSON 파싱
                const decodedProfile = decodeURIComponent(profileParam);
                const parsedProfile = JSON.parse(decodedProfile);
                setProfile(parsedProfile); // 상태에 프로필 저장

                // if (window.opener) {
                //     window.opener.profileData = parsedProfile; // 부모 창으로 데이터 전달
                // }

                if (window.opener) {
                    const event = new CustomEvent('profileData', { detail: parsedProfile }); // 이벤트 생성
                    window.opener.dispatchEvent(event); // 부모 창에 이벤트 디스패치
                }
                window.close(); // 팝업 창을 닫는 코드 추가
            } catch (error) {
                console.error("프로필 데이터 처리 오류", error);
            }
        }
    }, [location]); // location이 변경될 때마다 실행

    // 프로필 정보가 로드된 후 처리할 내용
    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
        <div>
        </div>
    );
};

export default NaverClose;