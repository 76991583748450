import React, { useEffect, useState } from "react";
import {
    postArriveDelete,
    postCompleteWasteDelete, postCompleteWasteSave,
    postCompleteWasteSearch, postCompleteWasteUpdate,
    postInformationSendSearch
} from "../../../api/information";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import Modal from "react-bootstrap/Modal";
import { MobileHeader } from "../../common/MobileHeader";
import { Sidebar } from "../../common/Sidebar";
import { PcHeader } from "../../common/PcHeader";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

export const ContractCompletion = () => {

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [showWait, setShowWait] = useState(false);
    const [responseData, setResponseData] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false);

    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 15,
    })

    const [adminCheck, setAdminCheck] = useState(false)
    const [waiting, setWaiting] = useState(false);
    // 총 페이지 수 상태
    const [totalPages, setTotalPages] = useState(0);
    // 모든 체크박스의 상태를 관리하는 state
    const [checkedItems, setCheckedItems] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const completeSearch = async () => {


        try {
            const response = await postCompleteWasteSearch(pageNation);
            if (response.code === "0000") {
                // 응답 데이터가 배열 형태로 오는지 확인하고, 없으면 빈 배열을 할당
                const resultData = response.result.result || [];
                setResponseData(resultData);

                // resultData가 비어있다면 totalCount는 0으로 설정
                const totalCount = resultData.length > 0 ? resultData[0].totalCount : 0;
                setTotal(totalCount);

                // 페이지 수 계산 (totalCount가 0일 경우 최소 페이지 1로 설정)
                setTotalPages(Math.max(1, Math.ceil(totalCount / pageNation.rowCnt)));

                // adminCheck 값이 true인지 확인하고 설정
                if (response.result.adminCheck) {
                    setAdminCheck(true);
                }
            }
        } catch (e) {
            setShowErrorMessage(e.message);
            setShowError(true);
        }

    }

    const handleErrorClose = () => {
        setShowError(false)

    }

    // office 입력값 변경 핸들러
    const handleOfficeChange = (index, value, part) => {

        const newData = [...responseData];

        if (part === 'office') {
            newData[index].office = value;
        }
        else if (part === 'submitCheck') {
            newData[index].submitCheck = value;
        }
        setResponseData(newData);
    };


    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        completeSearch()

    }, []);

    // 페이지 번호가 변경될 때마다 데이터 검색
    useEffect(() => {
        completeSearch();
    }, [pageNation]); // pageNation이 변경될 때마다 호출

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };


    const handleSuccessClose = () => {
        setShowSuccess(false);
        scrollToTop()
        completeSearch()
        setCheckedItems([]);
    }

    const handleWaitClose = () => {
        setShowWait(false)
        scrollToTop();
    }

    // const [loadingCheck, setLoadingCheck] = useState(false);
    const handlerSubmit = async (item) => {

        let loadingCheck = true;
        if(adminCheck){
            if (checkedItems.length === 0) {
                setShowErrorMessage("하나의 항목을 선택해야 합니다.");
                setShowError(true);
                // setLoadingCheck(false); // 로딩 상태를 false로 설정
                loadingCheck = false
                return; // 함수 종료
            }

            if (checkedItems.length > 1) {
                setShowErrorMessage("한 번에 하나의 항목만 선택할 수 있습니다.");
                setShowError(true);
                loadingCheck = false;
                return;
            }
        }
        const selectedItemIdx = item.idx; // 선택된 항목의 idx 가져오기
        const selectedItem = responseData.find(item => item.idx === selectedItemIdx);
        // 선택된 항목의 wasteCompleteCheck 상태 확인
        if (selectedItem.wasteCompleteCheck === "제출") {
            setIsLoading(false);
            setShowErrorMessage("이미 제출된 항목입니다.");
            setShowError(true);
            setCheckedItems([])
            loadingCheck = false
            // setLoadingCheck(false); // 로딩 상태를 false로 설정
            return; // 함수 종료
        }

        if(loadingCheck){
            setIsLoading(true);
        }

        try {
            // setShowWait(true)

            const response = await postCompleteWasteSave({
                wasteRegistrationIdx: selectedItemIdx
            })

            // setPosition(response.position);
            if(response.code==="0000"){

                setShowSuccessMessage("제출되었습니다.")
                setShowSuccess(true)
                setCheckedItems([])
                completeSearch()
            }
            else{
                setShowErrorMessage("제출에 실패했습니다.");
                setShowError(true);
            }
        }
        catch (e) {
            setShowErrorMessage("관리자에게 문의바랍니다.");
            setShowError(true)
            setCheckedItems([])
        }
        finally {
            // setShowWait(false);  // API 응답 후 모달 닫기
            setIsLoading(false);
        }
    };

    const handlerSave = async () => {
        try {
            const response = await postCompleteWasteUpdate(responseData)

            if (response.code === "0000") {
                setShowSuccess(true)
                setShowSuccessMessage("저장 되었습니다.")
            }
        }
        catch (e) {
            setShowErrorMessage("관리자에게 문의바랍니다.");
            setShowError(true)
        }
    };

    // 삭제 버튼 클릭 시 체크된 항목 서버로 전송
    const handleDelete = async () => {
        try {

            if (checkedItems.length < 1) {
                setShowErrorMessage("행을 선택해주세요.")
                setShowError(true)
                return
            }
            const response = await postCompleteWasteDelete({
                idx: checkedItems
            })
            if (response.code === "0000") {
                setShowSuccess("삭제 되었습니다.")
                setShowSuccess(true)
            }
        } catch (e) {
            setShowErrorMessage("삭제 실패 관리자에게 문의바랍니다.")
            setShowError(true)
        }
    };

    const handleSelectAll = () => {
        if (isAllChecked) {
            // 전체 해제 시 상태 초기화
            setCheckedItems([]);
        } else {
            // 전체 선택 시 모든 idx 저장
            const allIds = responseData.map((item) => item.idx);
            setCheckedItems(allIds);
        }
        setIsAllChecked(!isAllChecked); // 전체 선택 상태 업데이트
    };

    const handleItemCheck = (idx) => {

        if (checkedItems.includes(idx)) {
            // 이미 선택된 항목이면 해당 idx를 제거
            setCheckedItems(checkedItems.filter((item) => item !== idx));
        } else {
            // 선택되지 않은 항목이면 idx 추가
            setCheckedItems([...checkedItems, idx]);
        }
    };

    const getStartIndex = (pageNum) => {
        return (pageNum - 1) * pageNation.rowCnt;
    };

    const startIndex = getStartIndex(pageNation.pageNum);
    return (
        <React.Fragment>
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_data">
                                <div className="card-header">
                                    완료 계약서
                                </div>
                                <div className="card-body">
                                    <div className="card_top_with_btns mb-13px">
                                        <h6 className="table_stats">계약체결완료 목록 (<span>{total}</span> 건)</h6>
                                        {adminCheck && (
                                            <div className="card_table_btntop">
                                                <button className="btn btn-primary"
                                                    onClick={handlerSave}
                                                >저장</button>
                                                <button className="btn btn-outline-danger ms-10px"
                                                    onClick={handleDelete}
                                                >삭제</button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="table_scroll">
                                        <table className="table table-bordered my_table xmb-55px">
                                            <thead>
                                                <tr>
                                                    {adminCheck && (
                                                    <th style={{ width: "5.61%" }} className="text-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={isAllChecked} // 전체 선택 여부에 따라 체크 상태 변화
                                                            onChange={handleSelectAll} // 전체 선택 핸들러
                                                        />
                                                    </th>
                                                        )}
                                                    <th style={{ width: "5.61%" }}>번호</th>
                                                    <th style={{ width: "42.6%" }}>공사 명</th>
                                                    <th style={{ width: "9.38%" }}>체결 완료일</th>
                                                    <th style={{ width: "9.7%" }}>제출처</th>
                                                    <th style={{ width: "9.7%" }}>제출가능 여부</th>
                                                    <th style={{ width: "8.04%" }}>제출여부</th>
                                                    <th>문서확인</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {responseData.length > 0 ? (
                                                    responseData.map((item, index) => (
                                                        <tr
                                                            key={item.idx}
                                                            // className="clickable"
                                                            // onClick={handlerSubmit}
                                                        >
                                                            {adminCheck && (
                                                            <td className="text-center">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={checkedItems.includes(item.idx)} // 개별 체크박스 상태
                                                                    onChange={() => handleItemCheck(item.idx)} // 개별 체크박스 핸들러
                                                                />
                                                            </td>)}
                                                            <td className="text-center">{startIndex+index + 1}</td>
                                                            <td className="text-center">{item.constructionText}</td>
                                                            <td className="text-center">{item.completeDate ? item.completeDate : "-"}</td>
                                                            <td className="text-center">
                                                                {adminCheck ? (
                                                                    <input type="text" className="form-control"
                                                                        value={item.office}
                                                                           onClick={(e) => e.stopPropagation()}
                                                                        onChange={(e) => handleOfficeChange(index, e.target.value, 'office')} // onChange 핸들러
                                                                    />
                                                                ) : (
                                                                    item.office
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                {adminCheck ? (
                                                                    <input type="text" className="form-control"
                                                                           onClick={(e) => e.stopPropagation()}
                                                                        value={item.submitCheck}
                                                                        onChange={(e) => handleOfficeChange(index, e.target.value, 'submitCheck')} // onChange 핸들러
                                                                    />
                                                                ) : (
                                                                    item.submitCheck
                                                                )}
                                                            </td>
                                                            <td className="text-center">{item.wasteCompleteCheck}</td>
                                                            <td className="text-center">
                                                                <button className="btn btn-primary btn_tbl"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // 클릭 이벤트 전파 방지
                                                                            handlerSubmit(item); // 원래의 함수 실행
                                                                        }}
                                                                >신고서 제출하기</button>
                                                            </td>
                                                        </tr>
                                                    )))
                                                    : (
                                                        <tr className="no-data-message">
                                                            <td className="text-center" colSpan={8}>조회된 데이터가 없습니다.</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination_container mb-55px">
                                        <ul>
                                            {responseData.length > 0 &&
                                                // 페이지가 존재하는 경우 페이지네이션 렌더링
                                                <>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                            alt="First Page"
                                                            onClick={() => handlePageChange(1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                            alt="Previous Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>

                                                    {/* 페이지 번호 배열 생성 */}
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li key={index}
                                                            onClick={() => handlePageChange(index + 1)}
                                                            className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                        >
                                                            {index + 1} {/* 페이지 번호 표시 */}
                                                        </li>
                                                    ))}

                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                            alt="Next Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum < totalPages ? pageNation.pageNum + 1 : totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                            alt="Last Page"
                                                            onClick={() => handlePageChange(totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <h5>실패</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>성공</h5>
                        <p>{showSuccessMessage}</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                    </Modal.Body>
                </Modal>
                <Modal
                    className="modal_alert1"
                    show={showWait}
                    centered
                    backdrop="static"  // 배경 클릭 시 닫히지 않도록 설정
                    keyboard={false}   // 키보드로 닫을 수 없도록 설정
                    >
                    <Modal.Body className="modal_body">
                        <img
                        className="alert_icon1"
                        src={process.env.PUBLIC_URL + "/images/alert_icon1.png"}
                        alt=""
                        />
                        <h5>제출 중입니다.
                            {/*{position}*/}
                        </h5>
                        <p>잠시만 기다려주세요.</p>
                    </Modal.Body>
                    </Modal>
                <LoadingSpinner isLoading={isLoading} text="데이터 처리중입니다. 30초~1분정도 예상됩니다." /> {/* 로딩 스피너 */}
            </div>
        </React.Fragment>
    );
}