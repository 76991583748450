import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useRef, useState} from "react";
import {scrollToTop} from "../../api/utils/scrollToTop";
import {
    postCoatingCreate,
    postCoatingSearch,
    postCoatingUpload,
    postContractEmailSend,
    postWasteSearch
} from "../../api/information";
import {
    postDeliveryRegistrationBase,
    postDeliveryRegistrationSave,
    postRegistrationSave,
    postRegistrationSearch
} from "../../api/delivery";
import {renderToString} from "react-dom/server";
import {ContractEmailTemplate} from "../information/request/ContractEmailTemplate";
import {DeliveryEmailTemplate} from "./DeliveryEmailTemplate";
import { MobileHeader } from "../common/MobileHeader";
import { Sidebar } from "../common/Sidebar";
import { PcHeader } from "../common/PcHeader";
export const RegisterDeliveryContractSecond = ({data,goBack}) => {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [selectedCoatingOption, setSelectedCoatingOption] = useState('electionCoating');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [selectedOption, setSelectedOption] = useState('email');
    const [showCoating, setShowCoating] = useState("")
    const [electionCoating,setElectionCoatingReq] = useState("")
    const [registerReq, setRegisterReq] = useState({
        idx: "", // delivery_registration의 기본키
        companyNumber: "", // 사업자 등록번호
        name: "", // 상호
        ceoName: "", // 대표자
        companyKind: "", // 업종
        ceoCellphone: "", // 연락처
        companyAddress: "", // 주소
        constructionAddress: "", // 현장주소
        constructionText: "", // 공사명
        constructionStartDate: "", // 공사기간 앞
        constructionEndDate: "", // 공사기간 뒤
        payText: "", // 결제조건
        useCheck: "도로공사용", // 사용용도 (1) 도로공사 (2) 되메우기 및 뒷채움용
        significant: "", // 주의사항 및 특이사항
        email: "", // 이메일
        cellphone: "", // 연락처
        deliveryKinds: [], // 폐기물 종류 단위 수량을 위한 리스트
        userIdx: "",
        type:"email",
        selectedCoatingOption : ""
    });
    const [loadUserInfo, setLoadUserInfo] = useState({
        companyName :"",
        email :"",
    })
    const [rows, setRows] = useState([
        {
            id: 1,
            deliveryRegistrationIdx: "",
            kindName: "", //납품명
            standard: "", //규격
            unit: "대", // 단위
            arrivePay: "0",
            anotherPay: "0",
            kindCount: "0",
            sum: "0",
            remark: "",
            significant: "",
        }
    ]);

    const handleCoatingClose = () => setShowCoating(false);

    const [coatingReq, setCoatingReq] = useState({
        type : "coating",
    })

    const [companyName, setCompanyName] = useState("")

    const [companyNumber, setCompanyNumber] = useState("")
    const companyNumberRef = useRef(null)

    const [companyNumber2, setCompanyNumber2] = useState("")
    const companyNumberRef2 = useRef(null)


    const [companyNumber3, setCompanyNumber3] = useState("")
    const companyNumberRef3 = useRef(null)

    const nameRef = useRef(null)
    const ceoNameRef = useRef(null)
    const companyKindRef = useRef(null)
    const companyAddressRef = useRef(null)
    const ceoCellphoneRef = useRef(null)
    const [complete, setComplete] = useState(false)
    const [deliveryKinds, setDeliveryKinds] = useState([]);
    useEffect(() => {
        scrollToTop();
        userBaseInfo()
        // 데이터가 정의되어 있을 때만 업데이트
        if (data.registerReq) {
            const updatedData = data.registerReq;
            setRegisterReq(prevState => ({
                ...prevState,
                name: updatedData.name || prevState.name,
                ceoName: updatedData.ceoName || prevState.ceoName,
                companyNumber: updatedData.companyNumber || prevState.companyNumber,
                companyKind: updatedData.companyKind || prevState.companyKind,
                ceoCellphone: updatedData.ceoCellphone || prevState.ceoCellphone,
                companyAddress: updatedData.companyAddress || prevState.companyAddress,
                constructionAddress: updatedData.constructionAddress || prevState.constructionAddress,
                constructionText: updatedData.constructionText || prevState.constructionText,
                constructionStartDate: updatedData.constructionStartDate || prevState.constructionStartDate,
                constructionEndDate: updatedData.constructionEndDate || prevState.constructionEndDate,
                payText: updatedData.payText || prevState.payText,
                useCheck: updatedData.useCheck || prevState.useCheck,
                significant: updatedData.significant || prevState.significant,
                email: updatedData.email || prevState.email,
                cellphone: updatedData.cellphone || prevState.cellphone,
                type: updatedData.type || prevState.type,
                deliveryKinds: updatedData.deliveryKinds || prevState.deliveryKinds,
            }));
            if (Array.isArray(updatedData.deliveryKinds) && updatedData.deliveryKinds.length > 0) {
                setRows(updatedData.deliveryKinds.map((item, index) => ({
                    id: index + 1, // id는 1부터 시작
                    kindName: item.kindName || "", // kindName을 kind로
                    standard: item.standard || "", // unit 그대로 사용
                    unit: item.unit || "", // unit 그대로 사용
                    arrivePay: item.arrivePay || "", // unit 그대로 사용
                    anotherPay: item.anotherPay || "", // unit 그대로 사용
                    kindCount: item.kindCount || "", // unit 그대로 사용
                    sum: item.sum || "", // unit 그대로 사용
                    remark: item.remark || 0, // kindCount 그대로
                    significant: item.significant || 0, // kindCount 그대로
                    checked: false // 체크박스는 초기값 false로 설정
                })));
                setDeliveryKinds(updatedData.deliveryKinds);
            } else {
                // wasteKinds가 비어있거나 정의되지 않은 경우 기본값 설정
                setRows([]);
                setDeliveryKinds([]);
            }
        }
    }, [data]);

    const userBaseInfo = async () => {

        try{

            const response = await postDeliveryRegistrationBase()

            if (response.result && response.result.result) {
                // 기존 상태를 유지하면서 새로운 정보로 업데이트
                setLoadUserInfo((prevState) => ({
                    ...prevState, // 이전 상태를 복사
                    ...response.result.result, // 새로운 정보로 업데이트
                }));
            }
        }
        catch (e){
            setShowErrorMessage("유저정보 불러오기 오류발생")
            setShowError(true)
        }
    }
    const companyNumberFirstHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        setCompanyNumber(e);
        if (e.length === 3) {
            companyNumberRef2.current.focus()
        }

    }

    const companyNumberSecondHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        if (e.length === 2) {
            companyNumberRef3.current.focus()
        }
        setCompanyNumber2(e);
    }

    const companyNumberThirdHandler = (e) => {

        e = e.replace(/\D/g, '');
        setCompanyNumber3(e);
    }

    const companyNumberFindCheck = async () => {

        try {

            const fullCompanyNumber = companyNumber + companyNumber2 + companyNumber3

            if (fullCompanyNumber.length !== 10) {
                setShowErrorMessage("사업자 등록번호 형식이 아닙니다.")
                setShowError(true)
                return
            }

            const updatedRegisterReq = {
                ...registerReq, //기존 필드 유지
                companyNumber: fullCompanyNumber
            };
            const response = await postRegistrationSearch(updatedRegisterReq)
            if (response.code === "0000") {
                if (response.result && response.result.length > 0) {
                    const result = response.result[0];
                    setRegisterReq(prevState => ({
                        ...prevState,
                        name: result.companyName || prevState.name || "",
                        ceoName: result.ceoName || prevState.ceoName || "",
                        ceoCellphone: result.ceoCellphone || prevState.ceoCellphone || "",
                        companyKind: result.companyKind || prevState.companyKind || "",
                        companyAddress: result.companyAddress || prevState.companyAddress || ""
                    }));
                }
                else{
                    setShowErrorMessage("사업자번호의 정보가 없습니다.");
                    setShowError(true);
                }
            }
        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }

    const handleErrorClose = () => {
        setShowError(false)
    }

    const handleSuccessClose = () => {
        setShowSuccess(false)
        setShowCoating(false)
        setComplete(true)
        scrollToTop()
    }
    const handleGoBack = () => {

        goBack(registerReq);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        setRegisterReq({
            ...registerReq, // 기존 req 상태 복사
            type: e.target.value // email 필드 업데이트
        });


    };

    const contractHandler = (e, part) => {
        const value = e.target.value;

        setRegisterReq((prevState) => {
            if (part === 'email') {
                // 이메일 업데이트
                return {
                    ...prevState,
                    email: value
                };
            } else if (part === 'cellphone') {
                // 전화번호 업데이트 (숫자만 남기기)
                const newValue = value.replace(/\D/g, '');
                return {
                    ...prevState,
                    cellphone: newValue
                };
            }
            return prevState; // 아무 변화가 없는 경우 기존 상태 반환
        });
    };

    const handleSubmit = async () => {

        if(complete){
            setShowErrorMessage("이미 전송된 사항입니다.")
            setShowError(true)
            return
        }

        const fullCompanyNumber = companyNumber + companyNumber2 + companyNumber3

        if (fullCompanyNumber.length !== 10) {
            setShowErrorMessage("사업자 등록번호 형식이 아닙니다.")
            setShowError(true)
            return
        }

        const updatedRegisterReq = {
            ...registerReq, //기존 필드 유지
            companyNumber: fullCompanyNumber
        };


        const errorMessages = {
            name: "상호를 입력해주세요.",
            ceoName: "대표자를 입력해주세요.",
            companyKind: "업종을 입력해주세요.",
            ceoCellphone: "연락처(사업자)를 입력해주세요.",
            companyAddress: "주소(사업자등록증상 주소)를 입력해주세요.",
        };

        for (const [key, value] of Object.entries(updatedRegisterReq)) {
            // errorMessages에 해당 key가 존재할 때만 검사
            if (key in errorMessages && value === "") {
                // key에 해당하는 에러 메시지를 errorMessages 객체에서 가져옴
                const errorMessage = errorMessages[key];
                if (errorMessage) {
                    setShowErrorMessage(errorMessage);
                    setShowError(true);
                }
                return;
            }
        }



        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(registerReq.type==="email"){
            if(!emailRegex.test(registerReq.email)){
                setShowErrorMessage("이메일 형식이 올바르지 않습니다.")
                setShowError(true)
                return
            }
        }
        else {
            if (registerReq.ceoCellphone.length !== 10 && registerReq.cellphone.length !== 11) {
                // setShowErrorMessage("연락처 형식이 올바르지 않습니다.")
                setShowErrorMessage("연락처 전송은 서비스 준비 중입니다.")
                setShowError(true)
                return
            }
        }

        try{
            const response = await postCoatingSearch()

            if(response.result.image){
                setElectionCoatingReq(response.result.image)
            }
        }

        catch (e){
            setShowErrorMessage("도장 불러오기 오류가 발생했습니다.")
            setShowError(true)
        }
        setShowCoating(true)

    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [createCoatingCheck, setCreateCoatingCheck] = useState(false)
    const [uploadCoatingCheck, setUploadCoatingCheck] = useState(false)
    const handleFileChange = (event) => {

        const files = event?.target?.files; // 선택된 파일들

        // 파일이 선택되지 않은 경우
        if (!files || files.length === 0) {
            return;
        }

        const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링

        if (validFiles.length === files.length) {
            setSelectedFile(Array.from(files));
            const file = validFiles[0]; // 첫 번째 파일로 미리보기 생성
            const reader = new FileReader();

            reader.onloadend = () => {
                setFileUrl(reader.result); // Base64 문자열로 설정
            };
            reader.readAsDataURL(file); // 파일을 Data URL로 읽음
            setUploadCoatingCheck(true)
            setSelectedCoatingOption("upload")
        }else {
            setShowErrorMessage("이미지 파일 및 PDF만 가능합니다.")
            setShowError(true)
            setUploadCoatingCheck(false)
        }
    };

    const isValidFileType = (file) => {
        const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };

    const handleUploadClick = () => {
        document.getElementById('fileInput').click();
    };

    const coatingHandlerSubmit = async () => {

        const fullCompanyNumber = companyNumber + companyNumber2 + companyNumber3

        if (fullCompanyNumber.length !== 10) {
            setShowErrorMessage("사업자 등록번호 형식이 아닙니다.")
            setShowError(true)
            return
        }

        const updatedRegisterReq = {
            ...registerReq, //기존 필드 유지
            companyNumber: fullCompanyNumber,
            selectedCoatingOption : selectedCoatingOption,
        };


        if(coatingReq.type==="coating"){
            if(electionCoating===""){
                if(!createCoatingCheck){
                    setShowErrorMessage("생성된 도장이 없습니다.")
                    setShowError(true)
                    return
                }
            }
        }
        else{
            if(!uploadCoatingCheck){
                setShowErrorMessage("업로드된 도장이 없습니다.")
                setShowError(true)
                return
            }

            try{
                const response = await postCoatingUpload({
                    file : selectedFile
                })
            }
            catch (e){
                setShowErrorMessage("업로드 도장 저장 오류입니다.")
                setShowError(true)
                setFileUrl("")
            }
        }

        // 현재 시점으로부터 30분 뒤의 시간 계산
        const currentDate = new Date();
        const dateTimeAfter30Minutes = new Date(currentDate.getTime() + 30 * 60000); // 30분을 밀리초로 계산

        // 년, 월, 일, 시, 분, 초를 개별적으로 추출하고 2자리 숫자로 맞추기
        const year = dateTimeAfter30Minutes.getFullYear();
        const month = String(dateTimeAfter30Minutes.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줌
        const day = String(dateTimeAfter30Minutes.getDate()).padStart(2, '0');
        const hours = String(dateTimeAfter30Minutes.getHours()).padStart(2, '0');
        const minutes = String(dateTimeAfter30Minutes.getMinutes()).padStart(2, '0');
        const seconds = String(dateTimeAfter30Minutes.getSeconds()).padStart(2, '0');

         // "년-월-일 시:분:초" 형식으로 문자열 생성
         const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;


         const htmlString = renderToString(<DeliveryEmailTemplate
            constructionText={registerReq.constructionText}
             requestEndTime ={formattedDateTime}
             userCompanyName ={loadUserInfo}
             resEmail = {registerReq.email}
            registrationIdx={""}
         />);
         const updateEmailReq = ({...updatedRegisterReq,
             html: htmlString, requestEndTime:formattedDateTime })
         //도장 등록 정보 있는지 체크
         try{
             const response = await postDeliveryRegistrationSave(updateEmailReq)
             setShowSuccess(true)
         }
         catch (e){
             setShowError(true)
             setShowErrorMessage(e.message)
             setShowCoating(false)
             scrollToTop()
         }

    }

    const handleCoatingOptionChange = (e) => {
        setSelectedCoatingOption(e.target.value);
        setCoatingReq({
            ...coatingReq,
            type: e.target.value
        });
    };

    useEffect(() => {
        if (showCoating) {
            setSelectedCoatingOption('coating');
        }
    }, [showCoating]);

    const companyNameHandler =(e) => {
        setCompanyName(e)
    }
    const coatingCreateClick = async () => {
        try{

            const response = await postCoatingCreate({
                companyName : companyName
            })


            if(response.result.image){
                setElectionCoatingReq(response.result.image)
                setCreateCoatingCheck(true)
                setSelectedCoatingOption("coating")
            }
        }
        catch (e){
            setShowErrorMessage(e.message)
            setShowError(true)
            setCreateCoatingCheck(false)
        }
    }

    const onKeyDownHandler = (e, part) => {
        if (e.key === 'Enter') {
            e.preventDefault();   // 기본 동작 (예: 폼 제출)을 취소합니다.

            if (part === 'name') {
                ceoNameRef.current.focus()
            } else if (part === 'ceoName') {
                companyKindRef.current.focus();
            } else if (part === 'companyKind') {
                ceoCellphoneRef.current.focus();
            } else if (part === 'ceoCellphone') {
                companyAddressRef.current.focus();

            }
        }
    };

    const ceoCellPhoneHandler = (e, part) => {
        let value = e.target.value;

        // 숫자가 아닌 문자는 제거
        value = value.replace(/\D/g, '');

        setRegisterReq((prevState) => ({
            ...prevState,
            ceoCellphone: value,
        }))

    };
    return (
        <React.Fragment>
            <Modal className="modal_alert2" show={showCoating}  onHide={handleCoatingClose} centered size={"lg"}>
                <Modal.Header>도장 선택</Modal.Header>
                <Modal.Body>
                    <div className="input_row">
                        <input type="text" placeholder="업체명을 입력해주세요." className="form-control"
                               onChange={(e) => companyNameHandler(e.target.value)}
                        />
                    </div>
                    <div className="stamp_container">
                        <div className="stamp_box">
                            <div className="stamp_top">
                                <h5>선택한 도장</h5>
                                <button className="btn btn-outline-secondary w-90px"
                                        onClick={coatingCreateClick}
                                >도장 생성</button>
                            </div>
                            <div className="stamp_option">
                                <label>
                                    {electionCoating ? (
                                        <img src={`data:image/png;base64,${electionCoating}`} alt="선택한 도장" style={{ width: '95px', height: '92px' }} />
                                    ) : (
                                        <img src={process.env.PUBLIC_URL + "/images/img_stamp.png"} alt="" style={{ width: '95px', height: '92px' }}/>
                                    )}
                                    <input name="stamp" type="radio" className="form-input-radio"
                                           value="coating"
                                           checked={selectedCoatingOption === 'coating'}
                                           onChange={(e) => handleCoatingOptionChange(e)}/>
                                </label>
                            </div>
                        </div>
                        <div className="stamp_box">
                            <div className="stamp_top">
                                <h5>전자도장</h5>
                                <button className="btn btn-outline-secondary w-100px"
                                        onClick={handleUploadClick}
                                >도장 업로드</button>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>handleFileChange(e)}
                                    style={{ display: 'none' }} // 숨김 처리
                                />
                            </div>
                            <div className="stamp_option">
                                <label>
                                    {fileUrl ? ( // 미리보기 URL이 존재하면
                                        <img src={fileUrl} alt="미리보기" style={{ width: '95px', height: '92px' }} />
                                    ) : (
                                        <img src={process.env.PUBLIC_URL + "/images/img_stamp.png"} alt="" style={{ width: '95px', height: '92px' }}/>
                                    )}
                                    <input name="stamp" type="radio" className="form-input-radio"
                                           value="upload"
                                           checked={selectedCoatingOption === 'upload'}
                                           onChange={(e) => handleCoatingOptionChange(e)}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary btn_modal_footer"
                            onClick={coatingHandlerSubmit}
                    >선택</button>
                </Modal.Footer>
            </Modal>
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="납품관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_main">
                                <div className="card-header">
                                    납품 계약서 등록
                                </div>
                                <div className="card-body">
                                    <div className="stepper_wrapper lg">
                                        <div className="stepper_line"></div>
                                        <ul>
                                            <li className="active">
                                                <div className="stepper_dot dot_1"></div>
                                                <div className="stepper_text">공사내용 및 납품 물품 목록</div>
                                            </li>
                                            <li className="active">
                                                <div className="stepper_dot dot_2"></div>
                                                <div className="stepper_text">발주처 및 공사내용 입력</div>
                                            </li>
                                            <li>
                                                <div className="stepper_dot dot_3"></div>
                                                <div className="stepper_text">전송완료</div>
                                            </li>
                                        </ul>
                                    </div>

                                    <h3 className="table_heading">3. 사업자등록번호 검색</h3>
                                    <table className="table table-bordered card_table">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "12.82%" }}>사업자 등록번호</th>
                                                <td>
                                                    <div className="flex-center-between">
                                                        <div class="frm_field input_phone input_phone_tbl">
                                                            <input placeholder="" type="text" className="form-control"
                                                                   ref={companyNumberRef}
                                                                   onChange={(e) => companyNumberFirstHandler(e.target.value)}
                                                                // onClick={companyNumberClick}
                                                                   value={companyNumber}
                                                                   maxLength={3}
                                                            />
                                                            <span className="txt_range">-</span>
                                                            <input placeholder="" type="text" className="form-control"
                                                                   ref={companyNumberRef2}
                                                                   onChange={(e) => companyNumberSecondHandler(e.target.value)}
                                                                // onClick={companyNumberClick2}
                                                                   maxLength={2}
                                                                   value={companyNumber2}
                                                            />
                                                            <span className="txt_range">-</span>
                                                            <input placeholder="" type="text" className="form-control"
                                                                   ref={companyNumberRef3}
                                                                   onChange={(e) => companyNumberThirdHandler(e.target.value)}
                                                                // onClick={companyNumberClick3}
                                                                   maxLength={5}
                                                                   value={companyNumber3}
                                                            />
                                                        </div>
                                                        <button className="btn btn-secondary"
                                                                onClick={companyNumberFindCheck}
                                                        >검색</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3 className="table_heading">4. 업체 정보입력</h3>
                                    <table className="table table-bordered card_table">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "12.82%" }}>상호</th>
                                                <td>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.name || ""}
                                                           onChange={(e) =>
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   name: e.target.value,
                                                               }))
                                                           }
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'name')}
                                                           ref={nameRef}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>대표자</th>
                                                <td>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.ceoName || ""}
                                                           onChange={(e) =>
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   ceoName: e.target.value,
                                                               }))
                                                           }
                                                           ref={ceoNameRef}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'ceoName')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>업종</th>
                                                <td>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.companyKind || ""}
                                                           onChange={(e) =>
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   companyKind: e.target.value,
                                                               }))
                                                           }
                                                           ref={companyKindRef}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'companyKind')}
                                                    />

                                                </td>
                                            </tr>
                                            <tr>
                                                <th>연락처(사업자)</th>
                                                <td>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.ceoCellphone || ""}
                                                           onChange={(e) => ceoCellPhoneHandler(e, 'part1')}
                                                           ref={ceoCellphoneRef}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'ceoCellphone')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>주소</th>
                                                <td>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.companyAddress || ""}
                                                           onChange={(e) =>
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   companyAddress: e.target.value,
                                                               }))
                                                           }
                                                           ref={companyAddressRef}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'companyAddress')}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="flex-center-between mb-5px">
                                        <h3 className="table_heading">5. 전자계약진행</h3>
                                        {/*<div className="d-flex">*/}
                                        {/*    <button className="btn btn-primary">전자계약진행</button>*/}
                                        {/*    <button className="btn btn-secondary ms-10px">인쇄하기</button>*/}
                                        {/*</div>*/}
                                    </div>
                                    <table className="table table-bordered card_table">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "12.82%" }}>이메일, SMS</th>
                                                <td>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input mt-10px"
                                                            type="radio"
                                                            name="inlineRadioOptions"
                                                            id="inlineRadio1"
                                                            value="email"
                                                            checked={selectedOption === 'email'}
                                                            onChange={handleOptionChange}
                                                        />
                                                        <div className="d-flex align-items-center">
                                                            <label className="form-check-label me-10px" for="inlineRadio1">이메일</label>
                                                            <input placeholder="" type="text" className="form-control"
                                                                   value={registerReq.email}
                                                                   onChange={(e) =>contractHandler(e,'email')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        {/*<input*/}
                                                        {/*    className="form-check-input mt-10px"*/}
                                                        {/*    type="radio"*/}
                                                        {/*    name="inlineRadioOptions"*/}
                                                        {/*    id="inlineRadio2"*/}
                                                        {/*    value="cellphone"*/}
                                                        {/*    checked={selectedOption === 'cellphone'}*/}
                                                        {/*    onChange={handleOptionChange}*/}
                                                        {/*/>*/}
                                                        {/*<div className="d-flex align-items-center">*/}
                                                        {/*    <label className="form-check-label me-10px" for="inlineRadio2">연락처</label>*/}
                                                        {/*    <input placeholder="" type="text" className="form-control"*/}
                                                        {/*           value={registerReq.cellphone}*/}
                                                        {/*           onChange={(e) =>contractHandler(e,'cellphone')}*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer card_footer_btngrp_end">
                                    <button className="btn btn-outline-secondary btn_footer"
                                    onClick={handleGoBack}
                                    >뒤로가기</button>
                                    <button className="btn btn-primary btn_footer"
                                    onClick={handleSubmit}
                                    >전자계약진행</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                    <h5>인증 실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showSuccess} onHide={handleClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5 className="singleLine">{registerReq.type === 'email' ? registerReq.email : registerReq.cellphone}</h5>
                    <p>전송 되었습니다.</p>
                    {/*<p className="mb-40px">전달받은 기재사항내용을 <span className="text-primary">“최종확인완료”</span> 하였습니다.</p>*/}
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}