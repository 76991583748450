import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
export const MobileHeader = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.toggle('sb-sidenav-toggled', isSidebarOpen);
    }, [isSidebarOpen])

    const handleLogout = () => {
        Cookies.remove('accToken'); // accToken 쿠키 삭제
        const userName = sessionStorage.getItem('oneclick_userName');
        if (userName) {
            sessionStorage.removeItem('loginId')
            sessionStorage.removeItem('oneclick_userName');
        }
        navigate("/login")
    };

    return (
        <React.Fragment>
            <div className="header-mob">
                <a className="nav-link" onClick={() => {
                    // alert();
                    setIsSidebarOpen(!isSidebarOpen);
                }}><img src={process.env.PUBLIC_URL + "/images/icon_hamburger.png"} alt="" /></a>

                <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-header">
                        <img src={process.env.PUBLIC_URL + "/images/icon_mob_header.png"} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href={process.env.REACT_APP_SERVER_URL + "/mypage/info"}>마이페이지</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>로그아웃</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </React.Fragment>
    );
}