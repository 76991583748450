import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import {postArriveUserInfo, postRegistration, postWasteKindSearch} from "../../../api/information";
import AddressSearchPopup from '../../../api/utils/AddressPopup';
import { MobileHeader } from '../../common/MobileHeader';
import { Sidebar } from '../../common/Sidebar';
import { PcHeader } from '../../common/PcHeader';

export const InformationWasteManageSecond = ({ data, goBack }) => {
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");

    const location = useLocation();
    const navigate = useNavigate();

    const [wasteKindOptions, setWasteKindOptions] = useState([]); //폐기물 종류
    const handleShow = () => setShowSuccess(true);

    const handleClose = () => {

        setShowSuccess(false)
        navigate("/")
    }

    useEffect(() => {
        scrollToTop()
        console.log("Received data:", data);
        wasteKindSearch()

    }, []);

    const orderNameRef = useRef(null)
    const orderCeoNameRef = useRef(null)
    const orderCompanyAddressRef = useRef(null)
    const constructionNameRef = useRef(null)
    const constructionCeoNameRef = useRef(null)
    const constructionAddressRef = useRef(null)
    const constructionTextRef = useRef(null)
    const conStructionSiteAddressDetailRef = useRef(null)


    const [registerReq, setRegisterReq] = useState({
        informationSendIdx: "",
        name: "",
        ceoName: "",
        companyKind: "",
        ceoCellphone: "",
        taxEmail: "",
        companyAddress: "",
        allbaroName: "",
        allbaroCellphone: "",
        siteManagerCellphone: "",
        siteManagerName: "",
        companyNumber: "",
        orderName: "",
        orderCeoName: "",
        orderCompanyAddress: "",
        constructionName: "",
        constructionCeoName: "",
        constructionAddress: "",
        constructionText: "", //공사명
        constructionTextDetail: "", //공사명 상세 삭제
        constructionStartDate: "",
        constructionEndDate: "",
        constructionSiteAddress: "",
        constructionSiteAddressDetail: "",
        wasteKinds: [],
    })

    useEffect(() => {
        scrollToTop();
        console.log("Received data 체크:", data);

        // 데이터가 정의되어 있을 때만 업데이트
        if (data && data.updatedRegisterReq) {
            const updatedData = data.updatedRegisterReq;
            setRegisterReq(prevState => ({
                ...prevState,
                informationSendIdx: updatedData.informationSendIdx || prevState.informationSendIdx,
                name: updatedData.name || prevState.name,
                ceoName: updatedData.ceoName || prevState.ceoName,
                companyKind: updatedData.companyKind || prevState.companyKind,
                ceoCellphone: updatedData.ceoCellphone || prevState.ceoCellphone,
                taxEmail: updatedData.taxEmail || prevState.taxEmail,
                companyAddress: updatedData.companyAddress || prevState.companyAddress,
                allbaroName: updatedData.allbaroName || prevState.allbaroName,
                allbaroCellphone: updatedData.allbaroCellphone || prevState.allbaroCellphone,
                siteManagerCellphone: updatedData.siteManagerCellphone || prevState.siteManagerCellphone,
                siteManagerName: updatedData.siteManagerName || prevState.siteManagerName,
                companyNumber: updatedData.companyNumber || prevState.companyNumber,
                // 추가 필드에 대해서도 동일하게 처리
                orderName: updatedData.orderName || prevState.orderName,
                orderCeoName: updatedData.orderCeoName || prevState.orderCeoName,
                orderCompanyAddress: updatedData.orderCompanyAddress || prevState.orderCompanyAddress,
                constructionName: updatedData.constructionName || prevState.constructionName,
                constructionCeoName: updatedData.constructionCeoName || prevState.constructionCeoName,
                constructionAddress: updatedData.constructionAddress || prevState.constructionAddress,
                constructionText: updatedData.constructionText || prevState.constructionText,
                constructionTextDetail: updatedData.constructionTextDetail || prevState.constructionTextDetail,
                constructionStartDate: updatedData.constructionStartDate || prevState.constructionStartDate,
                constructionEndDate: updatedData.constructionEndDate || prevState.constructionEndDate,
                constructionSiteAddress: updatedData.constructionSiteAddress || prevState.constructionSiteAddress,
                constructionSiteAddressDetail: updatedData.constructionSiteAddressDetail || prevState.constructionSiteAddressDetail,
                // wasteKinds: updatedData.wasteKinds || prevState.wasteKinds,
            }));
            if (Array.isArray(updatedData.wasteKinds) && updatedData.wasteKinds.length > 0) {
                setRows(updatedData.wasteKinds.map((item, index) => ({
                    id: index + 1, // id는 1부터 시작
                    kind: item.kindName || "폐콘크리트", // kindName을 kind로
                    unit: item.unit || "톤", // unit 그대로 사용
                    kindCount: item.kindCount || 0, // kindCount 그대로
                    checked: false // 체크박스는 초기값 false로 설정
                })));

                // wasteKinds 배열도 업데이트
                setWasteKinds(updatedData.wasteKinds);
            } else {
                // wasteKinds가 비어있거나 정의되지 않은 경우 기본값 설정
                setRows([]);
                setWasteKinds([]);
            }
        }

        wasteKindSearch();
    }, [data]);

    const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
    const [address, setAddress] = useState('');
    const handleErrorClose = () => {
        setShowError(false)
    }


    const wasteKindSearch = async () => {

        try {

            const response = await postWasteKindSearch()

            console.log(response)
            setWasteKindOptions(response.result);
        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }



    const onKeyDownHandler = (e, part) => {
        if (e.key === 'Enter') {
            e.preventDefault();   // 기본 동작 (예: 폼 제출)을 취소합니다.

            if (part === 'orderName') {
                orderCeoNameRef.current.focus()
            } else if (part === 'orderCeoName') {
                orderCompanyAddressRef.current.focus();
            } else if (part === 'orderCompanyAddress') {


                constructionTextRef.current.focus();
            }
            // else if (part === 'constructionName') {
            //     constructionCeoNameRef.current.focus();
            // } else if (part === 'constructionCeoName') {
            //     constructionAddressRef.current.focus();
            // } else if (part === 'constructionAddress') {
            //     constructionTextRef.current.focus();
            // }
            else if (part === 'constructionText') {
                conStructionSiteAddressDetailRef.current.focus();
            }


        }
    }

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        // 오늘 날짜를 시작 날짜로 설정
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1); // 내일 날짜 설정

        // yyyy-mm-dd 형식으로 날짜 변환
        const formattedToday = today.toISOString().split("T")[0];
        const formattedTomorrow = tomorrow.toISOString().split("T")[0];

        // 시작 날짜는 오늘, 종료 날짜는 내일로 설정 (최초 로드 시에만)

        setStartDate(formattedToday);
        setEndDate(formattedTomorrow);

        setRegisterReq((prev) => ({
            ...prev,
            constructionStartDate: formattedToday, // 선택된 모든 유효한 파일을 배열로 설정
            constructionEndDate: formattedTomorrow,
        }));
    }, []);

    // 시작 날짜가 변경될 때
    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        console.log(newStartDate)
        setStartDate(newStartDate);
        setRegisterReq((prev) => ({
            ...prev,
            constructionStartDate: newStartDate,
        }));

    };

    // 종료 날짜 변경 시, 시작 날짜보다 하루 이상 차이나지 않도록 제한
    const handleEndDateChange = (e) => {
        const selectedEndDate = new Date(e.target.value);
        const selectedStartDate = new Date(startDate);

        if (selectedEndDate >= selectedStartDate) {
            setEndDate(e.target.value);
        } else {
            setShowError(true)
            setShowErrorMessage("종료날짜는 시작 날짜 이전일 수없습니다.")
        }

        setRegisterReq((prev) => ({
            ...prev,
            constructionEndDate: e.target.value,
        }));
    };




    // 주소 검색 팝업 열기
    const handlePostcode = () => {
        setIsPostcodeOpen(true);
    };

    // 주소 선택 시 호출되는 함수
    const handleCompletePost = (data) => {
        const fullAddress = data.address; // 전체 주소

        console.log(fullAddress)
        setAddress(fullAddress);
        setRegisterReq((prev) => ({
            ...prev,
            constructionSiteAddress: fullAddress,
        }));
        setIsPostcodeOpen(false); // 팝업 닫기
    };

    const handleClosePopup = () => {
        setIsPostcodeOpen(false); // 팝업 닫기
        conStructionSiteAddressDetailRef.current.focus()
    };


    const handleSubmit = () => {
        // formData를 부모 컴포넌트로 넘기면서 goBack 호출
        goBack(registerReq);
    };


    const [wasteKinds, setWasteKinds] = useState([]);

    const [rows, setRows] = useState([{ id: 1, kind: "폐콘크리트", unit: "톤", kindCount: 0 }]);

    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            kind: "",
            unit: "톤",
            kindCount: 0 };
        setRows((prevRows) => [...prevRows, newRow]);
        // setWasteKinds((prevWasteKinds) => [
        //     ...prevWasteKinds,
        //     { kindName: "", unit: "톤", kindCount: 0 }
        // ]);
    };

    // rows가 변경될 때마다 wasteKinds 업데이트
    useEffect(() => {
        // wasteKinds를 rows의 수에 맞게 업데이트
        // const newWasteKinds = rows.map((row) => ({
        //     kindName: row.kind || "폐콘크리트",
        //     unit: row.unit || "톤",
        //     kindCount: row.kindCount || 0,
        // }));

        const newWasteKinds = rows.map((row, index) => {

            return {
                kindName: row.kind || wasteKinds[index]?.kindName || "",
                unit: row.unit || wasteKinds[index]?.unit || "",
                kindCount: row.kindCount !== undefined ? row.kindCount : (wasteKinds[index]?.kindCount || 0),
            };
        });

        setWasteKinds(newWasteKinds);
    }, [rows]); // rows가 변경될 때마다 실행


    useEffect(() => {
        // wasteKinds가 변경될 때마다 registerReq 업데이트
        setRegisterReq((prevRegisterReq) => ({
            ...prevRegisterReq,
            wasteKinds: wasteKinds, // wasteKinds 배열 추가
        }));
    }, [wasteKinds]); // wasteKinds가 변경될 때마다 실행


    const handleDeleteRow = () => {
        const updatedRows = rows.filter((row) => !row.checked); // 체크된 행 삭제
        const updatedWasteKinds = wasteKinds.filter((_, index) => !rows[index].checked); // wasteKinds에서 체크된 행 삭제

        // 최소 1행이 남도록 확인
        if (updatedRows.length === 0) {
            // 최소 1행이 필요하므로 마지막 행을 유지하거나 다른 로직을 수행
            setShowErrorMessage("최소 1행은 필수입니다.")
            setShowError(true)
            return; // 함수 종료
        }

        // 인덱스 재설정
        const reindexedRows = updatedRows.map((row, index) => ({
            ...row,
            id: index + 1, // 인덱스를 1부터 시작하도록 설정
        }));

        // 상태 업데이트
        setRows(reindexedRows);
        setWasteKinds(updatedWasteKinds);
    };

    // 선택 변경 처리
    const handleChange = (index, field, value) => {
        setWasteKinds((prevWasteKinds) => {
            const updatedWasteKinds = [...prevWasteKinds];
            // 선택한 필드에 따라 업데이트
            switch (field) {
                case "kind":
                    updatedWasteKinds[index].kindName = value; // kindName 업데이트
                    break;
                case "unit":
                    updatedWasteKinds[index].unit = value; // unit 업데이트
                    break;
                case "kindCount":
                    updatedWasteKinds[index].kindCount = value; // kindCount 업데이트
                    break;
                default:
                    break;
            }


            // rows 업데이트
            setRows((prevRows) => {
                const updatedRows = [...prevRows];
                updatedRows[index] = {
                    ...updatedRows[index],
                    kind: updatedWasteKinds[index].kindName,
                    unit: updatedWasteKinds[index].unit,
                    kindCount: updatedWasteKinds[index].kindCount,
                };
                return updatedRows;
            });

            return updatedWasteKinds; // 업데이트된 상태 반환
        });
    };

    const handleSave = async () => {
        try {
            console.log("레지스터" + registerReq)
            console.log("레지스터22", JSON.stringify(registerReq, null, 2));

            console.log(wasteKinds); // 모든 행의 wasteKinds를 리스트로 출력


            const errorMessages = {
                orderName: "발주처 상호를 입력해주세요.",
                orderCeoName: "발주처 대표자를 입력해주세요.",
                orderCompanyAddress: "발주처 주소를 입력해주세요.",
                // constructionName: "공사내용 상호를 입력해주세요.",
                // constructionCeoName: "공사내용 대표자를 입력해주세요.",
                // constructionAddress: "공사내용 주소(사업자등록증상 주소)를 입력해주세요.",
                constructionText: "공사명을 입력해주세요.",
                constructionStartDate: "공사기간을 입력해주세요.",
                constructionEndDate: "공사기간을 입력해주세요.",
                constructionSiteAddress: "현장 주소를 입력해주세요.",
                // constructionSiteAddressDetail: "현장 주소 상세를 입력해주세요.",
            };

            for (const [key, value] of Object.entries(registerReq)) {
                if (key in errorMessages && value === "") {
                    const errorMessage = errorMessages[key];
                    if (errorMessage) {
                        setShowErrorMessage(errorMessage);
                        setShowError(true);
                    }
                    return;
                }
            }

            const { constructionStartDate, constructionEndDate } = registerReq;
            // 날짜 비교 로직 추가
            if (new Date(constructionStartDate) > new Date(constructionEndDate)) {
                setShowErrorMessage("공사 시작일이 종료일보다 늦을 수 없습니다.");
                setShowError(true);
                return;
            }

            if (rows.length < 1) {
                setShowErrorMessage("폐기물 목록은 필수입니다.");
                setShowError(true);
                return;
            }
            
            for (let row of wasteKinds) {
                console.log(row.kindCount)
                if (row.kindCount < 1) {
                    setShowErrorMessage("모든 행의 수량은 1이상 입니다.");
                    setShowError(true);
                    return;
                }
            }


            const response = await postRegistration(registerReq)

            console.log(response)

            if (response.code === "0000") {
                setShowSuccess(true)
            }



        } catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    };
    // 체크박스 상태 변경 처리
    const handleCheckboxChange = (index) => {
        setRows((prevRows) => {
            return prevRows.map((row, idx) =>
                idx === index ? { ...row, checked: !row.checked } : row // 체크 상태 반전
            );
        });
    };




    return (
        <React.Fragment>
            <Modal className="modal_alert1" show={showSuccess} onHide={handleClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>전송완료</h5>
                    <p>작성하신 정보를 원클릭 플랫폼으로 전달하였습니다.<br />
                        감사합니다.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleClose}>확인</button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                    <h5>실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_main">
                                <div className="card-header">
                                    기재사항 등록
                                </div>
                                <div className="card-body">
                                    <div className="stepper_wrapper lg">
                                        <div className="stepper_line"></div>
                                        <ul>
                                            <li className="active">
                                                <div className="stepper_dot dot_1"></div>
                                                <div className="stepper_text">배출업체정보 입력</div>
                                            </li>
                                            <li className="active">
                                                <div className="stepper_dot dot_2"></div>
                                                <div className="stepper_text">발주처 및 공사내용 입력</div>
                                            </li>
                                            <li>
                                                <div className="stepper_dot dot_3"></div>
                                                <div className="stepper_text">전송완료</div>
                                            </li>
                                        </ul>
                                    </div>

                                    <h3 className="table_heading">3. 발주처 정보 입력(공사계약 업체 또는 관공서)</h3>
                                    <table className="table table-bordered card_table">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "12.82%" }}>상호</th>
                                                <td>
                                                    <input type="text" className="form-control"
                                                        value={registerReq.orderName || ""} // 값이 없을 때 빈 문자열로 처리
                                                        onChange={(e) =>
                                                            setRegisterReq((prevState) => ({
                                                                ...prevState,
                                                                orderName: e.target.value,
                                                            }))
                                                        }
                                                        onKeyDown={(e) => onKeyDownHandler(e, 'orderName')}
                                                        ref={orderNameRef}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>대표자</th>
                                                <td>
                                                    <input type="text" className="form-control"
                                                        value={registerReq.orderCeoName}
                                                        onChange={(e) =>
                                                            setRegisterReq((prevState) => ({
                                                                ...prevState,
                                                                orderCeoName: e.target.value,
                                                            }))
                                                        }
                                                        ref={orderCeoNameRef}
                                                        onKeyDown={(e) => onKeyDownHandler(e, 'orderCeoName')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>주소</th>
                                                <td>
                                                    <input type="text" className="form-control" placeholder=""
                                                        value={registerReq.orderCompanyAddress}
                                                        onChange={(e) =>
                                                            setRegisterReq((prevState) => ({
                                                                ...prevState,
                                                                orderCompanyAddress: e.target.value,
                                                            }))
                                                        }
                                                        ref={orderCompanyAddressRef}
                                                        onKeyDown={(e) => onKeyDownHandler(e, 'orderCompanyAddress')}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h3 className="table_heading">4. 공사내용 입력(발주처와 공사계약서상 내용기재)</h3>
                                    <table className="table table-bordered card_table">
                                        <tbody>
                                            {/*<tr>*/}
                                            {/*    <th style={{ width: "12.82%" }}>상호</th>*/}
                                            {/*    <td colSpan={3}>*/}
                                            {/*        <input type="text" className="form-control"*/}
                                            {/*            value={registerReq.constructionName}*/}
                                            {/*            onChange={(e) =>*/}
                                            {/*                setRegisterReq((prevState) => ({*/}
                                            {/*                    ...prevState,*/}
                                            {/*                    constructionName: e.target.value,*/}
                                            {/*                }))*/}
                                            {/*            }*/}
                                            {/*            ref={constructionNameRef}*/}
                                            {/*            onKeyDown={(e) => onKeyDownHandler(e, 'constructionName')}*/}
                                            {/*        />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <th>대표자</th>*/}
                                            {/*    <td colSpan={3}>*/}
                                            {/*        <input type="text" className="form-control"*/}
                                            {/*            value={registerReq.constructionCeoName}*/}
                                            {/*            onChange={(e) =>*/}
                                            {/*                setRegisterReq((prevState) => ({*/}
                                            {/*                    ...prevState,*/}
                                            {/*                    constructionCeoName: e.target.value,*/}
                                            {/*                }))*/}
                                            {/*            }*/}
                                            {/*            ref={constructionCeoNameRef}*/}
                                            {/*            onKeyDown={(e) => onKeyDownHandler(e, 'constructionCeoName')}*/}
                                            {/*        />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <th>주소<br />*/}
                                            {/*        (사업자등록증상 주소)</th>*/}
                                            {/*    <td colSpan={3}>*/}
                                            {/*        <input type="text" className="form-control"*/}
                                            {/*            value={registerReq.constructionAddress}*/}
                                            {/*            onChange={(e) =>*/}
                                            {/*                setRegisterReq((prevState) => ({*/}
                                            {/*                    ...prevState,*/}
                                            {/*                    constructionAddress: e.target.value,*/}
                                            {/*                }))*/}
                                            {/*            }*/}
                                            {/*            ref={constructionAddressRef}*/}
                                            {/*            onKeyDown={(e) => onKeyDownHandler(e, 'constructionAddress')}*/}
                                            {/*        />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            <tr>
                                                <th>공사명</th>
                                                <td colSpan={3}>
                                                    <input type="text" className="form-control"
                                                        value={registerReq.constructionText}
                                                        onChange={(e) =>
                                                            setRegisterReq((prevState) => ({
                                                                ...prevState,
                                                                constructionText: e.target.value,
                                                            }))
                                                        }
                                                        ref={constructionTextRef}
                                                        onKeyDown={(e) => onKeyDownHandler(e, 'constructionText')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>공사기간</th>
                                                <td colSpan={3}>
                                                    <div className="flex-center-between">
                                                        <div class="frm_field input_date input_phone_tbl">
                                                            <input placeholder="" type="date" class="form-control"
                                                                value={startDate}
                                                                onChange={handleStartDateChange}
                                                            />
                                                            <span className="txt_range">-</span>
                                                            <input placeholder="" type="date" class="form-control"
                                                                value={endDate}
                                                                onChange={handleEndDateChange} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>현장주소</th>
                                                <td colSpan={3}>
                                                    <div className="flex-center-between">
                                                        <button className="btn btn-secondary me-10px"
                                                            onClick={handlePostcode}
                                                        >주소찾기</button>
                                                        <input placeholder="" type="text" class="form-control"
                                                            value={registerReq.constructionSiteAddress}
                                                            disabled={true}
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    constructionSiteAddress: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                    <input placeholder="" type="text" class="form-control mt-6px"
                                                        value={registerReq.constructionSiteAddressDetail}
                                                        onChange={(e) =>
                                                            setRegisterReq((prevState) => ({
                                                                ...prevState,
                                                                constructionSiteAddressDetail: e.target.value,
                                                            }))
                                                        }
                                                        ref={conStructionSiteAddressDetailRef}
                                                        onKeyDown={(e) => onKeyDownHandler(e, 'conStructionSiteAddressDetail')}
                                                    />
                                                </td>
                                            </tr>

                                        </tbody>
                                        {isPostcodeOpen && (
                                            <AddressSearchPopup
                                                onComplete={handleCompletePost}
                                                onClose={handleClosePopup}
                                            />
                                        )}
                                    </table>

                                    <div className="card_top_with_btns mb-13px">
                                        <h3 className="table_heading mb-0">5. 폐기물 목록</h3>
                                        <div className="card_table_btntop">
                                            <button className="btn btn-outline-secondary" onClick={handleAddRow}>
                                                추가
                                            </button>
                                            <button className="btn btn-outline-danger ms-10px" onClick={handleDeleteRow}>
                                                삭제
                                            </button>
                                        </div>
                                    </div>
                                    <table className="table my_table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5.58%" }}>선택</th>
                                                <th style={{ width: "5.58%" }}>번호</th>
                                                <th style={{ width: "42.81%" }}>폐기물 종류</th>
                                                <th style={{ width: "28.1%" }}>단위</th>
                                                <th>수량</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={row.checked || false}
                                                            onChange={() => handleCheckboxChange(index)} // 체크박스 변경 시 처리
                                                        />
                                                    </td>
                                                    <td className="text-center">{row.id}</td>
                                                    <td>
                                                        <select
                                                            className="form-select"
                                                            value={wasteKinds[index]?.kindName || ""} // 해당 행의 kindName을 선택된 값으로 설정
                                                            onChange={(e) => handleChange(index, "kind", e.target.value)} // 선택 변경 시 처리
                                                        >
                                                            {wasteKindOptions.map((kind, idx) => (
                                                                <option key={idx} value={kind.kindName}>
                                                                    {kind.kindName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-select"
                                                            value={wasteKinds[index]?.unit || ""} // 해당 행의 unit을 선택된 값으로 설정
                                                            onChange={(e) => handleChange(index, "unit", e.target.value)}
                                                        >
                                                            <option value="톤">톤</option>
                                                            <option value="루베">루베</option>
                                                            <option value="대">대</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={wasteKinds[index]?.kindCount !== undefined ? wasteKinds[index].kindCount : 0} // kindCount가 undefined인 경우 0을 표시
                                                            onChange={(e) => handleChange(index, "kindCount", e.target.value)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer card_footer_btngrp_end">
                                    <button className="btn btn-outline-secondary btn_footer"
                                        onClick={handleSubmit}
                                    >뒤로가기</button>
                                    <button className="btn btn-primary btn_footer"
                                        onClick={handleSave}
                                    >전송하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}