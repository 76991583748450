import React, { useEffect, useState } from "react";
import { postInformationSendSearch } from "../../../api/information";
import Modal from "react-bootstrap/Modal";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import { PcHeader } from "../../common/PcHeader";
import { Sidebar } from "../../common/Sidebar";
export const InformationSend = () => {
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");

    const [responseData, setResponseData] = useState([])
    const [total, setTotal] = useState(0)

    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 15,
    })

    // 총 페이지 수 상태
    const [totalPages, setTotalPages] = useState(0);


    const getStartIndex = (pageNum) => {
        return (pageNum - 1) * pageNation.rowCnt;
    };

    const startIndex = getStartIndex(pageNation.pageNum);

    const informationSearch = async () => {


        try {
            const response = await postInformationSendSearch(pageNation)

            if (response.code === "0000") {
                setResponseData(response.result.result);
                setTotal(response.result.total)
                const totalCount = response.result.total || 0; // 총 개수가 0일 경우 처리
                setTotalPages(Math.max(1, Math.ceil(totalCount / pageNation.rowCnt))); // 최소 1페이지
            }
        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }

    }

    const handleErrorClose = () => {
        setShowError(false)
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        informationSearch()

    }, []);

    // 페이지 번호가 변경될 때마다 데이터 검색
    useEffect(() => {
        informationSearch();
    }, [pageNation]); // pageNation이 변경될 때마다 호출

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };

    const kindMapping = {
        "Handler/Transporter": "처리/업체",
        "Handler": "처리업체",
        "Transporter": "운반업체",
    };



    return (
        <div className="main-sidebar">
            <Sidebar />
            <div className="main-container">
                <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                <div className="body-box">
                    <div className="body-container">
                        <div className="card card_data">
                            <div className="card-header">
                                기재사항 발송
                            </div>
                            <div className="card-body">
                                <h6 className="table_stats">기재사항발송 목록 (<span>{total || 0}</span> 건)</h6>
                                <div className="table_scroll">
                                    <table className="table table-bordered my_table mb-55px">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5.6%" }}>No</th>
                                                <th style={{ width: "13.18%" }}>발송날짜</th>
                                                <th style={{ width: "9.36%" }}>발송자 아이디</th>
                                                <th style={{ width: "24.5%" }}>기재사항 요청 이메일</th>
                                                <th style={{ width: "15.22%" }}>기재사항 등록 여부</th>
                                                <th>발송업체</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {responseData.length > 0 ? (
                                                responseData.map((item, index) => (
                                                    <tr key={item.idx}>
                                                        <td className="text-center">{startIndex+index + 1}</td>
                                                        <td className="text-center">{item.createdAt}</td>
                                                        <td className="text-center">{item.userId}</td>
                                                        <td className="text-center">{item.email}</td>
                                                        <td className="text-center">{item.sendCheck}</td>
                                                        <td className="text-center">
                                                            {kindMapping[item.kind] || item.kind}
                                                        </td>
                                                    </tr>
                                                )))
                                                : (
                                                    <tr className="no-data-message">
                                                        <td className="text-center" colSpan={6}>조회된 데이터가 없습니다.</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination_container">
                                    <ul>
                                        {responseData.length &&
                                            // 페이지가 존재하는 경우 페이지네이션 렌더링
                                            <>
                                                <li className="pg_nav">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                        alt="First Page"
                                                        onClick={() => handlePageChange(1)}
                                                        className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                    />
                                                </li>
                                                <li className="pg_nav">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                        alt="Previous Page"
                                                        onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                        className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                    />
                                                </li>

                                                {/* 페이지 번호 배열 생성 */}
                                                {[...Array(totalPages)].map((_, index) => (
                                                    <li key={index}
                                                        onClick={() => handlePageChange(index + 1)}
                                                        className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                    >
                                                        {index + 1} {/* 페이지 번호 표시 */}
                                                    </li>
                                                ))}

                                                <li className="pg_nav">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                        alt="Next Page"
                                                        onClick={() => handlePageChange(pageNation.pageNum < totalPages ? pageNation.pageNum + 1 : totalPages)}
                                                        className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                    />
                                                </li>
                                                <li className="pg_nav">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                        alt="Last Page"
                                                        onClick={() => handlePageChange(totalPages)}
                                                        className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                    />
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                    <h5>인증 실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>
        </div>
    );
}