import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
    postDashBoardBottomSearch,
    postDashBoardEmailSend,
    postDashBoardEmailSendCheck,
    postDashBoardTotalCount
} from "../api/dashboard";
import { renderToString } from 'react-dom/server';
import { DashBoardEmailTemplate } from "./dashboard/DashBoardEmailTemplate";
import { useNavigate } from "react-router-dom";
import { MobileHeader } from "./common/MobileHeader";
import { PcHeader } from "./common/PcHeader";
import { Sidebar } from "./common/Sidebar"; 

export const Dashboard = () => {

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");

    const [responseData, setResponseData] = useState([])
    const [bottomResponseData, setBottomResponseData] = useState([])
    const [selectedMonth, setSelectedMonth] = useState('2024-11');
    const [total, setTotal] = useState(0)
    const navigate = useNavigate();

    const [initEmailReq, setInitEmailReq] = useState({
        email : "",
        html : "",
    })

    const [emailReq, setEmailReq] = useState({
        email: "",
        html: "",
    })

    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 15,
    })

    const [totalPages, setTotalPages] = useState(0);

    const [dashboardTotalCountReq, setDashBoardTotalCountReq] = useState({
        sendCount: "",
        arriveCount: "",
        contractCount: "",
        completeCount: "",
        submitCount: "",
    })


    const [isTimerCheck, setIsTimerCheck] = useState(false);
    const [timer, setTimer] = useState(30);

    const [yyyyMonth, setYyyyMonth] = useState("");


    const emailHandler = (e) => {
        const trimmedEmail = e.trim();
        setEmailReq({ ...emailReq, email: trimmedEmail })
    };

    const emailSendClick = async () => {
        try {
            if (isTimerCheck) {
                setShowErrorMessage(`다시 클릭하시려면 ${timer}초 대기해주세요.`);
                setShowError(true);
                return;
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailReq.email === "") {
                setShowErrorMessage("이메일을 입력해주세요.");
                setShowError(true)
                return
            } else if (!emailRegex.test(emailReq.email)) {
                setShowErrorMessage("유효하지 않은 이메일 형식입니다.");
                setShowError(true)
                return
            }

            if(!process.env.REACT_APP_EMAIL_URL) {
                setShowError("환경설정을 해주십시오.")
                setShowError(true)
                return;
            }

            // HTML 템플릿을 문자열로 변환
            const htmlString = renderToString(<DashBoardEmailTemplate />);

            const updateEmailReq = ({ ...emailReq, html: htmlString })

            const response = await postDashBoardEmailSend(updateEmailReq)
            
            if (response.code === "0000") {
                setShowSuccess(true)
                setShowSuccessMessage("[ " + emailReq.email +" ]"+ " 로 기재사항 요청을 전송하였습니다.")
                setEmailReq(initEmailReq)
                startCooldown(); // 쿨다운 시작
            }

        }
        catch (e) {
            setShowErrorMessage("기재사항 전송에 실패하였습니다.");
            setShowError(true)
        }

    }

    const startCooldown = () => {
        setIsTimerCheck(true);
        setTimer(30);

        const interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 1) {
                    clearInterval(interval);
                    setIsTimerCheck(false);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const handleErrorShow = (e) => {
        setShowErrorMessage(e);
        setShowError(true)
    }

    const handleErrorClose = () => {
        setShowError(false)
    }

    const handleSuccessClose = () => {
        setShowSuccess(false);

    }

    const dashboardSearchTotal = async () => {

        try {

            const response = await postDashBoardTotalCount();

            if (response.code === "0000") {
                const { sendCount, arriveCount, contractCount, completeCount, submitCount } = response.result.result[0];
                setDashBoardTotalCountReq({
                    sendCount: sendCount || "0",
                    arriveCount: arriveCount || "0",
                    contractCount: contractCount || "0",
                    completeCount: completeCount || "0",
                    submitCount: submitCount || "0"
                });
                setResponseData([])
            }

        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }

    }

    useEffect(() => {
        dashboardSearchTotal()
        // 오늘 날짜를 시작 날짜로 설정
        const today = new Date();
        const year = today.getFullYear(); // 현재 연도
        const month = String(today.getMonth() + 1).padStart(2, '0'); // 현재 월 (0부터 시작하므로 +1)
        // 원하는 형식으로 포맷팅
        const formattedDate = `${year}년 - ${month}월`;
        setYyyyMonth(formattedDate)
        setSelectedMonth(`${year}-${month}`);
    }, []);

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };

    const handleMonthChange = (event) => {
        let value = event.target.value;

        // YYYY-MM 형식만 허용하고 7자 이상 입력 제한
        if (value.length <= 7) {
            setSelectedMonth(value);
        }
    };


    useEffect(() => {
        dashboardBottomSearch()
    }, [selectedMonth]);

    const dashboardBottomSearch = async () => {

        try{

            const response = await postDashBoardBottomSearch({
                constructionEndDate: selectedMonth, // 문자열 템플릿 리터럴로 사용
            });

            if(response.code==="0000"){
                const resultData = response.result.result || [];
                setBottomResponseData(resultData);
                const totalCount = resultData.length > 0 ? resultData[0].totalCount : 0;
                setTotal(totalCount);
                setTotalPages(Math.max(1, Math.ceil(totalCount / pageNation.rowCnt))); // 최소 1페이지
            }



        }
        catch (e){
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }


    return (
        <React.Fragment>
            <MobileHeader />
            <div className="main-sidebar">

                <Sidebar />

                <div className="main-container">

                    <PcHeader title="대시보드" icon="/images/icon_header1.png" />

                    <div className="body-box">
                        <div className="body-container">
                            <div className="dash_top">
                                <div className="card dash_left">
                                    <div className="card-header">계약현황</div>
                                    <div className="card-body">
                                        <ul className="dash_boxes">
                                            <li onClick={()=>navigate('/information/info')}>
                                                <img src={process.env.PUBLIC_URL + "/images/dash_img1.png"} alt="" />
                                                <h6>기재사항 발송</h6>
                                                <h3>{dashboardTotalCountReq.sendCount ?? 0} <span>건</span></h3>
                                            </li>
                                            <li onClick={()=>navigate('/information/sendCheck')}>
                                                <img src={process.env.PUBLIC_URL + "/images/dash_img2.png"} alt="" />
                                                <h6>기재사항 도착</h6>
                                                <h3>{dashboardTotalCountReq.arriveCount ?? 0} <span>건</span></h3>
                                            </li>
                                            <li onClick={()=>navigate('/information/request')}>
                                                <img src={process.env.PUBLIC_URL + "/images/dash_img3.png"} alt="" />
                                                <h6>계약 진행요청</h6>
                                                <h3>{dashboardTotalCountReq.contractCount ?? 0}<span>건</span></h3>
                                            </li>
                                            <li onClick={()=>navigate('/information/contract')}>
                                                <img src={process.env.PUBLIC_URL + "/images/dash_img4.png"} alt="" />
                                                <h6>계약 완료</h6>
                                                <h3>{dashboardTotalCountReq.completeCount ?? 0}<span>건</span></h3>
                                            </li>
                                            <li onClick={()=>navigate('/information/complete')}>
                                                <img src={process.env.PUBLIC_URL + "/images/dash_img5.png"} alt="" />
                                                <h6>신고서 제출</h6>
                                                <h3>{dashboardTotalCountReq.submitCount ?? 0}<span>건</span></h3>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card dash_right">
                                    <div className="card-header">기재사항 요청하기</div>
                                    <div className="card-body">
                                        <div className="">
                                            <label className="form-label lbl_request">이메일
                                                <img className="ms-1" 
                                                     src={process.env.PUBLIC_URL + "/images/dash_icon_refresh.png"} 
                                                     alt="초기화" 
                                                     onClick={() => setEmailReq({...emailReq, email : ""})}/>
                                            </label>
                                            <div className="row_frm_req">
                                                <input type="text" className="form-control"
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            emailSendClick();
                                                        }
                                                    }}
                                                    onChange={(e) => emailHandler(e.target.value)}
                                                    value={emailReq.email}
                                                />
                                                <button className="btn btn-primary"
                                                    onClick={emailSendClick}
                                                >
                                                    기재사항 요청</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <ul className="dash_ul_text">
                                            <li>기재사항 요청을 전송할 이메일을 입력하고 요청 버튼 클릭</li>
                                            <li>해당 이메일로 기재사항 요청 내용이 전송됨</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="dash_btm">
                                <div className="card dash_btm">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        계약 만료 목록 ({total}) 건
                                        <input
                                            type="month"
                                            className="form-control toolbar_date"
                                            value={selectedMonth}
                                            onChange={handleMonthChange}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-bordered my_table">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5.6%" }}>No</th>
                                                    <th style={{ width: "13.24%" }}>계약 만료일자</th>
                                                    <th style={{ width: "13.94%" }}>업체 명</th>
                                                    <th>제목</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bottomResponseData.length > 0 ? (
                                                        bottomResponseData.map((item, index) => (
                                                        <tr
                                                            key={item.idx}
                                                        >
                                                            <td className="text-center">{index + 1}</td>
                                                            <td className="text-center">{item.constructionEndDate}</td>
                                                            <td className="text-center">{item.name}</td>
                                                            <td className="text-center">{item.constructionText}</td>
                                                        </tr>
                                                    )))
                                                    : (
                                                        <tr className="no-data-message">
                                                            <td className="text-center" colSpan={4}>조회된 데이터가 없습니다.</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div className="pagination_container">
                                            <ul>
                                                {bottomResponseData.length > 0 &&
                                                    // 페이지가 존재하는 경우 페이지네이션 렌더링
                                                    <>
                                                        <li className="pg_nav">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                                alt="First Page"
                                                                onClick={() => handlePageChange(1)}
                                                                className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                            />
                                                        </li>
                                                        <li className="pg_nav">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                                alt="Previous Page"
                                                                onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                                className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                            />
                                                        </li>

                                                        {/* 페이지 번호 배열 생성 */}
                                                        {[...Array(totalPages)].map((_, index) => (
                                                            <li key={index}
                                                                onClick={() => handlePageChange(index + 1)}
                                                                className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                            >
                                                                {index + 1} {/* 페이지 번호 표시 */}
                                                            </li>
                                                        ))}

                                                        <li className="pg_nav">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                                alt="Next Page"
                                                                onClick={() => handlePageChange(pageNation.pageNum < totalPages ? pageNation.pageNum + 1 : totalPages)}
                                                                className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                            />
                                                        </li>
                                                        <li className="pg_nav">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                                alt="Last Page"
                                                                onClick={() => handlePageChange(totalPages)}
                                                                className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                            />
                                                        </li>
                                                    </>
                                                }
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>인증 성공</h5>
                        <p>{showSuccessMessage}</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <h5>인증 실패</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
};