import Modal from "react-bootstrap/Modal";

export const DashBoardEmailTemplate = ({ serialNumber,informationSendIdx}) => {
    return (
        <>
            <div style={{ width: "100%", height: "100wh", minHeight: "900px", backgroundColor: "#f5f5f5", padding: "20px 0 40px 0 " }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "800px", height: "70px", margin: "0 auto", backgroundColor: "rgba(255, 223, 0, 1)", padding: "0 41px" }}>
                    {/*<img src={process.env.PUBLIC_URL + "/images/email_logo.png"} alt="" width={116} height={32} />*/}
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", margin: "0 auto", backgroundColor: "#fff" }}>
                    <div style={{ width: "100%", maxWidth: "800px", minHeight: "726px", margin: "0 auto", paddingTop: "40px" }}>
                        <h1 style={{
                            fontFamily: "Pretendard, NotoSans, Arial",
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "24px",
                            textAlign: "center",
                            marginBottom: "25px",
                            color: "rgba(0, 0, 0, 1)"
                        }}>계약 및 신고서 관련 정보 입력 요청</h1>
                        <p style={{
                            fontFamily: "Pretendard, NotoSans, Arial",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "19.2px",
                            textAlign: "center",
                            marginBottom: "63px",
                            color: "rgba(0, 0, 0, 1)"
                        }}>아래 정보를 누르면 기재사항 작성페이지로 이동합니다.</p>

                            <a href={process.env.REACT_APP_EMAIL_URL + `/verify?serialNumber=${serialNumber || "number"}&informationSendIdx=${informationSendIdx || "inforIdx"}`} style={{ textDecoration: 'none' }}>

                                <button style={{
                                display: "block", width: "185px", height: "40px", margin: "35px auto 50px auto", backgroundColor: "rgba(255, 223, 0, 1)", border: "none",
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "14px",
                                fontWeight: "700",
                                lineHeight: "16.8px",
                                textAlign: "center",
                                boxShadow: "unset",
                                borderRadius: "6px"
                            }}>내용 확인하고 서명하기</button>
                        </a>

                    </div>
                </div>
            </div>

        </>
    );
}

{/*    <button style={{*/ }
{/*        display: "block", width: "240px", height: "48px", margin: "50px auto", backgroundColor: "rgba(255, 223, 0, 1)", border: "none",*/ }
{/*        fontFamily: "Pretendard, NotoSans, Arial",*/ }
{/*        fontSize: "20px",*/ }
{/*        fontWeight: "500",*/ }
{/*        lineHeight: "23.87px",*/ }
{/*        textAlign: "center",*/ }
{/*        boxShadow: "unset",*/ }
{/*        borderRadius: "6px"*/ }
{/*    }}>내용 확인하고 서명하기</button>*/ }
{/*</div>*/ }