import Cookies from 'js-cookie';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export const Sidebar = () => {
    const location = useLocation();
    const [activePath, setActivePath] = useState(location.pathname);
    const navigater = useNavigate();

    useEffect(() => {
        setActivePath(location.pathname);
    }, [location]);
    const isActive = (path) => activePath === path ? 'active' : '';
    const savedUserName = sessionStorage.getItem('oneclick_userName')

    const [userName, setUserName] = useState("");

    useEffect(() => {
        setUserName(savedUserName)
    }, [savedUserName])

    function getActiveClass(href) {
        return window.location.pathname.startsWith(href) ? "active" : "";
    }

    function getActiveMenuClass(menu) {
        return window.location.pathname.indexOf(menu) >= 0 ? "active" : "";
    }

    const navigate = (path) => {
        navigater(path);
    };

    return (
        <div className="nav-container">
            <div className="nav-top-box">
                <img className="logo cursor-pointer" src={process.env.PUBLIC_URL + "/images/private_logo.png"} alt="" />
            </div>
            {/*{isLoggedIn ? (*/}
            <div className="nav-list">
                <ul>
                    {userName !== null && window.location.pathname.indexOf('verify') <= 0 ?
                        <>
                            <li className={getActiveClass(process.env.PUBLIC_URL + "/dashboard/main")  + ' clickable'}>
                                <a onClick={() => navigate("/dashboard/main")}>
                                    <img src={process.env.PUBLIC_URL + "/images/menu1.png"} alt="" />대시보드
                                </a>
                            </li>
                            <li>
                                <a className={getActiveMenuClass("information")}>
                                    <img src={process.env.PUBLIC_URL + "/images/menu2.png"} alt="" />폐기물 관리
                                </a>
                                <ul>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/information/info") + ' clickable'}>
                                        <a onClick={() => navigate("/information/info")}>기재사항 발송</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/information/sendCheck") + ' clickable'}>
                                        <a onClick={() => navigate("/information/sendCheck")}>기재사항 확인 및 수정</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/information/request") + ' clickable'}>
                                        <a onClick={() => navigate("/information/request")}>계약 진행요청</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/information/contract") + ' clickable'}>
                                        <a onClick={() => navigate("/information/contract")}>완료 계약서</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/information/complete") + ' clickable'}>
                                        <a onClick={() => navigate("/information/complete")}>신고서 제출</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a className={getActiveMenuClass("delivery")}>
                                    <img src={process.env.PUBLIC_URL + "/images/menu3.png"} alt="" />납품 관리
                                </a>
                                <ul>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/delivery/register") + ' clickable'}>
                                        <a onClick={() => navigate("/delivery/register")}>계약서 등록</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/delivery/sendCheck") + ' clickable'}>
                                        <a onClick={() => navigate("/delivery/sendCheck")}>납품 현황</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a className={getActiveMenuClass("mypage")}>
                                    <img src={process.env.PUBLIC_URL + "/images/menu4.png"} alt="" />마이 페이지
                                </a>
                                <ul>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/mypage/info") + ' clickable'}>
                                        <a onClick={() => navigate("/mypage/info")}>기본정보</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/mypage/transpoter") + ' clickable'}>
                                        <a onClick={() => navigate("/mypage/transpoter")}>수탁확인서</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/mypage/handler") + ' clickable'}>
                                        <a onClick={() => navigate("/mypage/handler")}>수탁능력확인서</a>
                                    </li>
                                    <li className={getActiveClass(process.env.PUBLIC_URL + "/mypage/coating") + ' clickable'}>
                                        <a onClick={() => navigate("/mypage/coating")}>도장</a>
                                    </li>
                                </ul>
                            </li>
                        </>
                        :
                        <>
                            <li>
                                <a 
                                    className={getActiveMenuClass(process.env.PUBLIC_URL + "/verify")}
                                >
                                    <img 
                                        src={process.env.PUBLIC_URL + (window.location.href.includes("/delivery") 
                                            ? "/images/menu3.png" 
                                            : "/images/menu2.png")} 
                                        alt="" 
                                    />
                                    {window.location.href.includes("/delivery") ? " 납품관리" : " 폐기물관리"}
                                </a>
                                <ul>
                                    {window.location.href.includes("/verify/register") ? (
                                        <li className="active clickable">
                                            <a>기재사항 등록</a>
                                        </li>
                                    ) : window.location.href.includes("/verify/request") ? (
                                        <li className="active clickable">
                                            <a>계약 진행요청</a>
                                        </li>
                                    ) : window.location.href.includes("/verify/delivery") ? (
                                        <li className="active clickable">
                                            <a>납품확인 및 날인</a>
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                        </>
                    }
                </ul>
            </div>
        </div>
    );
};