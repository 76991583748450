import { Link } from "react-router-dom"

export const PublicFooter = () => {
    return (
        <footer>
            <div className="public_footer_wrapper">
                <div className="public_footertop_wrapper">
                    <div className="public_footertop_outer">
                        <div className="footer_left">
                            <ul>
                                <li><Link to="/privacy_policy">개인정보처리방침</Link></li>
                                {/*<li><a href="/#">이메일무단수집거부</a></li>*/}
                                <li><Link to="/terms_of_use">이용약관</Link></li>
                            </ul>
                        </div>
                        {/*<div className="footer_right">*/}
                        {/*    <a href="http://www.daehanen.com/index.asp">대한이엔이 바로가기 <img src={process.env.PUBLIC_URL + "/images/footer_icon_next.png"} alt="" /></a>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="public_footer_content">
                    대표자 : 심형근<br />
                    사업자등록번호 : 876-20-02169   Tel : 010-7736-5539
                    e-mail : oneclick40@naver.com <br />개인정보관리책임자 : 심형근<br />
                    주소 : 전북특별자치도 군산시 궁포3로 14, 601동 9호(조촌동, 하늘빌딩) <br />
                    {/*
                    추후에 사용가능
                    Copyright ⓒ 2018 daehanen. ALL RIGHTS RESERVED.*/}
                </div>
            </div>
        </footer>
    );
}