import React, {useEffect, useState,useRef} from "react";
import Modal from 'react-bootstrap/Modal';
import {postMypageDriveDetailSave, postMypageDriveDetailSearch} from "../../api/mypage";
import {scrollToTop} from "../../api/utils/scrollToTop";
import { MobileHeader } from "../common/MobileHeader";
import { Sidebar } from "../common/Sidebar";
import { PcHeader } from "../common/PcHeader";

export const MyPageTransporter = () => {
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");


    const [responseData,setResponseData] = useState([])

    const handleErrorClose = () => {
        setShowError(false)
    }

    const handleSuccessClose= () => {
        setShowSuccess(false)
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        mypageLoading()
        kindPermitRef.current.focus()
    }

    const [register,setRegister] = useState({
        idx : "",
        userIdx : "",
        companyNumber:"",
        kindPermit:"",
        oneCombustible:0,
        oneNoncombustible : 0,
        permitNumber : "",
        permitOrgan : "",
        permitRegistration : "",
        targetWaste :"",
        myPageKinds : []
    })

    const kindPermitRef = useRef(null);
    const oneCombustibleRef = useRef(null);
    const oneNoncombustibleRef = useRef(null);
    const permitNumberRef = useRef(null);
    const permitOrganRef = useRef(null);
    const permitRegistrationRef = useRef(null);
    const quantityRef = useRef(null);
    const scaleRef = useRef(null);
    const targetWasteRef = useRef(null);


    const mypageLoading= async () => {
        try{

            const response = await postMypageDriveDetailSearch()
            if(response.code==="0000"){
                const result = response.result
                setResponseData(result)

                setRegister(prevState => ({
                    idx : result.idx,
                    userIdx : result.userIdx,
                    companyNumber: result.companyNumber,
                    kindPermit: result.kindPermit,
                    oneCombustible: result.oneCombustible,
                    oneNoncombustible : result.oneNoncombustible,
                    permitNumber : result.permitNumber,
                    permitOrgan : result.permitOrgan,
                    permitRegistration : result.permitRegistration,
                    quantity : result.quantity,
                    scale : result.scale,
                    targetWaste : result.targetWaste,
                }))

                const transformedRows = (result.myPageKinds || []).map(item => ({
                    scale: item.scale || "", // scale 값이 없을 경우 빈 문자열로 설정
                    quantity: item.quantity || "", // quantity 값이 없을 경우 빈 문자열로 설정
                    disabled: true,
                    checked: false,
                }));

                // rows 상태 업데이트
                setRows(transformedRows);
            }
        }
        catch (e){
            setShowErrorMessage("관리자에게 문의바랍니다.")
            setShowError(true)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        mypageLoading()
        kindPermitRef.current.focus()

    }, []);


    const transporterSave = async () => {

         try{

             const updatedRegister = {
                 ...register,
                 myPageKinds: rows,
             };

             const response = await postMypageDriveDetailSave(updatedRegister)

           if(response.code==="0000"){

                setShowSuccessMessage("저장 되었습니다.")
                 setShowSuccess(true)
             }
         }
         catch (e){
             setShowErrorMessage(e.message)
             setShowError(true)
         }
    }



    const onKeyDownHandler = (e,part) => {

        if (e.key === 'Enter') {
            if(part==="kindPermit"){
                permitNumberRef.current.focus();
            }else if(part==="permitNumber"){
                permitRegistrationRef.current.focus();
            }else if(part==="permitRegistration"){
                permitOrganRef.current.focus();
            }else if(part==="permitOrgan"){
                targetWasteRef.current.focus();
            }else if(part==="targetWaste"){
                oneNoncombustibleRef.current.focus();
            }else if(part==="oneNoncombustible"){
                oneCombustibleRef.current.focus();
            }

        }
    }
    const [selectAll, setSelectAll] = useState(false);
    const [rows, setRows] = useState([
        { scale: "", quantity: "", disabled: true, checked: false },
    ]);

    // 행 추가 함수
    const handleAddRow = () => {

        if(rows.length<5){
            setRows([
                ...rows,
                { scale: "", quantity: "", disabled: true, checked: false },
            ]);
        }
        else{
            setShowErrorMessage("최대5행 까지 가능합니다.")
            setShowError(true)
        }
    };
    // 입력값 변경 핸들러 (숫자만 입력 및 콤마 추가)
    const handleInputChange = (index, field, value) => {
        let formattedValue = value;

        if (field === "scale") {
            // 숫자와 점(.)만 허용
            formattedValue = value.replace(/[^0-9.]/g, "");
        } else if (field === "quantity") {
            // 숫자만 허용하고 포맷팅
            formattedValue = value.replace(/[^0-9]/g, "");
            formattedValue = new Intl.NumberFormat().format(formattedValue);
        }

        const updatedRows = [...rows];
        updatedRows[index][field] = formattedValue;
        setRows(updatedRows);
    };

    // 행 체크박스 선택 핸들러
    const handleRowCheckboxChange = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRows(updatedRows);

        // 전체 선택 상태 업데이트
        const allChecked = updatedRows.every((row) => row.checked);
        setSelectAll(allChecked);
    };

    // 전체 체크박스 선택 핸들러
    const handleSelectAllChange = () => {
        const updatedRows = rows.map((row) => ({
            ...row,
            checked: !selectAll,
        }));
        setRows(updatedRows);
        setSelectAll(!selectAll);
    };

    // 선택된 행 삭제
    const handleDeleteSelected = () => {
        const updatedRows = rows.filter((row) => !row.checked);
        setRows(updatedRows);
        setSelectAll(false);
    };


    return (
        <React.Fragment >
            <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5 className="singleLine">저장 성공</h5>
                    <p>수정 되었습니다.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인
                    </button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt=""/>
                    <h5>오류</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                    </button>
                </Modal.Body>
            </Modal>

            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="마이페이지" icon="/images/icon_square.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_main">
                                <div className="card-header">
                                    수탁확인서
                                </div>
                                <div className="card-body">
                                    <h3 className="card_body_heading">운반업체 확인서 정보</h3>

                                    <div className="table_scroll">
                                        <table className="table table-bordered card_table mb-4">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "12.82%" }}>업종(신고서)</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                        value={register.kindPermit || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       kindPermit: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={kindPermitRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'kindPermit')}
                                                        /></td>
                                                </tr>
                                                <tr>
                                                    <th>허가(신고)번호</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                        value={register.permitNumber || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       permitNumber: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={permitNumberRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'permitNumber')}
                                                        /></td>
                                                </tr>
                                                <tr>
                                                    <th>허가(신고)일<br />
                                                        (최종변경일)</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={register.permitRegistration || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       permitRegistration: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={permitRegistrationRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'permitRegistration')}
                                                        /></td>
                                                </tr>
                                                <tr>
                                                    <th>허가(신고수리)기관</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={register.permitOrgan || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       permitOrgan: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={permitOrganRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'permitOrgan')}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>영업대상폐기물</th>
                                                    <td colSpan={3}>
                                                        <textarea className="form-control" rows={4}
                                                                  value={register.targetWaste || ""}
                                                                  onChange={(e) => {
                                                                      setRegister((prevState) => ({
                                                                          ...prevState,
                                                                          targetWaste: e.target.value,
                                                                      }));
                                                                  }}
                                                                  ref={targetWasteRef}
                                                                  onKeyDown={(e) => onKeyDownHandler(e, 'targetWaste')}
                                                        ></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>불연성 1회 운반톤수</th>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                               value={register.oneNoncombustible || 0}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       oneNoncombustible: Number(e.target.value),
                                                                   }));
                                                               }}
                                                               ref={oneNoncombustibleRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'oneNoncombustible')}
                                                        /></td>
                                                    <th style={{ width: "12.82%" }}>가연성 1회 운반톤수</th>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                               value={register.oneCombustible || 0}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       oneCombustible: Number(e.target.value),
                                                                   }));
                                                               }}
                                                               ref={oneCombustibleRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'oneCombustible')}
                                                        /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="card_top_with_btns mb-13px">
                                        <h3 className="table_heading mb-0"></h3>
                                        <div className="card_table_btntop">
                                            <button className="btn btn-outline-secondary"
                                                    onClick={handleAddRow}
                                            >추가</button>
                                            <button className="btn btn-outline-danger ms-10px"
                                                    onClick={handleDeleteSelected}
                                            >삭제</button>
                                        </div>
                                    </div>
                                    <div className="table_scroll">
                                        <table className="table table-bordered tbody_noborder my_table xmb-55px">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "2.82%" }} rowSpan={2}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                        /></th>
                                                    <th style={{ width: "12.49%" }} rowSpan={2}>영업대상폐기물</th>
                                                    <th style={{ width: "12.49%" }} rowSpan={2}>
                                                        폐기물 처분<br />
                                                        또는 재활용 방법
                                                    </th>
                                                    <th style={{ width: "40.03%" }} colSpan={2}>
                                                        폐기물 중간 처분 또는 재활용 시설<br />
                                                        폐기물 처분 또는 재활용 방법
                                                    </th>
                                                    <th style={{ width: "12.49%" }} colSpan={2}>폐기물<br />
                                                        최종처분시설</th>
                                                    <th style={{ width: "12.49%" }} colSpan={2}>수집/운반차량</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "33.33%" }}>종류</th>
                                                    <th>용량(톤/시간)</th>
                                                    <th style={{ width: "6.24%" }}>용량(m3)</th>
                                                    <th>매립기간</th>
                                                    <th style={{ width: "6.24%" }}>규모(톤)</th>
                                                    <th>수량(대)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {rows.map((row, index) => (
                                                <tr key={index}>
                                                    <td className="p-3px text-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={row.checked}
                                                            onChange={() => handleRowCheckboxChange(index)}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={row.disabled}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={row.disabled}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={row.disabled}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={row.disabled}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={row.disabled}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={row.disabled}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.scale}
                                                            maxLength={10}
                                                            onChange={(e) =>
                                                                handleInputChange(index, "scale", e.target.value)
                                                            }
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.quantity}
                                                            maxLength={10}
                                                            onChange={(e) =>
                                                                handleInputChange(index, "quantity", e.target.value)
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer card_footer_btngrp_end">
                                    <button className="btn btn-primary btn_footer"
                                            onClick={transporterSave}
                                    >저장</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

