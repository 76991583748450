import { findIsPay, updateIsPay } from "../api/auth";
import { PublicFooter } from "./base/PublicFooter";
import { PublicHeader } from "./base/PublicHeader";
import { useNavigate } from "react-router-dom";
import { postPgUserInfo} from "../api/pg";
import {useEffect, useState} from "react";
import { ReactInicis } from '@hellojh/react-inicis';

export const FeeIntroduce = () => {

    const navigate = useNavigate();

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [mobileCheck,setMobileCheck] = useState(false)


    useEffect(() => {
        const script = document.createElement('script');
        // script.src = 'https://stgstdpay.inicis.com/stdjs/INIStdPay.js';  // 테스트 서버 URL, 실제 운영 시 변경 필요
        script.src = 'https://stdpay.inicis.com/stdjs/INIStdPay.js';  // 운영서버
        script.charset = 'UTF-8';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
        // 스크립트 로드 완료 시 호출되는 함수
        script.onload = () => {
            setIsScriptLoaded(true);
        };

        // 스크립트 로드 실패 시 호출되는 함수
        script.onerror = () => {
        };
        // userInfo()

        // Cleanup: 컴포넌트 언마운트 시 스크립트 제거
        return () => {
            document.body.removeChild(script);
        };
    }, []);


    const handleResize = () => {
        if (window.innerWidth <= 800 && !mobileCheck) {
            alert("결제는 PC에서 진행 해주세요.");
            setMobileCheck(true);
            navigate("/login")
        }
    };

    useEffect(() => {
        // 초기 화면 크기 확인
        handleResize();

        // resize 이벤트 리스너 등록
        window.addEventListener("resize", handleResize);
        // 컴포넌트 언마운트 시 리스너 해제
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [mobileCheck]);

    const payBtn = async () => {

        if(pgData.price===""){
            alert("상품을 선택해주세요.")
            return
        }


        const req = sessionStorage.getItem('loginId');

        if(req === null || req === "") {
            alert("결제를 하기 위해 로그인 또는 회원가입을 해주세요.")
            navigate('/login')
            return;
        }
        const body = {userId : req}

        const result = await findIsPay(body);

        const isPay = result?.result?.isPay

        if(isPay) {
            alert("이미 결제가 완료된 계정입니다.")
            navigate('/dashboard/main')
        }
        const updatedPgData = await userInfo(); // 업데이트 후 데이터 반환

        if(updatedPgData.code==="0000"){
            if (isScriptLoaded) {
                const form = document.getElementById('SendPayForm_id');
                if (form) {
                    window.INIStdPay.pay('SendPayForm_id');
                    setTimeout(() => {
                        const modalDiv = document.getElementById("inicisModalDiv");
                        if (modalDiv) {
                            modalDiv.style.opacity = 1; // Correct CSS change for opacity
                        }
                    }, 100); // 100 ms delay
                }
            } else {
            }
        }
    };

    useEffect(() => {
        processPayment()
    }, [navigate]);

    const processPayment = async () => {
        // URL에서 resultCode 파라미터를 추출
        const urlParams = new URLSearchParams(window.location.search);
        const encodedData = urlParams.get('data');

        if (encodedData) {
            try {
                // Base64로 인코딩된 데이터를 디코딩
                const decodedData = atob(encodedData);
                const [resultCode, mid] = decodedData.split(":");

                // 환경변수에서 MID 값 가져오기
                const midKey = process.env.REACT_APP_MID_KEY;

                // 필요한 body 생성
                const body = {
                    userId: sessionStorage.getItem('loginId'), // 로그인 아이디
                    // 기타 필요한 데이터 추가
                };

                // 먼저 updateIsPay 호출
                const result = await updateIsPay(body);

                // 결제 상태 업데이트 결과 확인
                if (result.code === "0000" && resultCode === '0000' && mid === midKey) {
                    // resultCode와 mid 값 검증
                        alert('결제가 완료되었습니다.');
                        navigate('/dashboard/main');

                } else {
                    alert('결제 정보가 올바르지 않습니다.');
                }
            } catch (error) {
                alert('결제 처리 중 오류가 발생하였습니다.');
            }
        }
    };

    const userInfo = async () => {
        try {
            const response = await postPgUserInfo(pgData);

            if (response.code === "0000") {
                const newPgData = {
                    oid: response.oid,
                    price: response.price,
                    signKey: response.signKey,
                    signature: response.signature,
                    timestamp: response.timestamp,
                    verification: response.verification,
                    mKey: response.mKey,
                    goodname: response.goodname,
                    buyername: response.buyername,
                    buyertel: response.buyertel,
                    buyeremail: response.buyeremail,
                    code : response.code,
                };
                setPgData(newPgData);
                return newPgData; // 업데이트된 상태를 반환
            }
        } catch (e) {
        }
    };




    const [pgData, setPgData] = useState({
        oid: "",
        price: "",
        signKey: "",
        signature: "",
        timestamp: "",
        verification: "",
        mKey: "",
        goodname: "",
        buyername: "",
        buyertel: "",
        buyeremail: "",
        code:"",
    })






    const handlePlanClick = (price, goodname) => {
        setPgData(prevState => ({
            ...prevState,
            price: price,
            goodname: goodname
        }));
        setSelectedPlan(goodname);
    };

    const [isPurchase, setIsPurchase] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState(""); // 선택된 플랜 이름 저장


    return (
        <>
            <PublicHeader />
            <div className="plan_content">
                <div className="card plan_box">
                    <div className="card-header">
                        <div className="card-title">원클릭 요금제</div>
                    </div>
                    <div className="card-body">
                        <h2 className="plan_text">합리적인 요금으로 편리한 업무서비스을 이용하세요.</h2>

                        <button className="btn btn-dark btn-lg btn_play_subscription">월 구독 서비스 결제</button>

                        <div className="plans_container">
                            <div
                                className={`plan_sm_box ${selectedPlan === "슬림" ? "bordered" : ""}`}
                                // onClick={() => handlePlanClick(29000, "슬림")}
                                onClick={() => handlePlanClick(29000, "슬림")}
                            >
                                <div className="plan_sm_top box1">
                                    <h4>슬림</h4>
                                    <p><small>월</small> 29,000원 </p>
                                </div>
                                <div className="plan_sm_btm">
                                    <ul>
                                        <li>기재사항 보내기</li>
                                        <li>계약서, 신고서 자동작성</li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className={`plan_sm_box ${selectedPlan === "프라임" ? "bordered" : ""}`}
                                // onClick={() => handlePlanClick(49000, "프라임")}
                                onClick={() => handlePlanClick(49000, "프라임")} >
                                <div className="plan_sm_top box2"
                                >
                                    <h4>프라임</h4>
                                    <p><small>월</small> 49,000원</p>
                                </div>
                                <div className="plan_sm_btm">
                                    <ul>
                                        <li>기재사항 보내기</li>
                                        <li>계약서, 신고서 자동작성</li>
                                        <li>전자진행(이메일,카카오톡)
                                            <ul>
                                                <li>- 폐기물계약서,신고서</li>
                                                <li>- 제품계약서</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div        className={`plan_sm_box ${selectedPlan === "플래티넘" ? "bordered" : ""}`}
                                        // onClick={() => handlePlanClick(69000, "플래티넘")}>
                                        onClick={() => handlePlanClick(69000, "플래티넘")}>
                                <div className="plan_sm_top box3"
                                >
                                    <h4>플래티넘</h4>
                                    <p><small>월</small>  69,000원 </p>
                                </div>
                                <div className="plan_sm_btm">
                                    <ul>
                                        <li>기재사항 보내기</li>
                                        <li>계약서, 신고서 자동작성</li>
                                        <li>전자진행(이메일,카카오톡)
                                            <ul>
                                                <li>- 폐기물계약서,신고서</li>
                                                <li>- 제품계약서</li>
                                            </ul>
                                        </li>
                                        <li>신고서 지자체 제출</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <p className="plan_btm_text">※환불정책은 회원가입약관 제20조(청약 철회 및 환불 및 유료서비스 정기결제 중단) 참조</p>
                    </div>
                    {/* Inicis Payment Form */}
                    <form
                        name="SendPayForm_id"
                        id="SendPayForm_id"
                        method="post"
                    >
                        {/* Hidden form fields for payment data */}
                        <input type="hidden" name="version" value="1.0" />
                        <input type="hidden" name="gopaymethod" value="Card:Directbank:vbank" />
                        {/*<input type="hidden" name="mid" value={process.env.REACT_APP_MID_KEY} />*/}
                        <input type="hidden" name="mid" value={process.env.REACT_APP_MID_KEY} />
                        <input type="hidden" name="oid" value={pgData.oid} />
                        <input type="hidden" name="price" value={pgData.price} />
                        <input type="hidden" name="timestamp" value={pgData.timestamp} />
                        <input type="hidden" name="use_chkfake" value='Y' />
                        <input type="hidden" name="signature" value={pgData.signature} />
                        <input type="hidden" name="verification" value={pgData.verification} />
                        <input type="hidden" name="mKey" value={pgData.mKey} />
                        <input type="hidden" name="currency" value="WON" />
                        <input type="hidden" name="goodname" value={pgData.goodname} />
                        <input type="hidden" name="buyername" value={pgData.buyername} />
                        <input type="hidden" name="buyertel" value={pgData.buyertel} />
                        <input type="hidden" name="buyeremail" value={pgData.buyeremail} />
                        <input type="hidden" name="returnUrl" value={process.env.REACT_APP_SERVER_URL + "/api/pg/return"} />
                        {/*<input type="hidden" name="closeUrl" value={process.env.REACT_APP_SERVER_URL + "/feeIntroduce"} />*/}
                        <input type="hidden" name="closeUrl" value={process.env.REACT_APP_SERVER_URL+"/feeIntroduce/close"} />
                        <input type="hidden" name="acceptmethod" value="HPP(1):below1000:centerCd(Y)" />
                    </form>
                    <div className="card-footer justify-content-center">

                        <button className="btn btn-primary"
                            onClick={payBtn}>결제하기</button>
                    </div>

                </div>
            </div>
            <PublicFooter />
        </>
    );
}
{/*<button className="btn btn-primary" onClick={()=>funcPay()}>결제하기</button>*/ }
{/*<button className="btn btn-primary" onClick={()=>pgOpenPopUp()}>결제하기</button>*/ }
// const funcPay = async () => {
//
//     const req = sessionStorage.getItem('loginId');
//
//     if(req === null || req === "") {
//         alert("결제를 하기 위해 로그인 또는 회원가입을 해주세요.")
//         navigate('/login')
//         return;
//     }
//
//     const body = {userId : req}
//
//     const result = await findIsPay(body);
//
//     const isPay = result?.result?.isPay
//
//     if(isPay) {
//         alert("이미 결제가 완료된 계정입니다.")
//         navigate('/dashboard/main')
//     }else{
//         const result = await updateIsPay(body);
//         if(result.code === "0000"){
//             alert("결제가 완료되었습니다.")
//             navigate('/dashboard/main')
//         }else{
//             alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.")
//             return;
//         }
//     }
//
//
//
// }

// data={{
//     oid: pgData.oid,
//     price: pgData.price,
//     signKey: pgData.signKey,
//     signature: pgData.signature,
//     timestamp: pgData.timestamp,
//     verification: pgData.verification
// }}