import axios from "axios";
import Cookies from "js-cookie";
axios.defaults.headers.post["Content-Type"] = "application/json";

const basicApi = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});


// 모든 요청에 헤더에 토큰을 추가하는 인터셉터 설정
basicApi.interceptors.request.use(
    config => {
        const token = document.cookie.replace(/(?:(?:^|.*;\s*)accToken\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 설정
basicApi.interceptors.response.use(
    response => {
        // 응답이 성공적인 경우
        return response.data;
    },
    error => {
        // 응답이 실패한 경우
        if (error.response) {
            // 서버가 응답을 반환한 경우
            if (error.response.status === 401) {
                // 401 Unauthorized 처리 로직
                Cookies.remove('accToken')
                // 로그인 페이지로 리디렉션
                window.open(process.env.PUBLIC_URL + "/")
            } else if (error.response.status === 403) {
                // 403 Forbidden 처리 로직
                // 접근 거부 처리, 알림 표시 등 추가 로직
                alert('Access denied');
                Cookies.remove('accToken')
                window.open(process.env.PUBLIC_URL + "/")
            }

            return Promise.reject(error.response.data);

        }
        else if(error.request){
            return Promise.reject(new Error("시스템 오류가 발생했습니다. 고객센터로 문의주세요."));
        }
        return Promise.reject(error.response.data);
    }
);

class APIClient {
    get = (url, data) => {
        return basicApi.get(url, { params: data });
    };

    create = (url, data) => {
        return basicApi.post(url, data);
    };


    delete = (url, data) => {
        return basicApi.delete(url, { data: data });
    };

    updateWithFile = (url, data) => {
        const formData = new FormData();
        // 모든 파일을 'files'라는 키 아래에 추가
        data.files.forEach((file) => {
            formData.append("files", file); // 'files'라는 동일한 키 아래에 추가
        });

        // filesId 배열은 문자열로 변환해서 전송 (서버에서 처리 가능하도록)
        formData.append("filesId", data.filesId.join(',')); // '1,2' 형식으로 변환하여 추가

        // userId 추가
        formData.append("userId", data.userId);
        formData.append("type",data.type)
        formData.append("companyNumber",data.companyNumber)

        const config = {
            headers: {
                ...basicApi.defaults.headers,
                "content-type": "multipart/form-data",
            },
        };
        return basicApi.post(url, formData, config);
    };

    coatingUpload = (url, data) => {
        const formData = new FormData();
        formData.append("file", data.file[0]);

        const config = {
            headers: {
                ...basicApi.defaults.headers,
                "content-type": "multipart/form-data",
            },
        };
        return basicApi.post(url, formData, config);
    };

    noMemberCoatingUpload = (url, data) => {
        const formData = new FormData();
        formData.append("file", data.file[0]);
        formData.append("wasteRegistrationIdx",data.wasteRegistrationIdx)


        const config = {
            headers: {
                ...basicApi.defaults.headers,
                "content-type": "multipart/form-data",
            },
        };
        return basicApi.post(url, formData, config);
    };



    blobFile = (url, data) => {
        const config = {
            headers: {
                'Accept': 'application/json;charset=utf-8', // 파일 형식에 따라 조정 필요
            },
            responseType: 'blob', // 응답을 blob으로 처리
        };
        return basicApi.post(url, data, config);
    };


}

export { APIClient };
