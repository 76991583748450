import { postArriveDelete, postArriveSearch, postInformationSendSearch } from "../../../api/information";
import React, { useEffect, useState } from "react";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { MobileHeader } from "../../common/MobileHeader";
import { Sidebar } from "../../common/Sidebar";
import { PcHeader } from "../../common/PcHeader";

export const InformationSendCheck = () => {

    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");

    const [responseData, setResponseData] = useState([])
    const [total, setTotal] = useState(0)

    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 15,
    })

    const [adminCheck, setAdminCheck] = useState(false)

    // 총 페이지 수 상태
    const [totalPages, setTotalPages] = useState(0);

    // 모든 체크박스의 상태를 관리하는 state
    const [checkedItems, setCheckedItems] = useState([]); // 선택된 항목들의 idx를 저장하는 상태
    const [isAllChecked, setIsAllChecked] = useState(false); // 전체 선택 여부를 저장하는 상태
    const informationSearch = async () => {

        try {

            const response = await postArriveSearch(pageNation)

            if (response.code === "0000") {

                const resultData = response.result?.result || [];
                setResponseData(resultData);


                // resultData가 비어있다면 totalCount는 0으로 설정
                const totalCount = resultData.length > 0 ? resultData[0].totalCount : 0;
                setTotal(totalCount);


                setTotalPages(Math.max(1, Math.ceil(totalCount / pageNation.rowCnt))); // 최소 1페이지

                console.log(response.result.check)

                if (response.result.check === 1) {
                    setAdminCheck(true)
                }
                else {
                    setAdminCheck(false)
                }
            }
        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }

    }

    const handleErrorClose = () => {
        setShowError(false)
        informationSearch()
        setCheckedItems([]);
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        informationSearch()
    }, []);

    // 페이지 번호가 변경될 때마다 데이터 검색
    useEffect(() => {
        informationSearch();
    }, [pageNation]); // pageNation이 변경될 때마다 호출

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };



    // 전체 선택/해제 핸들러
    const handleSelectAll = () => {
        if (isAllChecked) {
            // 전체 해제 시 상태 초기화
            setCheckedItems([]);
        } else {
            // 전체 선택 시 모든 idx 저장
            const allIds = responseData.map((item) => item.idx);
            setCheckedItems(allIds);
        }
        setIsAllChecked(!isAllChecked); // 전체 선택 상태 업데이트
    };

    // 개별 체크박스 핸들러
    const handleItemCheck = (idx) => {

        if (checkedItems.includes(idx)) {
            // 이미 선택된 항목이면 해당 idx를 제거
            setCheckedItems(checkedItems.filter((item) => item !== idx));
        } else {
            // 선택되지 않은 항목이면 idx 추가
            setCheckedItems([...checkedItems, idx]);
        }
    };

    // 삭제 버튼 클릭 시 체크된 항목 서버로 전송
    const handleDelete = async () => {
        try {

            console.log({ idx: checkedItems })

            const response = await postArriveDelete({
                idx: checkedItems
            })

            if (response.code === "0000") {
                setShowErrorMessage("삭제 되었습니다.")
                setShowError(true)
            }
        } catch (e) {
            setShowErrorMessage("삭제 실패 관리자에게 문의바랍니다.")
            setShowError(true)
        }
    };

    const handlerDetail = (idx, approveCheck) => {

        try {
            navigate(`/information/sendCheck/detail/${idx}`, { state: { approveCheck } });

        }
        catch (e) {
            setShowErrorMessage("상세내용 오류 관리자에게 문의바랍니다.")
            setShowError(true)
        }
    }


    const getStartIndex = (pageNum) => {
        return (pageNum - 1) * pageNation.rowCnt;
    };

    const startIndex = getStartIndex(pageNation.pageNum);
    return (
        <React.Fragment >
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_data">
                                <div className="card-header">
                                    기재사항 확인 및 수정
                                </div>
                                <div className="card-body">
                                    <div className="card_top_with_btns mb-13px">
                                        <h6 className="table_stats">기재사항확인 및 수정 목록 (<span>{total}</span> 건)</h6>

                                        {adminCheck && (
                                            <button className="btn btn-outline-danger"
                                                onClick={handleDelete}
                                            >삭제</button>
                                        )}
                                    </div>
                                    <div className="table_scroll">
                                        <table className="table table-bordered my_table xmb-55px">
                                            <thead>
                                                <tr>
                                                    {adminCheck && (
                                                        <th style={{ width: "5.59%" }} className="text-center">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={isAllChecked} // 전체 선택 여부에 따라 체크 상태 변화
                                                                onChange={handleSelectAll} // 전체 선택 핸들러
                                                            />
                                                        </th>
                                                    )}
                                                    <th style={{ width: "5.59%" }}>번호</th>
                                                    <th style={{ width: "35.46%" }}>공사 명</th>
                                                    <th style={{ width: "15.34%" }}>신청일시</th>
                                                    <th style={{ width: "15.24%" }}>요청 이메일</th>
                                                    <th style={{ width: "8.4%" }}>진행 승인여부</th>
                                                    <th style={{ width: "8.4%" }}>상세내용</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {responseData.length > 0 ? (
                                                    responseData.map((item, index) => (
                                                        <tr key={item.idx}
                                                            className="clickable"
                                                            onClick={() => handlerDetail(item.idx, item.approveCheck)}>
                                                            {adminCheck && (
                                                                <td className="text-center">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={checkedItems.includes(item.idx)} // 개별 체크박스 상태
                                                                        onChange={() => handleItemCheck(item.idx)} // 개별 체크박스 핸들러
                                                                    />
                                                                </td>
                                                            )}
                                                            <td className="text-center">{startIndex + index + 1}</td>
                                                            <td className="text-center">{item.constructionText}</td>
                                                            <td className="text-center">{item.createdAt}</td>
                                                            <td className="text-center">{item.email}</td>
                                                            <td className="text-center">{item.approveCheck}</td>
                                                            <td className="text-center">
                                                                <button className="btn btn-primary btn_tbl"
                                                                    onClick={() => handlerDetail(item.idx, item.approveCheck)}
                                                                >상세내용 보기</button>
                                                            </td>
                                                        </tr>
                                                    )))
                                                    : (
                                                        <tr className="no-data-message">
                                                            <td className="text-center" colSpan={7}>조회된 데이터가 없습니다.</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>



                                    <div className="pagination_container mb-55px">
                                        <ul>
                                            {responseData.length > 0 &&
                                                // 페이지가 존재하는 경우 페이지네이션 렌더링
                                                <>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                            alt="First Page"
                                                            onClick={() => handlePageChange(1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                            alt="Previous Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>

                                                    {/* 페이지 번호 배열 생성 */}
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li key={index}
                                                            onClick={() => handlePageChange(index + 1)}
                                                            className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                        >
                                                            {index + 1} {/* 페이지 번호 표시 */}
                                                        </li>
                                                    ))}

                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                            alt="Next Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum < totalPages ? pageNation.pageNum + 1 : totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                            alt="Last Page"
                                                            onClick={() => handlePageChange(totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>삭제 성공</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}