import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {postArriveSearchDetail, postWasteSearch} from "../../../api/information";
import {scrollToTop} from "../../../api/utils/scrollToTop";
import {scrollToRef} from "../../../api/utils/scrollToRef";
import {fileUpload} from "../../../api/auth";
import {InformationSendCheckEditSecond} from "./InformationSendCheckEditSecond";
import InformationSendCheckEditFirstImagePreviewModal from "./modal/InformationSendCheckEditFirstImagePreviewModal";
import { MobileHeader } from '../../common/MobileHeader';
import { Sidebar } from '../../common/Sidebar';
import { PcHeader } from '../../common/PcHeader';

export const InformationSendCheckEditFirst = () => {

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const {idx} = useParams();
    
    const location = useLocation();
    const { approveCheck } = location.state || {};
    const approveCheckValue = approveCheck === 'Y';
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");

    const handleErrorClose = () => {
        setShowError(false)
    }
    const [registerReq, setRegisterReq] = useState({
        informationSendIdx: "",
        name: "",
        ceoName: "",
        companyKind: "",
        ceoCellphone: "",
        taxEmail: "",
        companyAddress: "",
        allbaroName: "",
        allbaroCellphone: "",
        siteManagerCellphone: "",
        siteManagerName: "",
        companyNumber: "",
        orderName: "",
        orderCeoName: "",
        orderCompanyAddress: "",
        constructionName: "",
        constructionCeoName: "",
        constructionAddress: "",
        constructionText: "", //공사명
        constructionStartDate: "",
        constructionEndDate: "",
        constructionSiteAddress: "",
        constructionSiteAddressDetail: "",
        wasteKinds: [],
    })

    const [companyNumber, setCompanyNumber] = useState("")
    const companyNumberRef = useRef(null)

    const [companyNumber2, setCompanyNumber2] = useState("")
    const companyNumberRef2 = useRef(null)


    const [companyNumber3, setCompanyNumber3] = useState("")
    const companyNumberRef3 = useRef(null)


    const [registerFileReq, setRegisterFileReq] = useState({
        companyNumberFile: [],
        fileName: "",
    })

    const companyNumberFileRef = useRef(null)
    const [companyFileCheck, setCompanyFileCheck] = useState(false)

    const nameRef = useRef(null)
    const ceoNameRef = useRef(null)
    const companyKindRef = useRef(null)
    const companyAddressRef = useRef(null)
    const allbaroNameRef = useRef(null)
    const siteManagerNameRef = useRef(null)


    const [ceoCellphonePart1, setCeoCellphonePart1] = useState("");
    const [ceoCellphonePart2, setCeoCellphonePart2] = useState("");
    const [ceoCellphonePart3, setCeoCellphonePart3] = useState("");

    const [allbarophonePart1, setAllbarophonePart1] = useState("");
    const [allbarophonePart2, setAllbarophonePart2] = useState("");
    const [allbarophonePart3, setAllbarophonePart3] = useState("");

    const [siteManagerphonePart1, setSiteManagerPhonePart1] = useState("");
    const [siteManagerphonePart2, setSiteManagerPhonePart2] = useState("");
    const [siteManagerphonePart3, setSiteManagerPhonePart3] = useState("");

    const part1Ref = useRef(null) //연락처 앞
    const part2Ref = useRef(null); //중간
    const part3Ref = useRef(null); //끝

    const allbaroPart1Ref = useRef(null) //올바로 연락처 앞
    const allbaroPart2Ref = useRef(null); //중간
    const allbaroPart3Ref = useRef(null); //끝

    const siteManagePart1Ref = useRef(null) //현장 연락처 앞
    const siteManagePart2Ref = useRef(null); //중간
    const siteManagePart3Ref = useRef(null); //끝

    const [taxEmailLocalPart, setTaxEmailLocalPart] = useState('');
    const [taxEmailDomainPart, setTaxEmailDomainPart] = useState('');

    const taxEmailRef1 = useRef(null)
    const taxEmailRef2 = useRef(null);

    const [previewUrl, setPreviewUrl] = useState('');

    const loadingDetail = async () => {

        try {
            console.log("idx체크" + idx)

            const response = await postArriveSearchDetail({
                idx: idx,
            })

            if (response.code === "0000") {

                const result = response.result[0]; // 첫 번째 결과 가져오기
                const base64Data = result.fileByte; // Base64 데이터 가져오기

                // Base64 데이터를 Blob으로 변환
                const byteCharacters = atob(base64Data); // Base64 문자열을 디코드
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers); // Uint8Array로 변환
                const blob = new Blob([byteArray], {type: 'image/png'}); // Blob 생성 (여기서는 PNG로 가정)
                const url = URL.createObjectURL(blob); // Blob URL 생성
                setPreviewUrl(url); // 상태에 URL 저장

                setRegisterFileReq(preveState => ({
                    fileName: result.fileName || "",
                }));

                setRegisterReq(prevState => ({
                    informationSendIdx: result.informationSendIdx || "",
                    name: result.name || "",
                    ceoName: result.ceoName || "",
                    companyKind: result.companyKind || "",
                    ceoCellphone: result.ceoCellphone || "",
                    taxEmail: result.taxEmail || "",
                    companyAddress: result.companyAddress || "",
                    allbaroName: result.allbaroName || "",
                    allbaroCellphone: result.allbaroCellphone || "",
                    siteManagerCellphone: result.siteManagerCellphone || "",
                    siteManagerName: result.siteManagerName || "",
                    companyNumber: result.companyNumber || "",
                    orderName: result.orderName || "",
                    orderCeoName: result.orderCeoName || "",
                    orderCompanyAddress: result.orderCompanyAddress || "",
                    constructionName: result.constructionName || "",
                    constructionCeoName: result.constructionCeoName || "",
                    constructionAddress: result.constructionAddress || "",
                    constructionText: result.constructionText || "",
                    constructionTextDetail: result.constructionTextDetail || "",
                    constructionStartDate: result.constructionStartDate || "",
                    constructionEndDate: result.constructionEndDate || "",
                    constructionSiteAddress: result.constructionSiteAddress || "",
                    constructionSiteAddressDetail: result.constructionSiteAddressDetail || "",
                    wasteKinds: result.wasteKinds || [], // 기존 값 유지
                }));


                const companyNumber = result.companyNumber || "";

                // 전화번호 처리
                handleCellphone(result.ceoCellphone || "", setCeoCellphonePart1, setCeoCellphonePart2, setCeoCellphonePart3);
                handleCellphone(result.allbaroCellphone || "", setAllbarophonePart1, setAllbarophonePart2, setAllbarophonePart3);
                handleCellphone(result.siteManagerCellphone || "", setSiteManagerPhonePart1, setSiteManagerPhonePart2, setSiteManagerPhonePart3);

                setCompanyNumber(companyNumber.slice(0, 3))
                setCompanyNumber2(companyNumber.slice(3, 5))
                setCompanyNumber3(companyNumber.slice(5))

                const emailParts = result.taxEmail.split('@');
                setTaxEmailLocalPart(emailParts[0] || '');
                setTaxEmailDomainPart(emailParts[1] || '');


            }

        } catch (e) {

            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }


    const handleCellphone = (cellphone, setPart1, setPart2, setPart3) => {
        if (cellphone.length === 11) {
            setPart1(cellphone.slice(0, 3));
            setPart2(cellphone.slice(3, 7));
            setPart3(cellphone.slice(7));
        } else {
            setPart1(cellphone.slice(0, 3));
            setPart2(cellphone.slice(3, 6));
            setPart3(cellphone.slice(6));
        }
    };


    useEffect(() => {
        scrollToTop()
        loadingDetail()
    }, []);

    const companyNumberFirstHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        setCompanyNumber(e);
        if (e.length === 3) {
            companyNumberRef2.current.focus()
        }

    }

    const companyNumberSecondHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        if (e.length === 2) {
            companyNumberRef3.current.focus()
        }
        setCompanyNumber2(e);
    }

    const companyNumberThirdHandler = (e) => {

        e = e.replace(/\D/g, '');
        setCompanyNumber3(e);
    }

    const handleTaxEmailLocalPartChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^a-zA-Z0-9]/g, '');

        setTaxEmailLocalPart(value);
    };

    const handleTaxEmailDomainPartChange = (e) => {
        let value = e.target.value;

        // 알파벳과 숫자가 아닌 문자는 제거합니다.
        value = value.replace(/[^a-zA-Z0-9.]/g, '');
        if (value === "") {
            setTimeout(() => {
                taxEmailRef2.current.focus();
            }, 0);
        }
        setTaxEmailDomainPart(value);
    };

    const companyNumberFindCheck = async () => {

        try {

            const fullCompanyNumber = companyNumber + companyNumber2 + companyNumber3

            if (fullCompanyNumber.length !== 10) {
                setShowErrorMessage("사업자 등록번호 형식이 아닙니다.")
                setShowError(true)
                return
            }

            const updatedRegisterReq = {
                ...registerReq, //기존 필드 유지
                companyNumber: fullCompanyNumber
            };

            const response = await postWasteSearch(updatedRegisterReq)
            console.log(response)
            if (response.code === "0000") {
                const result = response.result.result;

                const base64Data = response.result.fileByte; // Base64 데이터 가져오기

                // Base64 데이터를 Blob으로 변환
                const byteCharacters = atob(base64Data); // Base64 문자열을 디코드
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers); // Uint8Array로 변환
                const blob = new Blob([byteArray], {type: 'image/png'}); // Blob 생성 (여기서는 PNG로 가정)
                const url = URL.createObjectURL(blob); // Blob URL 생성
                setPreviewUrl(url); // 상태에 URL 저장

                setRegisterFileReq(preveState => ({
                    fileName: result.fileName || "",
                }));


                const fileResult = response.result.fileName ? response.result.fileName[0] : ""; // 배열에서 첫 번째 파일 이름
                setRegisterReq(prevState => ({
                    informationSendIdx: result.informationSendIdx, // 기존 값 유지
                    name: result.name || "",
                    ceoName: result.ceoName || "",
                    companyKind: result.companyKind || "",
                    ceoCellphone: result.ceoCellphone || "",
                    taxEmail: result.taxEmail || "",
                    companyAddress: result.companyAddress || "",
                    allbaroName: result.allbaroName || "",
                    allbaroCellphone: result.allbaroCellphone || "",
                    siteManagerCellphone: result.siteManagerCellphone || "",
                    siteManagerName: result.siteManagerName || "",
                    companyNumber: result.companyNumber || "",
                    orderName: prevState.orderName || "",
                    orderCeoName: prevState.orderCeoName || "",
                    orderCompanyAddress: prevState.orderCompanyAddress || "",
                    constructionName: prevState.constructionName || "",
                    constructionCeoName: prevState.constructionCeoName || "",
                    constructionAddress: prevState.constructionAddress || "",
                    constructionText: prevState.constructionText || "",
                    constructionTextDetail: prevState.constructionTextDetail || "",
                    constructionStartDate: prevState.constructionStartDate || "",
                    constructionEndDate: prevState.constructionEndDate || "",
                    constructionSiteAddress: prevState.constructionSiteAddress || "",
                    constructionSiteAddressDetail: prevState.constructionSiteAddressDetail || "" ,
                }));

                setRegisterFileReq(prevState => ({
                    fileName: fileResult || "", // 배열에서 첫 번째 파일 이름을 사용
                }));
                const ceoCellphone = result.ceoCellphone || "";
                const allbaroCellphone = result.allbaroCellphone || "";
                const siteManagerCellphone = result.siteManagerCellphone || "";

                // 전화번호 처리
                handleCellphone(result.ceoCellphone || "", setCeoCellphonePart1, setCeoCellphonePart2, setCeoCellphonePart3);
                handleCellphone(result.allbaroCellphone || "", setAllbarophonePart1, setAllbarophonePart2, setAllbarophonePart3);
                handleCellphone(result.siteManagerCellphone || "", setSiteManagerPhonePart1, setSiteManagerPhonePart2, setSiteManagerPhonePart3);

                const emailParts = result.taxEmail.split('@');
                setTaxEmailLocalPart(emailParts[0] || '');
                setTaxEmailDomainPart(emailParts[1] || '');
            }
        } catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }

    const companyNumberClick = () => {
        scrollToRef(companyNumberRef)
    }

    const ceoCellPhoneHandler = (e, part) => {
        let value = e.target.value;

        // 숫자가 아닌 문자는 제거
        value = value.replace(/\D/g, '');

        if (part === 'part1') {
            setCeoCellphonePart1(value);
            if (value.length === 3) {
                if (value === '010') {
                    part2Ref.current.maxLength = 4;
                } else {
                    part2Ref.current.maxLength = 3;
                }
                part2Ref.current.focus();
            }
        } else if (part === 'part2') {
            setCeoCellphonePart2(value);
            if ((ceoCellphonePart1 === '010' && value.length === 4) ||
                (ceoCellphonePart1 !== '010' && value.length === 3)) {
                part3Ref.current.focus();
            }
        } else if (part === 'part3') {
            setCeoCellphonePart3(value);
        } else if (part === 'allbaroPart1') {
            setAllbarophonePart1(value)
            if (value.length === 3) {
                if (value === '010') {
                    allbaroPart2Ref.current.maxLength = 4;
                } else {
                    allbaroPart2Ref.current.maxLength = 3;
                }
                allbaroPart2Ref.current.focus();
            }
        } else if (part === 'allbaroPart2') {
            setAllbarophonePart2(value)
            if ((allbarophonePart1 === '010' && value.length === 4) ||
                (allbarophonePart1 !== '010' && value.length === 3)) {
                allbaroPart3Ref.current.focus();
            }
        } else if (part === 'allbaroPart3') {
            setAllbarophonePart3(value)
        } else if (part === 'siteManagerPart1') {
            setSiteManagerPhonePart1(value)
            if (value.length === 3) {
                if (value === '010') {
                    siteManagePart2Ref.current.maxLength = 4;
                } else {
                    siteManagePart2Ref.current.maxLength = 3;
                }
                siteManagePart2Ref.current.focus();
            }
        } else if (part === 'siteManagerPart2') {
            setSiteManagerPhonePart2(value)
            if ((siteManagerphonePart1 === '010' && value.length === 4) ||
                (siteManagerphonePart1 !== '010' && value.length === 3)) {
                siteManagePart3Ref.current.focus();
            }
        } else if (part === 'siteManagerPart3') {
            setSiteManagerPhonePart3(value)
        }
    };

    const onKeyDownHandler = (e, part) => {
        if (e.key === 'Enter') {
            e.preventDefault();   // 기본 동작 (예: 폼 제출)을 취소합니다.

            if (part === 'companyNumber') {
                nameRef.current.focus()
            } else if (part === 'name') {
                ceoNameRef.current.focus();
            } else if (part === 'ceoName') {
                companyKindRef.current.focus();
            } else if (part === 'companyKind') {
                part1Ref.current.focus()
            } else if (part === 'part1') {
                part2Ref.current.focus()
            } else if (part === 'part2') {
                part3Ref.current.focus()
            } else if (part === 'part3') {
                companyAddressRef.current.focus();
            } else if (part === 'companyAddress') {
                taxEmailRef1.current.focus();
            } else if (part === 'taxEmailLocal') {
                taxEmailRef2.current.focus();
            } else if (part === 'taxEmailDomain') {
                allbaroNameRef.current.focus();
            } else if (part === 'allbaroName') {
                allbaroPart1Ref.current.focus();
            } else if (part === 'allbaroPart1') {
                allbaroPart2Ref.current.focus();
            } else if (part === 'allbaroPart2') {
                allbaroPart3Ref.current.focus();
            } else if (part === 'allbaroPart3') {
                siteManagerNameRef.current.focus();
            } else if (part === 'siteManagerName') {
                siteManagePart1Ref.current.focus();
            } else if (part === 'siteManagerPart1') {
                siteManagePart2Ref.current.focus();
            } else if (part === 'siteManagerPart2') {
                siteManagePart3Ref.current.focus();
            }

        }
    };

    const [currentComponent, setCurrentComponent] = useState('first'); // 현재 컴포넌트를 제어하는 상태
    const [dataToPass, setDataToPass] = useState(null); // 두 번째 컴포넌트로 전달할 데이터

    const nextHandlerClick = () => {

        // 현재 registerReq 상태 확인
        console.log("현재 registerReq:", registerReq);

        const mergedCompanyNumber = `${companyNumber}${companyNumber2}${companyNumber3}`;
        const mergedCeoCellphone = `${ceoCellphonePart1}${ceoCellphonePart2}${ceoCellphonePart3}`;
        const mergedEmail = taxEmailLocalPart && taxEmailDomainPart
            ? `${taxEmailLocalPart}@${taxEmailDomainPart}` : "";
        const mergedAllbarophone = `${allbarophonePart1}${allbarophonePart2}${allbarophonePart3}`;
        const mergedSiteManagerphone = `${siteManagerphonePart1}${siteManagerphonePart2}${siteManagerphonePart3}`;

        console.log("머지체크" + mergedCompanyNumber)

        const updatedRegisterReq = {
            ...registerReq, //기존 필드 유지
            ceoCellphone: mergedCeoCellphone,
            taxEmail: mergedEmail,
            allbaroCellphone: mergedAllbarophone,
            siteManagerCellphone: mergedSiteManagerphone,
            companyNumber: mergedCompanyNumber,
        };


        console.log("업데이트" + updatedRegisterReq)

        const errorMessages = {
            companyNumber: "배출업체 사업자 등록번호를 입력해주세요.",
            name: "배출업체 상호를 입력해주세요.",
            ceoName: "배출업체 대표자를 입력해주세요.",
            companyKind: "배출업체 업종을 입력해주세요.",
            ceoCellphone: "배출업체 연락처를 입력해주세요.",
            taxEmail: "세금계산서발행 이메일 주소를 입력해주세요.",
            companyAddress: "주소(사업자등록증상 주소)를 입력해주세요.",
            allbaroName: "올바로 담당자 성함을 입력해주세요.",
            allbaroCellphone: "올바로 담당자 연락처를 입력해주세요.",
            siteManagerCellphone: "현장 담당자 연락처를 입력해주세요.",
            siteManagerName: "현장 담당자 성함을 입력해주세요.",
        };

        for (const [key, value] of Object.entries(updatedRegisterReq)) {
            // errorMessages에 해당 key가 존재할 때만 검사
            if (key in errorMessages && value === "") {
                // key에 해당하는 에러 메시지를 errorMessages 객체에서 가져옴
                const errorMessage = errorMessages[key];
                if (errorMessage) {
                    setShowErrorMessage(errorMessage);
                    setShowError(true);
                }
                return;
            }
        }

        console.log("파일체크" + companyFileCheck)

        // register(mergedCompanyNumber)

        setDataToPass({
            updatedRegisterReq,
            registerFileReq
        });
        // / 첫 번째 컴포넌트에서 두 번째 컴포넌트로 전환
        setCurrentComponent('second');
    };

    const [secondComponentData, setSecondComponentData] = useState({}); // 두 번째 컴포넌트의 데이터를 저장

    // 두 번째 컴포넌트에서 첫 번째 컴포넌트로 돌아가면서 데이터를 넘기는 함수
    const goBackToFirst = (data) => {

        console.log("데이터체크" + data)
        setSecondComponentData(data);
        setCurrentComponent('first');
    };

    // useEffect를 통해 secondComponentData가 변경될 때 registerReq 업데이트
    useEffect(() => {
        console.log("전달받은데이터" + secondComponentData)
        if (secondComponentData) {
            setRegisterReq(prevRegisterReq => ({
                ...prevRegisterReq, // 기존 필드 유지
                ...secondComponentData, // 새로운 데이터로 업데이트
            }));
        }
    }, [secondComponentData]);

    const companyNumberFileHandler = (event) => {
        const files = event?.target?.files; // 선택된 파일들
        console.log(companyFileCheck);

        // 파일 크기 500KB 제한 (500 * 1024 bytes)
        const MAX_FILE_SIZE = 500 * 1024; // 500KB

        // 이미지 및 PDF 파일만 유효한 파일로 필터링
        const validFiles = Array.from(files).filter(file => isValidFileType(file) && file.size <= MAX_FILE_SIZE);

        // 파일이 유효한 파일만 있는지 확인
        if (validFiles.length === files.length) {
            const fileName = files[0].name; // 첫 번째 파일의 이름만 가져옴

            console.log(fileName); // 'company.png'만 출력
            setRegisterFileReq((prev) => ({
                ...prev,
                companyNumberFile: validFiles, // 선택된 모든 유효한 파일을 배열로 설정
                fileName: fileName,
            }));
            console.log("files", files);
            console.log("밸리드용", validFiles);

            const fileUrl = URL.createObjectURL(validFiles[0]); // 파일의 URL 생성

            setCompanyFileCheck(true);
        } else {
            // 유효한 파일이 아닌 경우 이미지 및 PDF 파일만 허용한다고 메시지 표시
            if (Array.from(files).some(file => !isValidFileType(file))) {
                setShowErrorMessage("이미지 파일 및 PDF만 가능합니다.");
            }
            // 파일 크기가 500KB 초과인 경우
            if (Array.from(files).some(file => file.size > MAX_FILE_SIZE)) {
                setShowErrorMessage("파일 크기가 500KB 이하만 가능합니다.");
            }

            setShowError(true);
            setCompanyFileCheck(false);
        }
    };

    const isValidFileType = (file) => {
        const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };


    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태

    const openModal = () => {
        setIsModalOpen(true);
    };


    return (
        <React.Fragment>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt=""/>
                    <h5>인증 실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                    </button>
                </Modal.Body>
            </Modal>
            <MobileHeader/>
            {currentComponent === 'first' ? (
                <div className="main-sidebar">
                    <Sidebar/>
                    <div className="main-container">
                        <PcHeader title="폐기물 관리" icon="/images/icon_header2.png"/>
                        <div className="body-box">
                            <div className="body-container">
                                <div className="card card_main">
                                    <div className="card-header">
                                        <span>기재사항 확인 및 수정 &nbsp;&gt; &nbsp;</span> 기재사항 상세정보
                                    </div>
                                    <div className="card-body">
                                        <div className="stepper_wrapper lg">
                                            <div className="stepper_line"></div>
                                            <ul>
                                                <li className="active">
                                                    <div className="stepper_dot dot_1"></div>
                                                    <div className="stepper_text">배출업체정보</div>
                                                </li>
                                                <li className="">
                                                    <div className="stepper_dot dot_2"></div>
                                                    <div className="stepper_text">발주처 및 공사내용</div>
                                                </li>
                                                <li>
                                                    <div className="stepper_dot dot_3"></div>
                                                    <div className="stepper_text">확인완료</div>
                                                </li>
                                            </ul>
                                        </div>

                                        <h3 className="table_heading">1. 사업자등록번호</h3>
                                        <div className="table_scroll">
                                            <table className="table table-bordered card_table">
                                                <tbody>
                                                <tr>
                                                    <th style={{width: "12.82%"}}>사업자 등록번호</th>
                                                    <td>
                                                        <div className="flex-center-between">
                                                            <div class="frm_field input_phone input_phone_tbl">
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       ref={companyNumberRef}
                                                                       onChange={(e) => companyNumberFirstHandler(e.target.value)}
                                                                       value={companyNumber}
                                                                       maxLength={3}
                                                                       disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       ref={companyNumberRef2}
                                                                       onChange={(e) => companyNumberSecondHandler(e.target.value)}
                                                                       maxLength={2}
                                                                       value={companyNumber2}
                                                                       disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       ref={companyNumberRef3}
                                                                       onChange={(e) => companyNumberThirdHandler(e.target.value)}
                                                                       maxLength={5}
                                                                       value={companyNumber3}
                                                                       onKeyDown={(e) => onKeyDownHandler(e, 'companyNumber')}
                                                                       disabled={approveCheckValue}
                                                                />
                                                            </div>

                                                            {!approveCheckValue && (
                                                            <button className="btn btn-secondary"
                                                                    onClick={companyNumberFindCheck}
                                                            >검색
                                                            </button>
                                                                )}
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <h3 className="table_heading">2. 배출업체 정보(시공사, 발주처에서 의뢰 받은 업체)</h3>
                                        <div className="table_scroll">
                                            <table className="table table-bordered card_table">
                                                <tbody>
                                                <tr>
                                                    <th style={{width: "12.82%"}}>상호</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={registerReq.name || ""} // 값이 없을 때 빈 문자열로 처리
                                                               onChange={(e) =>
                                                                   setRegisterReq((prevState) => ({
                                                                       ...prevState,
                                                                       name: e.target.value,
                                                                   }))
                                                               }
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'name')}
                                                               ref={nameRef}
                                                               disabled={approveCheckValue}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>대표자</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={registerReq.ceoName}
                                                               onChange={(e) =>
                                                                   setRegisterReq((prevState) => ({
                                                                       ...prevState,
                                                                       ceoName: e.target.value,
                                                                   }))
                                                               }
                                                               ref={ceoNameRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'ceoName')}
                                                               disabled={approveCheckValue}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>업종 (대표업종1개)</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control" placeholder=""
                                                               value={registerReq.companyKind}
                                                               onChange={(e) =>
                                                                   setRegisterReq((prevState) => ({
                                                                       ...prevState,
                                                                       companyKind: e.target.value,
                                                                   }))
                                                               }
                                                               ref={companyKindRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'companyKind')}
                                                               disabled={approveCheckValue}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>연락처</th>
                                                    <td colSpan={3}>
                                                        <div className="flex-center-between">
                                                            <div class="frm_field input_phone input_phone_tbl">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="part1"
                                                                    value={ceoCellphonePart1}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'part1')}
                                                                    maxLength={3}
                                                                    ref={part1Ref}
                                                                    disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="part2"
                                                                    value={ceoCellphonePart2}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'part2')}
                                                                    maxLength={ceoCellphonePart1 === '010' ? 4 : 3}
                                                                    ref={part2Ref}
                                                                    disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="part3"
                                                                    value={ceoCellphonePart3}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'part3')}
                                                                    maxLength={4}
                                                                    ref={part3Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'part3')}
                                                                    disabled={approveCheckValue}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>주소<br/>
                                                        (사업자등록증상 주소)
                                                    </th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control" placeholder=""
                                                               value={registerReq.companyAddress}
                                                               onChange={(e) => setRegisterReq({
                                                                   ...registerReq,
                                                                   companyAddress: e.target.value
                                                               })}
                                                               ref={companyAddressRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'companyAddress')}
                                                               disabled={approveCheckValue}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>세금계산서 발행<br/>
                                                        이메일 주소
                                                    </th>
                                                    <td colSpan={3}>
                                                        <div class="frm_field input_email input_email_tbl">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={taxEmailLocalPart}
                                                                onChange={handleTaxEmailLocalPartChange}
                                                                ref={taxEmailRef1}
                                                                onKeyDown={(e) => onKeyDownHandler(e, 'taxEmailLocal')}
                                                                disabled={approveCheckValue}
                                                            />
                                                            <span className="txt_range">@</span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={taxEmailDomainPart}
                                                                onChange={handleTaxEmailDomainPartChange}
                                                                ref={taxEmailRef2}
                                                                onKeyDown={(e) => onKeyDownHandler(e, 'taxEmailDomain')}
                                                                disabled={approveCheckValue}
                                                            />
                                                            <select
                                                                className="form-select"
                                                                onChange={handleTaxEmailDomainPartChange}
                                                                value={taxEmailDomainPart}
                                                                disabled={approveCheckValue}
                                                            >
                                                                <option value="">직접입력</option>
                                                                <option value="naver.com">naver.com</option>
                                                                <option value="gmail.com">gmail.com</option>
                                                                <option value="hanmail.net">hanmail.net</option>
                                                                <option value="yahoo.com">yahoo.com</option>
                                                                <option value="daum.net">daum.net</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>올바로 담당자 성함</th>
                                                    <td style={{width: "37.18%"}}>
                                                        <input type="text" className="form-control" placeholder=""
                                                               value={registerReq.allbaroName}
                                                               onChange={(e) => setRegisterReq({
                                                                   ...registerReq,
                                                                   allbaroName: e.target.value
                                                               })}
                                                               disabled={approveCheckValue}
                                                        />
                                                    </td>
                                                    <th style={{width: "12.82%"}}>올바로 담당자 연락처</th>
                                                    <td>
                                                        <div className="flex-center-between">
                                                            <div class="frm_field input_phone input_phone_tbl">
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       value={allbarophonePart1}
                                                                       onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart1')}
                                                                       maxLength={3}
                                                                       ref={allbaroPart1Ref}
                                                                       onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart1')}
                                                                       disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       value={allbarophonePart2}
                                                                       onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart2')}
                                                                       maxLength={allbarophonePart1 === '010' ? 4 : 3}
                                                                       ref={allbaroPart2Ref}
                                                                       onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart2')}
                                                                       disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       value={allbarophonePart3}
                                                                       onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart3')}
                                                                       maxLength={4}
                                                                       ref={allbaroPart3Ref}
                                                                       onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart3')}
                                                                       disabled={approveCheckValue}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>현장 담당자 성함</th>
                                                    <td>
                                                        <input type="text" className="form-control" placeholder=""
                                                               value={registerReq.siteManagerName}
                                                               onChange={(e) => setRegisterReq({
                                                                   ...registerReq,
                                                                   siteManagerName: e.target.value
                                                               })}
                                                               disabled={approveCheckValue}
                                                        />
                                                    </td>
                                                    <th>현장 담당자 연락처</th>
                                                    <td>
                                                        <div className="flex-center-between">
                                                            <div class="frm_field input_phone input_phone_tbl">

                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       value={siteManagerphonePart1}
                                                                       onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart1')}
                                                                       maxLength={3}
                                                                       ref={siteManagePart1Ref}
                                                                       onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart1')}
                                                                       disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       value={siteManagerphonePart2}
                                                                       onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart2')}
                                                                       maxLength={siteManagerphonePart1 === '010' ? 4 : 3}
                                                                       ref={siteManagePart2Ref}
                                                                       onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart2')}
                                                                       disabled={approveCheckValue}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text"
                                                                       className="form-control"
                                                                       value={siteManagerphonePart3}
                                                                       onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart3')}
                                                                       maxLength={4}
                                                                       ref={siteManagePart3Ref}
                                                                       onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart3')}
                                                                       disabled={approveCheckValue}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>사업자 등록증 사본 첨부</th>
                                                    <td colSpan={3}>
                                                        <div className="tbl_files_outer">
                                                            <input
                                                                placeholder=""
                                                                type="file"
                                                                className="form-control d-none"
                                                                ref={companyNumberFileRef}
                                                                id={"input-file"}
                                                                onChange={(e) => companyNumberFileHandler(e)}
                                                                disabled={approveCheckValue}
                                                            />
                                                            <ul className="list_files">
                                                                {registerFileReq.fileName && (
                                                                    <li>{registerFileReq.fileName}</li>
                                                                )}
                                                            </ul>
                                                            {registerFileReq.fileName !== "" ?
                                                            <button className="btn btn-secondary" 
                                                                onClick={openModal}
                                                                >미리보기
                                                            </button> : ""}
                                                            <InformationSendCheckEditFirstImagePreviewModal
                                                                show={isModalOpen}
                                                                handleClose={() => setIsModalOpen(false)}
                                                                download={registerFileReq.fileName}
                                                                previewUrl={previewUrl}/>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-footer card_footer_btngrp_end">
                                        <button className="btn btn-outline-secondary btn_footer"
                                            onClick={()=>navigate('/information/sendCheck')}
                                        >목록</button>
                                        <button className="btn btn-primary btn_footer"
                                                onClick={nextHandlerClick}
                                        >다음으로
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <InformationSendCheckEditSecond data={dataToPass} goBack={goBackToFirst} approveCheck={approveCheckValue}/>
            )}
        </React.Fragment>
    );
}