import { APIClient } from "./apiCore";
import * as url from "./urls/Pg";
import {POST_PG_OPEN_POP_UP, POST_PG_USER_INFO} from "./urls/Pg";
const api = new APIClient();
export const postPgUserInfo = (data) => api.create(url.POST_PG_USER_INFO, data)






