import React, { useEffect } from "react";
import {useNavigate} from "react-router-dom";

const FeeIntroduceClose = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // 스크립트 동적 추가
        const script = document.createElement("script");
        script.src = "https://stdpay.inicis.com/stdjs/INIStdPay_close.js";
        script.type = "text/javascript";
        script.charset = "UTF-8";
        document.body.appendChild(script);

        // 스크립트가 성공적으로 로드되면 리다이렉트
        script.onload = () => {
            console.log("Script loaded successfully.");
            navigate("/feeIntroduce");
        };

        // 스크립트 클린업
        return () => {
            document.body.removeChild(script);
        };
    }, [navigate]); // navigate는 의존성 배열에 추가해야 함

    return (
        <div>
        </div>
    );
};

export default FeeIntroduceClose;
