import { MobileHeader } from "../common/MobileHeader";
import { PcHeader } from "../common/PcHeader";
import { Sidebar } from "../common/Sidebar";
import React, { useState ,useRef, useEffect} from "react";
import {
    postCoatingCreate,
    postCoatingSearch,
    postCoatingUpload,
    postNoMemberCoatingCreate
} from "../../api/information";
import Modal from "react-bootstrap/Modal";
import {scrollToTop} from "../../api/utils/scrollToTop";

export const MyPageCoating = () => {

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [createCoatingCheck, setCreateCoatingCheck] = useState(false)
    const [uploadCoatingCheck, setUploadCoatingCheck] = useState(false)

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [electionCoating,setElectionCoatingReq] = useState("")
    const [selectedCoatingOption, setSelectedCoatingOption] = useState('electionCoating');

    const handleErrorClose = () => {
        setShowError(false)
    }

    const [coatingReq, setCoatingReq] = useState({
        type : "coating",
    })
    const handleUploadClick = () => {
        // 파일 선택 창 열기
        fileInputRef.current.click();
    };

    const handleSuccessClose = () => {
        setShowSuccess(false);
        mypageLoading()
        scrollToTop()
    }

    const [companyName, setCompanyName] = useState("")

    const handleFileChange = (event) => {

        const files = event?.target?.files; // 선택된 파일들

        // 파일이 선택되지 않은 경우
        if (!files || files.length === 0) {
            return;
        }

        const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링

        if (validFiles.length === files.length) {
            setSelectedFile(Array.from(files));
            const file = validFiles[0]; // 첫 번째 파일로 미리보기 생성
            const reader = new FileReader();

            reader.onloadend = () => {
                setFileUrl(reader.result); // Base64 문자열로 설정
            };
            reader.readAsDataURL(file); // 파일을 Data URL로 읽음
            setUploadCoatingCheck(true)
            // setSelectedCoatingOption("upload")
        }else {
            setShowErrorMessage("이미지 파일만 가능합니다.")
            setShowError(true)
            setUploadCoatingCheck(false)
        }
    };


    const handleCoatingOptionChange = (e) => {
        setSelectedCoatingOption(e.target.value);
        setCoatingReq({
            ...coatingReq,
            type: e.target.value
        });
    };

    const isValidFileType = (file) => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };


    const coatingCreateClick = async () => {
        try{

            const response = await postCoatingCreate({
                companyName : companyName
            })


            if(response.result.image){
                setElectionCoatingReq(response.result.image)
                setCreateCoatingCheck(true)
                setSelectedCoatingOption("coating")
            }
        }
        catch (e){
            setShowErrorMessage(e.message)
            setShowError(true)
            setCreateCoatingCheck(false)
        }
    }

    const companyNameHandler =(e) => {
        setCompanyName(e)
    }

    const coatingHandlerSubmit = async () => {


        if(!uploadCoatingCheck && electionCoating===""){
            setShowErrorMessage("생성된 도장이나 업로드 도장이 없습니다.")
            setShowError(true)
            return
        }

        if(uploadCoatingCheck){
            try {
                const response = await postCoatingUpload({
                    file: selectedFile
                })
            } catch (e) {
                setShowErrorMessage("업로드 도장 저장 오류입니다.")
                setShowError(true)
                setFileUrl("")
            }
        }

        setShowSuccess(true)
    }


    const mypageLoading = async () => {

        try{
            const response = await postCoatingSearch()

            if(response.result.image){
                setElectionCoatingReq(response.result.image)
            }
            if(response.result.uploadImage){
                // setFileUrl(response.result.uploadImage)
                // const byteCharacters = new Uint8Array(response.result.uploadImage);
                // const byteString = String.fromCharCode(...byteCharacters);
                // const base64String = btoa(byteString); // Base64로 인코딩
                setFileUrl(`data:image/png;base64,${response.result.uploadImage}`); // Base64 문자열로 URL 생성
            }
        }

        catch (e){
            setShowErrorMessage("도장 불러오기 오류가 발생했습니다.")
            setShowError(true)
        }
    }

    useEffect(() => {
        mypageLoading()
    }, []);

    return (
        <React.Fragment >
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="마이페이지" icon="/images/icon_square.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_main">
                                <div className="card-header">
                                    마이페이지
                                </div>
                                <div className="card-body">
                                    <h3 className="card_body_heading">도장 만들기</h3>

                                    <div className="table_scroll">
                                        <table className="table table-bordered card_table mb-4">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "12.82%" }}>업체명</th>
                                                    <td>
                                                        <div className="d-flex">
                                                            <input placeholder="업체명을 입력해주세요." type="text" className="form-control"
                                                                   onChange={(e) => companyNameHandler(e.target.value)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="stampimg_container">
                                        <div className="toolbar">
                                            <div className="row wd-100">
                                                <div className="col text-end">
                                                    <button className="btn btn-outline-secondary ms-10px"
                                                            onClick={coatingCreateClick}
                                                    >도장 생성</button>
                                                    </div>
                                                    <div className="col text-end">
                                                    <button className="btn btn-outline-secondary ms-10px" onClick={handleUploadClick}>
                                                        도장 업로드</button>
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }} // input은 숨기기
                                                        onChange={(e) =>handleFileChange(e)}
                                                    />
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="imgs_outer">

                                            <div className="stamimg_box">
                                                <div className="imgoutr">
                                                    {/*<img src={process.env.PUBLIC_URL + "/images/stamp_img2.png"} alt="" />*/}
                                                    {electionCoating ? (
                                                        <img src={`data:image/png;base64,${electionCoating}`} alt="선택한 도장"
                                                             // style={{ width: '180px', height: '180px' }}
                                                        />
                                                    ) : (
                                                        <img src={process.env.PUBLIC_URL + "/images/stamp_img2.png"} alt=""
                                                             // style={{ width: '180px', height: '180px' }}
                                                        />
                                                    )}
                                                    <input name="stamp" type="radio" className="form-input-radio"
                                                           value="coating"
                                                        checked={selectedCoatingOption === 'coating'}
                                                           style={{ display: 'none' }} // input은 숨기기
                                                    />
                                                    <div>전자 도장</div>
                                                </div>
                                            </div>
                                            <div className="stamimg_box">
                                                <div className="imgoutr">
                                                    {fileUrl ? ( // 미리보기 URL이 존재하면
                                                        <img src={fileUrl} alt="미리보기" />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '250px',
                                                                height: '250px',
                                                                border: '1px solid #ccc', // 테두리 추가
                                                                backgroundColor: '#f0f0f0' // 배경색 설정
                                                            }}
                                                        ></div>
                                                    )}
                                                    <input name="stamp" type="radio" className="form-input-radio"
                                                           value="upload"
                                                           style={{ display: 'none' }} // input은 숨기기
                                                    />
                                                    <div>업로드 도장</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer card_footer_btngrp_end">
                                    <button className="btn btn-primary btn_footer"
                                    onClick={coatingHandlerSubmit}
                                    >저장</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt=""/>
                        <h5>오류</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                        </button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>성공</h5>
                        <p>저장 되었습니다.</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment >
    );
}

