//login
export const POST_LOGIN = "api/auth/login" // 로그인
export const JOIN_MEMBER_SHIP = "api/auth/joinMemberShip" // 회원가입등록
export const JOIN_MEMBER_ID_CHECK = "api/auth/joinMemberIdCheck" // 아이디 중복확인
export const JOIN_MEMBER_COMPANY_NUMBER_CHECK = "api/auth/joinMemberCompanyNumberCheck" // 아이디 중복확인
export const JOIN_MEMBER_EMAIL_SEND = "api/auth/joinMemberShipEmailSend" //회원 가입 시 인증 코드 전송
export const JOIN_MEMBER_EMAIL_CHECK = "api/auth/joinMemberEmailCheck" //회원가입 이메일 중복확인

export const FIND_ID = "api/auth/findId" // 아이디 찾기 체크
export const FIND_ID_EMAIL_SEND = "api/auth/findIdEmailSend" // 아이디 찾기 이메일 보내기

export const NEW_PASSWORD="api/auth/newPassword" //비밀번호 재설정 이메일 보내기
export const NEW_PASSWORD_SAVE="api/auth/newPasswordSave"  //비밀번호 재설정 인증코드 확인
export const FILE_UPLOAD="api/auth/fileUpload"  //파일 업로드

export const FIND_IS_PAY="api/auth/findIsPay"  //결제 유무
export const UPDATE_IS_PAY="api/auth/updateIsPay"  //결제유무 업데이트

