import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Landing } from "../pages/Landing";
import { Login } from "../pages/Login";
import { MemberShipAgree } from "../pages/memberShip/MemberShipAgree";
import { MemberShipRegister } from "../pages/memberShip/MemberShipRegister";
import { FindId } from "../pages/memberShip/FindId";
import { FindPassword } from "../pages/memberShip/FindPassword";
import { Dashboard } from "../pages/Dashboard";
import { Verify } from "../pages/information/Verify";
import PrivateEmailRoute from "../pages/private/PrivateEmailRoute";
import { InformationWasteManageFirst } from "../pages/information/register/InformationWasteManageFirst";
import { InformationWasteManageSecond } from "../pages/information/register/InformationWasteManageSecond";
import PrivateLoginRoute from "../pages/private/PrivateLoginRoute";
import { InformationSend } from "../pages/information/send/InformationSend";
import { InformationSendCheck } from "../pages/information/sendCheck/InformationSendCheck";
import { InformationSendCheckEditFirst } from "../pages/information/sendCheck/InformationSendCheckEditFirst";
import { RequestContractPage } from "../pages/information/request/RequestContractPage";
import { InformationSendCheckEditSecond } from "../pages/information/sendCheck/InformationSendCheckEditSecond";
import { ContractCompletion } from "../pages/information/complete/ContractCompletion";
import { ContractCompletionCheck } from "../pages/information/complete/ContractCompletionCheck";
import { RequestContractDetail } from "../pages/information/request/RequestContractDetail";
import { MyPageBase } from "../pages/mypage/MyPageBase";
import { RequestNoMemberContractDetail } from "../pages/information/request/RequestNoMemberContractDetail";
import { VerifyPdf } from "../pages/information/request/VerifyPdf";
import { MyPageTransporter } from "../pages/mypage/MyPageTransporter";
import { MyPageHandler } from "../pages/mypage/MyPageHandler";
import { MyPageCoating } from "../pages/mypage/MyPageCoating";
import { FeeIntroduce } from "../pages/FeeIntroduce";
import { RegisterDeliveryContractFirst } from "../pages/delivery/RegisterDeliveryContractFirst";
import { DeliveryContractCheck } from "../pages/delivery/DeliveryContractCheck";
import { VerifyDelivery } from "../pages/delivery/VerifyDelivery"
import { DeliveryNoMemberDetail } from "../pages/delivery/DeliveryNoMemberDetail";
import FeeIntroduceClose from "../pages/FeeIntroduceClose";
import NaverClose from "../pages/NaverClose";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TermsOfUse } from "../pages/TermsOfUse";
const RouterConfig = () => {

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/naverClose" element={<NaverClose />} />
        <Route path="/memberShipAgree" element={<MemberShipAgree />} />
        <Route path="/memberShipRegister" element={<MemberShipRegister />} />
        <Route path="/findId" element={<FindId />} />
        <Route path="/findPassword" element={<FindPassword />} />

        <Route path="/verify/request/detail/:idx/:constructionText" element={<RequestNoMemberContractDetail />} />
        <Route path="/verify/delivery/detail/:idx/:constructionText" element={<DeliveryNoMemberDetail />} />

        {/* 등록 시 본인인증/*/}
        <Route path="/verify" element={<Verify />} />
        {/* 폐기물 계약 요청 본인인증/*/}
        <Route path="/verifyPdf" element={<VerifyPdf />} />
        {/* 납품 계약 요청 본인인증/*/}
        <Route path="/verifyDelivery" element={<VerifyDelivery />} />

        {/* 요금안내/*/}
        <Route path="/feeIntroduce" element={<FeeIntroduce />} />
        <Route path="/feeIntroduce/close" element={<FeeIntroduceClose />} />

        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_of_use" element={<TermsOfUse />} />

        {/* 이메일 체크 하는곳/*/}
        {/*<Route element={<PrivateEmailRoute/>}>*/}
        <Route path="/verify/register/wasterManage" element={<InformationWasteManageFirst />} />
        <Route path="/verify/register/wasterManage/manage" element={<InformationWasteManageSecond />} />
        <Route path="/information/check/" element={<InformationSendCheckEditSecond />} />

        {/*</Route>*/}

        {/* 로그인시 가능 루트/*/}
        <Route element={<PrivateLoginRoute />}>
          <Route path="/dashboard/*" element={<DashBoardRoutes />} />
          <Route path="/information/*" element={<InformationRoutes />} />
          <Route path="/mypage/*" element={<MyPageRoutes />} />
          <Route path="/delivery/*" element={<DeliveryRoutes />} />
        </Route>

      </Routes>
    </Router>
  );
};

function DashBoardRoutes() {
  return (
    <Routes>
      <Route path="main" element={<Dashboard />} />

    </Routes>
  );
}

function MyPageRoutes() {
  return (
    <Routes>
      <Route path="info" element={<MyPageBase />} />
      <Route path="transpoter" element={<MyPageTransporter />} />
      <Route path="handler" element={<MyPageHandler />} />
      <Route path="coating" element={<MyPageCoating />} />
    </Routes>
  );
}

function DeliveryRoutes() {
  return (
    <Routes>
      <Route path="register" element={<RegisterDeliveryContractFirst />} />
      <Route path="sendCheck" element={<DeliveryContractCheck />} />
    </Routes>
  );
}


function InformationRoutes() {
  return (
    <Routes>
      <Route path="info" element={<InformationSend />} />
      <Route path="sendCheck" element={<InformationSendCheck />} />
      <Route path="/sendCheck/detail/:idx" element={<InformationSendCheckEditFirst />} />
      <Route path="request" element={<RequestContractPage />} />
      <Route path="/request/detail/:idx/:constructionText" element={<RequestContractDetail />} />
      <Route path="contract" element={<ContractCompletion />} />
      <Route path="complete" element={<ContractCompletionCheck />} />

    </Routes>
  );
}


export default RouterConfig;
