import React, {useState,useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {postDashBoardEmailSendCheck} from "../../../api/dashboard";
import { MobileHeader } from "../../common/MobileHeader";
import { Sidebar } from "../../common/Sidebar";
import { PcHeader } from "../../common/PcHeader";
import {postContractNoMemberEmailSendCheck} from "../../../api/information";


export const VerifyPdf = () => {

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [email, setEmail] = useState("");



    const location = useLocation();
    const navigate = useNavigate();

    const getRegistrationIdx = () => {
        const params = new URLSearchParams(location.search);
        return params.get('registrationIdx');
    };
    const getConstructionText = () => {
        const params = new URLSearchParams(location.search);
        return params.get('constructionText');
    };

    const constructionText = getConstructionText();
    const idx = getRegistrationIdx();

    const [emailReq, setEmailReq] = useState({
        email:"",
        serialNumber:"",
        informationSendIdx:"",
        noMemberIdx:idx,
    })

    useEffect(() => {
        const oneclickNoMemberCheck = sessionStorage.getItem('oneclick_noMemberCheck');
        if (oneclickNoMemberCheck) {
            sessionStorage.removeItem('oneclick_noMemberCheck');
        }
    }, []);

    const handleErrorClose =()=>{
        setShowError(false)
    }

    const handleSuccessClose = (idx,constructionText) => {
        setShowSuccess(false);
        navigate(`/verify/request/detail/${idx}/${constructionText}`, { state: emailReq }); // 'nextPage'를 원하는 경로로 변경
    }

    const emailHandler= (e) => {

        setEmail(e);
        setEmailReq({...emailReq, email: e})
    };

    const handleSuccessKeyDown= (e) =>{
        if(e.key ==="Enter"){
            emailSendClick()
        }
    }

    const emailSendClick = async () => {
        try {

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (email === "") {
                setShowErrorMessage("이메일을 입력해주세요.");
                setShowError(true)
                return
            }

            const response = await postContractNoMemberEmailSendCheck(emailReq)

            if(response.code==="0000"){
                sessionStorage.setItem('oneclick_noMemberCheck','Y')
                setShowSuccessMessage("인증되었습니다.")
                setShowSuccess(true)
            }
            else{
                setShowErrorMessage("이메일이 일치하지않습니다.")
                setShowError(true)
            }
        }
        catch (e){
            setShowErrorMessage(e.message)
            setShowError(true)
        }

    }

    return (
        <React.Fragment>
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_id_verify">
                                <div className="card-body">
                                    <img className="box_img" src={process.env.PUBLIC_URL + "/images/icon_verify.png"} alt="" />
                                    <h3>본인 확인 필요</h3>
                                    <p>본 메일을 수신한 이메일 주소를 입력해주세요.</p>
                                    <div className="frm_verify">
                                        <div className="frm_login_row mb-0">
                                            <label className="form-label">이메일</label>
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <img src={process.env.PUBLIC_URL + "/images/icon_input_email.png"} alt="" />
                                                </span>
                                                <input type="text" className="form-control" placeholder="이메일을 입력 해주세요."
                                                value={email}
                                               onChange={(e)=>emailHandler(e.target.value)}
                                                       onKeyDown={handleSuccessKeyDown}
                                                />
                                            </div>
                                            {/* <div className="form-text">We'll never share your email with anyone else.</div> */}
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-lg btn_verify"
                                            onClick={()=>emailSendClick(idx,constructionText)}
                                    >확인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>인증 성공</h5>
                        <p>{showSuccessMessage}</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={()=>handleSuccessClose(idx,constructionText)}
                        >확인</button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <h5>인증 실패</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}