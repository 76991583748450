import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
export const PcHeader = (props) => {
    const navigate = useNavigate();

    const [loginName, setLoginName] = useState("")

    useEffect(() => {
        const savedUserName = sessionStorage.getItem('oneclick_userName')
        setLoginName(savedUserName)
    }, []);

    const handleLogout = () => {
        Cookies.remove('accToken'); // accToken 쿠키 삭제
        const userName = sessionStorage.getItem('oneclick_userName');
        if (userName) {
            sessionStorage.removeItem('oneclick_userName');
            sessionStorage.removeItem('loginId');
        }
        navigate("/login")
    };

    return (
        <div className="header-container">
            <div className="header_title">
                <img src={process.env.PUBLIC_URL + props.icon} alt="" />{props.title}
            </div>
            { loginName !== null && window.location.pathname.indexOf('verify') <=0 && window.location.pathname.indexOf('noMember') <= 0 ?
                <div className="header_toolbar">
                <ul>
                    <li>
                        <img src={process.env.PUBLIC_URL + "/images/icon_user_header.png"} alt="" />
                        <span>{loginName}님</span> 안녕하세요
                    </li>
                    <li
                        onClick={() => {
                        navigate("/mypage/info");
                    }}>마이페이지
                    </li>
                    <li>
                        <a href="/" onClick={handleLogout}>로그아웃</a>
                    </li> {/* a 태그로 감싸고 onClick 적용 */}
                </ul>
            </div> : ""
            } 
        </div>
    );
}