import { Navigate, Outlet } from "react-router-dom";
import Cookies from 'js-cookie';
import { findIsPay } from "../../api/auth";
import { useState, useEffect } from "react";

const PrivateLoginRoute = () => {
    const [canAccess, setCanAccess] = useState(null); // `null`은 로딩 상태를 나타냅니다.

    useEffect(() => {
        const checkAccess = async () => {
            const loginId = sessionStorage.getItem("loginId");

            if (!loginId) {
                setCanAccess(false);
                return;
            }

            const body = { userId: loginId };
            try {
                const isPayResponse = await findIsPay(body);
                const isPay = isPayResponse?.result?.isPay ?? false;
                const isLoggedIn = !!Cookies.get("accToken");

                setCanAccess(isPay && isLoggedIn);
            } catch (error) {
                setCanAccess(false); // 에러 발생 시 접근 불가로 설정
            }
        };

        checkAccess();
    }, []);

    // 로딩 상태 처리
    if (canAccess === null) {
        return <div>Loading...</div>; // 로딩 스피너 또는 메시지를 렌더링
    }

    // 접근 가능 여부에 따라 리다이렉트 또는 라우트 렌더링
    return canAccess ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateLoginRoute;

