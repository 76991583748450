import React, { useState, useEffect } from "react";
import "./assets/fonts/pretendard/Pretendard.css";
import './Common.css';
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap";
import RouterConfig from "./router/RouterConfig";
import { LoadingProvider } from "./spinner/LoadingContext";

function App()
 {
  return (
    <LoadingProvider>
      <RouterConfig />
    </LoadingProvider>
  );
}

export default App;
