import React, {useEffect, useRef, useState} from "react";
import {
    postMypageAuthSearch, postMypageBaseDetailAuthSave,
    postMypageBaseDetailAuthSearch,
    postMypageBaseDetailSave,
    postMypageBaseDetailSearch
} from "../../api/mypage";
import Modal from "react-bootstrap/Modal";
import {scrollToTop} from "../../api/utils/scrollToTop";
import {fileUpload} from "../../api/auth";
import { PcHeader } from "../common/PcHeader";
import { Sidebar } from "../common/Sidebar";
import { MobileHeader } from "../common/MobileHeader";


export const MyPageBase = () => {

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");

    const [companyNumber, setCompanyNumber] = useState("")
    const companyNumberRef = useRef(null)

    const [companyNumber2, setCompanyNumber2] = useState("")
    const companyNumberRef2 = useRef(null)


    const [companyNumber3, setCompanyNumber3] = useState("")
    const companyNumberRef3 = useRef(null)



    const [registerReq, setRegisterReq] = useState({
        idx: "",
        companyNumber: "",
        name: "",
        ceoName: "",
        companyKind: "",
        ceoCellphone: "",
        taxEmail: "",
        companyAddress: "",
        allbaroName: "",
        allbaroCellphone: "",
        siteManagerCellphone: "",
        siteManagerName: "",
        anteUserId : "",
        antePassword : "",
        userId : "",
    })

    const [registerFileReq, setRegisterFileReq] = useState({
        companyNumberFile: [],
        companyNumberFileName: "",
        driveFile: [], //운반
        driveFileName :"",
        permitFile: [], //처리
        permitFileName: "",
    })

    const companyNumberFileRef = useRef(null)
    const [companyFileCheck, setCompanyFileCheck] = useState(false)
    const [driveFileCheck, setDriveFileCheck] = useState(false)
    const [permitFileCheck, setPermitFileCheck] = useState(false)

    const nameRef = useRef(null)
    const ceoNameRef = useRef(null)
    const companyKindRef = useRef(null)
    const companyAddressRef = useRef(null)
    const allbaroNameRef = useRef(null)
    const siteManagerNameRef = useRef(null)


    const [ceoCellphonePart1, setCeoCellphonePart1] = useState("");
    const [ceoCellphonePart2, setCeoCellphonePart2] = useState("");
    const [ceoCellphonePart3, setCeoCellphonePart3] = useState("");

    const [allbarophonePart1, setAllbarophonePart1] = useState("");
    const [allbarophonePart2, setAllbarophonePart2] = useState("");
    const [allbarophonePart3, setAllbarophonePart3] = useState("");

    const [siteManagerphonePart1, setSiteManagerPhonePart1] = useState("");
    const [siteManagerphonePart2, setSiteManagerPhonePart2] = useState("");
    const [siteManagerphonePart3, setSiteManagerPhonePart3] = useState("");

    const part1Ref = useRef(null) //연락처 앞
    const part2Ref = useRef(null); //중간
    const part3Ref = useRef(null); //끝

    const allbaroPart1Ref = useRef(null) //올바로 연락처 앞
    const allbaroPart2Ref = useRef(null); //중간
    const allbaroPart3Ref = useRef(null); //끝

    const siteManagePart1Ref = useRef(null) //현장 연락처 앞
    const siteManagePart2Ref = useRef(null); //중간
    const siteManagePart3Ref = useRef(null); //끝

    const [taxEmailLocalPart, setTaxEmailLocalPart] = useState('');
    const [taxEmailDomainPart, setTaxEmailDomainPart] = useState('');

    const taxEmailRef1 = useRef(null)
    const taxEmailRef2 = useRef(null);

    const anteUserIdRef = useRef(null)
    const antePasswordRef = useRef(null)

    const [adminUserCheck, setAdminUserCheck] = useState(false)

    const [kindCheck ,setKindCheck] = useState("")


    const [isChanged, setIsChanged] = useState(false);
    const [fileCheck , setFileCheck] = useState(false)

    // 이전 데이터를 저장할 ref
    const prevRegisterReq = useRef(registerReq);
    const prevRegisterFileReq = useRef(registerFileReq);

    const [adminInitialize, setAdminInitialize] = useState(false)


    useEffect(() => {
        prevRegisterReq.current = registerReq;
        prevRegisterFileReq.current = registerFileReq;
    }, [registerReq, registerFileReq]);
    const handleErrorClose = () => {
        setShowError(false)
    }


    const handleSuccessClose= () => {
        setShowSuccess(false)
        adminMyPageLoading()
        baseMyPageDetailLoading()
        scrollToTop()
    }

    useEffect(() => {
    }, [showSuccess]);
    const adminMyPageLoading = async () => {
        try{
            const response = await postMypageAuthSearch()
            if(response.result.adminCheck===true){
                setAdminUserCheck(true)
            }
            if(response.result.kind.length>0){
                setKindCheck(response.result.kind)
            }
        }
        catch (e){
            setShowErrorMessage("관리자에게 문의바랍니다.")
            setShowError(true)
        }
    }

    const adminCompanyNumberFind = async () => {

        try{

        }
        catch (e){
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }


    const baseMyPageDetailLoading = async () => {
        try {
            const response = await postMypageBaseDetailSearch();
            if (response.code === "0000") {
                const result = response.result || {};  // result가 없을 때 빈 객체로 초기화

                setRegisterReq(prevState => ({
                    idx: result.idx || "",
                    name: result.companyName || "",
                    ceoName: result.ceoName || "",
                    ceoCellphone: result.ceoCellphone || "",
                    userId: result.userId || "",
                    taxEmail: result.taxEmail || "",
                    companyAddress: result.companyAddress || "",
                    allbaroName: result.allbaroName || "",
                    allbaroCellphone: result.allbaroCellphone || "",
                    siteManagerCellphone: result.siteManagerCellphone || "",
                    siteManagerName: result.siteManagerName || "",
                    anteUserId: result.anteUserId || "",
                    antePassword: result.antePassword || "",
                }));

                setRegisterFileReq(preveState => ({
                    companyNumberFileName: result.companyFileName || "",
                    driveFileName: result.driveFileName || "",
                    permitFileName: result.permitFileName || "",
                }));

                const companyNumber = result.companyNumber || "";
                handleCellphone(result.ceoCellphone || "", setCeoCellphonePart1, setCeoCellphonePart2, setCeoCellphonePart3);
                handleCellphone(result.allbaroCellphone || "", setAllbarophonePart1, setAllbarophonePart2, setAllbarophonePart3);
                handleCellphone(result.siteManagerCellphone || "", setSiteManagerPhonePart1, setSiteManagerPhonePart2, setSiteManagerPhonePart3);

                setCompanyNumber(companyNumber.slice(0, 3));
                setCompanyNumber2(companyNumber.slice(3, 5));
                setCompanyNumber3(companyNumber.slice(5));

                const emailParts = (result.taxEmail || "").split('@');
                setTaxEmailLocalPart(emailParts[0] || '');
                setTaxEmailDomainPart(emailParts[1] || '');
            }
        } catch (e) {
            setShowErrorMessage("정보를 불러오지 못했습니다.");
            setShowError(true);
        }
    };


    const handleCellphone = (cellphone, setPart1, setPart2, setPart3) => {
        if (cellphone.length === 11) {
            setPart1(cellphone.slice(0, 3));
            setPart2(cellphone.slice(3, 7));
            setPart3(cellphone.slice(7));
        } else {
            setPart1(cellphone.slice(0, 3));
            setPart2(cellphone.slice(3, 6));
            setPart3(cellphone.slice(6));
        }
    };

    const [adminCompanyNumberReq, setAdminCompanyNumberReq] = useState({
        adminCompanyNumber1 :"",
        adminCompanyNumber2 :"",
        adminCompanyNumber3 :"",
    })

    const adminCompanyNumberRef = useRef(null)
    const adminCompanyNumberRef2 = useRef(null)
    const adminCompanyNumberRef3 = useRef(null)

    useEffect(() => {
        adminMyPageLoading()
        baseMyPageDetailLoading()
        scrollToTop()
    }, []);

    const companyNumberFirstHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        setCompanyNumber(e);
        if (e.length === 3) {
            companyNumberRef2.current.focus()
        }
        setIsChanged(true)

    }

    const companyNumberSecondHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        if (e.length === 2) {
            companyNumberRef3.current.focus()
        }
        setCompanyNumber2(e);
        setIsChanged(true)
    }

    const companyNumberThirdHandler = (e) => {

        e = e.replace(/\D/g, '');
        setCompanyNumber3(e);
        setIsChanged(true)
    }

    const onKeyDownHandler = (e, part) => {
        if (e.key === 'Enter') {
            e.preventDefault();   // 기본 동작 (예: 폼 제출)을 취소합니다.

            if (part === 'companyNumber') {
                nameRef.current.focus()
            } else if (part === 'name') {
                ceoNameRef.current.focus();
            } else if (part === 'ceoName') {
                companyKindRef.current.focus();
            } else if (part === 'part1') {
                part2Ref.current.focus()
            } else if (part === 'part2') {
                part3Ref.current.focus()
            } else if (part === 'part3') {
                companyAddressRef.current.focus();
            } else if (part === 'companyAddress') {
                taxEmailRef1.current.focus();
            } else if (part === 'taxEmailLocal') {
                taxEmailRef2.current.focus();
            } else if (part === 'taxEmailDomain') {
                allbaroNameRef.current.focus();
            } else if (part === 'allbaroName') {
                allbaroPart1Ref.current.focus();
            } else if (part === 'allbaroPart1') {
                allbaroPart2Ref.current.focus();
            } else if (part === 'allbaroPart2') {
                allbaroPart3Ref.current.focus();
            } else if (part === 'allbaroPart3') {
                siteManagerNameRef.current.focus();
            } else if (part === 'siteManagerName') {
                siteManagePart1Ref.current.focus();
            } else if (part === 'siteManagerPart1') {
                siteManagePart2Ref.current.focus();
            } else if (part === 'siteManagerPart2') {
                siteManagePart3Ref.current.focus();
            } else if (part === 'siteManagerPart3') {
                anteUserIdRef.current.focus();
            }
            else if (part === 'anteUserId') {
                antePasswordRef.current.focus();
            }


        }
    };

    const adminSearchHandler = (e, part) => {
        let value = e.target.value;

        // 숫자가 아닌 문자는 제거
        value = value.replace(/\D/g, '');
        if (part === 'adminCompanyNumber1') {
            setAdminCompanyNumberReq(prevState => ({
                ...prevState,
                adminCompanyNumber1: value
            }));
            if(value.length===3){
                adminCompanyNumberRef2.current.focus()
            }
        } else if (part === 'adminCompanyNumber2') {
            setAdminCompanyNumberReq(prevState => ({
                ...prevState,
                adminCompanyNumber2: value
            }));
            if(value.length===2){
                adminCompanyNumberRef3.current.focus()
            }
        }
        else if (part === 'adminCompanyNumber3') {
            setAdminCompanyNumberReq(prevState => ({
                ...prevState,
                adminCompanyNumber3: value
            }));
        }
    }

    const adminSearchCheck = async () => {

        try{
            const { adminCompanyNumber1, adminCompanyNumber2, adminCompanyNumber3 } = adminCompanyNumberReq;
            const combinedNumbers = `${adminCompanyNumber1}${adminCompanyNumber2}${adminCompanyNumber3}`;

            const response = await postMypageBaseDetailAuthSearch({
                companyNumber : combinedNumbers
            })


            if(response.code==="0000"){
                const result = response.result

                setRegisterReq(prevState => ({
                    idx: result.idx,
                    name: result.companyName || "",
                    ceoName: result.ceoName || "",
                    ceoCellphone: result.ceoCellphone || "",
                    userId : result.userId || "",
                    taxEmail: result.taxEmail || "",
                    companyAddress: result.companyAddress || "",
                    allbaroName: result.allbaroName || "",
                    allbaroCellphone: result.allbaroCellphone || "",
                    siteManagerCellphone: result.siteManagerCellphone || "",
                    siteManagerName: result.siteManagerName || "",
                    anteUserId: result.anteUserId || "",
                    antePassword :result.antePassword || "",
                }));
                setRegisterFileReq(preveState => ({
                    companyNumberFileName: result.companyFileName || "",
                    driveFileName: result.driveFileName || "",
                    permitFileName: result.permitFileName || "",
                    companyNumberFile: result.companyFile || "",
                    driveFile: result.driveFile || "",
                    permitFile: result.permitFile || "",
                }));

                const companyNumber = result.companyNumber || "";
                handleCellphone(result.ceoCellphone || "", setCeoCellphonePart1, setCeoCellphonePart2, setCeoCellphonePart3);
                handleCellphone(result.allbaroCellphone || "", setAllbarophonePart1, setAllbarophonePart2, setAllbarophonePart3);
                handleCellphone(result.siteManagerCellphone || "", setSiteManagerPhonePart1, setSiteManagerPhonePart2, setSiteManagerPhonePart3);

                setCompanyNumber(companyNumber.slice(0, 3))
                setCompanyNumber2(companyNumber.slice(3, 5))
                setCompanyNumber3(companyNumber.slice(5))

                const emailParts = result.taxEmail.split('@');
                setTaxEmailLocalPart(emailParts[0] || '');
                setTaxEmailDomainPart(emailParts[1] || '');
                setAdminInitialize(true)
            }
        }
        catch (e)
        {
            setShowErrorMessage(e.message)
            setShowError(true)
            setAdminInitialize(false)
        }
    }

    const ceoCellPhoneHandler = (e, part) => {
        let value = e.target.value;

        // 숫자가 아닌 문자는 제거
        value = value.replace(/\D/g, '');

        if (part === 'part1') {
            setCeoCellphonePart1(value);
            if (value.length === 3) {
                if (value === '010') {
                    part2Ref.current.maxLength = 4;
                } else {
                    part2Ref.current.maxLength = 3;
                }
                part2Ref.current.focus();
            }
        } else if (part === 'part2') {
            setCeoCellphonePart2(value);
            if ((ceoCellphonePart1 === '010' && value.length === 4) ||
                (ceoCellphonePart1 !== '010' && value.length === 3)) {
                part3Ref.current.focus();
            }
        } else if (part === 'part3') {
            setCeoCellphonePart3(value);
        } else if (part === 'allbaroPart1') {
            setAllbarophonePart1(value)
            if (value.length === 3) {
                if (value === '010') {
                    allbaroPart2Ref.current.maxLength = 4;
                } else {
                    allbaroPart2Ref.current.maxLength = 3;
                }
                allbaroPart2Ref.current.focus();
            }
        } else if (part === 'allbaroPart2') {
            setAllbarophonePart2(value)
            if ((allbarophonePart1 === '010' && value.length === 4) ||
                (allbarophonePart1 !== '010' && value.length === 3)) {
                allbaroPart3Ref.current.focus();
            }
        } else if (part === 'allbaroPart3') {
            setAllbarophonePart3(value)
        } else if (part === 'siteManagerPart1') {
            setSiteManagerPhonePart1(value)
            if (value.length === 3) {
                if (value === '010') {
                    siteManagePart2Ref.current.maxLength = 4;
                } else {
                    siteManagePart2Ref.current.maxLength = 3;
                }
                siteManagePart2Ref.current.focus();
            }
        } else if (part === 'siteManagerPart2') {
            setSiteManagerPhonePart2(value)
            if ((siteManagerphonePart1 === '010' && value.length === 4) ||
                (siteManagerphonePart1 !== '010' && value.length === 3)) {
                siteManagePart3Ref.current.focus();
            }
        } else if (part === 'siteManagerPart3') {
            setSiteManagerPhonePart3(value)
        }
        setIsChanged(true)
    };

    const handleTaxEmailLocalPartChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^a-zA-Z0-9]/g, '');

        setTaxEmailLocalPart(value);
        setIsChanged(true)
    };

    const handleTaxEmailDomainPartChange = (e) => {
        let value = e.target.value;

        // 알파벳과 숫자가 아닌 문자는 제거합니다.
        value = value.replace(/[^a-zA-Z0-9.]/g, '');
        if (value === "") {
            setTimeout(() => {
                taxEmailRef2.current.focus();
            }, 0);
        }
        setTaxEmailDomainPart(value);
        setIsChanged(true)
    };

    const fileHandler = (event,check) => {
        const files = event?.target?.files;

        setFileCheck(true);

        const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링
        if (validFiles.length === files.length) {

            const fileName = files[0].name; // 첫 번째 파일의 이름만 가져옴
            if(check==='companyNumberFile'){
                setRegisterFileReq((prev) => ({
                    ...prev,
                    companyNumberFile: validFiles, // 선택된 모든 유효한 파일을 배열로 설정
                    companyNumberFileName: fileName,
                }));
                setCompanyFileCheck(true)
            }
            else if(check ==='driveFile'){
                setRegisterFileReq((prev) => ({
                    ...prev,
                    driveFile: validFiles, // 선택된 모든 유효한 파일을 배열로 설정
                    driveFileName: fileName,
                }));
                setDriveFileCheck(true)
            }
            else if(check ==='permitFile'){
                setRegisterFileReq((prev) => ({
                    ...prev,
                    permitFile: validFiles, // 선택된 모든 유효한 파일을 배열로 설정
                    permitFileName: fileName,
                }));
                setPermitFileCheck(true)
            }

        } else {
            setShowErrorMessage("이미지 파일 및 PDF만 가능합니다.")
            setShowError(true)
            setCompanyFileCheck(false)
        }
        setIsChanged(true)
    };
    const removeFileHandler = (e,check) => {

        if(check==='companyNumberFile'){
            setRegisterFileReq(prevState => ({
                ...prevState,
                companyNumberFileName: '' // 파일 이름을 제거
            }));
            setCompanyFileCheck(false)
        }
        else if(check ==='driveFile'){
            setRegisterFileReq(prevState => ({
                ...prevState,
                driveFileName: '' // 파일 이름을 제거
            }));
            setDriveFileCheck(false)
        }
        else if(check ==='permitFile'){
            setRegisterFileReq(prevState => ({
                ...prevState,
                permitFileName: '' // 파일 이름을 제거
            }));
            setPermitFileCheck(false)
        }
        setIsChanged(true)
    }

    const isValidFileType = (file) => {
        const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };

    const baseSave = async () => {

        if(!isChanged){
            setShowErrorMessage("변경사항이 없습니다.")
            setShowError(true)
            return
        }

        const mergedCompanyNumber = `${companyNumber}${companyNumber2}${companyNumber3}`;
        const mergedCeoCellphone = `${ceoCellphonePart1}${ceoCellphonePart2}${ceoCellphonePart3}`;
        const mergedEmail = taxEmailLocalPart && taxEmailDomainPart
            ? `${taxEmailLocalPart}@${taxEmailDomainPart}` : "";
        const mergedAllbarophone = `${allbarophonePart1}${allbarophonePart2}${allbarophonePart3}`;
        const mergedSiteManagerphone = `${siteManagerphonePart1}${siteManagerphonePart2}${siteManagerphonePart3}`;
        const updatedRegisterReq = {
            ...registerReq, //기존 필드 유지
            ceoCellphone: mergedCeoCellphone,
            taxEmail: mergedEmail,
            allbaroCellphone: mergedAllbarophone,
            siteManagerCellphone: mergedSiteManagerphone,
            companyNumber: mergedCompanyNumber,
        };

        const errorMessages = {
            companyNumber: "사업자 등록번호를 입력해주세요.",
            name: "상호를 입력해주세요.",
            ceoName: "대표자를 입력해주세요.",
            ceoCellphone: "연락처를 입력해주세요.",
            taxEmail: "세금계산서발행 이메일 주소를 입력해주세요.",
            companyAddress: "주소(사업자등록증상 주소)를 입력해주세요.",
            allbaroName: "올바로 담당자 성함을 입력해주세요.",
            allbaroCellphone: "올바로 담당자 연락처를 입력해주세요.",
            siteManagerCellphone: "현장 담당자 연락처를 입력해주세요.",
            siteManagerName: "현장 담당자 성함을 입력해주세요.",
            anteUserId: "분담금 납부 아이디를 입력해주세요.",
            antePassword : "분담금 납부 비밀번호를 입력해주세요.",
        };

        for (const [key, value] of Object.entries(updatedRegisterReq)) {
            if (key in errorMessages && value === "") {
                const errorMessage = errorMessages[key];
                if (errorMessage) {
                    setShowErrorMessage(errorMessage);
                    setShowError(true);
                }
                return;
            }
        }

        try{
            const {companyNumberFile, permitFile, driveFile} = registerFileReq


            if(registerFileReq.companyNumberFileName===''){
                setShowError(true)
                setShowErrorMessage("사업자 등록증 사본 첨부는 필수입니다.")
                return
            }

            else if(kindCheck==='Transporter' || kindCheck==='Handler/Transporter'){
                if(registerFileReq.driveFileName===''){
                    setShowError(true)
                    setShowErrorMessage("운반 허가증 사본 첨부는 필수입니다.")
                    return
                }
            }

            if(kindCheck==='Handler' || kindCheck==='Handler/Transporter'){
                if(registerFileReq.permitFileName===''){
                    setShowError(true)
                    setShowErrorMessage("처리 허가증 사본 첨부는 필수입니다.")
                    return
                }
            }

            if(fileCheck){
                const fileIds = [];

                if (companyFileCheck && companyNumberFile.length > 0) {
                    fileIds.push(...Array(companyNumberFile.length).fill(1));
                }
                if (permitFileCheck && permitFile.length > 0) {
                    fileIds.push(...Array(permitFile.length).fill(2));
                }
                if (driveFileCheck && driveFile.length > 0) {
                    fileIds.push(...Array(driveFile.length).fill(3));
                }
                // files 배열을 fileIds의 길이에 따라 설정
                const files = [
                    ...(companyFileCheck && companyNumberFile.length > 0 ? companyNumberFile : []),
                    ...(driveFileCheck && driveFile.length > 0 ? driveFile : []),
                    ...(permitFileCheck && permitFile.length > 0 ? permitFile : []),
                ];
                // data 객체 생성
                const data = {
                    files: files.length > 0 ? files : [], // files가 비어있지 않으면 추가, 비어있으면 빈 배열
                    userId: registerReq.userId,
                    filesId: fileIds, // fileId 배열 설정
                    type: 'mypage',
                };
                const response2 = await fileUpload(data);
            }
            if(adminInitialize){
                const response = await postMypageBaseDetailAuthSave(updatedRegisterReq)
            }
            else{
                const response = await postMypageBaseDetailSave(updatedRegisterReq)
            }
            setShowSuccess(true)
        }
        catch (e){
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }

    return (
        <React.Fragment >
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="마이페이지" icon="/images/icon_square.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_main">
                                <div className="card-header">
                                    마이페이지
                                </div>
                                <div className="card-body">
                                    <h3 className="card_body_heading">기본정보 및 허가증</h3>

                                    {adminUserCheck && (
                                        <>
                                    <h3 className="table_heading p-0">사업자 등록번호로 검색</h3>
                                    <table className="table table-bordered card_table mb-4">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "12.82%" }}>사업자 등록번호</th>
                                                <td>
                                                    <div className="flex-center-between">
                                                        <div class="frm_field input_phone input_phone_tbl">
                                                            <
                                                                input placeholder="" type="text" class="form-control"
                                                                      ref={adminCompanyNumberRef}
                                                                      maxLength={3}
                                                                      value={adminCompanyNumberReq.adminCompanyNumber1 || ""} // 값이 없을 때 빈 문자열로 처리
                                                                      onChange={(e) => adminSearchHandler(e, 'adminCompanyNumber1')}
                                                            />
                                                            <span className="txt_range">-</span>
                                                            <input placeholder="" type="text" class="form-control"
                                                                   value={adminCompanyNumberReq.adminCompanyNumber2 || ""} // 값이 없을 때 빈 문자열로 처리
                                                                   onChange={(e) => adminSearchHandler(e, 'adminCompanyNumber2')}
                                                                    maxLength={2}
                                                                   ref={adminCompanyNumberRef2}
                                                            />
                                                            <span className="txt_range">-</span>
                                                            <input placeholder="" type="text" class="form-control"
                                                                   value={adminCompanyNumberReq.adminCompanyNumber3 || ""} // 값이 없을 때 빈 문자열로 처리
                                                                   onChange={(e) => adminSearchHandler(e, 'adminCompanyNumber3')}
                                                                maxLength={5}
                                                                   ref={adminCompanyNumberRef3}
                                                            />
                                                        </div>
                                                        <button className="btn btn-secondary"
                                                            onClick={adminSearchCheck}
                                                        >검색</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                        </>
                                    )}
                                    <h3 className="table_heading p-0">기본정보</h3>
                                    <table className="table table-bordered card_table">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "12.82%" }}>사업자 등록번호</th>
                                                <td colSpan={3}>
                                                    <div class="frm_field input_phone input_phone_tbl">
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               ref={companyNumberRef}
                                                               onChange={(e) => companyNumberFirstHandler(e.target.value)}
                                                               value={companyNumber}
                                                               maxLength={3}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               ref={companyNumberRef2}
                                                               onChange={(e) => companyNumberSecondHandler(e.target.value)}
                                                               maxLength={2}
                                                               value={companyNumber2}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               ref={companyNumberRef3}
                                                               onChange={(e) => companyNumberThirdHandler(e.target.value)}
                                                               maxLength={5}
                                                               value={companyNumber3}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'companyNumber')}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>상호</th>
                                                <td colSpan={3}>
                                                    <input type="text" className="form-control"
                                                           value={registerReq.name || ""} // 값이 없을 때 빈 문자열로 처리
                                                           onChange={(e) => {
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   name: e.target.value,
                                                               }))
                                                               setIsChanged(true);
                                                           }}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'name')}
                                                           ref={nameRef}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>대표자</th>
                                                <td colSpan={3}>
                                                    <input type="text" className="form-control"
                                                           value={registerReq.ceoName}
                                                           onChange={(e) => {
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   ceoName: e.target.value,
                                                               }));
                                                               setIsChanged(true);
                                                           }}
                                                           ref={ceoNameRef}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'ceoName')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>연락처</th>
                                                <td colSpan={3}>
                                                    <div class="frm_field input_phone input_phone_tbl">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="part1"
                                                            value={ceoCellphonePart1}
                                                            onChange={(e) => ceoCellPhoneHandler(e, 'part1')}
                                                            maxLength={3}
                                                            ref={part1Ref}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="part2"
                                                            value={ceoCellphonePart2}
                                                            onChange={(e) => ceoCellPhoneHandler(e, 'part2')}
                                                            maxLength={ceoCellphonePart1 === '010' ? 4 : 3}
                                                            ref={part2Ref}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="part3"
                                                            value={ceoCellphonePart3}
                                                            onChange={(e) => ceoCellPhoneHandler(e, 'part3')}
                                                            maxLength={4}
                                                            ref={part3Ref}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'part3')}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>주소<br />
                                                    (사업자등록증상 주소)</th>
                                                <td colSpan={3}>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.companyAddress}
                                                           onChange={(e) => {
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   companyAddress: e.target.value,
                                                               }));
                                                               setIsChanged(true); // 변경 사항이 있을 경우 true로 설정
                                                           }}

                                                           ref={companyAddressRef}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'companyAddress')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>세금계산서 발행<br />
                                                    이메일 주소</th>
                                                <td>
                                                    <div class="frm_field input_email input_email_tbl">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={taxEmailLocalPart}
                                                            onChange={handleTaxEmailLocalPartChange}
                                                            ref={taxEmailRef1}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'taxEmailLocal')}
                                                        />
                                                        <span className="txt_range">@</span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={taxEmailDomainPart}
                                                            onChange={handleTaxEmailDomainPartChange}
                                                            ref={taxEmailRef2}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'taxEmailDomain')}
                                                        />
                                                        <select
                                                            className="form-select"
                                                            onChange={handleTaxEmailDomainPartChange}
                                                            value={taxEmailDomainPart}
                                                        >
                                                            <option value="">직접입력</option>
                                                            <option value="naver.com">naver.com</option>
                                                            <option value="gmail.com">gmail.com</option>
                                                            <option value="hanmail.net">hanmail.net</option>
                                                            <option value="yahoo.com">yahoo.com</option>
                                                            <option value="daum.net">daum.net</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>올바로 담당자 성함</th>
                                                <td style={{ width: "37.18%" }}>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.allbaroName}
                                                           onChange={(e) => {
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   allbaroName: e.target.value,
                                                               }));
                                                               setIsChanged(true); // 변경 사항이 있을 경우 true로 설정
                                                           }}
                                                    />
                                                </td>
                                                <th style={{ width: "12.82%" }}>올바로 담당자 연락처</th>
                                                <td>
                                                    <div class="frm_field input_phone input_phone_tbl">
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               value={allbarophonePart1}
                                                               onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart1')}
                                                               maxLength={3}
                                                               ref={allbaroPart1Ref}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart1')}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               value={allbarophonePart2}
                                                               onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart2')}
                                                               maxLength={allbarophonePart1 === '010' ? 4 : 3}
                                                               ref={allbaroPart2Ref}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart2')}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               value={allbarophonePart3}
                                                               onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart3')}
                                                               maxLength={4}
                                                               ref={allbaroPart3Ref}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart3')}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>현장 담당자 성함</th>
                                                <td>
                                                    <input type="text" className="form-control" placeholder=""
                                                           value={registerReq.siteManagerName}

                                                           onChange={(e) => {
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   siteManagerName: e.target.value,
                                                               }));
                                                               setIsChanged(true); // 변경 사항이 있을 경우 true로 설정
                                                           }}
                                                    />
                                                </td>
                                                <th>현장 담당자 연락처</th>
                                                <td>
                                                    <div class="frm_field input_phone input_phone_tbl">
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               value={siteManagerphonePart1}
                                                               onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart1')}
                                                               maxLength={3}
                                                               ref={siteManagePart1Ref}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart1')}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               value={siteManagerphonePart2}
                                                               onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart2')}
                                                               maxLength={siteManagerphonePart1 === '010' ? 4 : 3}
                                                               ref={siteManagePart2Ref}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart2')}
                                                        />
                                                        <span className="txt_range">-</span>
                                                        <input placeholder="" type="text"
                                                               className="form-control"
                                                               value={siteManagerphonePart3}
                                                               onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart3')}
                                                               maxLength={4}
                                                               ref={siteManagePart3Ref}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart3')}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>분담금 납부 아이디</th>
                                                <td>
                                                    <input placeholder="" type="text" class="form-control"
                                                           value={registerReq.anteUserId || ""}

                                                           onChange={(e) => {
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   anteUserId: e.target.value,
                                                               }));
                                                               setIsChanged(true); // 변경 사항이 있을 경우 true로 설정
                                                           }}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'anteUserId')}
                                                           ref={anteUserIdRef}
                                                    />
                                                </td>
                                                <th>분남금 납부 비밀번호</th>
                                                <td>
                                                    <input placeholder="" type="password" class="form-control"
                                                           value={registerReq.antePassword || ""}
                                                           onChange={(e) => {
                                                               setRegisterReq((prevState) => ({
                                                                   ...prevState,
                                                                   antePassword: e.target.value,
                                                               }));
                                                               setIsChanged(true); // 변경 사항이 있을 경우 true로 설정
                                                           }}
                                                           onKeyDown={(e) => onKeyDownHandler(e, 'antePassword')}
                                                           ref={antePasswordRef}
                                                    /></td>
                                            </tr>
                                            <tr>
                                                <th>사업자 등록증 사본 첨부</th>
                                                <td colSpan={3}>
                                                    <div className="tbl_files_outer">
                                                        <input placeholder="" type="file" className="form-control d-none"
                                                               ref={companyNumberFileRef}
                                                               id={"input-file"}
                                                               onChange={(e) => fileHandler(e,'companyNumberFile')}
                                                        />
                                                        <ul className="list_files ">
                                                            {registerFileReq.companyNumberFileName &&
                                                                <li>{registerFileReq.companyNumberFileName}
                                                                    <img src={process.env.PUBLIC_URL + "/images/icon_fileclose.png"} alt=""
                                                                         // onClick={removeFile}
                                                                         onClick={(e)=>removeFileHandler(e,'companyNumberFile')}
                                                                    />
                                                                </li>
                                                            }
                                                        </ul>
                                                        <button className="btn btn-secondary">
                                                            <label htmlFor={"input-file"}>
                                                                파일첨부
                                                            </label>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {/* 운반 허가증 사본 첨부 (Transporter 포함) */}
                                            {(kindCheck === 'Handler/Transporter' || kindCheck === 'Transporter') && (
                                                <tr>
                                                    <th>운반 허가증 사본 첨부</th>
                                                    <td colSpan={3}>
                                                        <div className="tbl_files_outer">
                                                            <input
                                                                placeholder=""
                                                                type="file"
                                                                className="form-control d-none"
                                                                ref={companyNumberFileRef}
                                                                id="drive-file"
                                                                onChange={(e) => fileHandler(e, 'driveFile')}
                                                            />
                                                            <ul className="list_files">
                                                                {registerFileReq.driveFileName && (
                                                                    <li>
                                                                        {registerFileReq.driveFileName}
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/images/icon_fileclose.png"}
                                                                            alt=""
                                                                            onClick={(e) => removeFileHandler(e, 'driveFile')}
                                                                        />
                                                                    </li>
                                                                )}
                                                            </ul>
                                                            <button className="btn btn-secondary">
                                                                <label htmlFor="drive-file">파일첨부</label>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {/* 처리 허가증 사본 첨부 (Handler 포함) */}
                                            {(kindCheck === 'Handler/Transporter' || kindCheck === 'Handler') && (
                                                <tr>
                                                    <th>처리 허가증 사본 첨부</th>
                                                    <td colSpan={3}>
                                                        <div className="tbl_files_outer">
                                                            <input
                                                                placeholder=""
                                                                type="file"
                                                                className="form-control d-none"
                                                                ref={companyNumberFileRef}
                                                                id="permit-file"
                                                                onChange={(e) => fileHandler(e, 'permitFile')}
                                                            />
                                                            <ul className="list_files">
                                                                {registerFileReq.permitFileName && (
                                                                    <li>
                                                                        {registerFileReq.permitFileName}
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/images/icon_fileclose.png"}
                                                                            alt=""
                                                                            onClick={(e) => removeFileHandler(e, 'permitFile')}
                                                                        />
                                                                    </li>
                                                                )}
                                                            </ul>
                                                            <button className="btn btn-secondary">
                                                                <label htmlFor="permit-file">파일첨부</label>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>


                                </div>
                                <div className="card-footer card_footer_btngrp_end">
                                    <button className="btn btn-primary btn_footer"
                                    onClick={baseSave}
                                    >수정하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5 className="singleLine">저장 성공</h5>
                    <p>수정되었습니다.</p>
                <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인
                </button>
            </Modal.Body>
        </Modal>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt=""/>
                    <h5>오류</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                    </button>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}