export const DeliveryEmailTemplate= ({resEmail,registrationIdx, constructionText, userCompanyName, requestEndTime }) => {

    const { userCompanyName: companyName, email } = userCompanyName;

    return (
        <>
            <div style={{ width: "100%", height: "100wh", minHeight: "900px", backgroundColor: "#f5f5f5", padding: "20px 0 40px 0 " }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "800px", height: "70px", margin: "0 auto", backgroundColor: "rgba(255, 223, 0, 1)", padding: "0 41px" }}>
                    {/*<img src={process.env.PUBLIC_URL + "/images/email_logo.png"} alt="" width={116} height={32} />*/}
                </div>
                <div style={{ width: "100%", maxWidth: "800px", minHeight: "726px", margin: "0 auto", backgroundColor: "#fff" }}>
                    <div style={{ width: "100%", maxWidth: "800px", minHeight: "726px", margin: "0 auto", paddingTop: "40px" }}>
                        <h1 style={{
                            fontFamily: "Pretendard, NotoSans, Arial",
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "24px",
                            textAlign: "center",
                            marginBottom: "25px",
                            color: "rgba(0, 0, 0, 1)"
                        }}>서명할 차례입니다.</h1>
                        <p style={{
                            fontFamily: "Pretendard, NotoSans, Arial",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "19.2px",
                            textAlign: "center",
                            marginBottom: "63px",
                            color: "rgba(0, 0, 0, 1)"
                        }}>서명 정보를 확인해주세요.</p>
                        <div style={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "800px", height: "72px", border: "1px solid rgba(220, 220, 220, 1)", borderWidth: "1px 0 1px 0", padding: "0 88px" }}>
                            <div style={{
                                width: "39.25%",
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "19.2px",
                                textAlign: "left",
                                color: "rgba(0, 0, 0, 1)"
                            }}>문서 제목</div>
                            <div style={{
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "22px",
                                textAlign: "left",
                                color: "rgba(0, 0, 0, 1)"
                            }}>{constructionText}<br />
                                </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "800px", height: "fit-content", padding: "0 88px", margin: "15px 0 0 0" }}>
                            <div style={{
                                width: "39.25%",
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "19.2px",
                                textAlign: "left",
                            }}>서명 요청자</div>
                            <div style={{
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "19.2px",
                                textAlign: "left",
                            }}>{companyName}({email})</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "800px", height: "fit-content", padding: "0 88px", margin: "0 0px 38px 0" }}>
                            <div style={{
                                width: "39.25%",
                                height: "51px",
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "19.2px",
                                textAlign: "left",
                            }}>구매자</div>
                            <div style={{
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "22px",
                                textAlign: "left",
                                height: "51px",
                                padding: "4px 6px"
                            }}>
                                <div>1. {resEmail}<br /></div>
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "800px", height: "72px", border: "1px solid rgba(220, 220, 220, 1)", borderWidth: "1px 0 1px 0", padding: "0 88px" }}>
                            <div style={{
                                width: "39.25%",
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "19.2px",
                                textAlign: "left",
                            }}>서명 유효기간 만료일</div>
                            <div style={{
                                fontFamily: "Pretendard, NotoSans, Arial",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "19.2px",
                                textAlign: "left",
                            }}>{requestEndTime}</div>
                        </div>
                        {/*<a href={`http://localhost:3000/oneclick/verify?serialNumber=${serialNumber || "number"}&informationSendIdx=${registrationIdx || "inforIdx"}`} style={{ textDecoration: 'none' }}>*/}
                        {/*<a*/}
                        {/*    // href={`http://192.168.0.6:8100/oneclick/verifyDelivery?registration=${registrationIdx || "registrationIdx"}&construction=${constructionText || "constructionText"}&email=${resEmail || "resEmail"}`}*/}
                        {/*    href={`http://redcube.pw:8100/oneclick/verifyDelivery?registration=${registrationIdx || "registrationIdx"}&construction=${constructionText || "constructionText"}&email=${resEmail || "resEmail"}`}*/}
                        {/*    style={{ textDecoration: 'none' }}*/}
                        {/*>*/}
                            <a
                                // href={`http://localhost:3000/oneclick/verifyDelivery?registration=${registrationIdx || "registrationIdx"}&construction=${constructionText || "constructionText"}&email=${resEmail || "resEmail"}`}
                                // href={process.env.REACT_APP_EMAIL_URL+`/verifyDelivery?registration=${registrationIdx || "registrationIdx"}&construction=${constructionText || "constructionText"}&email=${resEmail || "resEmail"}`}

                                href={process.env.REACT_APP_EMAIL_URL + `/verifyDelivery?registration=${registrationIdx || "registrationIdx"}&construction=${constructionText || "constructionText"}`}
                                style={{ textDecoration: 'none' }}
                            >
                        <button style={{
                            display: "block", width: "185px", height: "40px", margin: "35px auto 50px auto", backgroundColor: "rgba(255, 223, 0, 1)", border: "none",
                            fontFamily: "Pretendard, NotoSans, Arial",
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "16.8px",
                            textAlign: "center",
                            boxShadow: "unset",
                            borderRadius: "6px"
                        }}>내용 확인하고 서명하기</button>
                    </a>
                    </div>
                </div>
            </div>
        </>
    );
}