import { PublicFooter } from "../base/PublicFooter"
import { PublicHeader } from "../base/PublicHeader"
import React, {useRef, useState, useEffect} from "react";
import { useNavigate,useLocation } from 'react-router-dom';
export const MemberShipAgree = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo } = location.state || {};

    const [isAgreed1, setIsAgreed1] = useState(false);
    const isAgreed1Ref = useRef(null)
    const [isAgreed2, setIsAgreed2] = useState(false);
    const isAgreed2Ref = useRef(null)
    const [isAgreed3, setIsAgreed3] = useState(false);
    const isAgreed3Ref = useRef(null)
    const [AllAgreed, setAllAgree] = useState(false);
    const AllAgreedRef = useRef(null)

    const handleCheckboxChange1 = () => {
        setIsAgreed1(!isAgreed1);
        // scrollToRef(isAgreed2Ref)
    };

    const handleCheckboxChange2 = () => {
        setIsAgreed2(!isAgreed2);
        // scrollToRef(isAgreed3Ref)
    };

    const handleCheckboxChange3 = () => {
        setIsAgreed3(!isAgreed3);
        // scrollToRef(AllAgreedRef)
    };

    const handleCheckboxAllChange = () => {
        const newAgreedState = !AllAgreed;
        setAllAgree(newAgreedState);
        setIsAgreed1(newAgreedState);
        setIsAgreed2(newAgreedState);
        setIsAgreed3(newAgreedState);
    };

    useEffect(()=>{
        if(isAgreed1 && isAgreed2 && isAgreed3) setAllAgree(true)
        else setAllAgree(false)          
    },[isAgreed1, isAgreed2, isAgreed3])


    const handleGoBack = () => {
        navigate(-1); // 이전 페이지로 이동
    };

    // const scrollToRef = (ref) => {
    //     ref.current.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'center',
    //         inline: 'nearest'
    //     });
    // };
    const handleGoNext = () => {

        if(!isAgreed1){
            alert('필수약관 동의가 필요합니다.')
            // scrollToRef(isAgreed1Ref)
            return
        }

        if(!isAgreed2){
            alert('필수약관 동의가 필요합니다.')
            // scrollToRef(isAgreed2Ref)
            return
        }

        if(!isAgreed3){
            alert('필수약관 동의가 필요합니다.')
            // scrollToRef(isAgreed3Ref)
            return
        }

        if(!AllAgreed){
            // scrollToRef(AllAgreedRef)
            return
        }

        navigate("/memberShipRegister", { state:{userInfo}})


    };

    useEffect(() => {
        // 0.1초 후에 스크롤을 맨 위로 이동
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    return (
        <>
            <PublicHeader />
            <div className="memberterms_content">
                <div className="card memberterms_box">
                    <div className="card-header">
                        <div className="card-title">회원가입 약관</div>
                    </div>
                    <div className="card-body">
                        <div className="stepper_wrapper">
                            <div className="stepper_line"></div>
                            <ul>
                                <li className="active">
                                    <div className="stepper_dot dot_1"></div>
                                    <div className="stepper_text">약관동의</div>
                                </li>
                                <li>
                                    <div className="stepper_dot dot_2"></div>
                                    <div className="stepper_text">사용자정보 입력</div>
                                </li>
                                <li>
                                    <div className="stepper_dot dot_3"></div>
                                    <div className="stepper_text">회원가입완료</div>
                                </li>
                            </ul>
                        </div>
                        <h2 className="membership_text">원클릭 서비스 약관에 동의해주세요.</h2>

                        <div className="terms_section_wrapper">
                            <h4>1. &nbsp;회원서비스 약관 <span>(필수)</span></h4>
                            {/*<OneTextService />*/}
                            <div className="terms_content_box">
                                <br />
                                제 1 장 총칙<br />
                                <ul>
                                    <li>제 1 조 (목적)</li>
                                    <li>본 약관은 원클릭플랫폼(이하 회사)와 회사의 서비스(이하에서 정의함)를 이용하는 자간의 관계와 권리 의무 및 책임 사항, 기타 서비스 이용에 관한 제반 사항을 정함을 목적으로 합니다.</li>
                                    <li>제 2조 용어의 정의</li>
                                    <li>본 약관에서 사용하는 용어의 의미는 다음 각 호와 같으며, 여기에서 정의되지 않은 약관상 용어의 의미는 본 약관에서 달리 정의하지 않는 한 관련 법령 및 일반적인 거래관행에 따릅니다.</li>
                                    <li>1) '서비스' : 회사가 사용자에게 ‘사이트’ 을 통해 제공하는 업무와 이에 수반되는 제반 작업을 의미합니다.
                                        회사는 경영 정책상 또는 안정적인 서비스 제공을 위한 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 '서비스'를 변경할 수 있으며 변경된 내용은 제3조의 방법으로 공지합니다.</li>
                                    <li>
                                        2)    '사용자' : 회사의 서비스를 이용하는 고객을 의미합니다.
                                    </li>
                                    <li>3)    ‘가입신청자’ : 회사의 사이트상의 회원가입을 통해 사용자가 되고자 하는 자를 의미합니다.</li>
                                    <li>4)    '계정' : 사용자가 회사의 사이트 을 통해 회원 가입하여 부여 받은 계정의 의미를 가집니다.</li>
                                    <li>5)'사이트' : 회사가 사용자에게 서비스를 제공하기 위해 운영하는 원클릭플랫폼 웹사이트(http://one-cl.com)를 의미합니다.</li>
                                    <li>6)    ‘개인정보’ : 개인정보 보호법 제2조 제1호에서 정의된 의미를 가집니다.</li>
                                    <li>7)    ‘사용자 데이터’ : 사용자가 서비스를 이용하기 위하여 또는 서비스를 이용하는 과정에서 생성하거나 생성된 개인정보 외의 모든 데이터를 의미하며, 사이트 사용에 따른 통계학적 정보를 포함합니다.</li>
                                    <li>8)   ‘제3자 데이터’ : 사용자가 아닌 제3의 사이트 이용자(이하 본 호에서 ‘제3자’)가 서비스 이용과정에서 사이트에 작성한 정보로서, 제3자가 자발적으로 공개한 개인정보 및/또는 회사가 본 약관에서 정한 바에 따라 제3자가 제공 받는 서비스와 직접적으로 관련 있는 사용자들에게 제한적으로 공개한 비개인정보를 의미합니다.</li>
                                    <li>제 3조 약관의 명시와 설명 및 개정</li>
                                    <li>1. 회사는 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 전화번호, 사업자 등록번호 등을 사용자가 알 수 있도록 사이트에 게시합니다.</li>
                                    <li>2. 본 약관은 사용자가 본 약관의 내용을 충분히 이해한 후 그 내용을 준수하는 것에 동의함으로써 효력이 발생합니다.  서비스 이용을 시작한 사용자는 당연히 본 약관을 준수함에 동의한 것으로 간주합니다.</li>
                                    <li>3. 약관의 변경이 필요하다고 회사에서 판단할 경우 “약관의 규제에 관한 법률”, “정보통신망이용촉진 및 정보보호 등에 관한 법률” 등 관계 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 사이트의 화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. </li>
                                    <li>4. 제3항에 따라 공지된 적용일자 이후에 사용자가 서면으로 명시적 거부의사를 표명하지 않을 경우에는 개정된 약관에 동의하는 것으로 간주하며, 변경된 약관에 동의하지 않는 사용자는 사이트의 회원 탈퇴를 통해 서비스 이용을 해지할 수 있습니다. 제3항의 조치에도 불구하고 약관의 개정 사실을 알지 못함으로써 발생하는 사용자의 피해에 대하여 회사는 책임을 지지 않습니다.</li>
                                </ul>
                                <br />
                                제 2 장 회원가입<br />
                                <ul>
                                    <li>제4조 회원가입 신청</li>
                                    <li>1.    사용자는 회사가 제공하는 다음 각호의 절차에 따라 회원가입을 진행합니다.</li>
                                    <li>1)    본 약관에 대한 동의</li>
                                    <li>2)    회원가입 완료 확인</li>
                                    <li>2.    회사는 본 약관에 동의하고 회원 정보를 사실대로 기재한 가입신청자의 가입 신청을 승낙함을 원칙으로 합니다. 가입신청자는 회사에서 제공한 필요사항을 기재하여야 하며, 본인 이메일 또는 휴대폰 인증 등 회사가 지정한 방법을 통한 본인확인 절차를 이행하여야 합니다. 단, 다음 각 호에 해당하는 신청에 대해서는 회사가 회원가입을 거부하거나 회원에게 탈퇴 요청을 할 수 있습니다.</li>
                                    <li>1)    가입신청자가 본 약관에 의하여 이전에 사용자 자격을 상실한 적이 있는 경우 (단, 회사의 사용자 재가입 승낙을 얻은 경우에는 예외로 합니다.)</li>
                                    <li>2)    실명이 아니거나 타인의 명의를 이용한 경우</li>
                                    <li>3)    허위 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
                                    <li>4)    사용자가 서비스의 정상적인 제공을 저해하거나 다른 사용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우</li>
                                    <li>5)    사용자의 귀책사유로 인하여 승인이 불가하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우</li>
                                    <li>6)    기타 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있다고 판단하는 경우</li>
                                    <li>3.    회사는 다음에 해당하는 상황에서 회원가입심사를 지연할 수 있습니다.</li>
                                    <li>1)    회사의 설비에 여유가 없거나 기술적 장애가 있는 경우</li>
                                    <li>2)    서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우</li>
                                    <li>3)    기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우</li>
                                    <li>4.    회사는 사용자의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청하거나 증빙자료를 추가로 요청할 수 있습니다.</li>
                                    <li>5.    사용자가 제공한 정보는 본인의 실제 정보로 간주되며 정보가 허위이거나 현재의 사실과 일치하지 않을 경우에 발생하는 모든 문제에 대해 회사는 책임지지 않습니다.</li>
                                    <li>제5조 개인정보 보호 및 이용</li>
                                    <li>1. 회사는 “개인정보 보호법” 등 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보 수집 및 이용에 대해서는 관련 법령, 본 약관, 회사의 개인정보 처리방침 이용약관이 적용됩니다.</li>
                                    <li>2. 사용자는 원활한 서비스 이용을 위하여 사용자가 제공받는 서비스와 직접적으로 관련 있는 다음의 사용자에게 아래 표에서 열거한 개인정보를 공유할 수 있습니다. 회사는 서비스의 원활한 제공을 위하여 관련 법령에 따라 개인정보를 수집 ∙ 이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 개인정보 보호 및 이용에 관련된 상세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/agree_register_one.png"} alt="" />
                                </ul>
                                <br />
                                제 3장 회원 및 계정 관리<br />
                                <ul>
                                    <li>제6조 회원 계정</li>
                                    <li>1.    회원 계정은 ‘운반업체계정’(이하 “운반계정”)과 ‘처리업체계정’(이하“처리계정) 및 `운반처리업체계정`(이하 ”운반처리계정“)으로 구분됩니다.</li>
                                    <li>2.   계정은 본 약관 제4조에서 정한 절차에 따라 사용자가 회원가입을 완료함으로써 생성되며 계정을 이용하는 경우 회사에서 제공하는 서비스 및 시스템 상 계정의 권한에 해당하는 모든 기능에 접근할 수 있습니다.</li>
                                    <li>3.   계정이 본 약관을 위배하여 회사에 손해를 입힌 경우의 책임은 전적으로 계정에 있습니다.</li>
                                    <li>제7조 회원 정보의 변경</li>
                                    <li>1. 사용자는 회원가입 시 기재한 사항이나 서비스 이용 과정에서 회사에 제공한 정보가 변경되었을 경우 즉시 사용자 정보를 변경하여야 하며, 변경사항을 수정하지 않아 발생한 모든 불이익에 대하여 회사는 책임지지 않습니다.
                                        운반업체 및 처리업체는 필요에 따라 배출업체 정보을 수정 및 저장할수 있습니다.</li>
                                </ul>
                                <br />
                                제4장 계약당사자의 의무 및 책임<br />
                                <ul>
                                    <li>제8조 사용자 의무 및 책임</li>
                                    <li>1.    회원으로 가입된 모든 사용자는 본 약관의 규정, 이용 안내와 관련하여 회사가 공지한 사항을 준수할 의무가 있습니다.</li>
                                    <li>2.    회사가 유료로 제공하는 서비스를 이용할 경우, 사용자는 회사가 정한 서비스 이용요금을 지정된 날짜에 납입할 의무가 있습니다.</li>
                                    <li>3.    사용자가 생성하거나 제공한 개인정보 및 사용자데이터에 대한 책임은 전적으로 고객에게 있습니다.  사이트 또는 시스템 상에 등록된 정보에 변경이 있는 경우, 사용자는 해당 정보를 바로 업데이트해야 합니다.</li>
                                    <li>4.    사용자는 회사의 사전 서면 동의 없이 본 계약과 관련하여 취득한 기술상, 업무상 및 영업상의 비밀 및 제반 정보를 제3자에게 누설하거나 본 계약의 이행을 위한 목적 이외의 용도로 사용하여서는 안 됩니다.</li>
                                    <li>5.    사용자는 본 서비스의 이용에 있어서 본 약관 등 회사가 제공하는 각종 정보 및 주의 사항 등을 확인하고 본 서비스의 내용 및 구조를 충분히 이해한 후 자신의 판단과 책임으로 이용해야 합니다.</li>
                                    <li>6.    사용자는 아이디와 비밀번호에 관한 관리 책임이 있습니다. 사용자는 자신의 아이디 및 비밀번호를 제3자에게 제공, 공개하거나 제3자가 이용하도록 해서는 안 됩니다.</li>
                                    <li> - 사용자가 자신의 아이디 및 비밀번호 유출 또는 제3자 사용에 대해 인지하고도 비밀번호를 변경하지 않은 경우 또는 이와 같은 사정을 회사에 통지하지 않거나 회사의 조치에 응하지 않은 경우 등 사용자가 본 조의 의무를 준수하지 않아 발생하는 모든 손실이나 손해 등 불이익에 대한 책임은 사용자에게 있습니다.</li>
                                    <li> - 사용자의 아이디와 비밀번호가 외부에 누출되었다고 의심되는 경우, 회사는 계정 잠금 등 보호조치를 취할 수 있으며 사용자의 이용이 제한될 수 있습니다.</li>
                                    <li>7.     사용자 계정에 미납요금이 존재하는 경우, 회사는 사용자에게 14일의 기간을 정하여 통화, 서면, 이메일 등의 방법으로 이행을 최고하여 계약을 해지하고 미납요금에 대한 손해배상을 청구할 수 있습니다.</li>
                                    <li>제9조 회사의 의무 및 책임</li>
                                    <li>1.    회사는 관계 법령과 본 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 하여야 합니다.</li>
                                    <li>2.    회사는 회원에 대한 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선 작업 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 해결이 곤란한 기술적 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</li>
                                    <li>3.    회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다. 회사는 관련 법령, 본 약관, 위치기반서비스 이용약관 및 개인정보 처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.</li>
                                </ul>
                                <br />
                                제5장 서비스 이용 및 중단<br />
                                <ul>
                                    <li>제10조 사용자 데이터</li>
                                    <li>1. 사용자는 서비스 이용과정에서 사이트에 사용자데이터를 업로드, 제공, 저장, 송부 또는 공개할 수 있습니다. 사용자 데이터에 대한 지식재산권 등의 권리는 여전히 해당 사용자에게 전적으로 귀속됩니다. 사용자는 사이트에 부정확하거나 무효 또는 완전하지 않은 정보, 기타 관련 법령 및 본 약관에서 허용되지 않는 정보를 제공하거나 입력하여서는 안 됩니다.  사용자가 사이트에 제공 또는 입력한 사용자 데이터에 대한 책임(법령 위반에 따른 벌금·과태료 등의 제재 책임을 포함하되 이에 한정되지 아니함)은 전적으로 해당 사용자가 부담하며, 회사는 이에 대해 아무런 책임을 지지 않습니다(단, 관련 경우에 회사의 고의 과실이 존재하는 경우는 예외로 함).</li>
                                    <li>2.          회사는 사용자가 제공받는 서비스와 직접적으로 연관이 있는 다음의 자들에 대하여 해당 사용자의 원활한 서비스 이용을 위하여 합리적으로 요구되는 범위 내에서 해당 사용자의 사용자데이터의 일부를 공유할 수 있습니다.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/agree_register_two.png"} alt="" />
                                    <li>3. 본 약관 제5조 및 본 조 제2항의 적용을 전제로, 관련 법령이 허용하는 한도 내에서 회사는 [(i) 서비스 향상 및 개선, (ii) 회사 및 회사 계열회사의 신규 제품이나 서비스 개발, 출시 및 개선, (iii) 홍보마케팅 전략 수립 및 마케팅/홍보물 제작, (iv) 고객과의 커뮤니케이션, (v) 통계 목적, (vi) 과학적 연구]를 위하여 사용자 데이터 및 개인정보를 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기 기타 유사한 행위(이하 본 항에서 ‘처리’)를 할 수 있습니다.  사용자는 본 약관에 동의함으로써 회사가 본 조 제2항 및 제3항의 목적을 위해 사용자데이터를 처리하는 것에 동의하게 됩니다.</li>
                                    <li>제11조 제3자 데이터</li>
                                    <li>1. 사용자는 서비스 이용과정에서 제3자 데이터에 접근할 수 있습니다.  제3자 데이터에 대한 지식재산권 등의 권리는 여전히 해당 데이터를 생성한 회사 또는 개인에게 귀속되고, 사용자가 서비스를 이용한다고 해서 사용자에게 그에 대한 권리의 전부 또는 일부가 이전되는 것은 아닙니다.  제3자 데이터가 부정확하거나 무효 또는 완전하지 않은 정보, 기타 관련 법령 및 본 약관에서 허용되지 않는 정보에 해당하여 발생한 책임(법령 위반에 따른 벌금·과태료 등의 제재 책임을 포함하되 이에 한정되지 아니함)은 사이트에서 해당 데이터를 생성한 회사 또는 개인이 전적으로 부담하며, 회사는 아무런 책임을 부담하지 않습니다(단, 관련 경우에 회사에 고의 또는 중대한 과실이 존재하는 경우는 예외로 함).</li>
                                    <li>2. 사용자는 회사 또는 제3자 데이터의 작성자로부터 달리 동의 받지 않는 한 오직 서비스 이용 목적에 필요한 범위에 한하여 제3자 데이터를 이용하여야 하고, 그 이외의 목적으로 이를 이용하거나 외부로 공개, 누설, 복제, 재생산, 수정, 변경할 수 없습니다.</li>
                                    <li>제12조 제3자에 대한 공개</li>
                                    <li>관련 법령, 본 약관, 개인정보 처리방침 이용약관에서 정한 경우를 제외하고, 회사는 사용자가 달리 동의하지 않는 한 사용자의 개인정보 및 사용자 데이터를 제3자에게 공개하거나 공유하지 않습니다.</li>
                                    <li>제13조 서비스 제한조치</li>
                                    <li>1. 회사는 다음 각 호의 경우 해당 사용자에 대하여 서비스 제공을 중단할 수 있습니다.</li>
                                    <li>1) 사용자가 서비스를 이용함에 있어서 본 약관, 개인정보 처리방침, 이용약관 또는 관련         법령을 위반한 경우</li>
                                    <li>2) 사용자가 서비스를 이용함에 있어서 제3자의 권리나 영업비밀을 침해한 경우</li>
                                    <li>3) 사용자가 서비스를 이용함에 있어서 건전한 사회질서를 위반하거나, 미풍양속, 공공질         서에 반하는 행위를 하는 경우</li>
                                    <li>4) 사용자가 회사의 시스템, 서버 등에 과부하를 발생시키거나 자동화 프로그램 등을 통해 기계적인 접근을 하거나, 침입 또는 침입을 시도하는 등 회사의 시스템 및 서버에 부당하게 접근하거나 그러한 시도를 하는 경우</li>
                                    <li>5) 사용자가 회사가 명확하게 공지한 운영정책이나 공지사항을 위반하는 경우</li>
                                </ul>
                                <br />
                                제6장 회원 탈퇴<br />
                                <ul>
                                    <li>제14조 사용자의 이용계약 해지</li>
                                    <li>1. 사용자가 서비스 이용 기간 중 이용계약을 해지하고자 하는 경우 본인이 직접 사이트를 통하여 탈퇴 신청을 하거나 서면으로 회사에 계약 해지 의사를 밝혀야 합니다.</li>
                                    <li>2. 회사는 사용자 가입정보를 탈퇴 시 지체 없이 말소합니다. 단, 관계법령이 있는 경우 관계법령을 따릅니다.</li>
                                    <li>제15조 회사의 이용계약 해지</li>
                                    <li>1. 회사는 사용자가 본 약관에서 정한 사용자의 의무를 위반한 경우에는 사용자에 대한 사전 통보 후 이용계약을 해지할 수 있습니다. 다만, 사용자가 관계 법령 위반이나 고의 또는 중대한 과실로 회사에게 손해를 입힌 경우에는 사전 통보 없이 이용계약을 해지할 수 있습니다.</li>
                                    <li>2. 회사가 이용계약을 해지하는 경우 회사는 사용자에게 서면, 전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을 통보합니다.</li>
                                    <li>1)    해지사유</li>
                                    <li>2)    해지일</li>
                                    <li>3.    제1항의 경우 회원은 서비스의 이용권한을 상실하며, 그로 인한 손해배상을 청구할 수 없습니다.</li>
                                </ul>
                                <br />
                                제7장 손해배상 등<br />
                                <ul>
                                    <li>제16조 (손해배상)</li>
                                    <li>1. 회사가 고의 또는 중과실로 사용자에게 손해를 끼친 경우, 사용자가 입은 손해에 대하여 배상할 책임이 있습니다.</li>
                                    <li>2. 사용자가 본 약관을 위반하여 회사에 손해를 끼친 경우, 사용자는 회사에 대하여 손해를 배상할 책임이 있습니다.</li>
                                    <li>제17조 (유료서비스 이용 계약)</li>
                                    <li>1. 회사가 유료회원의 이용신청을 승낙한 날로부터 유료서비스는 개시되며, 회사의 기술적 사유, 운영상 사유 등 기타 사정에 의하여 유료서비스를 개시할 수 없는 경우에는 회원에게 개별 통지 또는 공지합니다.</li>
                                    <li>2. 회사는 회원의 유료서비스 이용신청이 있는 경우, 해당 회원에게 이용신청의 내용을 통지합니다. 회사의 통지를 받은 회원은 의사표시의 불일치 등 오류가 있는 경우 회사에 정정 또는 수정을 요청하여야 하며, 회사는 회원의 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 이 약관의 청약 철회 등에 관한 규정에 따릅니다.</li>
                                    <li>3. 회사는 다음 각 호와 같은 유료서비스를 제공하며, 회사의 사정, 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.</li>
                                    <li>4.판매방식에 따른 분류</li>
                                    <li>1) 직접판매 : 회사가 제공하는 유료서비스를 회원에게 결제 수단을 통해 직접 제공하는 방식을 말합니다.</li>
                                    <li>5.이용 기간에 따른 분류</li>
                                    <li>1) 월 요금제 : 요금제 사용 기간이 월 단위인 유료서비스를 말합니다.</li>
                                    <li>6.정기결제 종류에 따른 분류 </li>
                                    <li>1) 월 정기결제 : 매월 카드 또는 예치금 등을 통해 자동으로 결제, 갱신되는 유료 서비스를 말합니다.</li>
                                    <li>제18조 (요금제 변경)</li>
                                    <li>1. 회사는 회원이 유료서비스 변경을 신청할 경우 즉시 반영합니다. 단, 요금제의 종류 및 변경 신청 시점 등에 따라 요금제 변경 시점은 달라질 수 있다.</li>
                                    <li>제19조 (결제수단 등)</li>
                                    <li>1. 회원이 유료서비스의 결제를 위하여 이용할 수 있는 수단은 다음 같습니다.</li>
                                    <li>1) 신용카드</li>
                                    <li>제20조 (청약 철회 및 환불 및 유료서비스 정기결제 중단)</li>
                                    <li>1. 유료 회원은 유료서비스 공급일 이후 제공되는 서비스을 사용하지 않았을 경우 7일 이내에 회사에 결제 취소(청약 철회)를 요청할 수 있습니다.</li>
                                    <li>2. 유료회원이 유료서비스 공급일 이후 청약 철회가 가능한 기간(유료서비스 공급일로부터 7일 이내)을 경과하여 청약 철회 및 환불을 신청하면 전체 이용대금에서 기 이용일수에  해당하는 금액을 환불합니다.</li>
                                    <li>3. 유료회원이 유료서비스 정기결제를 중단했을 때, 이미 결제가 완료된 잔여 계약 기간 동안 유료서비스를 이용할 수 있습니다.</li>
                                    <li>4. 청약의 철회는 유료회원이 서비스 홈페이지를 통해 할 수 있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이 발생하고, 회사는 유료회원의 의사표시를 수령한 후 지체 없이 이러한 사실을 유료회원에게 회신합니다.</li>
                                    <li>5. 회사는 유료회원이 청약 철회 신청을 확인 후 환불 금액이 있을 경우, 원칙적으로 유료회원의 해당 의사표시를 수령한 날로부터 3영업일 이내에 결제수단 별 사업자에게 대금의 청구 정지 내지 취소를 요청하고, 유료회원이 결제한 동일 결제수단으로 환불함을 원칙으로 합니다. 단, 회사가 사전에 유료회원에게 전자메일, 서비스 홈페이지로 공지한 경우 및 아래의 각 호의 경우와 같이 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을 수 있습니다.
                                    신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납 확인일로부터 3영업일 이내
                                        결제수단 별 사업자가 회사와의 약정을 통하여 사전에 대금 청구 정지 내지 결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당 기한을 지난 환불의 경우
                                        유료회원이 환불 처리에 필요한 정보 내지 자료를 회사에 즉시 제공하지 않는 경우(현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지 아니하거나, 타인 명의의 계좌를 제공하는 경우 등)
                                        해당 회원의 명시적 의사표시가 있는 경우</li>
                                    <li>6. 회원이 유료서비스를 프로모션 등을 통해 무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니하고 이용하는 유료서비스에 대하여 회사는 회원에게 유료서비스 결제 대금을 환불할 의무를 부담하지 않습니다.</li>
                                    <li>7. 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 계약을 해제, 해지하거나 서비스 이용 제한, 손해배상 청구 등의 조치를 취할 수 있으며, 계약 해지 후 환불하여야 할 금액이 있을 경우에는 일정한 범위 내에서 회원이 부담할 금액을 공제하고 환불합니다. 이 경우 회원은 해당 회사의 조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 확인하는 경우 서비스 이용 재개 등을 할 수 있으며, 이용자가 자신의 고의, 과실이 없었음을 입증한 경우 회사는 서비스를 정지한 기간만큼 이용 기간을 연장합니다.</li>
                                    <li>8. 유료회원의 신청 또는 동의에 따라 월 정기결제 중인 유료서비스의 경우, 해당 회원이 유료서비스의 이용요금을 체납하는 경우 연체가 발생한 날 자동으로 이용권 해지가 될 수 있으므로, 유료서비스를 유지하고자 하는 유료회원은 이용요금의 체납 또는 결제수단의 연체가 발생하지 않도록 사전에 조치하여야 합니다.</li>
                                    <li>9. 관련 법령에서 달리 정하지 않는 한, 유료서비스를 이용 중인 유료회원이 탈퇴하는 경우 유료서비스는 즉시 해지되며 환불은 불가능합니다. 이 경우 유료회원의 정보와 서비스이용내용은 관련 법령이 정하는 경우를 제외하고는 약관 및 이 약관에 따라 처리됩니다.</li>
                                    <li>제21조 (유료서비스 내용 변경)</li>
                                    <li>1. 회사는 운영상, 기술상의 필요에 따라 공급하는 유료서비스 내용(가액, 제공 서비스 내역 등)을 변경할 수 있으며, 제9조에서 정한 방법에 따라 유료회원에게 사전공지합니다.</li>
                                    <li>제22조 (회사의 면책)</li>
                                    <li>1. 회사는 전시, 사변, 천재지변, 국가비상사태, 해결이 곤란한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이 면제됩니다.</li>
                                    <li>2. 회사는 사용자의 귀책사유로 인한 서비스의 중지, 이용장애 및 이용계약 해지에 대하여는 책임이 면제됩니다.</li>
                                    <li>3. 사용자 또는 회사의 이용계약 해지 등으로 인하여 사용자와 회사의 이용계약이 종료되는 경우, 관계 법령, 본 약관, 위치기반서비스 이용약관 및 개인정보 처리방침에 따라 회사가 사용자정보(개인정보 및 사용자 데이터를 포함하며, 이하 동일함)를 보유하는 경우를 제외하고는 회사는 보다 나은 서비스 환경 등을 위하여 해지 즉시 사용자의 가입정보 및 관련된 모든 데이터를 삭제할 수 있으며 회사는 이용계약 종료 이후 사용자의 가입정보 및 관련 데이터 삭제로 인한 책임을 부담하지 않습니다</li>
                                    <li>4. 회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 사용자에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                                    <li>5. 회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                                    <li>6. 회사는 사용자의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다.</li>
                                    <li>7. 회사는 사용자 또는 제3자가 서비스 내 또는 사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                                    <li>8. 회사는 사용자 상호간 또는 사용자와 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</li>
                                    <li>9. 회사는 사용자의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 기재하지 아니하여 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
                                    <li>10. 회사는 관련 법령, 정부 정책 등에 따라 서비스 또는 사용자에 따라 서비스 이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에 따라 발생하는 서비스 이용 관련 제반사항에 대해서는 책임이 면제됩니다.</li>
                                    <li>제23조 (사용자의 고충처리 및 분쟁해결)</li>
                                    <li>1. 회사는 사용자의 편의를 고려하여 사용자의 의견이나 불만을 제시하는 방법을 서비스 초기화면 또는 홈페이지에서 안내합니다. 회사는 이러한 사용자의 의견이나 불만을 처리하기 위한 운영합니다.</li>
                                    <li>2. 회사는 사용자로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 사용자에게 장기간이 소요되는 사유와 처리일정을 회사 홈페이지에 공지하거나 전자우편, 전화 또는 서면 등으로 통보합니다.</li>
                                    <li>3. 회사와 사용자간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 사용자에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.</li>
                                    <li>제24조 (준거법 및 관할법원)</li>
                                    <li>1. 회사와 사용자 간에 제기된 소송은 대한민국 법률을 준거법으로 합니다.</li>
                                    <li>2. 회사와 사용자간 발생한 분쟁에 관한 소송에 대해서는 서울중앙지방법원이 관할권을 가지는 것으로 합니다.</li>
                                    <li>본 약관은 2024년 11월 01일부터 적용됩니다.  </li>
                                </ul>
                            </div>
                            <div className="terms_agree_btm">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           value="1" id="chk_agree1"
                                           checked={isAgreed1}
                                           onChange={handleCheckboxChange1}
                                           ref = {isAgreed1Ref}

                                    />
                                    <label className="form-check-label" for="chk_agree1">
                                        해당 약관에 동의 합니다.
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="terms_section_wrapper">
                            <h4>2. &nbsp;서비스 활용 목적의 개인정보 및 데이터 공개 동의 <span>(필수)</span></h4>
                            <div className="terms_content_box">
                               <br />
                                개인정보 보호 및 이용<br />
                                <ul>
                                  <li>1. 회사는 “개인정보 보호법” 등 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보 수집 및 이용에 대해서는 서비스 관련 법령, 회사의 개인정보 처리방침, 회원가입 이용약관 및 서비스 이용약관이 적용됩니다.</li>
                                  <li>2. 사용자는 원활한 서비스 이용을 위하여 사용자가 제공받는 서비스와 직접적으로 관련 있는 다음의 사용자에게 아래 표에서 열거한 개인정보를 공유할 수 있습니다. 회사는 서비스의 원활한 제공을 위하여 관련 법령에 따라 개인정보를 수집 ∙ 이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 개인정보 보호 및 이용에 관련된 상세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/agree_register_three.png"} alt="" />
                                    <li>2. 사용자는 원활한 서비스 이용을 위하여 사용자가 제공받는 서비스와 직접적으로 관련 있는 다음의 사용자에게 아래 표에서 열거한 개인정보를 공유할 수 있습니다. 회사는 서비스의 원활한 제공을 위하여 관련 법령에 따라 개인정보를 수집 ∙ 이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 개인정보 보호 및 이용에 관련된 상세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.</li>
                                </ul>
                                <br/>
                                사용자 데이터<br/>
                                <ul>
                                    <li>3. 사용자는 서비스 이용과정에서 사이트에 사용자데이터를 업로드, 제공, 저장, 송부 또는 공개할 수 있습니다. 사용자 데이터에 대한 지식재산권 등의 권리는 여전히 해당 사용자에게 전적으로 귀속됩니다. 사용자는 사이트에 부정확하거나 무효 또는 완전하지 않은 정보, 기타 관련 법령 및 본 약관에서 허용되지 않는 정보를 제공하거나 입력하여서는 안 됩니다. 사용자가 사이트에 제공 또는 입력한 사용자 데이터에 대한 책임(법령 위반에 따른 벌금·과태료 등의 제재 책임을 포함하되 이에 한정되지 아니함)은 전적으로 해당 사용자가 부담하며, 회사는 이에 대해 책임을 지지 않습니다(단, 관련 경우에 회사의 고의 또는 중대한 과실이 존재하는 경우는 예외로 함).</li>
                                    <li>4. 회사는 사용자가 제공받는 서비스와 직접적으로 연관이 있는 다음의 자들에 대하여, 해당 사용자의 원활한 서비스 이용을 위하여 합리적으로 요구되는 범위 내에서 해당 사용자의 사용자데이터의 일부를 공개할 수 있습니다.</li>
                                    <img src={process.env.PUBLIC_URL + "/images/agree_register_four.png"} alt="" />
                                    <li>5. 서비스의 회원가입 이용약관 제5조 및 제11조 제2항의 적용을 전제로, 관련 법령이 허용하는 한도 내에서 회사는 [(i) 서비스 향상 및 개선, (ii) 회사 및 회사 계열회사의 신규 제품이나 서비스 개발, 출시 및 개선, (iii) 홍보마케팅 전략 수립 및 마케팅/홍보물 제작, (iv) 고객과의 커뮤니케이션, (v) 통계 목적, (vi) 과학적 연구]를 위하여 사용자 데이터 및 개인정보를 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기 기타 유사한 행위(이하 본 항에서 ‘처리’)를 할 수 있습니다.</li>
                                    <li>[필수]</li>
                                    <img src={process.env.PUBLIC_URL + "/images/agree_register_three.png"} alt="" />
                                    <li>[사용자 데이터]</li>
                                    <img src={process.env.PUBLIC_URL + "/images/agree_register_four.png"} alt="" />
                                </ul>
                            </div>
                            <div className="terms_agree_btm">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="1" id="chk_agree2"
                                           checked={isAgreed2}
                                           onChange={handleCheckboxChange2}
                                           ref = {isAgreed2Ref}
                                          />
                                   <label className="form-check-label" for="chk_agree2">
                                        해당 약관에 동의 합니다.
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="terms_section_wrapper">
                            <h4>3. &nbsp;개인정보 처리방침 <span>(필수)</span></h4>
                            <div className="terms_content_box">
                                <br />
                                원클릭플랫폼 (이하 "회사")는 서비스를 제공하는 회사로 개인정보보호의 중요성에 대해 인식하고 있으며 "개인정보 보호법" 등 관련 법률과 규정을 준수함과 동시에 다음의 개인정보처리방침에 따라 적절한 취급 및 보호를 위해 노력합니다. 회사는 개인정보처리방침을 통하여 고객(이하 “사용자”)이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br />
                                <ul>
                                    <li>제1조. 개인정보
                                        <ul>
                                         <li>본 개인정보처리방침에서 "개인정보"는 개인정보 보호법 제 2조 제1항에 따라 정의된 개인정보, 즉 살아 있는 개인에 관한 정보로서 성명, 주민등록번호 등을 통하여 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사는 개인 정보의 취급에 관한 법령, 국가가 정하는 지침 규범을 준수합니다.</li>
                                        </ul>
                                    </li>
                                    <li>제2조. 개인정보 수집</li>
                                        <ul>
                                            <li>1.    개인정보 수집 및 이용 항목</li>
                                            <ul>
                                                <li>회사는 홈페이지, 전화, 이메일, 상담 등의 방법으로 수집 및 이용목적에 따라 필요한 최소한의 사용자 정보를 수집하고 이용합니다. 수집하는 개인정보 항목과 수집 및 이용목적은 다음과 같습니다.

                                                    수집 및 이용목적

                                                    수집항목

                                                    회원가입 및 관리, 서비스 제공

                                                    개인사업자, 법인

                                                    회사명, 대표자 이름, 회사 주소·이메일·전화번호,

                                                    사업자번호, 한국건설자원공제조합 정보 , 회사 계좌 및 카드 정보, 허가증 정보


                                                    서비스 문의

                                                    이름, 연락처, 이메일, 회사명 등 질문 및 요청의 접수와 답변을 위한 정보들</li>
                                            </ul>
                                            <li>2.    개인정보 수집 방법</li>
                                            <li>회사는 아래와 같은 방법을 통해 관련 법령에 따라 개인정보를 수집합니다.</li>
                                            <ul>
                                                <li>1)    회원가입 및 서비스 이용 과정에서 사용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우</li>
                                                <li>2)    제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우</li>
                                                <li>3)    웹페이지, 메일, 팩스, 전화 등 영업 응대와 고객센터를 통한 상담 과정에서 인입, 처리한 경우</li>
                                                <li>4)    온·오프라인에서 진행되는 이벤트/행사 등 참여해서 수집, 전달받은 정보</li>
                                            </ul>
                                            <li>제3조. 개인정보 이용의 목적</li>
                                            <li>회사는 사용자 정보의 아래 이용 목적을 명확하게 파악하고 이용 목적 이외에는 개인정보를 활용하지 않으며 이용목적에 포함되지 않는 개인정보 활용이 이루어지지 않도록 적절한 조치를 취합니다.</li>
                                            <ul>
                                                <li>1.    회원가입 및 관리</li>
                                                <li>계정 및 비밀번호 관리, 사용자 등록 메일을 전송, 회사의 서비스에 대한 약관, 정책 등에 위반하는 행위에 대한 대응, 회사 서비스 약관 등의 변경 통지 등을 위한 목적으로 개인정보를 처리합니다.</li>
                                                <li>2.    서비스 제공</li>
                                                <li>회사 서비스의 제공, 회사 서비스 개선, 새로운 서비스 개발 등을 지원, 회사 서비스에 관련한 통계 데이터 취득 등을 위한 목적으로 개인정보를 처리합니다.</li>
                                                <li>3.    서비스 문의</li>
                                                <li>회사 서비스에 대한 안내, 문의 등에 응대하기 위한 목적으로 개인정보를 처리합니다.</li>
                                            </ul>
                                            <li>제4조. 개인정보의 보유⋅이용 기간 및 파기</li>
                                            <li>회사가 제공하는 서비스를 사용자가 이용하는 동안 회사는 사용자의 개인정보를 계속 보유하고, 서비스 제공 등을 위해 이용하며, 이용목적이 달성된 때에는 당해 개인정보를 즉시 파기합니다. 다만, 아래 제6조 사용자의 요구에 의한 (열람, 정정, 삭제 등) 사항에서 설명한 절차와 방법에 따라 사용자 본인이 직접 삭제하거나 수정한 정보, 가입해지를 요청한 경우에는 재생할 수 없는 방법에 따라 디스크나 개인정보가 보관된 데이터상에서 완전히 삭제하며 추후 열람이나 이용할 수 없는 상태로 처리됩니다. 단, 관련 법령의 규정에 따라 보존할 필요가 있는 경우 관계 법령에서 정한 일정 기간 동안 사용자 정보를 보관합니다. 당해 정보는 그 보관의 목적으로만 이용하며 보존 기간은 아래와 같습니다.</li>
                                            <ul>
                                                <li>1.    서비스 사용자 가입정보</li>
                                                <li>사용자 탈퇴 또는 자격 상실: 부정이용 이슈 방지를 위해 탈퇴 혹은 자격상실일 이후 탈퇴 DB에서 6개월 후 삭제</li>
                                                <li>2.    서비스 제공 및 거래기록: 국세기본법에 따른 거래 사실 보존(5년)</li>
                                                <li>3.    서비스 이용 계약에 관한 기록: 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 보존(5년)</li>
                                                <li>4.    대금결제 및 재화 등의 공급에 관한 기록: 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 보존(5년)</li>
                                                <li>5.    소비자의 불만 또는 분쟁 처리에 관한 기록: 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 보존(3년)</li>
                                                <li>1년 이상 서비스 이용 기록이 없는 사용자의 가입정보는 개인정보 보호법 제39조의6에 따라 일반 사용자의 가입정보와 분리하여 별도로 저장 및 관리됩니다(휴면계정으로 전환). 회사는 휴면계정으로 전환되는 시점 최소 30일 이전까지 해당 내용에 대해 이메일 등을 통해 사용자에게 사전 통지합니다. 분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용•제공하지 않습니다.</li>
                                            </ul>
                                            <li>제5조. 개인정보의 제3자 제공</li>
                                            <li>회사가 제공하는 서비스를 사용자가 이용하는 동안 회사는 사용자의 개인정보를 계속 보유하고, 서비스 제공 등을 위해 이용하며, 이용목적이 달성된 때에는 당해 개인정보를 즉시 파기합니다. 다만, 아래 제6조 사용자의 요구에 의한 (열람, 정정, 삭제 등) 사항에서 설명한 절차와 방법에 따라 사용자 본인이 직접 삭제하거나 수정한 정보, 가입해지를 요청한 경우에는 재생할 수 없는 방법에 따라 디스크나 개인정보가 보관된 데이터상에서 완전히 삭제하며 추후 열람이나 이용할 수 없는 상태로 처리됩니다. 단, 관련 법령의 규정에 따라 보존할 필요가 있는 경우 관계 법령에서 정한 일정 기간 동안 사용자 정보를 보관합니다. 당해 정보는 그 보관의 목적으로만 이용하며 보존 기간은 아래와 같습니다.</li>
                                            <ul>
                                                <li>1.    서비스 사용자 가입정보</li>
                                                <li>회사는 사용자의 동의 없이 개인정보(법인을 통한 개인 가입 정보 포함)를 제3자에 제공하고 있지 않으며 현재 사용자의 동의 하에 제3자 제공되고 있는 개인정보는 다음과 같습니다.
                                                    제공받는 자 제공 목적 제공항목 보유 및 이용기간 소속회사 서비스 회원 정보 및 운영 정보 활용 이름, 휴대전화번호, 주소, 이메일, 소속회사, 허가증정보 회원 탈퇴 시까지 보유 및 이용 배출업체, 운반업체, 처리업체 회사명, 대표자 이름, 회사 주소·이메일·전화번호, 사업자번호, 한국건설자원공제조합 정보 , 허가증 정보 회원 탈퇴 시까지 보유 및 이용 소속회사, 운반업체, 처리업체 서비스 운영 시 계약 및 신고서 체결 목적 활용 사명, 대표자 이름, 회사 주소·이메일·전화번호, 사업자번호, 한국건설자원공제조합 정보 , 허가증 정보 회원 탈퇴 시까지 보유 및 이용 한편, 관련 법령에 따라 개인정보를 제3자에게 제공하는 경우는 사용자의 별도 동의 없이 개인정보를 제공할 수 있습니다.
                                                </li>
                                                <li>1.    법령의 규정, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                                                <li>2.    통계작성, 학술연구, 시장조사 정보제공의 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우</li>
                                                <li>3.    회사가 제공하는 서비스의 사용자와 타인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</li>
                                            </ul>
                                            <li>제6조. 개인정보의 이용 또는 제공</li>
                                            <ul>
                                                <li>1.    회사는 개인정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리
                                                    약관에 명시한 후 개인정보주체의 동의를 얻어야 합니다.</li>
                                                <li>2.    회사는 전자계약 및 신고서 작성 목적으로 개인정보를 보유하며,
                                                    이를 위해서 6개월간 보관합니다.</li>
                                                <li>3.    회사는  타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 정보 이용·제공사실 확인자료를 보존하며, 해당 자료는 12개월간 보관합니다</li>
                                            </ul>
                                            <li>제7조. 개인정보의 위탁처리</li>
                                            <li>회사는 사용자에게 양질의 서비스를 제공하고 원활한 업무처리를 위해서 사용자의 개인정보를 아래와 같이 취급을 위탁하고 있으며, 관계 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 규정하고 있습니다.</li>
                                            <ul>
                                                <li>1.    위탁 업무 : 전자 결제 서비스
                                                    수탁업체 : KG 이니시스
                                                    위탁 기간 : 위탁계약 종료 시까지
                                                </li>
                                            </ul>
                                            <li>제8조. 개인정보의 열람, 정정, 동의 철회방법</li>
                                            <li>사용자는 언제든지 등록된 자신의 개인정보에 대한 열람, 정정, 삭제 또는 이용정지를 특정 화면에서 설정 변경하실 수 있습니다. 삭제 또는 처리정지를 요구하고자 하면 사용자 본인 또는 정당한 대리인임을 확인한 후 이용목적의 달성에 필요한 범위 내에서 바로 필요한 조사 후, 그 결과에 따라 개인정보의 열람, 정정, 동의 철회 등을 실시합니다. 다만, 개인정보의 열람 및 정정을 제한할 수 있는 경우는 아래와 같습니다.</li>
                                            <ul>
                                                <li>1.    다른 사람의 생명, 신체, 재산 또는 권익을 현저하게 해할 우려가 있는 경우</li>
                                                <li>2.    법률에 따라 금지되거나 제한된 경우 등</li>
                                            </ul>
                                            <li>사용자는 본 조에 따른 권리행사를 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수 있습니다. 이 경우 “개인정보 처리방법에 관한 고시” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. </li>
                                            <li> 제9조. 개인정보주체의 권리 및 행사방법</li>
                                            <ul>
                                                <li>1.    회원은 회사에 대하여 언제든지 개인정보를 제공 및 개인정보의 제3자 제공에 대한 동의의 전부 또는 일부를 회사와 동의 하에 철회할 수 있습니다. 이 경우 회사는 수집한 개인정보 이용, 제공사실 확인자료를 파기합니다.</li>
                                                <li>2.    회원은 회사에 대하여 언제든지 개인정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.</li>
                                                <li>3.    회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다</li>
                                                <li>4.   회원은 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.</li>
                                            </ul>
                                            <li>제10조. 쿠키(Cookie)에 의한 개인정보 수집</li>
                                            <li>회사는 홈페이지 접속자의 편의를 위하여 '쿠키(Cookie)'를 사용합니다. 쿠키란, 웹사이트가 사용자의 웹브라우저에 보내는 작은 텍스트 파일로서 사용자의 PC에 저장됩니다.</li>
                                            <ul>
                                                <li>1.    사용자가 웹사이트 또는 부가정보를 이용할 때 세션을 유지하기 위해 쿠키를 사용합니다.</li>
                                                <li>2.    서비스의 보안 강화를 위하여 쿠키를 사용합니다.</li>
                                                <li>3.    사용자의 접속빈도나 방문시간 등을 분석하여 서비스 개편, 차별화된 정보를 제공하기 위한 분석자료로 이용됩니다.</li>
                                                <li>4.    팝업 정보제공에 대하여 사용자가 '보지 않기'를 요청하였는지를 파악하기 위하여 쿠키를 사용합니다.</li>
                                                <li>사용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저 옵션 설정을 통해 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 또는 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 사용자가 쿠키설치를 거부하셨을 경우 서비스 제공에 제한이 있을 수 있습니다.</li>
                                            </ul>
                                            <li>제11조. 개인정보의 안전성 확보 조치</li>
                                            <li>회사는 사용자의 개인정보를 취급하면서 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있으며, 안전성 확보를 위하여 개인정보 보호법 등 관련 법령에 따라 다음과 같은 기술적 대책을 마련하고 있습니다. 회사는 해킹이나 컴퓨터 바이러스 등에 의해 사용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여 사용자의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</li>
                                            <li>제12조. 면책</li>
                                            <li>만일 회사가 개인정보 보호법 등 관련 법령에 따른 보호 대책을 갖추었음에도 불구하고 해커 등에 의한 부당한 행위로 인하여 사용자와 제3자에게 손해가 발생한 경우 회사는 책임지지 않으므로 양해 바랍니다. 또한, 사용자 본인확인을 위한 정보가 분실 또는 제3자에게 알려지지 않도록 엄중한 관리 부탁드립니다. 사용자 자신의 책임으로 인하여 발생한 손해의 경우, 회사는 책임지지 않습니다.</li>
                                            <li>제13조. 개인정보 보호책임자</li>
                                            <li>회사는 개인정보에 대한 민원처리를 담당하는 개인정보 보호책임자를 다음과 같이 지정하고 있습니다.
                                                개인정보 보호책임자
                                                이 름 : 심형근
                                                직책 : 담당
                                                전 화 : 010-7736-5539
                                                E-Mail : oneclick40@naver.com
                                                기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                                                개인정보침해신고센터 (한국인터넷진흥원 운영) (http://privacy.kisa.or.kr / 국번없이 118)
                                                대검찰청 사이버수사과 (http://www.spo.go.kr / 국번없이 1301)
                                                경찰청 사이버안전지킴이 (http://www.police.go.kr/www/security/cyber.jsp / 국번없이 182)</li>
                                            <li>제14조. 개인정보처리방침 변경에 따른 공지</li>
                                            <li>본 개인정보처리방침은 2024년 10월 18일 최초 제정되었으며 정부의 법령ㆍ정책 또는 보안기술의 변경, 기타 사유에 따라 내용의 추가ㆍ삭제ㆍ변경이 발생할 수 있습니다. 이 경우 변경되는 개인정보처리방침 개정 최소 7일 이전에 홈페이지를 통해 변경 이유와 내용을 공지하도록 하겠습니다.</li>
                                            <li>본 약관은 2024년 11월 01일부터 적용됩니다. (가입일자에 따라 적용시점 상이)</li>
                                        </ul>
                                </ul>
                            </div>
                            <div className="terms_agree_btm">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="1" id="chk_agree3"
                                           checked={isAgreed3}
                                           onChange={handleCheckboxChange3}
                                           ref = {isAgreed3Ref}
                                           />
                                    <label className="form-check-label" for="chk_agree3">
                                        해당 약관에 동의 합니다.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="terms_agree_btm mt-10px">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="1" id="chk_agreeAll"
                                       checked={AllAgreed}
                                       onChange={handleCheckboxAllChange}
                                       ref = {AllAgreedRef}
                                />
                                <label className="form-check-label lbl_agreeAll" for="chk_agreeAll">
                                    전체약관동의
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-secondary"
                        onClick={handleGoBack}
                        >돌아가기</button>
                        <button className="btn btn-primary ms-10px"
                        onClick={handleGoNext}
                        >동의하기</button>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
    );
}