const baseUrl = "/api/delivery"
export const POST_DELIVERY_REGISTRATION_SEARCH = baseUrl+"/registrationSearch"  //납품 등록시 사업자등록번호 검색
export const POST_DELIVERY_REGISTRATION_SAVE = baseUrl+"/registrationSave"  //납품 등록시 사업자등록번호 검색
export const POST_DELIVERY_REGISTRATION_BASE = baseUrl+"/registrationBase"  //납품 등록시 자신의 정보
export const POST_DELIVERY_NO_MEMBER_EMAIL_SEARCH = baseUrl+"/noMemberEmailSearch"  //비회원 이메일 인증
export const POST_DELIVERY_COMPLETE_SEARCH = baseUrl+"/completeSearch"  //납품 현황
export const POST_DELIVERY_DOCUMENT_UPDATE = baseUrl+"/documentUpdate"  //납품 현황 수정
export const POST_DELIVERY_DELIVERY_DELETE = baseUrl+"/deliveryDelete"  //납품 현황 삭제
export const POST_DELIVERY_NO_MEMBER_PRE_VIEW = baseUrl+"/deliveryNoMemberPreView"  //납품 비회원 프리뷰
export const POST_DELIVERY_NO_MEMBER_COATING_CREATE = baseUrl+"/deliveryNoMemberCoatingCreate"  //납품 비회원 도장 생성
export const POST_DELIVERY_NO_MEMBER_COATING_UPLOAD = baseUrl+"/deliveryNoMemberCoatingUpload"  //납품 비회원 도장 업로드
export const POST_DELIVERY_NO_MEMBER_SAVE = baseUrl+"/deliveryNoMemberSave"  //납품 비회원 전자 승인여부
export const POST_DELIVERY_DOCUMENT_MEMBER_PRE_VIEW = baseUrl+"/deliveryDocumentMemberPreView"  //납품 비회원 전자 승인여부


