import React, { useEffect, useState } from "react";
import {
    postCoatingCreate,
    postCoatingPreView,
    postCoatingSearch,
    postCoatingUpload,
    postContractEmailSend,
    postContractInfo
} from "../../../api/information";
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf';
import { PDFDocument } from 'pdf-lib';
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { renderToString } from "react-dom/server";
import { DashBoardEmailTemplate } from "../../dashboard/DashBoardEmailTemplate";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import { ContractEmailTemplate } from "./ContractEmailTemplate";
import {postPdfCoatingMemberPreView} from "../../../api/pdf";
import { MobileHeader } from "../../common/MobileHeader";
import { Sidebar } from "../../common/Sidebar";
import { PcHeader } from "../../common/PcHeader";

GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';

export const RequestContractDetail = () => {

    const navigate = useNavigate();
    const [combinedPdfUrl, setCombinedPdfUrl] = useState('');
    const [combinedPdfUrl2, setCombinedPdfUrl2] = useState('');
    const [selectedOption, setSelectedOption] = useState('email');
    const [selectedCoatingOption, setSelectedCoatingOption] = useState('electionCoating');

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showCoating, setShowCoating] = useState("")
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [electionCoating, setElectionCoatingReq] = useState("")
    const [uploadCoating, setUploadCoating] = useState("")
    const [urlArr, setUrlArr] = useState([]);
    const [contractInfo, setContractInfo] = useState({});

    const [companyName, setCompanyName] = useState("")
    const [complete, setComplete] = useState(false)


    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [createCoatingCheck, setCreateCoatingCheck] = useState(false)
    const [uploadCoatingCheck, setUploadCoatingCheck] = useState(false)
    const handleErrorClose = () => {
        setShowError(false)
    }

    const { idx, constructionText } = useParams();


    const [loadUserInfo, setLoadUserInfo] = useState({
        userCompanyName: "",
        userEmail: "",
        idx : idx,
    })


    const handleSuccessClose = () => {
        setShowSuccess(false);
        setShowCoating(false)
        setComplete(true)
        scrollToTop()
        navigate('/information/request')
    }

    const handleCoatingClose = () => setShowCoating(false);

    const [req, setReq] = useState({
        type: "email",
        email: "",
        phone: "",
        html: "",
        wasteRegistrationIdx: idx,
        requestEndTime: "",
    })

    const [coatingReq, setCoatingReq] = useState({
        type: "coating",
    })

    const pdfSearch = async () => {
        try {
            const response = await postPdfCoatingMemberPreView(loadUserInfo);
            const contractPdfData = response.result.contractPdf; // 왼쪽 계약 PDF
            const contractPdfData2 = response.result.contractPdf2; // 왼쪽 계약 PDF2
            
            const base64Right1 = response.result.base64Right1; // 오른쪽 첫번째
            const base64Right2 = response.result.base64Right2; // 오른쪽 세번째
            const base64Right3 = response.result.base64Right3; // 오른쪽 네번째
            const base64PdfFile2 = response.result.base64PdfFile2; // 오른쪽 두번째
            const base64PdfFile3 = response.result.base64PdfFile3; // 오른쪽 다섯번째
            const arr = [];

            setLoadUserInfo((prevState) => ({
                ...prevState,               // 기존 상태 유지
                userCompanyName: response.result.userCompanyName,  // 새로운 회사 이름으로 업데이트
                userEmail: response.result.userEmail,  // 새로운 회사 이름으로 업데이트
            }));


            if (contractPdfData && contractPdfData2) {
                const combinedPdf = await combinePdfs(contractPdfData, contractPdfData2);
                setCombinedPdfUrl(URL.createObjectURL(combinedPdf)); // Blob URL 생성
                arr.push(URL.createObjectURL(combinedPdf))
            }

            // 오른쪽 PDF 결합 (주석 순서대로)
            const combinedRightPdf = await combinePdfsSequential([
                base64Right1,
                base64PdfFile2,
                base64Right2,
                base64Right3,
                base64PdfFile3,
            ]);
            setCombinedPdfUrl2(URL.createObjectURL(combinedRightPdf));

            arr.push(URL.createObjectURL(combinedRightPdf))
            setUrlArr(arr);

        } catch (e) {
            setShowErrorMessage("마이페이지에 정보를 등록해주세요.")
            setShowError(true)
        }
    };

    const combinePdfs = async (base64Pdf1, base64Pdf2) => {
        const pdf1Blob = await createPdfBlob(base64Pdf1);
        const pdf2Blob = await createPdfBlob(base64Pdf2);

        // pdf-lib를 사용하여 PDF 문서 합치기
        const pdf1Doc = await PDFDocument.load(await pdf1Blob.arrayBuffer());
        const pdf2Doc = await PDFDocument.load(await pdf2Blob.arrayBuffer());

        const combinedPdf = await PDFDocument.create();
        const [pdf1Pages, pdf2Pages] = await Promise.all([
            combinedPdf.copyPages(pdf1Doc, pdf1Doc.getPageIndices()),
            combinedPdf.copyPages(pdf2Doc, pdf2Doc.getPageIndices()),
        ]);

        pdf1Pages.forEach((page) => combinedPdf.addPage(page));
        pdf2Pages.forEach((page) => combinedPdf.addPage(page));

        const pdfBytes = await combinedPdf.save();
        return new Blob([pdfBytes], { type: 'application/pdf' });
    };

    const createPdfBlob = (base64Data) => {
        return new Promise((resolve) => {
            const pdfData = atob(base64Data); // Base64 문자열 디코딩
            const byteNumbers = new Uint8Array(pdfData.length);
            for (let i = 0; i < pdfData.length; i++) {
                byteNumbers[i] = pdfData.charCodeAt(i);
            }
            const blob = new Blob([byteNumbers], { type: 'application/pdf' });
            resolve(blob);
        });
    };


    // 여러 Base64 PDF 데이터를 순서대로 결합하는 함수
    const combinePdfsSequential = async (base64Pdfs) => {
        const combinedPdf = await PDFDocument.create();

        for (const base64Pdf of base64Pdfs) {
            const pdfBlob = await createPdfBlob(base64Pdf);
            const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
            const pages = await combinedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            pages.forEach((page) => combinedPdf.addPage(page));
        }

        const pdfBytes = await combinedPdf.save();
        return new Blob([pdfBytes], { type: 'application/pdf' });
    };

    useEffect(() => {
        scrollToTop()
        pdfSearch(); // 컴포넌트 마운트 시 PDF 요청
    }, []);

    const contractHandler = (e, part) => {
        let value = e.target.value;

        if (part === 'email') {
            // 이메일 정규식 검사
            setReq(() => ({
                ...req, // 기존 req 상태 복사
                email: value // email 필드 업데이트
            }));

        } else if (part === 'cellphone') {
            const newValue = value.replace(/\D/g, ''); // 숫자만 남기기
            setReq({
                ...req,
                phone: newValue
            });

        }
    };

    const handleCoatingOptionChange = (e) => {
        console.log(e.target.value)
        setSelectedCoatingOption(e.target.value);
        setCoatingReq({
            ...coatingReq,
            type: e.target.value
        });
    };

    const handleSubmit = async () => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //
        if (complete) {
            setShowErrorMessage("이미 전송된 사항입니다.")
            setShowError(true)
            return
        }
        if (req.type === "email") {
            if (!emailRegex.test(req.email)) {
                setShowErrorMessage("이메일 형식이 올바르지 않습니다.")
                setShowError(true)
                return
            }
        }
        else {
            if (req.phone.length !== 10 && req.phone.length !== 11) {
                setShowErrorMessage("연락처 형식이 올바르지 않습니다.")
                // setShowErrorMessage("연락처 전송은 서비스 준비 중입니다.")
                setShowError(true)
                return
            }
        }
        try {
            const response = await postCoatingSearch()

            if (response.result.image) {
                setElectionCoatingReq(response.result.image)
            }
            if (response.result.uploadImage) {
                // uploadImage를 Base64로 변환 후 setSelectedFile에 저장

                setUploadCoating(response.result.uploadImage); // 여기서는 byte 그대로 저장할 수도 있음

                const base64Image = response.result.uploadImage;
                const fileName = "uploadImage.jpg"; // 파일 이름
                const mimeType = "image/jpeg"; // MIME 타입

                const file = base64ToFile(base64Image, fileName, mimeType);
                console.log("생성된 File 객체:", file);

                setSelectedFile([file]); // 배열로 전달
                console.log("생성된 파일:", file);
                // File 객체를 배열에 넣어 setSelectedFile 호출
                setSelectedFile([file]); // 기존 handleFileChange처럼 처리

                setUploadCoatingCheck(true);
            }
        }

        catch (e) {
            setShowErrorMessage("도장 불러오기 오류가 발생했습니다.")
            setShowError(true)
        }
        setShowCoating(true)
    };
    // Base64 문자열을 Blob 객체로 변환하여 File 객체를 생성하는 함수
    const base64ToFile = (base64, fileName, mimeType) => {
        const byteCharacters = atob(base64); // Base64 문자열을 디코딩
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: mimeType }); // MIME 타입을 실제 형식에 맞게 설정
        return new File([blob], fileName, { type: mimeType });
    };
    const handleFileChange = (event) => {
        const files = event?.target?.files; // 선택된 파일들
        // 파일이 선택되지 않은 경우
        if (!files || files.length === 0) {
            return;
        }
        const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링

        if (validFiles.length === files.length) {

            console.log("업로드시 파일"+Array.from(files))

            setSelectedFile(Array.from(files));
            const file = validFiles[0]; // 첫 번째 파일로 미리보기 생성
            const reader = new FileReader();

            reader.onloadend = () => {
                setFileUrl(reader.result); // Base64 문자열로 설정
            };
            reader.readAsDataURL(file); // 파일을 Data URL로 읽음
            setUploadCoatingCheck(true)
            setSelectedCoatingOption("upload")
            setUploadCoating("")
        } else {
            setShowErrorMessage("이미지 파일 및 PDF만 가능합니다.")
            setShowError(true)
            setUploadCoatingCheck(false)
        }
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        setReq({
            ...req, // 기존 req 상태 복사
            type: e.target.value // email 필드 업데이트
        });


    };


    const coatingCreateClick = async () => {
        try {

            const response = await postCoatingCreate({
                companyName: companyName
            })


            if (response.result.image) {
                setElectionCoatingReq(response.result.image)
                setCreateCoatingCheck(true)
                setSelectedCoatingOption("coating")
            }
        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
            setCreateCoatingCheck(false)
        }
    }

    const companyNameHandler = (e) => {
        setCompanyName(e)
    }



    const isValidFileType = (file) => {
        const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };

    const handleUploadClick = () => {
        document.getElementById('fileInput').click();
    };

    const coatingHandlerSubmit = async () => {

        if (coatingReq.type === "coating") {
            if (electionCoating === "") {
                if (!createCoatingCheck) {
                    setShowErrorMessage("생성된 도장이 없습니다.")
                    setShowError(true)
                    return
                }
            }
        }
        else {
            if (!uploadCoatingCheck) {
                setShowErrorMessage("업로드된 도장이 없습니다.")
                setShowError(true)
                return
            }

            try {
                const response = await postCoatingUpload({
                    file: selectedFile
                })
            }
            catch (e) {
                setShowErrorMessage("업로드 도장 저장 오류입니다.")
                setShowError(true)
                setFileUrl("")
            }
        }

        // 현재 시점으로부터 30분 뒤의 시간 계산
        const currentDate = new Date();
        const dateTimeAfter30Minutes = new Date(currentDate.getTime() + 30 * 60000); // 30분을 밀리초로 계산


        // 년, 월, 일만 추출하고 2자리 숫자로 맞추기
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줌
        const day = String(currentDate.getDate()).padStart(2, '0');

        // "년 월 일" 형식으로 문자열 생성
        const formattedDate = `${year}년 ${month}월 ${day}일`;

        const htmlString = renderToString(<ContractEmailTemplate
            registrationIdx={idx} constructionText={constructionText}
            requestEndTime={formattedDate}
            userCompanyName={loadUserInfo}
            resEmail={req.email}
        />);
        const updateEmailReq
            = ({ ...req, selectCoatingOption: selectedCoatingOption, html: htmlString, requestEndTime: formattedDate,
            type : selectedOption,
            approveEmail : req.email ? req.email : "",
            approveCellphone : req.phone ? req.phone : "",
            phoneNumber : selectedOption==="cellphone" ? req.phone : req.email,
            constructionText : constructionText,
            registrationIdx : idx,
        })
        //도장 등록 정보 있는지 체크

        try {
            const response = await postContractEmailSend(updateEmailReq)
            setShowCoating(false)
            setShowSuccess(true)
        }
        catch (e) {
            setShowError(true)
            setShowErrorMessage(e.message)
            setShowCoating(false)
            scrollToTop()
        }

    }

    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 1,
    })

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };

    useEffect(() => {
        if (showCoating) {
            setSelectedCoatingOption('coating');
        }
    }, [showCoating]);

    const getInfo = async (data) => { 
        let contractInfoApi = await postContractInfo({idx : data});
        setContractInfo(contractInfoApi.result?.result)
    }

    useEffect(()=>{
        console.log(contractInfo)
    },[contractInfo])

    useEffect(()=>{
        getInfo(idx)
    },[idx])


    return (
        <React.Fragment>

            <Modal className="modal_alert2" show={showCoating} onHide={handleCoatingClose} centered size={"lg"}>
                <Modal.Header>도장 선택</Modal.Header>
                <Modal.Body>
                    <div className="input_row">
                        <input type="text" placeholder="업체명을 입력해주세요." className="form-control"
                            onChange={(e) => companyNameHandler(e.target.value)}
                        />
                    </div>
                    <div className="stamp_container">
                        <div className="stamp_box">
                            <div className="stamp_top">
                                <h5>선택한 도장</h5>
                                <button className="btn btn-outline-secondary w-90px"
                                    onClick={coatingCreateClick}
                                >도장 생성</button>
                            </div>
                            <div className="stamp_option">
                                <label>
                                    {electionCoating ? (
                                        <img src={`data:image/png;base64,${electionCoating}`} alt="선택한 도장" style={{ width: '95px', height: '92px' }} />
                                    ) : (
                                        <img src={process.env.PUBLIC_URL + "/images/img_stamp.png"} alt="" style={{ width: '95px', height: '92px' }} />
                                    )}
                                    <input name="stamp" type="radio" className="form-input-radio"
                                        value="coating"
                                        checked={selectedCoatingOption === 'coating'}
                                        onChange={(e) => handleCoatingOptionChange(e)} />
                                </label>
                            </div>
                        </div>
                        <div className="stamp_box">
                            <div className="stamp_top">
                                <h5>전자도장</h5>
                                <button className="btn btn-outline-secondary w-100px"
                                    onClick={handleUploadClick}
                                >도장 업로드</button>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e)}
                                    style={{ display: 'none' }} // 숨김 처리
                                />
                            </div>
                            <div className="stamp_option">
                                <label>
                                    {uploadCoating ? ( // uploadImage가 있으면 uploadImage를 표시
                                        <img src={`data:image/png;base64,${uploadCoating}`} alt="업로드된 이미지" style={{ width: '95px', height: '92px' }} />
                                    ) : fileUrl ? ( // fileUrl이 있으면 fileUrl을 표시
                                        <img src={fileUrl} alt="미리보기" style={{ width: '95px', height: '92px' }} />
                                    ) : ( // 둘 다 없으면 기본 이미지를 표시
                                        <img src={process.env.PUBLIC_URL + "/images/img_stamp.png"} alt="" style={{ width: '95px', height: '92px' }} />
                                    )}
                                    <input name="stamp" type="radio" className="form-input-radio"
                                        value="upload"
                                        checked={selectedCoatingOption === 'upload'}
                                        onChange={(e) => handleCoatingOptionChange(e)} />
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary btn_modal_footer"
                        onClick={coatingHandlerSubmit}
                    >선택</button>
                </Modal.Footer>
            </Modal>

            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_data">
                                <div className="card-header">
                                    계약서 미리보기
                                </div>
                                <div className="card-body">
                                    <div>
                                        <ul className="dash_ul_text mb-20px">
                                            <li>PDF가 여러 종류일경우, 아래의 페이지네이션을 통해 제어할 수 있습니다!</li>
                                        </ul>
                                    </div>
                                        <div className="grey_box text-center">
                                            <iframe src={urlArr[pageNation.pageNum - 1]} width="90%" height="1000px" title="Combined PDF" />
                                        </div>
                                    <div className="pagination_container mb-55px">
                                        <ul>
                                            {urlArr.length > 0 &&
                                                // 페이지가 존재하는 경우 페이지네이션 렌더링
                                                <>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                            alt="First Page"
                                                            onClick={() => handlePageChange(1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                            alt="Previous Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    {console.log(urlArr.length)}
                                                    {/* 페이지 번호 배열 생성 */}
                                                    {[...Array(urlArr.length)].map((_, index) => (
                                                        <li key={index}
                                                            onClick={() => handlePageChange(index + 1)}
                                                            className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                        >
                                                            {index + 1} {/* 페이지 번호 표시 */}
                                                        </li>
                                                    ))}

                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                            alt="Next Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum < urlArr.length ? pageNation.pageNum + 1 : urlArr.length)}
                                                            className={pageNation.pageNum === urlArr.length ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                            alt="Last Page"
                                                            onClick={() => handlePageChange(urlArr.length)}
                                                            className={pageNation.pageNum === urlArr.length ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>
                                    { contractInfo && contractInfo?.transporterCheck === "N" ?
                                    <>
                                            <table className="table table-bordered card_table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "12.82%" }}>전자계약진행</th>
                                                        <td>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input mt-10px"
                                                                    type="radio"
                                                                    name="inlineRadioOptions"
                                                                    id="inlineRadio1"
                                                                    value="email"
                                                                    checked={selectedOption === 'email'}
                                                                    onChange={handleOptionChange}
                                                                />
                                                                <div className="d-flex align-items-center">
                                                                    <label className="form-check-label me-10px" htmlFor="inlineRadio1">이메일</label>
                                                                    <input placeholder="" type="text" className="form-control"
                                                                        value={req.email}
                                                                        onChange={(e) => contractHandler(e, 'email')}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input mt-10px"
                                                                    type="radio"
                                                                    name="inlineRadioOptions"
                                                                    id="inlineRadio2"
                                                                    value="cellphone"
                                                                    checked={selectedOption === 'cellphone'}
                                                                    onChange={handleOptionChange}
                                                                />
                                                                <div className="d-flex align-items-center">
                                                                    <label className="form-check-label me-10px" htmlFor="inlineRadio1">연락처</label>
                                                                    <input placeholder="" type="text" className="form-control"
                                                                        value={req.phone}
                                                                        onChange={(e) => contractHandler(e, 'cellphone')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </> : ""
                                        }
                                        <div className="card_footer_btngrp_end">
                                            <button className="btn btn-outline-secondary btn_footer"
                                                onClick={() => navigate('/information/request')}
                                            >뒤로가기</button>
                                            {contractInfo?.transporterCheck === "N" ?
                                            <button className="btn btn-primary btn_footer"
                                                onClick={handleSubmit}
                                            >전송하기</button> : ""
                                            }
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <h5>오류</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                        </button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>{req.type === 'email' ? req.email : req.cellphone}</h5>
                        <p> 전송 되었습니다.</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}