import React, { useEffect, useState } from "react";
import {
    postCompleteDocumentContributionDownload,
    postCompleteDocumentMemberPreView,
    postCompleteDocumentPermitDownload,
    postCompleteDocumentSearch, postCompleteDocumentUpdate,
    postCompleteWasteDelete,
} from "../../../api/information";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import Modal from "react-bootstrap/Modal";
import { MobileHeader } from "../../common/MobileHeader";
import { Sidebar } from "../../common/Sidebar";
import { PcHeader } from "../../common/PcHeader";
import {postPdfCoatingMemberPreView} from "../../../api/pdf";
import {PDFDocument} from "pdf-lib";
import DeliveryCheckPreviewModal from "../../delivery/modal/DeliveryCheckPreviewModal";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

export const ContractCompletionCheck = () => {


    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [responseData, setResponseData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPermitModalOpen, setIsPermitModalOpen] = useState(false);
    const [isContributionModalOpen,setContributionModalOpen] = useState(false);
    const [total, setTotal] = useState(0)

    const [pageNation, setPageNation] = useState({
        pageNum: 1,
        rowCnt: 15,
    })

    const [pdfPagination, setPdfPagination] = useState({
        pageNum : 1,
        rowCnt : 1,
    })

    const [adminCheck, setAdminCheck] = useState(false)

    // 총 페이지 수 상태
    const [totalPages, setTotalPages] = useState(0);
    // 모든 체크박스의 상태를 관리하는 state
    const [checkedItems, setCheckedItems] = useState([]); // 선택된 항목들의 idx를 저장하는 상태
    const [isAllChecked, setIsAllChecked] = useState(false); // 전체 선택 여부를 저장하는 상태

    const [combinedPdfUrl, setCombinedPdfUrl] = useState('');
    const [combinedPdfUrl2, setCombinedPdfUrl2] = useState('');
    const [permitUrl, setPermitUrl] = useState('');
    const [contributionUrl, setContributionUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const completeSearch = async () => {


        try {
            const response = await postCompleteDocumentSearch(pageNation);

            if (response.code === "0000") {
                // 응답 데이터가 배열 형태로 오는지 확인하고, 없으면 빈 배열을 할당
                const resultData = response.result.result || [];
                setResponseData(resultData);
                // resultData가 비어있다면 totalCount는 0으로 설정
                const totalCount = resultData.length > 0 ? resultData[0].totalCount : 0;
                setTotal(totalCount);

                // 페이지 수 계산 (totalCount가 0일 경우 최소 페이지 1로 설정)
                setTotalPages(Math.max(1, Math.ceil(totalCount / pageNation.rowCnt)));

                // adminCheck 값이 true인지 확인하고 설정
                if (response.result.adminCheck) {
                    setAdminCheck(true);
                    console.log("체크");
                }
            }
        } catch (e) {
            setShowErrorMessage(e.message);
            setShowError(true);
        }

    }


    const handleErrorClose = () => {
        setShowError(false)

    }


    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        completeSearch()

    }, []);

    // 페이지 번호가 변경될 때마다 데이터 검색
    useEffect(() => {
        completeSearch();
    }, [pageNation]); // pageNation이 변경될 때마다 호출

    const handlePageChange = (newPageNum) => {
        setPageNation((prev) => ({ ...prev, pageNum: newPageNum }));
    };

    const handlePdfPageChange = (newPageNum) => {
        setPdfPagination((prev) => ({ ...prev, pageNum: newPageNum }));
    };

    useEffect(()=>{
        console.log(" > ", pdfPagination)
        console.log(">" , urlArr)
    },[pdfPagination])

    const handleSuccessClose = () => {
        setShowSuccess(false);
        scrollToTop()
        completeSearch()
        setCheckedItems([]);
    }

    const getMimeType = (fileName) => {
        const ext = fileName.split('.').pop().toLowerCase();  // 확장자 추출 (소문자로 처리)

        switch (ext) {
            case 'pdf':
                return 'application/pdf';
            case 'png':
                return 'image/png';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'gif':
                return 'image/gif';
            case 'txt':
                return 'text/plain';
            case 'csv':
                return 'text/csv';
            case 'doc':
            case 'docx':
                return 'application/msword';
            default:
                return 'application/octet-stream';  // 알 수 없는 파일 형식은 기본 이진 파일로 처리
        }
    };


    const handleContributionDocument = async (item) => {
        setIsLoading(true)
        try {
            const response = await postCompleteDocumentContributionDownload(item);

            const base64Data = response.result.fileContent;
            const fileName = response.result.fileName;
            if (base64Data && fileName) {
                let mimeType = 'application/octet-stream'; // 기본 MIME 타입 설정
                if (fileName.endsWith('.pdf')) {
                    mimeType = 'application/pdf';
                } else if (fileName.endsWith('.png')) {
                    mimeType = 'image/png';
                } else if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
                    mimeType = 'image/jpeg';
                }
                // Base64 문자열을 Blob으로 변환
                const binaryString = atob(base64Data);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], { type: mimeType }); // Blob 생성
                setIsLoading(false)
                setPermitUrl(URL.createObjectURL(blob));
                setIsPermitModalOpen(true);

            }
        } catch (e) {
            setIsLoading(false)
            setShowErrorMessage(e.message || "분담금 납부 확인서 불러오기에 실패했습니다."); // 에러 메시지를 문자열로 변환
            setShowError(true); // 에러 표시
            setContributionModalOpen(false)
        }
    };

    const handlerSave = async () => {

        console.log("데이터체크" + responseData)
        try {

            if (checkedItems.length < 1) {
                setShowErrorMessage("저장할 행을 선택해주세요.")
                setShowError(true)
                return
            }
            const selectedData = responseData.filter(item => checkedItems.includes(item.idx));

            const response = await postCompleteDocumentUpdate(selectedData)

            if (response.code === "0000") {
                setShowSuccess(true)
                setShowSuccessMessage("저장 되었습니다.")
            }
        }
        catch (e) {
            setShowErrorMessage("관리자에게 문의바랍니다.");
            setShowError(true)
        }
    };

    // 삭제 버튼 클릭 시 체크된 항목 서버로 전송
    const handleDelete = async () => {
        try {

            console.log(checkedItems.length)

            if (checkedItems.length < 1) {
                setShowErrorMessage("삭제할 행을 선택해주세요.")
                setShowError(true)
                return
            }

            console.log({ idx: checkedItems })

            const response = await postCompleteWasteDelete({
                idx: checkedItems
            })

            if (response.code === "0000") {
                setShowSuccess("삭제 되었습니다.")
                setShowSuccess(true)
            }
        } catch (e) {
            setShowErrorMessage("삭제 실패 관리자에게 문의바랍니다.")
            setShowError(true)
        }
    };

    const handleSelectAll = () => {
        if (isAllChecked) {
            // 전체 해제 시 상태 초기화
            setCheckedItems([]);
        } else {
            // 전체 선택 시 모든 idx 저장
            const allIds = responseData.map((item) => item.idx);
            setCheckedItems(allIds);
        }
        setIsAllChecked(!isAllChecked); // 전체 선택 상태 업데이트
    };

    const handleItemCheck = (idx) => {

        if (checkedItems.includes(idx)) {
            // 이미 선택된 항목이면 해당 idx를 제거
            setCheckedItems(checkedItems.filter((item) => item !== idx));
        } else {
            // 선택되지 않은 항목이면 idx 추가
            setCheckedItems([...checkedItems, idx]);
        }
    };

    const handleLastCheckChange = (newValue, idx) => {
        const updatedData = responseData.map(item =>
            item.idx === idx ? { ...item, lastCheck: newValue } : item
        );

        console.log(updatedData)
        setResponseData(updatedData);

    };

    const handleIsModalClose = () => {
        setIsModalOpen(false)

    }
    const handleOpenModal = (item) => {

        pdfSearch(item)
    };

    // const handlePermitOpenModal = async (item,type) =>{
    //     try {
    //             const response = await postCompleteDocumentPermitDownload(item);
    //             console.log(response);
    //             const base64Data = response.result.fileContent;
    //             const fileName = response.result.fileName;
    //             if (base64Data && fileName) {
    //                 let mimeType = 'application/octet-stream'; // 기본 MIME 타입 설정
    //                 if (fileName.endsWith('.pdf')) {
    //                     mimeType = 'application/pdf';
    //                 } else if (fileName.endsWith('.png')) {
    //                     mimeType = 'image/png';
    //                 } else if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
    //                     mimeType = 'image/jpeg';
    //                 }
    //                 // Base64 문자열을 Blob으로 변환
    //                 const binaryString = atob(base64Data);
    //                 const len = binaryString.length;
    //                 const bytes = new Uint8Array(len);
    //                 for (let i = 0; i < len; i++) {
    //                     bytes[i] = binaryString.charCodeAt(i);
    //                 }
    //                 const blob = new Blob([bytes], { type: mimeType }); // Blob 생성
    //                 setPermitUrl(URL.createObjectURL(blob));
    //                 // const arr = [];
    //                 // arr.push(URL.createObjectURL(blob))
    //                 // setUrlArr(arr)
    //                 setIsPermitModalOpen(true)
    //             }
    //         } catch (e) {
    //             setShowErrorMessage(e.message ||  "허가증 불러오기에 실패했습니다.");
    //             setShowError(true);
    //
    //         }
    //
    // }

    const handleAllOpenModal = async (item, type) => {
        switch (type) {
            case "drive":
                handlePermitOpenModal(item, type);
                break;
            case "permit":
                handlePermitOpenModal(item, type);
                break;
            case "document":
                pdfSearch(item, type);
                break;
            case "contribution":
                handleContributionDocument(item, type);
                break;
            default :
                setShowError(true)
                setShowErrorMessage("관리자에게 문의바랍니다.")
        }
    };
    const handlePermitOpenModal = async (item,type) =>{

        try {
            console.log("아이템체크"+item)
            console.log("타입체크"+type)
            item.type = type;

            const response = await postCompleteDocumentPermitDownload(item);
            console.log(response);
            const base64Data = response.result.fileContent;
            const fileName = response.result.fileName;
            if (base64Data && fileName) {
                let mimeType = 'application/octet-stream'; // 기본 MIME 타입 설정
                if (fileName.endsWith('.pdf')) {
                    mimeType = 'application/pdf';
                } else if (fileName.endsWith('.png')) {
                    mimeType = 'image/png';
                } else if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
                    mimeType = 'image/jpeg';
                }
                // Base64 문자열을 Blob으로 변환
                const binaryString = atob(base64Data);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], { type: mimeType }); // Blob 생성
                setPermitUrl(URL.createObjectURL(blob));
                setIsPermitModalOpen(true)
            }
        } catch (e) {
            setShowErrorMessage(e.message ||  "허가증 불러오기에 실패했습니다.");
            setShowError(true);

        }

    }
    const handlePermitCloseModal = () => {
        setPermitUrl(null)
        setIsPermitModalOpen(false);

    };

    const handleContributionOpenModal = (item) =>{

        handleContributionDocument(item)
    }
    const handleContributionModalClose = () => {
        // setContributionModalOpen(false);
        setPermitUrl(null)
        setIsPermitModalOpen(false);
    };

    const [urlArr, setUrlArr] = useState([]);


    const pdfSearch = async (item) => {
        try {
            console.log(item)

            const response = await postCompleteDocumentMemberPreView(item);
            console.log(response); // 서버 응답 확인

            const contractPdfData = response.result.contractPdf; // 왼쪽 계약 PDF
            const contractPdfData2 = response.result.contractPdf2; // 왼쪽 계약 PDF2

            const base64Right1 = response.result.base64Right1; // 오른쪽 첫번째
            const base64Right2 = response.result.base64Right2; // 오른쪽 세번째
            const base64Right3 = response.result.base64Right3; // 오른쪽 네번째
            const base64PdfFile2 = response.result.base64PdfFile2; // 오른쪽 두번째
            const base64PdfFile3 = response.result.base64PdfFile3; // 오른쪽 다섯번째

            if (contractPdfData && contractPdfData2) {
                const combinedPdf = await combinePdfs(contractPdfData, contractPdfData2);
                setCombinedPdfUrl(URL.createObjectURL(combinedPdf)); // Blob URL 생성
            }

            // 오른쪽 PDF 결합 (주석 순서대로)
            const combinedRightPdf = await combinePdfsSequential([
                contractPdfData,
                contractPdfData2,
                base64Right1,
                base64PdfFile2,
                base64Right2,
                base64Right3,
                base64PdfFile3,
            ]);
            // setCombinedPdfUrl2(URL.createObjectURL(combinedRightPdf));
            // setIsModalOpen(true);  // 모달 열기
            setPermitUrl(URL.createObjectURL(combinedRightPdf));
            setIsPermitModalOpen(true);


        } catch (e) {
            setShowErrorMessage("관리자에게 문의바랍니다.")
            setShowError(true)
            setIsModalOpen(false)
        }
    };

    useEffect(()=>{
        console.log(urlArr)
    },[urlArr])


    const combinePdfs = async (base64Pdf1, base64Pdf2) => {
        const pdf1Blob = await createPdfBlob(base64Pdf1);
        const pdf2Blob = await createPdfBlob(base64Pdf2);

        // pdf-lib를 사용하여 PDF 문서 합치기
        const pdf1Doc = await PDFDocument.load(await pdf1Blob.arrayBuffer());
        const pdf2Doc = await PDFDocument.load(await pdf2Blob.arrayBuffer());

        const combinedPdf = await PDFDocument.create();
        const [pdf1Pages, pdf2Pages] = await Promise.all([
            combinedPdf.copyPages(pdf1Doc, pdf1Doc.getPageIndices()),
            combinedPdf.copyPages(pdf2Doc, pdf2Doc.getPageIndices()),
        ]);

        pdf1Pages.forEach((page) => combinedPdf.addPage(page));
        pdf2Pages.forEach((page) => combinedPdf.addPage(page));

        const pdfBytes = await combinedPdf.save();
        return new Blob([pdfBytes], { type: 'application/pdf' });
    };

    const createPdfBlob = (base64Data) => {
        return new Promise((resolve) => {
            const pdfData = atob(base64Data); // Base64 문자열 디코딩
            const byteNumbers = new Uint8Array(pdfData.length);
            for (let i = 0; i < pdfData.length; i++) {
                byteNumbers[i] = pdfData.charCodeAt(i);
            }
            const blob = new Blob([byteNumbers], { type: 'application/pdf' });
            resolve(blob);
        });
    };


    // 여러 Base64 PDF 데이터를 순서대로 결합하는 함수
    const combinePdfsSequential = async (base64Pdfs) => {
        const combinedPdf = await PDFDocument.create();

        for (const base64Pdf of base64Pdfs) {
            const pdfBlob = await createPdfBlob(base64Pdf);
            const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
            const pages = await combinedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            pages.forEach((page) => combinedPdf.addPage(page));
        }

        const pdfBytes = await combinedPdf.save();
        return new Blob([pdfBytes], { type: 'application/pdf' });
    };

    const getStartIndex = (pageNum) => {
        return (pageNum - 1) * pageNation.rowCnt;
    };

    const startIndex = getStartIndex(pageNation.pageNum);

    const handlePrint = () => {
        const iframe = document.querySelector("iframe");
        iframe.contentWindow.print();
    };


    return (
        
        <React.Fragment>
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_data">
                                <div className="card-header">
                                    완료 계약서
                                </div>
                                <div className="card-body">
                                    <div className="card_top_with_btns mb-13px">
                                        <h6 className="table_stats">신고서제출 목록 (<span>{total}</span> 건)</h6>
                                        {adminCheck && (
                                            <div className="card_table_btntop">
                                                <button className="btn btn-primary"
                                                    onClick={handlerSave}
                                                >저장</button>
                                                <button className="btn btn-outline-danger ms-10px"
                                                    onClick={handleDelete}
                                                >삭제</button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="table_scroll">
                                        <table className="table table-bordered my_table xmb-55px">
                                            <thead>
                                                <tr>
                                                    {adminCheck && (
                                                    <th style={{ width: "5.61%" }} className="text-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={isAllChecked} // 전체 선택 여부에 따라 체크 상태 변화
                                                            onChange={handleSelectAll} // 전체 선택 핸들러
                                                        />
                                                    </th>
                                                        )}
                                                    <th style={{ width: "5.61%" }}>번호</th>

                                                    <th style={{ width: "42.6%" }}>공사 명</th>
                                                    <th style={{ width: "9.38%" }}>처리완료일</th>
                                                    <th style={{ width: "9.7%" }}>처리완료</th>
                                                    <th style={{ width: "9.7%" }}>허가증 확인서</th>
                                                    <th style={{ width: "6.38%" }}>신고서</th>
                                                    <th>문서확인</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {responseData.length > 0 ? (
                                                    responseData.map((item, index) => (
                                                        <tr
                                                            key={item.idx}
                                                        >
                                                            {adminCheck && (
                                                            <td className="text-center">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={checkedItems.includes(item.idx)} // 개별 체크박스 상태
                                                                    onChange={() => handleItemCheck(item.idx)} // 개별 체크박스 핸들러
                                                                />
                                                            </td>
                                                                )}
                                                            <td className="text-center">{startIndex+index + 1}</td>
                                                            <td className="text-center">{item.constructionText}</td>
                                                            <td className="text-center">{item.lastDate ? item.lastDate : "-"}</td>
                                                            <td className="text-center">
                                                                {adminCheck ? (
                                                                    <select className="form-select"
                                                                        value={item.lastCheck}
                                                                        onChange={(e) => handleLastCheckChange(e.target.value, item.idx)} // idx를 전달
                                                                    >
                                                                        <option>미완료</option>
                                                                        <option>완료</option>
                                                                    </select>
                                                                ) : (
                                                                    item.lastCheck
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                                    <button className="btn btn-secondary btn_tbl w-128px"
                                                                            onClick={() => handleAllOpenModal(item, 'drive')}>
                                                                        운반 허가증
                                                                    </button>
                                                                    <button className="btn btn-secondary btn_tbl w-128px"
                                                                            onClick={() => handleAllOpenModal(item, 'permit')}>
                                                                        처리 허가증
                                                                    </button>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <button className="btn btn-secondary btn_tbl w-80px"
                                                                        onClick={()=>
                                                                            handleAllOpenModal(item,'document')}
                                                            >계약서</button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-primary btn_tbl w-148px"
                                                                        onClick={() =>
                                                                            handleAllOpenModal(item,'contribution')}
                                                            >분담금 납부 확인서</button></td>
                                                            <DeliveryCheckPreviewModal
                                                                show={isPermitModalOpen}
                                                                handleClose={handlePermitCloseModal}
                                                                previewUrl={permitUrl}
                                                                handlePrint={handlePrint}
                                                            />
                                                        </tr>

                                                    )))
                                                    : (
                                                        <tr className="no-data-message">
                                                            <td className="text-center" colSpan={8}>조회된 데이터가 없습니다.</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination_container mb-55px">
                                        <ul>
                                            {responseData.length > 0 &&
                                                // 페이지가 존재하는 경우 페이지네이션 렌더링
                                                <>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_first.png"}
                                                            alt="First Page"
                                                            onClick={() => handlePageChange(1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_prev.png"}
                                                            alt="Previous Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum > 1 ? pageNation.pageNum - 1 : 1)}
                                                            className={pageNation.pageNum === 1 ? 'disabled' : ''}
                                                        />
                                                    </li>

                                                    {/* 페이지 번호 배열 생성 */}
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li key={index}
                                                            onClick={() => handlePageChange(index + 1)}
                                                            className={pageNation.pageNum === index + 1 ? 'active' : ''}
                                                        >
                                                            {index + 1} {/* 페이지 번호 표시 */}
                                                        </li>
                                                    ))}

                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_next.png"}
                                                            alt="Next Page"
                                                            onClick={() => handlePageChange(pageNation.pageNum < totalPages ? pageNation.pageNum + 1 : totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                    <li className="pg_nav">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/images/pg_last.png"}
                                                            alt="Last Page"
                                                            onClick={() => handlePageChange(totalPages)}
                                                            className={pageNation.pageNum === totalPages ? 'disabled' : ''}
                                                        />
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <h5>실패</h5>
                        <p>{showErrorMessage}</p>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                    </Modal.Body>
                </Modal>
                <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                        <h5>성공</h5>
                        <p>{showSuccessMessage}</p>
                        <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                    </Modal.Body>
                </Modal>
                { isModalOpen &&
                <Modal className="modal_alert1" show={isModalOpen} onHide={handleIsModalClose} centered>
                    <Modal.Body>
                        <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                        <div className="card-body">
                        <div className="grey_box_container">
                            <div className="grey_box">
                                <iframe src={combinedPdfUrl} width="100%" height="800px" title="Combined PDF" />
                            </div>
                            <div className="grey_box">
                                <iframe src={combinedPdfUrl2} width="100%" height="800px" title="Combined PDF" />
                            </div>
                        </div>
                        </div>
                        <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleIsModalClose}>확인</button>
                    </Modal.Body>
                </Modal>
                }




                
                { isContributionModalOpen &&
                    <Modal className="modal_alert1" show={isContributionModalOpen} onHide={handleContributionModalClose} centered>
                        <Modal.Body>
                            <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                            <div className="card-body">
                                <div className="grey_box_container">
                                    <div className="grey_box">
                                        <iframe src={contributionUrl} width="100%" height="800px" title="Combined PDF" />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleContributionModalClose}>확인</button>
                        </Modal.Body>
                    </Modal>
                }
            </div>
            
        <LoadingSpinner isLoading={isLoading} text="데이터 처리중입니다. 30초~1분정도 예상됩니다." /> {/* 로딩 스피너 */}
        </React.Fragment>
    );
}