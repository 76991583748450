// LoadingContext.js
import React, { createContext, useContext, useState } from "react";

// 로딩 상태 관리 Context 생성
const LoadingContext = createContext();

// 로딩 상태와 startLoading, stopLoading을 제공하는 프로바이더
export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

// 로딩 상태를 사용할 수 있는 커스텀 훅
export const useLoading = () => useContext(LoadingContext);
