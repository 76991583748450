import { APIClient } from "./apiCore";
import * as url from "./urls/Oauth2";
import {POST_KAKAO_MESSAGE, POST_OAUTH2_CALL_KAKAO} from "./urls/Oauth2";
const api = new APIClient();

//LOGIN
export const postOauth2CallKaKao = (data) => api.create(url.POST_OAUTH2_CALL_KAKAO, data)
export const postKakaoMessage = (data) => api.create(url.POST_KAKAO_MESSAGE, data)



