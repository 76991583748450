import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { scrollToTop } from "../../api/utils/scrollToTop";
import { RegisterDeliveryContractSecond } from "./RegisterDeliveryContractSecond";
import { MobileHeader } from "../common/MobileHeader";
import { Sidebar } from "../common/Sidebar";
import { PcHeader } from "../common/PcHeader";
import {postKakaoMessage} from "../../api/oauth2";

export const RegisterDeliveryContractFirst = () => {

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");

    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");

    const [deliveryKinds, setDeliveryKinds] = useState([]);



    const [registerReq, setRegisterReq] = useState({
        idx: "", // delivery_registration의 기본키
        companyNumber: "", // 사업자 등록번호
        name: "", // 상호
        ceoName: "", // 대표자
        companyKind: "", // 업종
        ceoCellphone: "", // 연락처
        companyAddress: "", // 주소
        constructionAddress: "", // 현장주소
        constructionText: "", // 공사명
        constructionStartDate: "", // 공사기간 앞
        constructionEndDate: "", // 공사기간 뒤
        payText: "", // 결제조건
        useCheck: "도로공사용", // 사용용도 (1) 도로공사 (2) 되메우기 및 뒷채움용
        significant: "", // 주의사항 및 특이사항
        email: "", // 이메일
        cellphone: "", // 연락처
        deliveryKinds: [], // 폐기물 종류 단위 수량을 위한 리스트
        userIdx: "",// userIdx (default as a number)
        type: "email"
    });

    const constructionTextRef = useRef(null)
    const constructionAddressRef = useRef(null)
    const payTextRef = useRef(null)


    const [rows, setRows] = useState([
        {
            id: 1,
            deliveryRegistrationIdx: "",
            kindName: "", //납품명
            standard: "고상", //규격
            unit: "대", // 단위
            arrivePay: "0",
            anotherPay: "0",
            kindCount: "0",
            sum: "0",
            remark: "",
            significant: "",
        }
    ]);
    // 선택된 행의 인덱스를 추적하기 위한 상태
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [currentComponent, setCurrentComponent] = useState('first'); // 현재 컴포넌트를 제어하는 상태
    const [dataToPass, setDataToPass] = useState(null); // 두 번째 컴포넌트로 전달할 데이터
    const [secondComponentData, setSecondComponentData] = useState({}); // 두 번째 컴포넌트의 데이터를 저장
    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            deliveryRegistrationIdx: "",
            kindName: "", //납품명
            standard: "고상", //규격
            unit: "대", // 단위
            arrivePay: "0",
            anotherPay: "0",
            kindCount: "0",
            sum: "0",
            remark: "0",
            significant: "0",
        };

        // rows에 새 행 추가
        setRows((prevRows) => [...prevRows, newRow]);
    };

    const handleUseCheckChange = (value) => {
        if (registerReq.useCheck !== value) {
            setRegisterReq((prevState) => ({
                ...prevState,
                useCheck: value // 새로운 값으로 상태 설정
            }));
        }
    };

    const handleCheckboxChange = (index) => {
        setSelectedRowIndex(index);
        setRows((prevRows) => {
            return prevRows.map((row, idx) => {
                // 선택된 행의 체크 상태만 반전시키고 나머지 데이터는 그대로 유지
                if (idx === index) {
                    return { ...row, checked: !row.checked }; // 체크 상태 반전
                }
                return row; // 나머지 행은 그대로 반환
            });
        });
    };

    const handleErrorClose = () => {
        setShowError(false)
        scrollToTop()
    }

    const handleSuccessClose = () => {
        setShowSuccess(false);
        scrollToTop()
    }
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1); // 내일 날짜 설정

        // yyyy-mm-dd 형식으로 날짜 변환
        const formattedToday = today.toISOString().split("T")[0];
        const formattedTomorrow = tomorrow.toISOString().split("T")[0];

        // 시작 날짜는 오늘, 종료 날짜는 내일로 설정 (최초 로드 시에만)

        setStartDate(formattedToday);
        setEndDate(formattedTomorrow);

        setRegisterReq((prev) => ({
            ...prev,
            constructionStartDate: formattedToday, // 선택된 모든 유효한 파일을 배열로 설정
            constructionEndDate: formattedTomorrow,
        }));

        constructionTextRef.current.focus()
    }, []);

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);
        setRegisterReq((prev) => ({
            ...prev,
            constructionStartDate: newStartDate,
        }));

    };

    // 종료 날짜 변경 시, 시작 날짜보다 하루 이상 차이나지 않도록 제한
    const handleEndDateChange = (e) => {
        const selectedEndDate = new Date(e.target.value);
        const selectedStartDate = new Date(startDate);

        if (selectedEndDate >= selectedStartDate) {
            setEndDate(e.target.value);
        } else {
            setShowError(true)
            setShowErrorMessage("종료날짜는 시작 날짜 이전일 수없습니다.")
        }

        setRegisterReq((prev) => ({
            ...prev,
            constructionEndDate: e.target.value,
        }));
    };

    const handleChange = (index, field, value) => {

        setDeliveryKinds((prevDeliveryKinds) => {
            const updatedDeliveryKinds = [...prevDeliveryKinds];

            // 선택한 필드에 따라 업데이트
            switch (field) {
                case "kindName":
                    updatedDeliveryKinds[index].kindName = value;
                    break;
                case "standard":
                    updatedDeliveryKinds[index].standard = value;
                    break;
                case "unit":
                    updatedDeliveryKinds[index].unit = value;
                    break;
                case "arrivePay":
                    value = value.replace(/\D/g, '');
                    updatedDeliveryKinds[index].arrivePay = value;
                    break;
                case "anotherPay":
                    value = value.replace(/\D/g, '');
                    updatedDeliveryKinds[index].anotherPay = value;
                    break;
                case "kindCount":
                    value = value.replace(/\D/g, '');
                    updatedDeliveryKinds[index].kindCount = value;
                    break;
                case "remark":
                    updatedDeliveryKinds[index].remark = value;
                    break;
                case "significant":
                    updatedDeliveryKinds[index].significant = value;
                    break;
                default:
                    break;
            }

            // arrivePay와 anotherPay의 합계를 계산하여 sum에 업데이트
            const arrivePay = parseFloat(updatedDeliveryKinds[index].arrivePay) || 0; // 숫자로 변환
            const anotherPay = parseFloat(updatedDeliveryKinds[index].anotherPay) || 0; // 숫자로 변환
            const totalSum = arrivePay + anotherPay; // sum 계산

            // sum을 업데이트
            updatedDeliveryKinds[index].sum = totalSum; // 포맷팅 제거

            // rows 업데이트
            setRows((prevRows) => {
                const updatedRows = [...prevRows];
                updatedRows[index] = {
                    ...updatedRows[index],
                    arrivePay: updatedDeliveryKinds[index].arrivePay,
                    anotherPay: updatedDeliveryKinds[index].anotherPay,
                    sum: updatedDeliveryKinds[index].sum,
                    standard: updatedDeliveryKinds[index].standard,
                    kindName: updatedDeliveryKinds[index].kindName,
                    unit: updatedDeliveryKinds[index].unit,
                    kindCount: updatedDeliveryKinds[index].kindCount,
                    tonCount: updatedDeliveryKinds[index].tonCount,
                    remark: updatedDeliveryKinds[index].remark,
                    significant: updatedDeliveryKinds[index].significant,
                };
                return updatedRows;
            });

            return updatedDeliveryKinds;
        });
    };

    const goBackToFirst = (data) => {

        setSecondComponentData(data); // 두 번째 컴포넌트로부터 받은 데이터를 저장
        setCurrentComponent('first'); // 첫 번째 컴포넌트로 전환
    };

    // // useEffect를 통해 secondComponentData가 변경될 때 registerReq 업데이트
    // useEffect(() => {
    //     console.log("전달받은데이터" + secondComponentData)
    //     if (secondComponentData) {
    //         setRegisterReq(prevRegisterReq => ({
    //             ...prevRegisterReq, // 기존 필드 유지
    //             ...secondComponentData, // 새로운 데이터로 업데이트
    //         }));
    //     }
    // }, [secondComponentData]);

    const handleDeleteRow = () => {
        const updatedRows = rows.filter((row) => !row.checked); // 체크된 행 삭제
        const updatedWasteKinds = deliveryKinds.filter((_, index) => !rows[index].checked); // wasteKinds에서 체크된 행 삭제

        // 최소 1행이 남도록 확인
        if (updatedRows.length === 0) {
            // 최소 1행이 필요하므로 마지막 행을 유지하거나 다른 로직을 수행
            setShowErrorMessage("최소 1행은 필수입니다.")
            setShowError(true)
            return; // 함수 종료
        }
        // 인덱스 재설정
        const reindexedRows = updatedRows.map((row, index) => ({
            ...row,
            id: index + 1, // 인덱스를 1부터 시작하도록 설정
        }));
        // 상태 업데이트
        setRows(reindexedRows);
        setDeliveryKinds(updatedWasteKinds);
    };

    const nextHandlerClick = () => {
        const errorMessages = {
            constructionText: "공사명을 입력해주세요..",
            constructionAddress: "현장주소를 입력해주세요.",
            payText: "결제조건을 입력해주세요.",
        };

        for (const [key, value] of Object.entries(registerReq)) {
            if (key in errorMessages && value === "") {
                const errorMessage = errorMessages[key];
                if (errorMessage) {
                    setShowErrorMessage(errorMessage);
                    setShowError(true);
                }
                return;
            }
        }

        const deliveryKindMessages = {
            kindName: "납품명을 입력해주세요.",
            standard: "규격을 입력해주세요",
            arrivePay: "운반비를 입력해주세요.",
            anotherPay: "처리비를 입력해주세요.",
            kindCount: "수량을 입력해주세요.",
        };

        for (let i = 0; i < deliveryKinds.length; i++) { // 인덱스 0부터 시작
            const wasteKind = deliveryKinds[i]; // 각 wasteKind 객체

            for (const [key, value] of Object.entries(wasteKind)) {
                if (key in deliveryKindMessages && (value === "" || value === "0" || value === 0)) {
                    const deliveryKindMessage = deliveryKindMessages[key];
                    if (deliveryKindMessage) {
                        setShowErrorMessage(deliveryKindMessage);
                        setShowError(true);
                        return;
                    }
                }
            }
        }

        setDataToPass({
            registerReq,
        });
        // / 첫 번째 컴포넌트에서 두 번째 컴포넌트로 전환
        setCurrentComponent('second');

    }

    useEffect(() => {
        const newDeliveryKinds = rows.map((row, index) => {
            return {
                kindName: row.kind || deliveryKinds[index]?.kindName || "",
                unit: row.unit || deliveryKinds[index]?.unit || "",
                standard: row.standard || deliveryKinds[index]?.standard || "",
                kindCount: row.kindCount !== undefined ? row.kindCount : (deliveryKinds[index]?.kindCount || 0),
                arrivePay: row.arrivePay !== undefined ? row.arrivePay : (deliveryKinds[index]?.arrivePay || 0),
                anotherPay: row.anotherPay !== undefined ? row.anotherPay : (deliveryKinds[index]?.anotherPay || 0),
                sum: (parseInt(row.arrivePay, 10) + parseInt(row.anotherPay, 10)),
                remark: row.remark || deliveryKinds[index]?.remark || "",
                significant: row.significant || deliveryKinds[index]?.significant || "",
            };
        });
        setDeliveryKinds(newDeliveryKinds);
    }, [rows]);


    useEffect(() => {
        setRegisterReq((prevRegisterReq) => ({
            ...prevRegisterReq,
            deliveryKinds: deliveryKinds,
        }));
    }, [deliveryKinds]);

    const onKeyDownHandler = (e, part) => {
        if (e.key === 'Enter') {
            e.preventDefault();   // 기본 동작 (예: 폼 제출)을 취소합니다.

            if (part === 'constructionText') {
                constructionAddressRef.current.focus()
            } else if (part === 'constructionAddress') {
                payTextRef.current.focus();
            }

        }
    };

    return (
        <React.Fragment >
            <MobileHeader />

            {currentComponent === 'first' ? (
                <div className="main-sidebar">
                    <Sidebar />
                    <div className="main-container">
                        <PcHeader title="납품관리" icon="/images/icon_header2.png" />
                        <div className="body-box">
                            <div className="body-container">
                                <div className="card card_main">
                                    <div className="card-header">
                                        납품 계약서 등록
                                    </div>
                                    <div className="card-body">
                                        <div className="stepper_wrapper lg">
                                            <div className="stepper_line"></div>
                                            <ul>
                                                <li className="active">
                                                    <div className="stepper_dot dot_1"></div>
                                                    <div className="stepper_text">공사내용 및 납품 물품 목록</div>
                                                </li>
                                                <li className="">
                                                    <div className="stepper_dot dot_2"></div>
                                                    <div className="stepper_text">발주처 및 공사내용 입력</div>
                                                </li>
                                                <li>
                                                    <div className="stepper_dot dot_3"></div>
                                                    <div className="stepper_text">전송완료</div>
                                                </li>
                                            </ul>
                                        </div>

                                        <h3 className="table_heading">1. 공사내용</h3>
                                        <div className="table_scroll">
                                            <table className="table table-bordered card_table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "12.82%" }}>공사명</th>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder=""
                                                                value={registerReq.constructionText || ""}
                                                                onChange={(e) =>
                                                                    setRegisterReq((prevState) => ({
                                                                        ...prevState,
                                                                        constructionText: e.target.value,
                                                                    }))
                                                                }
                                                                ref={constructionTextRef}
                                                                onKeyDown={(e) => onKeyDownHandler(e, 'constructionText')}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>공사기간</th>
                                                        <td>
                                                            <div className="flex-center-between">
                                                                <div className="frm_field input_date input_phone_tbl">
                                                                    <input placeholder="" type="date" className="form-control"
                                                                        value={startDate}
                                                                        onChange={handleStartDateChange}
                                                                    />
                                                                    <span className="txt_range">-</span>
                                                                    <input placeholder="" type="date" className="form-control"
                                                                        value={endDate}
                                                                        onChange={handleEndDateChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>현장주소</th>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder=""
                                                                value={registerReq.constructionAddress || ""}
                                                                onChange={(e) =>
                                                                    setRegisterReq((prevState) => ({
                                                                        ...prevState,
                                                                        constructionAddress: e.target.value,
                                                                    }))
                                                                }
                                                                ref={constructionAddressRef}
                                                                onKeyDown={(e) => onKeyDownHandler(e, 'constructionAddress')}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>결제조건</th>
                                                        <td>
                                                            <input type="text" className="form-control" placeholder=""
                                                                value={registerReq.payText || ""}
                                                                onChange={(e) =>
                                                                    setRegisterReq((prevState) => ({
                                                                        ...prevState,
                                                                        payText: e.target.value,
                                                                    }))
                                                                }
                                                                ref={payTextRef}
                                                                onKeyDown={(e) => onKeyDownHandler(e, 'payText')}
                                                            />

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>사용용도</th>
                                                        <td>
                                                            <div>
                                                                <input
                                                                    className="form-check-input me-1"
                                                                    type="checkbox"
                                                                    id="usagePurpose1"
                                                                    checked={registerReq.useCheck === "도로공사용"}
                                                                    onChange={() => handleUseCheckChange("도로공사용")}
                                                                />
                                                                <label htmlFor="usagePurpose1">도로공사용</label>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className="form-check-input me-1"
                                                                    type="checkbox"
                                                                    id="usagePurpose2"
                                                                    checked={registerReq.useCheck === "되메우기 및 뒷채움용(해당되는 것에 표시)"}
                                                                    onChange={() => handleUseCheckChange("되메우기 및 뒷채움용(해당되는 것에 표시)")}
                                                                />
                                                                <label htmlFor="usagePurpose2">되메우기 및 뒷채움용(해당되는 것에 표시)</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="card_top_with_btns mb-13px">
                                            <h3 className="table_heading mb-0">2. 납품 물품 목록</h3>
                                            <div className="card_table_btntop">
                                                <button className="btn btn-outline-secondary"
                                                    onClick={handleAddRow}
                                                >추가</button>
                                                <button className="btn btn-outline-danger ms-10px"
                                                    onClick={handleDeleteRow}
                                                >삭제</button>
                                            </div>
                                        </div>
                                        <div className="table_scroll">
                                            <table className="table my_table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "5.58%" }}>선택</th>
                                                        <th style={{ width: "5.58%" }}>번호</th>
                                                        <th style={{ width: "11.11%" }}>납품명</th>
                                                        <th style={{ width: "11.11%" }}>규격</th>
                                                        <th style={{ width: "11.11%" }}>단위</th>
                                                        <th style={{ width: "11.04%" }}>수량</th>
                                                        <th style={{ width: "11.11%" }}>운반비</th>
                                                        <th style={{ width: "11.11%" }}>처리비</th>
                                                        <th style={{ width: "11.11%" }}>합계금액</th>
                                                        <th>비고</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rows.map((row, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={row.checked || false}
                                                                    onChange={() => handleCheckboxChange(index)} // 체크박스 변경 시 처리
                                                                />
                                                            </td>
                                                            <td className="text-center">{row.id}</td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={deliveryKinds[index]?.kindName || ""}
                                                                    onChange={(e) => handleChange(index, "kindName", e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={deliveryKinds[index]?.standard || ""}
                                                                    disabled={true}
                                                                    onChange={(e) => handleChange(index, "standard", e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <select
                                                                    className="form-select"
                                                                    value={deliveryKinds[index]?.unit || ""}
                                                                    onChange={(e) => handleChange(index, "unit", e.target.value)}
                                                                >
                                                                    <option value="대">대</option>
                                                                    <option value="루베">루베</option>
                                                                    
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={deliveryKinds[index]?.kindCount || ""}
                                                                    onChange={(e) => handleChange(index, "kindCount", e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text-end"
                                                                    className="form-control"
                                                                    value={Number(deliveryKinds[index]?.arrivePay).toLocaleString('ko') || ""}
                                                                    onChange={(e) => handleChange(index, "arrivePay", e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text-end"
                                                                    className="form-control"
                                                                    value={Number(deliveryKinds[index]?.anotherPay).toLocaleString('ko') || ""}
                                                                    onChange={(e) => handleChange(index, "anotherPay", e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    disabled={true}
                                                                    type="text-end"
                                                                    className="form-control"
                                                                    value={Number(deliveryKinds[index]?.sum).toLocaleString('ko') || ""}
                                                                    onChange={(e) => handleChange(index, "sum", e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={deliveryKinds[index]?.remark || ""}
                                                                    onChange={(e) => handleChange(index, "remark", e.target.value)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <th colSpan={3} className="delivery-text">주의사항 및 특이사항</th>
                                                        <td colSpan={7}>
                                                            <textarea className="form-control" rows={4}
                                                                value={registerReq.significant || ""}
                                                                onChange={(e) =>
                                                                    setRegisterReq((prevState) => ({
                                                                        ...prevState,
                                                                        significant: e.target.value,
                                                                    }))
                                                                }
                                                            >
                                                            </textarea>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-footer card_footer_btngrp_end">
                                        <button className="btn btn-primary btn_footer"
                                            onClick={nextHandlerClick}
                                        >다음으로</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            ) : (
                <RegisterDeliveryContractSecond data={dataToPass} goBack={goBackToFirst} />
            )}

            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                    <h5>오류</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                    </button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>ㅋ</h5>
                    <p> 전송 되었습니다.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                </Modal.Body>
            </Modal>
        </React.Fragment >
    );
}