import { APIClient } from "./apiCore";
import * as url from "./urls/Pdf";
import {POST_PDF_COATING_MEMBER_PRE_VIEW, POST_PDF_COATING_NO_MEMBER_PRE_VIEW} from "./urls/Pdf";
const api = new APIClient();
export const postPdfCoatingMemberPreView = (data) => api.create(url.POST_PDF_COATING_MEMBER_PRE_VIEW, data)
export const postPdfCoatingNoMemberPreView = (data) => api.create(url.POST_PDF_COATING_NO_MEMBER_PRE_VIEW, data)






