const baseUrl = "/api/mypage"
export const POST_MYPAGE_AUTH_SEARCH = baseUrl+"/authSearch" // 첫 렌더링 시 admin Check

export const POST_MYPAGE_BASE_DETAIL_SAVE = baseUrl+"/baseDetailSave" // 일반 사용자 저장
export const POST_MYPAGE_BASE_DETAIL_SEARCH = baseUrl+"/baseDetailSearch" // 일반 사용자 검색
export const POST_MYPAGE_BASE_DETAIL_AUTH_SEARCH = baseUrl+"/baseDetailAuthSearch" //  관리자 사용자 검색
export const POST_MYPAGE_BASE_DETAILAUTH_SAVE = baseUrl+"/baseDetailAuthSave" //  관리자 사용자 저장
export const POST_MYPAGE_DRIVE_DETAIL_SEARCH = baseUrl+"/driveDetailSearch" //  수탁확인서 검색
export const POST_MYPAGE_DRIVE_DETAIL_SAVE = baseUrl+"/driveDetailSave" //  수탁확인서 저장
export const POST_MYPAGE_HANDLER_DETAIL_SEARCH = baseUrl+"/handlerDetailSearch" //  수탁능력확인서 검색
export const POST_MYPAGE_HANDLER_DETAIL_SAVE = baseUrl+"/handlerDetailSave" //  수탁능력확인서 저장


