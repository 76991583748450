import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

export const VerifyDeliverySidebar = () => {

    return (
        <div className="nav-container">
            <div className="nav-top-box">
                <img className="logo cursor-pointer" src={process.env.PUBLIC_URL + "/images/private_logo.png"} alt="" />
            </div>
            <div className="nav-list">
                <ul>
                    <li>
                        <ul>
                            <li className="active">
                                <a>납품 확인 및 날인</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
};
