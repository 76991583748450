import { useEffect, useRef, useState } from "react";
import { PublicFooter } from "../base/PublicFooter";
import { PublicHeader } from "../base/PublicHeader";
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from "react-router-dom";
import {findId,findIdEmailSend} from "../../api/auth";

export const FindId = () => {
    const navigate = useNavigate();

    const [findIdReq, setFindIdReq] = useState({
        email: "",
        name: "",
    })

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("");

    const [nameKeyDownCheck, setNameKeyDownCheck] = useState(null);
    const [nameCheck, setNameCheck] = useState(false)
    const nameRef = useRef(null);

    const emailFirstRef = useRef(null);
    const emailSecondRef = useRef(null);
    const [emailDomain, setEmailDomain] = useState("naver.com");
    const [emailDomainCheck, setEmailDomainCheck] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [isDirectInput, setIsDirectInput] = useState(false);


    const [emailFirstCheck, setEmailFirstCheck] = useState(false)
    const [emailSecondCheck, setEmailSecondCheck] = useState(false)
    const [emailFirst,setEmailFirst] = useState("")
    const [emailSecond,setEmailSecond] = useState("")

    const nameClick = () => {
        nameRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });
    }

    const emailClick = () => {
        emailFirstRef.current.focus()
        emailFirstRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    }

    const emailSendClick = () => {
        emailSecondRef.current.focus()
        emailSecondRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    }

    const nameKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(nameKeyDownCheck){
                emailClick();
            }

        }
    }


    const nameHandler = (e) => {
        const nameRegex = /^[a-zA-Z가-힣]+$/;

        let minLength = 2;
        let maxLength = 8;

        if (e === "") {
            setNameErrorMessage("이름을 입력해주세요.")
            setNameKeyDownCheck(false)
            setNameCheck(false)
        }
        else if (e.length < minLength || e.length > maxLength){
            setNameErrorMessage("이름은 2글자 이상 8자 이하입니다.")
            setNameKeyDownCheck(false)
            setNameCheck(false)
        }
        else if (!nameRegex.test(e)) {
            setNameErrorMessage("올바른 이름을 입력해주세요.");
            setNameKeyDownCheck(false)
            setNameCheck(false)
        }
        else {
            setFindIdReq({...findIdReq, name:e})
            setNameErrorMessage("");
            setNameKeyDownCheck(true)
            setNameCheck(true)
        }

    }

    const emailFirstHandler = (e) => {

        const alphanumericRegex = /^[a-zA-Z0-9]*$/; // 영어와 숫자만 허용하는 정규식

        if(e===""){
            setEmailErrorMessage("이메일을 입력해주세요.")
            setEmailFirstCheck(false)
        }
        else if (!alphanumericRegex.test(e)) {
            setEmailErrorMessage("이메일 형식이 아닙니다.")
            setEmailFirstCheck(false)
        } else {
            setEmailErrorMessage("")
            setEmailFirstCheck(true)
            setEmailFirst(e)
        }

        updateEmail(emailDomain);

    }
    const emailSecondHandler = (e) => {


        const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // 도메인 형식 정규식

        if(e===""){
            setEmailErrorMessage("이메일 입력해주세요.")
            setEmailDomain(e)
            setEmailSecondCheck(false)
        }
        else if (!domainRegex.test(e)) {
            setEmailErrorMessage("이메일 형식이 아닙니다.")
            setEmailDomain(e)
            setEmailSecondCheck(false)
        } else {
            setEmailDomain(e)
            setEmailErrorMessage("")
            setEmailSecondCheck(true)
            setEmailDomainCheck(true)
        }
        updateEmail(e);
    }
    const handleSelectChange = (e) => {

        const selectedValue = e.target.value;
        if (selectedValue === "직접입력") {
            setIsDirectInput(true)
            setEmailDomain("")
            setTimeout(() => {
                emailSecondRef.current.focus();
            }, 0);

        }
        else {
            setIsDirectInput(false);
            setEmailDomain(selectedValue);
            setEmailSecond(selectedValue)
        }
        updateEmail(selectedValue);

    };


    const handleClose =()=>{

        setShow(false)
        navigate("/")
    }

    // const handleClose = () => setShow(false);

    const handleErrorShow =(e)=>{
        setShowErrorMessage(e);
        setShowError(true)
    }

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    };
    const handleGoNext = async () => {
        try{

            if(!nameCheck){
                scrollToRef(nameRef)
                nameHandler(findIdReq.name)
                return
            }
            if(!emailFirstCheck){
                scrollToRef(emailFirstRef)
                emailFirstHandler(emailFirst)
                return
            }
            if(!emailSecondCheck && emailDomainCheck ){
                scrollToRef(emailSecondRef)
                emailSecondHandler(emailDomain)
                return
            }

            const response = await findId(findIdReq)

            if(response.code==="0000"){
                const responseEmail = await findIdEmailSend(findIdReq)
                setShow(true)
            }

        }
        catch (e){
            handleErrorShow(e.message);
        }
    };
    const handleErrorClose =()=>{
        setShowError(false)
    }

    const handleGoBack = () => {
        navigate(-1);
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const updateEmail = (domain) => {
        const firstPart = emailFirstRef.current.value;
        const fullEmail = isDirectInput ? `${firstPart}@${domain}` : `${firstPart}@${emailDomain}`;
        setFindIdReq({ ...findIdReq, email: fullEmail });
    };

    return (
        <>
            <Modal className="modal_alert1" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>아이디전송 완료</h5>
                    <p>입력하신 이메일로 아이디가 전송되었습니다.<br />
                        이메일을 확인해주세요.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleClose}>확인</button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>아이디전송 실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>
            <PublicHeader />
            <div className="findid_content">
                <div className="card findid_box">
                    <div className="card-header">
                        <div className="card-title">아이디 찾기</div>
                    </div>
                    <div className="card-body">

                        <h2 className="findid_text">찾으려는 아이디의 이름과 이메일을 입력해주세요.<br />
                            아이디를 이메일로 전달해드립니다.</h2>

                        <div className="findid_frm_outer">
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">이름</label>
                                <div class="frm_field">
                                    <input placeholder="이름을 입력해주세요." type="text"
                                        ref={nameRef}
                                        onClick={nameClick}
                                        onChange={(e) => nameHandler(e.target.value)}
                                           onKeyDown={nameKeyDownHandler}
                                        class="form-control" />

                                </div>
                                {nameErrorMessage && (
                                    <div className="error-message">
                                        {nameErrorMessage}
                                    </div>
                                )}
                            </div>
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">이메일</label>
                                <div class="frm_field input_email">
                                    <input placeholder="" type="text" class="form-control"
                                           ref={emailFirstRef}
                                           onClick={emailClick}
                                           onChange={(e)=>emailFirstHandler(e.target.value)}

                                    />
                                    <span className="txt_range">@</span>
                                    <input placeholder="" type="text" class="form-control"
                                           ref={emailSecondRef}
                                           value={emailDomain}
                                           onClick={emailSendClick}
                                           onChange={(e) => emailSecondHandler(e.target.value)}
                                           disabled={!isDirectInput}
                                    />
                                    <select className="form-select"
                                    onChange={handleSelectChange}
                                    >
                                        <option value="naver.com">naver.com</option>
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="hanmail.net">hanmail.net</option>
                                        <option value="yahoo.com">yahoo.com</option>
                                        <option value="daum.net">daum.net</option>
                                        <option>직접입력</option>
                                    </select>
                                </div>
                                {emailErrorMessage && (
                                    <div className="error-message">
                                        {emailErrorMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-secondary"
                        onClick={handleGoBack}
                        >돌아가기</button>
                        <button className="btn btn-primary ms-10px"
                        onClick={handleGoNext}
                        >인증하기</button>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
    );
}