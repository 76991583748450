import {useRef, useState, useEffect} from "react";
import { PublicFooter } from "../base/PublicFooter";
import { PublicHeader } from "../base/PublicHeader";
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from "react-router-dom";
import {newPassword, newPasswordSave, findId, findIdEmailSend} from "../../api/auth";

export const FindPassword = () => {
    const navigate = useNavigate(); // useNavigate 훅 사용
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");


    const [passwordReq,setPasswordReq] = useState({
        email:"",
        name:"",
        userId:"",
    })

    const [newPasswordReq,setNewPasswordReq] = useState({
        email:"",
        passwordCode:0,
        password:"",
        passwordCheck:"",
    })

    const [buttonAuthCodeSend, setButtonAuthCodeSend] = useState(false)

    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [nameKeyDownCheck, setNameKeyDownCheck] = useState(false);
    const nameRef = useRef(null);
    const [nameCheck, setNameCheck] = useState(false)


    const [userIdErrorMessage,setUserIdErrorMessage] = useState("");
    const [userIdKeyDownCheck, setUserIdKeyDownCheck] = useState(false)
    const userIdRef = useRef(null);

    const [emailErrorMessage,setEmailErrorMessage] = useState("");
    const [emailFirstKeyDownCheck, setEmailFirstDownCheck] = useState(false)
    const [emailFirstReq,setEmailFirstReq] = useState("")
    const emailFirstRef = useRef(null);

    const [emailSecondKeyDownCheck, setEmailSecondDownCheck] = useState(false)
    const [emailSecondReq,setEmailSecondReq] = useState("naver.com")
    const emailSecondRef = useRef(null);

    const [authCodeErrorMessage,setAuthCodeErrorMessage] = useState("");
    const [authCodeKeyDownCheck, setAuthCodeKeyDownCheck] = useState(false)
    const authCodeRef = useRef(null);

    const [passwordErrorMessage,setPasswordErrorMessage] = useState("");
    const [passwordKeyDownCheck, setPasswordKeyDownCheck] = useState(false)
    const passwordRef = useRef(null);
    const [valuePassword,setValuePassword] = useState("");

    const [passwordCheckErrorMessage,setPasswordCheckErrorMessage] = useState("");
    const [passwordCheckKeyDownCheck, setPasswordCheckKeyDownCheck] = useState(false)
    const passwordCheckRef = useRef(null);
    const [valuePasswordCheck,setValuePasswordCheck] = useState("");

    const [isDirectInput, setIsDirectInput] = useState(false); // 이메일 Second disabled
    const [nameDisabled, setNameDisabled] = useState(false)
    const [userIdDisabled, setUserIdDisabled] = useState(false)
    const [emailFirstDisabled, setEmailFirstDisabled] = useState(false)
    const [selectEmailDisabled, setSelectEmailDisabled] = useState(false)

    const [isPasswordTouched, setIsPasswordTouched] = useState(false);
    const [isPasswordCheckTouched, setIsPasswordCheckTouched] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () =>
    {
        setShow(false);
        navigate("/login")
    }
    const handleSuccessClose = () => {

        setShowSuccess(false);

        authCodeRef.current.focus()
    }


    const nameClick = () => {
        nameRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });
    }

    const userIdClick = () => {
        userIdRef.current.focus()
        userIdRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });
    }

    const emailFirstClick = () => {
        emailFirstRef.current.focus()
        emailFirstRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    }

    const emailSecondClick = () => {
            emailFirstRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'nearest'
            });

    }

    const authCodeClick = () => {
        authCodeRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    }

    const passwordClick = () => {
        passwordRef.current.focus()
        passwordRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    }
    const passwordCheckClick = () => {
        passwordCheckRef.current.focus()
        passwordCheckRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    }


    const nameHandler = (e) => {

        const nameRegex = /^[a-zA-Z가-힣]+$/;

        let minLength = 2;
        let maxLength = 8;

        if (e === "") {
            setNameErrorMessage("이름을 입력해주세요.")
            setNameKeyDownCheck(false)
            setNameCheck(false)
        }
        else if (e.length < minLength || e.length > maxLength){
            setNameErrorMessage("이름은 2글자 이상 8자 이하입니다.")
            setNameKeyDownCheck(false)
            setNameCheck(false)
        }
        else if (!nameRegex.test(e)) {
            setNameErrorMessage("올바른 이름을 입력해주세요.");
            setNameKeyDownCheck(false)
            setNameCheck(false)
        }
        else {
            setPasswordReq({...passwordReq, name:e})
            setNameErrorMessage("");
            setNameKeyDownCheck(true)
            setNameCheck(true)
        }
    }



    const userIdHandler = (e) => {
        let minLength = 5;
        if (e === "") {
            setUserIdErrorMessage("아이디를 입력해주세요.")
            setUserIdKeyDownCheck(false)
        }
        else if (e.length < minLength){
            setUserIdErrorMessage("아이디는 5글자 이상 입니다.")
            setUserIdKeyDownCheck(false)
        }
        else {
            setPasswordReq({...passwordReq, userId:e})
            setUserIdErrorMessage("");
            setUserIdKeyDownCheck(true)
        }
    }

    const authCodeHandler = (e) => {

        const length = 6;

        if (e === "") {
            setAuthCodeErrorMessage("인증코드 입력해주세요.")
            setAuthCodeKeyDownCheck(false)
        }
        else if (e.length !== length){
            setAuthCodeErrorMessage("인증코드가 올바르지 않습니다.")
            setAuthCodeKeyDownCheck(false)
        }
        else {
            setNewPasswordReq({...newPasswordReq, passwordCode:e})
            setAuthCodeErrorMessage("");
            setAuthCodeKeyDownCheck(true)
        }
    }

    const passwordHandler = (e) => {


        setValuePassword(e);
        setNewPasswordReq({...newPasswordReq, password: e});
        setIsPasswordTouched(true); // 페이지 렌더링 시 문구 표시 제어
    }

    const passwordCheckHandler = (e) => {

        setValuePasswordCheck(e);
        setNewPasswordReq({...newPasswordReq, passwordCheck: e});
        setIsPasswordCheckTouched(true); // 페이지 렌더링 시 문구 표시 제어

    }

    // 비밀번호와 비밀번호 확인 일치 및 정규식 검사
    useEffect(() => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`{}\[\]:;'"<>,.?\/\\|-])[a-zA-Z\d!@#$%^&*()_+~`{}\[\]:;'"<>,.?\/\\|-]{8,16}$/;

        let isPasswordValid = false; // 비밀번호 문구 제어용도
        let isPasswordCheckValid = false; // 비밀번호 확인 문구 제어용도

        if (isPasswordTouched) {
            if (valuePassword) {
                if (valuePassword.length < 8 || valuePassword.length > 16) {
                    setPasswordErrorMessage("새 비밀번호는 8~16자 입니다.");
                    setPasswordKeyDownCheck(false);
                } else if (!passwordRegex.test(valuePassword)) {
                    setPasswordErrorMessage("비밀번호는 영문, 숫자, 특수문자를 포함해야 합니다.");
                    setPasswordKeyDownCheck(false);
                } else {
                    setPasswordErrorMessage("");
                    setPasswordKeyDownCheck(true);
                    isPasswordValid = true; // 비밀번호가 유효한 경우 true
                }
            } else {
                setPasswordErrorMessage("비밀번호를 입력해주세요.");
                setPasswordKeyDownCheck(false);
            }
        }

        if (isPasswordCheckTouched) {
            if (valuePasswordCheck) {
                if (valuePasswordCheck.length < 8 || valuePasswordCheck.length > 16) {
                    setPasswordCheckErrorMessage("비밀번호 확인은 8~16자 입니다.");
                    setPasswordCheckKeyDownCheck(false);
                } else if (!passwordRegex.test(valuePasswordCheck)) {
                    setPasswordCheckErrorMessage("비밀번호 확인은 영문, 숫자, 특수문자를 포함해야 합니다.");
                    setPasswordCheckKeyDownCheck(false);
                } else {
                    setPasswordCheckErrorMessage("");
                    setPasswordCheckKeyDownCheck(true);
                    isPasswordCheckValid = true; // 비밀번호 확인이 유효한 경우 true
                }
            } else {
                setPasswordCheckErrorMessage("비밀번호 확인을 입력해주세요.");
                setPasswordCheckKeyDownCheck(false);
            }
        }

        // 비밀번호와 비밀번호 확인이 모두 유효할 때만 일치 여부를 검사
        if (isPasswordValid && isPasswordCheckValid) {
            if (valuePassword !== valuePasswordCheck) {
                setPasswordCheckErrorMessage("두 개의 비밀번호가 일치하지 않습니다.");
                setPasswordCheckKeyDownCheck(false);
            } else {
                setPasswordCheckErrorMessage("");
                setPasswordCheckKeyDownCheck(true);
            }
        }
    }, [valuePassword, valuePasswordCheck, isPasswordTouched, isPasswordCheckTouched]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const emailFirstHandler = (e) => {
        const alphanumericRegex = /^[a-zA-Z0-9]*$/; // 영어와 숫자만 허용하는 정규식

        if(e===""){
            setEmailErrorMessage("이메일을 입력해주세요.")
            setEmailFirstDownCheck(false)
            setEmailFirstReq(e)
        }
        else if (!alphanumericRegex.test(e)) {
            setEmailErrorMessage("이메일 형식이 아닙니다.")
            setEmailFirstDownCheck(false)
            setEmailFirstReq(e)
        } else {
            setEmailErrorMessage("")
            setEmailFirstDownCheck(true)
            setEmailFirstReq(e)
            setEmailSecondDownCheck(true)
        }
    }

    const emailSecondHandler = (e) => {

        const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // 도메인 형식 정규식

        if(e===""){
            setEmailErrorMessage("이메일 입력해주세요.")
            setEmailSecondDownCheck(false)
            setEmailSecondReq(e)
        }
        else if (!domainRegex.test(e)) {
            setEmailErrorMessage("이메일 형식이 아닙니다.")
            setEmailSecondDownCheck(false)
            setEmailSecondReq(e)
        } else {
            setEmailErrorMessage("")
            setEmailSecondDownCheck(true)
            setEmailSecondReq(e)
        }
    }

    const handleSelectChange = (e) => {

        const selectedValue = e.target.value;
        if (selectedValue === "직접입력") {
            setIsDirectInput(true)
            setEmailSecondReq("")
            setTimeout(() => {
                emailSecondRef.current.focus();
            }, 0);

        }
        else {
            setIsDirectInput(false);
            setEmailSecondDownCheck(true)
            setEmailSecondReq(selectedValue)
        }

    };

    const nameKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(nameKeyDownCheck){
                userIdClick();
            }

        }
    }

    const userIdKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(userIdKeyDownCheck){
                emailFirstClick();
            }

        }
    }

    const authCodeKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(authCodeKeyDownCheck){
                passwordClick();
            }

        }
    }

    const passwordKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(passwordKeyDownCheck){
                passwordCheckClick();
            }

        }
    }


    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'nearest'
        });
    };

    const handleAuthCodeSend = async () => {
        try{

            if(buttonAuthCodeSend){
                return
            }
            if(!nameCheck){
                scrollToRef(nameRef)
                nameHandler(passwordReq.name)
                return
            }
            if(!emailFirstKeyDownCheck){
                scrollToRef(emailFirstRef)
                emailFirstHandler(emailFirstReq)
                return
            }
            if(!emailSecondKeyDownCheck){
                scrollToRef(emailSecondRef)
                emailSecondHandler(emailSecondReq)
                return
            }
            const fullEmail = isDirectInput ? `${emailFirstReq}@${emailSecondReq}` : `${emailFirstReq}@${emailSecondReq}`;

            const updatedPasswordReq = {
                ...passwordReq, // 기존 필드 유지
                email: fullEmail // email 필드에 fullEmail 값 추가
            };

            const response = await newPassword(updatedPasswordReq)


            if(response.code==="0000"){

                setShowSuccess(true)
                setShowSuccessMessage("인증코드를 전송하였습니다.")
                setButtonAuthCodeSend(true);
                setNameDisabled(true)
                setUserIdDisabled(true)
                setEmailFirstDisabled(true)
                setIsDirectInput(false)
                setSelectEmailDisabled(true)
                setNewPasswordReq({ ...newPasswordReq, email: fullEmail });
            }

        }
        catch (e){
            handleErrorShow(e.message);
            setButtonAuthCodeSend(false);
            setNameDisabled(false)
            setUserIdDisabled(false)
            setEmailFirstDisabled(false)
            setSelectEmailDisabled(false)
        }
    };
    const handleErrorShow =(e)=>{
        setShowErrorMessage(e);
        setShowError(true)
    }

    const handleErrorClose =()=>{
        setShowError(false)
    }

    const handleGoNext = async () => {
        try{

            if(!authCodeKeyDownCheck){
                scrollToRef(authCodeRef)
                nameHandler(newPasswordReq.passwordCode)
                return
            }
            if(!passwordKeyDownCheck){
                scrollToRef(passwordRef)
                passwordHandler(newPasswordReq.password)
                return
            }
            if(!passwordCheckKeyDownCheck){
                scrollToRef(passwordCheckRef)
                passwordCheckHandler(newPasswordReq.passwordCheck)
                return
            }
            const response = await newPasswordSave(newPasswordReq)


            if(response.code==="0000"){
                setShow(true)
            }

        }
        catch (e){
            handleErrorShow(e.message);
        }
    };

    useEffect(() => {

        if(passwordKeyDownCheck && passwordCheckKeyDownCheck){
            if (valuePassword !== valuePasswordCheck) {
                setPasswordCheckErrorMessage("두 개의 비밀번호가 일치하지 않습니다.");
                setPasswordCheckKeyDownCheck(false);
            } else {
                setPasswordCheckErrorMessage("");
                setPasswordCheckKeyDownCheck(true)
            }
        }


    }, [valuePassword, valuePasswordCheck]);

    return (
        <>
            <Modal className="modal_alert1" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>비밀번호 변경완료</h5>
                    <p>비밀번호가 변경되었습니다.<br />
                        변경된 비밀번호로 다시 로그인해주세요.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleClose}>확인</button>
                </Modal.Body>
            </Modal>

            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>인증 실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>

            <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>인증 성공</h5>
                    <p>{showSuccessMessage}</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                </Modal.Body>
            </Modal>
            <PublicHeader />
            <div className="findpwd_content">
                <div className="card findpwd_box">
                    <div className="card-header">
                        <div className="card-title">비밀번호 찾기</div>
                    </div>
                    <div className="card-body">

                        <h2 className="findpwd_text">찾으려는 비밀번호의 계정 정보를 입력해주세요.<br />
                            이메일 인증 후 비밀번호를 재설정 할 수 있습니다.</h2>

                        <div className="findpwd_frm_outer">
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">이름</label>
                                <div class="frm_field">
                                    <input placeholder="이름을 입력해주세요." type="text"
                                           ref={nameRef}
                                           onClick={nameClick}
                                           onChange={(e) => nameHandler(e.target.value)}
                                           onKeyDown={nameKeyDownHandler}
                                           disabled={nameDisabled}
                                           class="form-control" />
                                </div>
                                {nameErrorMessage && (
                                    <div className="error-message">
                                        {nameErrorMessage}
                                    </div>
                                )}
                            </div>
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">아이디</label>
                                <div class="frm_field">
                                    <input placeholder="아이디를 입력해주세요." type="text" class="form-control"
                                    ref={userIdRef}
                                   onClick={userIdClick}
                                   onChange={(e)=>userIdHandler(e.target.value)}
                                   onKeyDown={userIdKeyDownHandler}
                                           disabled={userIdDisabled}
                                    />
                                </div>
                                {userIdErrorMessage && (
                                    <div className="error-message">
                                        {userIdErrorMessage}
                                    </div>
                                )}
                            </div>
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">이메일</label>
                                <div class="frm_field input_email">
                                    <input placeholder="" type="text" class="form-control"
                                           ref={emailFirstRef}
                                           onClick={emailFirstClick}
                                           onChange={(e)=>emailFirstHandler(e.target.value)}
                                           disabled={emailFirstDisabled}
                                    />
                                    <span className="txt_range">@</span>
                                    <input placeholder="" type="text" class="form-control"
                                           ref={emailSecondRef}
                                           value={emailSecondReq}
                                           onClick={emailSecondClick}
                                           onChange={(e) => emailSecondHandler(e.target.value)}
                                           disabled={!isDirectInput}
                                    />
                                    <select className="form-select"
                                            onChange={handleSelectChange}
                                            disabled={selectEmailDisabled}
                                    >
                                        <option value="naver.com">naver.com</option>
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="hanmail.net">hanmail.net</option>
                                        <option value="yahoo.com">yahoo.com</option>
                                        <option value="daum.net">daum.net</option>
                                        <option>직접입력</option>
                                    </select>
                                </div>
                                {emailErrorMessage && (
                                    <div className="error-message">
                                        {emailErrorMessage}
                                    </div>
                                )}
                            </div>
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">인증코드</label>
                                <div class="frm_field">
                                    <input placeholder="인증코드를 입력해주세요." type="number" class="form-control"
                                           ref={authCodeRef}
                                           onClick={authCodeClick}
                                           onKeyDown={authCodeKeyDownHandler}
                                           onChange={(e) => authCodeHandler(e.target.value)}
                                    />
                                    <button className="btn btn-secondary"
                                    onClick={handleAuthCodeSend}

                                    >인증코드전송</button>
                                </div>
                                {authCodeErrorMessage && (
                                    <div className="error-message">
                                        {authCodeErrorMessage}
                                    </div>
                                )}
                            </div>
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">새 비밀번호</label>
                                <div class="frm_field">
                                    <input placeholder="8~16자의 영문, 숫자, 특수문자를 조합하여 사용하세요" type="password" class="form-control"
                                       ref={passwordRef}
                                       onClick={passwordClick}
                                           onKeyDown={passwordKeyDownHandler}
                                       onChange={(e) => passwordHandler(e.target.value)}
                                           value={valuePassword}
                                    />
                                </div>
                                {passwordErrorMessage && (
                                    <div className="error-message">
                                        {passwordErrorMessage}
                                    </div>
                                )}
                            </div>
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">비밀번호 확인</label>
                                <div class="frm_field">
                                    <input placeholder="8~16자의 영문, 숫자, 특수문자를 조합하여 사용하세요" type="password" class="form-control"
                                           ref={passwordCheckRef}
                                           onClick={passwordCheckClick}
                                           onChange={(e) => passwordCheckHandler(e.target.value)}
                                           value={valuePasswordCheck}
                                    />
                                </div>
                                {passwordCheckErrorMessage && (
                                    <div className="error-message">
                                        {passwordCheckErrorMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-secondary"
                        onClick={handleGoBack}
                        >돌아가기</button>
                        <button className="btn btn-primary ms-10px"
                        onClick={handleGoNext}
                        >변경하기</button>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
    );
}