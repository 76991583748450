import { useState, useEffect, useRef } from "react";
import { PublicFooter } from "../base/PublicFooter"
import { PublicHeader } from "../base/PublicHeader"
import Modal from 'react-bootstrap/Modal';
import { useNavigate,useLocation } from "react-router-dom";
import {
    joinMemberIdCheck,
    joinMemberEmailCheck,
    joinMemberShip,
    joinMemberShipEmailSend,
    joinMemberCompanyNumberCheck, newPasswordSave, fileUpload,
} from "../../api/auth"

export const MemberShipRegister = () => {
    const navigate = useNavigate(); // useNavigate 훅 사용
    const location = useLocation();
    const { userInfo } = location.state || {};
    const [userTypeCheck,setUserTypeCheck] = useState(false);
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [buttonAuthCodeSend, setButtonAuthCodeSend] = useState(false)


    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [nameKeyDownCheck, setNameKeyDownCheck] = useState(false);
    const nameRef = useRef(null);


    const [userIdErrorMessage,setUserIdErrorMessage] = useState("");
    const [userIdKeyDownCheck, setUserIdKeyDownCheck] = useState(false)
    const userIdRef = useRef(null);
    const [userIdCheck, setUserIdCheck] = useState(false) // 중복확인버튼
    const [userId,setUserId] = useState("")

    const [passwordErrorMessage,setPasswordErrorMessage] = useState("");
    const [passwordKeyDownCheck, setPasswordKeyDownCheck] = useState(false)
    const passwordRef = useRef(null);
    const [valuePassword,setValuePassword] = useState("");

    const [passwordCheckErrorMessage,setPasswordCheckErrorMessage] = useState("");
    const [passwordCheckKeyDownCheck, setPasswordCheckKeyDownCheck] = useState(false)
    const passwordCheckRef = useRef(null);
    const [valuePasswordCheck,setValuePasswordCheck] = useState("");
    const [isPasswordTouched, setIsPasswordTouched] = useState(false);
    const [isPasswordCheckTouched, setIsPasswordCheckTouched] = useState(false);

    const cellphoneRef = useRef(null);


    const [cellphoneErrorMessage,setCellphoneErrorMessage] = useState("");
    const [cellphone,setCellphone] = useState("")
    const [cellphoneCheck, setCellphoneCheck] = useState(false)

    const [cellphone2,setCellphone2] = useState("")
    const cellphoneRef2 = useRef(null);
    const [cellphoneCheck2, setCellphoneCheck2] = useState(false)

    const [cellphone3,setCellphone3] = useState("")
    const cellphoneRef3 = useRef(null);
    const [cellphoneCheck3, setCellphoneCheck3] = useState(false)

    const [emailErrorMessage,setEmailErrorMessage] = useState("");
    const [emailFirstKeyDownCheck, setEmailFirstDownCheck] = useState(false)
    const [emailFirstReq,setEmailFirstReq] = useState("")
    const emailFirstRef = useRef(null);

    const [emailSecondKeyDownCheck, setEmailSecondDownCheck] = useState(false)
    const [emailSecondReq,setEmailSecondReq] = useState("naver.com")
    const emailSecondRef = useRef(null);


    const [isDirectInput, setIsDirectInput] = useState(false); // 이메일 Second disabled
    const [nameDisabled, setNameDisabled] = useState(false)
    const [userIdDisabled, setUserIdDisabled] = useState(false)
    const [emailFirstDisabled, setEmailFirstDisabled] = useState(false)
    const [selectEmailDisabled, setSelectEmailDisabled] = useState(false)

    const [authCodeErrorMessage,setAuthCodeErrorMessage] = useState("");
    const [authCodeKeyDownCheck, setAuthCodeKeyDownCheck] = useState(false)
    const authCodeRef = useRef(null);
    const [authCodeDisabled, setAuthCodeDisabled] = useState(true)
    const [authCode,setAuthCode] = useState("")
    const [sendAttempts, setSendAttempts] = useState(0); // 전송 시도 횟수
    const [timer, setTimer] = useState(0); // 타이머 값
    const [isTimerActive, setIsTimerActive] = useState(false); // 타이머가 활성화 여

    const [kindReq,setKindReq] = useState("Handler/Transporter")
    const kindRef = useRef(null)

    const [companyNumberErrorMessage,setCompanyNumberErrorMessage] = useState("");
    const [companyNumber,setCompanyNumber] = useState("")
    const [companyNumberKeyDownCheck, setCompanyNumberKeyDownCheck] = useState(false)
    const companyNumberRef = useRef(null)
    const [companyNumberCheck,setCompanyNumberCheck] = useState(false)

    const [companyNumber2,setCompanyNumber2] = useState("")
    const [companyNumberKeyDownCheck2, setCompanyNumberKeyDownCheck2] = useState(false)
    const companyNumberRef2 = useRef(null)


    const [companyNumber3,setCompanyNumber3] = useState("")
    const [companyNumberKeyDownCheck3, setCompanyNumberKeyDownCheck3] = useState(false)
    const companyNumberRef3 = useRef(null)

    const companyNumberFileRef = useRef(null);
    const permitFileRef = useRef(null);

    const [companyFileCheck,setCompanyFileCheck] = useState(false)
    const [permitFileCheck,setPermitFileCheck] = useState(false)


    const [companyNumberFileErrorMessage,setCompanyNumberFileErrorMessage] = useState("");
    const [permitFileErrorMessage,setPermitFileErrorMessage] = useState("");

    const [registerFileReq,setRegisterFileReq] = useState({
        companyNumberFile :[],
        permitFile : [],
    })

    const [registerReq, setRegisterReq] = useState({
        userId: "",
        password: "",
        passwordCheck: "",
        name:"",
        email: "",
        cellphone:"",
        kind: kindReq,
        joinCode:"",
        companyNumber:"",
        idx:"", //emailCheckRepository 기본키
        id:"",
    })

    // const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSuccessClose = () => {

        setShowSuccess(false);

        setTimeout(() => {
            authCodeRef.current.focus()
        }, 0);

    }

    const handleClose = () => {
        setShow(false)
        navigate("/login")
    }

    const scrollToRef = (ref) => {
        ref.current.focus()
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });
    };

    const nameClick = () => {
        scrollToRef(nameRef)
    }

    const userIdClick = () => {
        scrollToRef(userIdRef)
    }

    const passwordClick = () => {
        scrollToRef(passwordRef)
    }

    const passwordCheckClick = () => {
        scrollToRef(passwordCheckRef)
    }
    const cellphoneClick = () => {
        scrollToRef(cellphoneRef)
    }

    const cellphone2Click = () => {
        scrollToRef(cellphoneRef2)
    }

    const cellphone3Click = () => {
        scrollToRef(cellphoneRef3)
    }

    const emailFirstClick = () => {
        scrollToRef(emailFirstRef)
    }

    const emailSecondClick = () => {
        scrollToRef(emailSecondRef)
    }
    const authCodeClick = () => {
        scrollToRef(authCodeRef)
    }

    const kindClick = () => {
        scrollToRef(kindRef)
    }

    const companyNumberClick = () => {
        scrollToRef(companyNumberRef)
    }

    const companyNumberClick2 = () => {
        scrollToRef(companyNumberRef2)
    }

    const companyNumberClick3 = () => {
        scrollToRef(companyNumberRef3)
    }

    const userIdFindCheck = async ()  => {

        try{

            if(!userIdKeyDownCheck){
                scrollToRef(userIdRef)
                userIdHandler(userId)
                return
            }


            const response = await joinMemberIdCheck(registerReq)

            if(response.code==="0000"){
                setUserIdCheck(true)
                setUserIdErrorMessage("사용가능한 아이디입니다.");
                setUserIdKeyDownCheck(true);
                setRegisterReq({...registerReq, userId:userId})
                passwordClick()
            }


        }
        catch (e){
            userIdClick()
            setUserIdErrorMessage("이미 사용중인 아이디입니다.");
            setUserIdKeyDownCheck(false);
            setUserIdCheck(false);
        }
    }

    const userIdKeyDownHandler=(e) =>{
        if (e.key === "Enter") {


            if(!userIdKeyDownCheck){
                scrollToRef(userIdRef)
                userIdHandler(userId)
                return
            }

            if(!userIdCheck){
                scrollToRef(userIdRef)
                setUserIdErrorMessage("로그인 중복확인을 진행해주세요.");
                return
            }


            if(!buttonAuthCodeSend){
                setUserIdErrorMessage("로그인 중복확인을 진행해주세요.");
                return
            }

            if(buttonAuthCodeSend){
                passwordClick();
            }

        }
    }

    const nameKeyDownHandler=(e) =>{
        if (e.key === "Enter") {

            if(userTypeCheck){
                cellphoneClick();
            }

            if(nameKeyDownCheck){
                userIdClick();
            }

        }
    }

    const passwordKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(passwordKeyDownCheck){
                passwordCheckClick();
            }

        }
    }

    const passwordCheckKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(passwordCheckKeyDownCheck){
                cellphoneClick();
            }

        }
    }


    const cellphoneCheckKeyDownHandler=(e) =>{
        if (e.key === "Enter") {

            if(userTypeCheck){
                authCodeClick()
            }
            if(cellphoneCheck&& cellphoneCheck2&& cellphoneCheck3){
                emailFirstClick();
            }

        }
    }

    const emailFirstKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(!isDirectInput){
                if(!buttonAuthCodeSend){
                    setEmailErrorMessage("인증코드 전송을 눌러주세요.")
                    return
                }
                authCodeClick();
            }

        }
    }

    const authCodeKeyDownHandler=(e) =>{
        if (e.key === "Enter") {
            if(authCodeKeyDownCheck){
                companyNumberClick();
            }

        }
    }



    useEffect(() => {
        window.scrollTo(0, 0);

        if(userInfo){
            setUserTypeCheck(true)
            setRegisterReq((prevState) => ({
                ...prevState,
                // name: userInfo.name || "",
                email: userInfo.email|| "",
                id: userInfo.id || "",
                type:userInfo.type || "",
            }));
            // email에서 '@' 앞부분만 잘라서 emailFirstReq에 설정
            const emailBeforeAt = userInfo.email.split('@')[0];
            setEmailFirstReq(emailBeforeAt); // email 앞부분을 emailFirstReq에 저장
            setValuePassword("********")
            setValuePasswordCheck("********")
            if(userInfo.type==="kakao"){
                setUserId("kakao_"+userInfo.id)
            }
            else{
                setUserId("naver_"+userInfo.id)
            }

        }
        else{
            nameRef.current.focus()
        }
    }, []);

    const nameHandler = (e) => {
        const nameRegex = /^[a-zA-Z가-힣]+$/;

        let minLength = 2;
        let maxLength = 8;

        if (e === "") {
            setNameErrorMessage("이름을 입력해주세요.")
            setNameKeyDownCheck(false)
        }
        else if (e.length < minLength || e.length > maxLength){
            setNameErrorMessage("이름은 2글자 이상 8자 이하입니다.")
            setNameKeyDownCheck(false)
        }
        else if (!nameRegex.test(e)) {
            setNameErrorMessage("영문자 및 한글만 가능합니다.");
            setNameKeyDownCheck(false)
        }
        else {
            setRegisterReq({...registerReq, name:e})
            setNameErrorMessage("");
            setNameKeyDownCheck(true)
        }
    };

    const userIdHandler = (e) => {

        const userIdRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]+$/;
        // (?=.*[a-zA-Z]): 영어 대소문자가 반드시 포함되어야 함을 의미합니다.
        // (?=.*\d): 숫자가 반드시 포함되어야 함을 의미합니다.
        //     [a-zA-Z0-9]+: 영어 대소문자와 숫자로만 구성될 수 있음을 의미합니다.
        let minLength = 5;
        setUserId(e)
        if (e === "") {
            setUserIdErrorMessage("아이디를 입력해주세요.")
            setUserIdKeyDownCheck(false)
            setUserIdCheck(false)

        }
        else if (e.length < minLength){
            setUserIdErrorMessage("아이디는 5글자 이상 입니다.")
            setUserIdKeyDownCheck(false)
            setUserIdCheck(false)

        }
        else if (!userIdRegex.test(e)) {
            setUserIdErrorMessage("아이디는 영어와 숫자조합으로만 가능합니다.");
            setUserIdKeyDownCheck(false);
            setUserIdCheck(false);

        }
        else {
            setRegisterReq({...registerReq, userId: e})
            setUserIdErrorMessage("");
            setUserIdKeyDownCheck(true)

        }
    }

    useEffect(() => {

        if(userIdKeyDownCheck && userIdCheck){
            setUserIdCheck(false)
            setUserIdKeyDownCheck(false)

        }



    }, [userId]);

    const passwordHandler = (e) => {
        setValuePassword(e);
        setRegisterReq({ ...registerReq, password: e });
        setIsPasswordTouched(true); // 페이지 렌더링 시 문구 표시 제어
    };

    const passwordCheckHandler = (e) => {
        setValuePasswordCheck(e);
        setRegisterReq({ ...registerReq, passwordCheck: e });
        setIsPasswordCheckTouched(true); // 페이지 렌더링 시 문구 표시 제어
    };

    // 비밀번호와 비밀번호 확인 일치 및 정규식 검사
    useEffect(() => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`{}\[\]:;'"<>,.?\/\\|-])[a-zA-Z\d!@#$%^&*()_+~`{}\[\]:;'"<>,.?\/\\|-]{8,16}$/;

        let isPasswordValid = false; // 비밀번호 문구 제어용도
        let isPasswordCheckValid = false; // 비밀번호 확인 문구 제어용도

        if (isPasswordTouched) {
            if (valuePassword) {
                if (valuePassword.length < 8 || valuePassword.length > 16) {
                    setPasswordErrorMessage("새 비밀번호는 8~16자 입니다.");
                    setPasswordKeyDownCheck(false);
                } else if (!passwordRegex.test(valuePassword)) {
                    setPasswordErrorMessage("비밀번호는 영문, 숫자, 특수문자를 포함해야 합니다.");
                    setPasswordKeyDownCheck(false);
                } else {
                    setPasswordErrorMessage("");
                    setPasswordKeyDownCheck(true);
                    isPasswordValid = true; // 비밀번호가 유효한 경우 true
                }
            } else {
                setPasswordErrorMessage("비밀번호를 입력해주세요.");
                setPasswordKeyDownCheck(false);
            }
        }

        if (isPasswordCheckTouched) {
            if (valuePasswordCheck) {
                if (valuePasswordCheck.length < 8 || valuePasswordCheck.length > 16) {
                    setPasswordCheckErrorMessage("비밀번호 확인은 8~16자 입니다.");
                    setPasswordCheckKeyDownCheck(false);
                } else if (!passwordRegex.test(valuePasswordCheck)) {
                    setPasswordCheckErrorMessage("비밀번호 확인은 영문, 숫자, 특수문자를 포함해야 합니다.");
                    setPasswordCheckKeyDownCheck(false);
                } else {
                    setPasswordCheckErrorMessage("");
                    setPasswordCheckKeyDownCheck(true);
                    isPasswordCheckValid = true; // 비밀번호 확인이 유효한 경우 true 
                }
            } else {
                setPasswordCheckErrorMessage("비밀번호 확인을 입력해주세요.");
                setPasswordCheckKeyDownCheck(false);
            }
        }

        // 비밀번호와 비밀번호 확인이 모두 유효할 때만 일치 여부를 검사
        if (isPasswordValid && isPasswordCheckValid) {
            if (valuePassword !== valuePasswordCheck) {
                setPasswordCheckErrorMessage("두 개의 비밀번호가 일치하지 않습니다.");
                setPasswordCheckKeyDownCheck(false);
            } else {
                setPasswordCheckErrorMessage("");
                setPasswordCheckKeyDownCheck(true);
            }
        }
    }, [valuePassword, valuePasswordCheck, isPasswordTouched, isPasswordCheckTouched]);

    const cellphoneHandler = (e) => {

        const length=3
        // 숫자만 입력 가능하게 필터링
        e = e.replace(/\D/g, '');


        if(e===""){
            setCellphoneErrorMessage("휴대폰번호를 입력해주세요.");
            setCellphoneCheck(false);
        }
        else if (e.length !== length){
            setCellphoneErrorMessage("휴대폰번호 형식이 올바르지 않습니다.")
            setCellphoneCheck(false)
        }
        else {
            setCellphoneErrorMessage("");
            cellphoneRef2.current.focus();
            setCellphoneCheck(true)
            setCellphone2("");
            setCellphone3("")
        }
        setCellphone(e)
    };

    const cellphoneHandler2 = (e) => {

        let length=3
        // 숫자만 입력 가능하게 필터링
        e = e.replace(/\D/g, '');

        if(cellphoneRef.current.value==="010"){
            length=4
        }

        if(e===""){
            setCellphoneErrorMessage("휴대폰번호를 입력해주세요.");
            setCellphoneCheck2(false);
        }
        else if (e.length !== length){
            setCellphoneErrorMessage("휴대폰번호 형식이 올바르지 않습니다.")
            setCellphoneCheck2(false)
        }
        else{
            setCellphoneErrorMessage("");
            cellphoneRef3.current.focus();
            setCellphoneCheck2(true)
        }
        setCellphone2(e)


    };

    const cellphoneHandler3 = (e) => {
        const length=4
        // 숫자만 입력 가능하게 필터링
        e = e.replace(/\D/g, '');

        if(e===""){
            setCellphoneErrorMessage("휴대폰번호를 입력해주세요.");
            setCellphoneCheck3(false);
        }
        else if (e.length !== length){
            setCellphoneErrorMessage("휴대폰번호 형식이 올바르지 않습니다.")
            setCellphoneCheck3(false)
        }
        else {
            setCellphoneErrorMessage("");
            setCellphoneCheck3(true)
        }
        setCellphone3(e)
    };

    const emailFirstHandler = (e) => {
        const alphanumericRegex = /^[a-zA-Z0-9]*$/; // 영어와 숫자만 허용하는 정규식

        if(e===""){
            setEmailErrorMessage("이메일을 입력해주세요.")
            setEmailFirstDownCheck(false)
            setEmailFirstReq(e)
        }
        else if (!alphanumericRegex.test(e)) {
            setEmailErrorMessage("이메일 형식이 아닙니다.")
            setEmailFirstDownCheck(false)
            setEmailFirstReq(e)
        } else {
            setEmailErrorMessage("")
            setEmailFirstDownCheck(true)
            setEmailFirstReq(e)
            setEmailSecondDownCheck(true)
        }
    }

    const emailSecondHandler = (e) => {

        const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // 도메인 형식 정규식

        if(e===""){
            setEmailErrorMessage("이메일 입력해주세요.")
            setEmailSecondDownCheck(false)
            setEmailSecondReq(e)
        }
        else if (!domainRegex.test(e)) {
            setEmailErrorMessage("이메일 형식이 아닙니다.")
            setEmailSecondDownCheck(false)
            setEmailSecondReq(e)
        } else {
            setEmailErrorMessage("")
            setEmailSecondDownCheck(true)
            setEmailSecondReq(e)
        }
    }

    const authCodeHandler = (e) => {

        const length = 6;

        // 숫자만 입력 가능하게 필터링
        e = e.replace(/\D/g, '');

        if (e === "") {
            setAuthCodeErrorMessage("인증코드 입력해주세요.")
            setAuthCodeKeyDownCheck(false)
        }
        else if (e.length !== length){
            setAuthCodeErrorMessage("인증코드가 올바르지 않습니다.")
            setAuthCodeKeyDownCheck(false)
        }
        else {
            setRegisterReq({...registerReq, joinCode:e})
            setAuthCodeErrorMessage("");
            setAuthCodeKeyDownCheck(true)
        }
        setAuthCode(e)
    }

    const companyNumberFirstHandler = (e) => {

        const length =3
        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');

        if (e === "") {
            setCompanyNumberErrorMessage("사업자 등록번호를 입력해주세요.")
            setCompanyNumberKeyDownCheck(false)
        }
        else if (e.length !== length){
            setCompanyNumberErrorMessage("사업자 등록번호가 올바르지 않습니다.")
            setCompanyNumberKeyDownCheck(false)
        }
        else {
            setCompanyNumberErrorMessage("");
            setCompanyNumberKeyDownCheck(true)
            companyNumberRef2.current.focus()
        }
        setCompanyNumber(e);
    }

    const companyNumberSecondHandler = (e) => {

        const length =2
        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');

        if (e === "") {
            setCompanyNumberErrorMessage("사업자 등록번호를 입력해주세요.")
            setCompanyNumberKeyDownCheck2(false)
        }
        else if (e.length !== length){
            setCompanyNumberErrorMessage("사업자 등록번호가 올바르지 않습니다.")
            setCompanyNumberKeyDownCheck2(false)
        }
        else {
            setCompanyNumberErrorMessage("");
            setCompanyNumberKeyDownCheck2(true)
            companyNumberRef3.current.focus()
        }
        setCompanyNumber2(e);
    }

    const companyNumberThirdHandler = (e) => {

        const length =5
        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');

        if (e === "") {
            setCompanyNumberErrorMessage("사업자 등록번호를 입력해주세요.")
            setCompanyNumberKeyDownCheck3(false)
        }
        else if (e.length !== length){
            setCompanyNumberErrorMessage("사업자 등록번호가 올바르지 않습니다.")
            setCompanyNumberKeyDownCheck3(false)
        }
        else {
            setCompanyNumberErrorMessage("");
            setCompanyNumberKeyDownCheck3(true)
        }
        setCompanyNumber3(e);
    }

    const handleSelectChange = (e) => {

        const selectedValue = e.target.value;
        if (selectedValue === "직접입력") {
            setIsDirectInput(true)
            setEmailSecondReq("")
            setTimeout(() => {
                emailSecondRef.current.focus();
            }, 0);

        }
        else {
            setIsDirectInput(false);
            setEmailSecondDownCheck(true)
            setEmailSecondReq(selectedValue)
        }

    };

    const handleSelectKindChange = (e) => {

        const selectedValue = e.target.value;

        setKindReq(selectedValue)

        switch (selectedValue){
            case "Handler/Transporter": setRegisterReq({...registerReq,kind:"Handler/Transporter"})
                break
            case "Transporter": setRegisterReq({...registerReq,kind:"Transporter"})
                break
            case "Handler": setRegisterReq({...registerReq,kind:"Handler"})
                break
            case "Generator": setRegisterReq({...registerReq,kind:"Generator"})
                break
            default:
                setRegisterReq({...registerReq,kind:"Handler/Transporter"})
        }

    };




    const handleGoBack = () => {
        navigate(-1);
    };

    const handleAuthCodeSend = async () => {
        try{

            if(buttonAuthCodeSend){
                return
            }
            if(!nameKeyDownCheck){
                scrollToRef(nameRef)
                nameHandler(registerReq.name)
                return
            }

            if(!userTypeCheck){



            if(!userIdKeyDownCheck){
                scrollToRef(userIdRef)
                userIdHandler(userId)
                return
            }

            if(!userIdCheck){
                scrollToRef(userIdRef)
                setUserIdErrorMessage("로그인 중복확인을 진행해주세요.");
                return
            }

            if(!passwordKeyDownCheck){
                scrollToRef(passwordRef)
                passwordHandler(valuePassword)
                return
            }

            if(!passwordCheckKeyDownCheck){
                scrollToRef(passwordCheckRef)
                passwordCheckHandler(valuePasswordCheck)
                return
            }
            if(!emailFirstKeyDownCheck){
                scrollToRef(emailFirstRef)
                emailFirstHandler(emailFirstReq)
                return
            }
            if(!emailSecondKeyDownCheck){
                scrollToRef(emailSecondRef)
                emailSecondHandler(emailSecondReq)
                return
            }
            }
            const fullEmail = isDirectInput ? `${emailFirstReq}@${emailSecondReq}` : `${emailFirstReq}@${emailSecondReq}`;

            const updatedRegisterReq = {
                ...registerReq, // 기존 필드 유지
                email: userTypeCheck ? userInfo.email : fullEmail, // email 필드에 조건에 맞는 값 추가
            };


            const response = await joinMemberEmailCheck(updatedRegisterReq)
            if(response.code==="0000"){
                const responseEmail = await joinMemberShipEmailSend(updatedRegisterReq)
                    setShowSuccess(true)
                    setShowSuccessMessage("인증코드를 전송하였습니다.")
                    setButtonAuthCodeSend(true);
                    setEmailErrorMessage("")
                    setAuthCodeDisabled(false)

                    setIsDirectInput(false);
                    setSendAttempts((prev) => prev + 1); // 전송 횟수 증가
                    startTimer(3);
                    setRegisterReq({
                        ...registerReq,
                        email: fullEmail,
                        idx: responseEmail.result.emailCheckRepositoryIdx // idx 필드에 값 추가
                    });
            }
        }
        catch (e){
            handleErrorShow(e.message);
            setButtonAuthCodeSend(false);
            setNameDisabled(false)
            setUserIdDisabled(false)
            setEmailFirstDisabled(false)
            setSelectEmailDisabled(false)
        }
    };

    // 타이머 시작 함수
    const startTimer = (duration) => {
        setIsTimerActive(true);
        setTimer(duration);

        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(interval);
                    setIsTimerActive(false);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };


    const handleErrorShow =(e)=>{
        setShowErrorMessage(e);
        setShowError(true)
    }

    const handleErrorClose =()=>{
        setShowError(false)
    }

    const companyNumberFindCheck = async ()  => {

        try{

            if(!companyNumberKeyDownCheck){
                scrollToRef(companyNumberRef)
                companyNumberFirstHandler(companyNumber)
                return
            }

            if(!companyNumberKeyDownCheck2){
                scrollToRef(companyNumberRef2)
                companyNumberSecondHandler(companyNumber2)
                return
            }

            if(!companyNumberKeyDownCheck3){
                scrollToRef(companyNumberRef3)
                companyNumberThirdHandler(companyNumber3)
                return
            }

            const fullCompanyNumber = companyNumber+companyNumber2+companyNumber3

            console.log(fullCompanyNumber)
            const updatedRegisterReq = {
                ...registerReq, //기존 필드 유지
                companyNumber: fullCompanyNumber
            };

            const response = await joinMemberCompanyNumberCheck(updatedRegisterReq)
            if(response.code==="0000"){
                setCompanyNumberCheck(true)
                setCompanyNumberErrorMessage("사용가능한 사업자등록번호입니다.");
                setRegisterReq({...registerReq, companyNumber:fullCompanyNumber})
            }
        }
        catch (e){
            companyNumberClick()
            setCompanyNumberErrorMessage("이미 사용중인 사업자등록번호입니다.");
            setCompanyNumberCheck(false)
        }
    }

    useEffect(() => {
        if(buttonAuthCodeSend){
            setButtonAuthCodeSend(false)
        }
    }, [emailFirstReq,emailSecondReq]);

    useEffect(() => {

        setCompanyNumberCheck(false)

    }, [companyNumber,companyNumber2, companyNumber3]);

    useEffect(() => {
        if (cellphone.length===3 && cellphoneCheck2 && cellphoneCheck3) {
            if (cellphone[0] !== '0') {
                setCellphoneErrorMessage("휴대폰번호 형식이 올바르지 않습니다.")
                setCellphoneCheck(false)
            } else {
                setCellphoneErrorMessage("")
                setCellphoneCheck(true)
            }
        }

    }, [cellphone,cellphone2,cellphone3]);


    const handleGoNext = async () => {
        try{


                if(!nameKeyDownCheck){
                    scrollToRef(nameRef)
                    nameHandler(registerReq.name)
                    return
                }
            if(!userTypeCheck){
                if(!userIdCheck){
                    scrollToRef(userIdRef)
                    userIdHandler(registerReq.userId)
                    return
                }
                if(!passwordKeyDownCheck){
                    scrollToRef(passwordRef)
                    passwordHandler(registerReq.password)
                    return
                }
                if(!passwordCheckKeyDownCheck){
                    scrollToRef(passwordCheckRef)
                    passwordCheckHandler(registerReq.passwordCheck)
                    return
                }

                if(!emailFirstKeyDownCheck){
                    scrollToRef(emailFirstRef)
                    emailFirstHandler(emailFirstReq)
                    return
                }

                if(!authCodeKeyDownCheck){
                    scrollToRef(authCodeRef)
                    authCodeHandler(registerReq.joinCode)
                    return
                }
            }

            if(!companyNumberCheck){
                scrollToRef(companyNumberRef)
                setCompanyNumberErrorMessage("사업자등록번호 중복 확인을 눌러주세요.")
                return
            }

            if(!companyFileCheck){
                scrollToRef(companyNumberFileRef)
                companyNumberFileHandler(registerFileReq.companyNumberFile)
                return
            }

            // if(!permitFileCheck){
            //     scrollToRef(permitFileRef)
            //     companyNumberFileHandler(registerFileReq.permitFile)
            //     return
            // }

            const fullCellphone=cellphone+cellphone2+cellphone3

            const updatedRegisterReq = {
                ...registerReq,
                ...(userInfo || {}),  // userInfo가 존재할 때만 덮어씀
                cellphone: fullCellphone,
                type: userInfo?.type || "normal"
            };

            const response = await joinMemberShip(updatedRegisterReq)


            if(response.code==="0000"){
                setShow(true)
                register()
            }

        }
        catch (e){
            handleErrorShow(e.message);
        }
    };

    const companyNumberFileClick = () => {
        if (companyNumberFileRef.current) {
            companyNumberFileRef.current.click();
        }
    };

    const permitFileClick = () => {
        // 버튼 클릭 시 파일 입력 요소 클릭
        if (permitFileRef.current) {
            permitFileRef.current.click();
        }
    };

    const companyNumberFileHandler = (event) => {
        const files = event?.target?.files; // 선택된 파일들

        if(files==null){
            setCompanyNumberFileErrorMessage("사업자등록증은 필수입니다.")
            return
        }
        const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링

        if (validFiles.length === files.length) {
            setRegisterFileReq((prev) => ({
                ...prev,
                companyNumberFile: validFiles, // 선택된 모든 유효한 파일을 배열로 설정
            }));
            setCompanyNumberFileErrorMessage("")
            setCompanyFileCheck(true)
        } else {
            setCompanyNumberFileErrorMessage("이미지 파일 및 PDF만 가능합니다.")
            setCompanyFileCheck(false)
        }
    };

    // const permitFileHandler = (event) => {
    //     const files = event?.target?.files; // 선택된 파일들
    //
    //     if(files==null){
    //         setPermitFileErrorMessage("허가증은 필수입니다.")
    //         return
    //     }
    //     const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링
    //
    //     if (validFiles.length === files.length) {
    //         setRegisterFileReq((prev) => ({
    //             ...prev,
    //             permitFile: validFiles, // 선택된 모든 유효한 파일을 배열로 설정
    //         }));
    //         setPermitFileErrorMessage("")
    //         setPermitFileCheck(true)
    //     } else {
    //         setPermitFileErrorMessage("이미지 파일 및 PDF만 가능합니다.")
    //         setPermitFileCheck(false)
    //     }
    // };

    const isValidFileType = (file) => {
        const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };

    const register = async () => {

        const { companyNumberFile, permitFile } = registerFileReq;

        if(!companyFileCheck){
            scrollToRef(companyNumberFileRef)
            companyNumberFileHandler(registerFileReq.companyNumberFile)
            return
        }

        // if(!permitFileCheck){
        //     scrollToRef(permitFileRef)
        //     permitFileHandler(registerFileReq.permitFile)
        //     return
        // }

        // if (companyNumberFile.length === 0
        //     // || permitFile.length === 0
        // )
        // {
        //     console.log("모든 파일이 선택되지 않았습니다."); // 파일이 없을 경우 처리
        //     return;
        // }

        // fileId 배열 생성
        const fileIds = [
            ...Array(companyNumberFile.length).fill(1), // companyNumberFile의 각 파일에 대해 1로 설정
            // ...Array(permitFile.length).fill(2), // permitFile의 각 파일에 대해 2로 설정
        ];

        // data 객체 생성
        const data = {
            files: [...companyNumberFile,
                // ...permitFile
            ], // 선택된 파일들을 배열로 설정
            // files: [registerFileReq.companyNumberFile, registerFileReq.permitFile], // 선택된 파일들을 배열로 설정
            userId: registerReq.userId,
            filesId: fileIds, // fileId 배열 설정
            type: 'register',
        };
        try {
            const response = await fileUpload(data); // data 객체 전송
        } catch (e) {
            handleErrorShow(e.message);
        }
    };
    return (
        <>

            <PublicHeader />
            <div className="userinfo_content">
                <div className={`card ${userTypeCheck ? 'userinfo_auth_box' : 'userinfo_box'}`}>
                    <div className="card-header">
                        <div className="card-title">사용자 정보 입력</div>
                    </div>
                    <div className="card-body">
                        <div className="stepper_wrapper">
                            <div className="stepper_line"></div>
                            <ul>
                                <li className="active">
                                    <div className="stepper_dot dot_1"></div>
                                    <div className="stepper_text">약관동의</div>
                                </li>
                                <li className="active">
                                    <div className="stepper_dot dot_2"></div>
                                    <div className="stepper_text">사용자정보 입력</div>
                                </li>
                                <li>
                                    <div className="stepper_dot dot_3"></div>
                                    <div className="stepper_text">회원가입완료</div>
                                </li>
                            </ul>
                        </div>
                        <h2 className="userinfo_text">서비스 이용 시 필요한 정보를 입력해주세요.</h2>

                        <div className="userinfo_frm_outer">


                            <div class="frm_row">
                                <label for="" class="form-label frm_label">이름</label>
                                <div class="frm_field">
                                    <input placeholder="이름을 입력해주세요." type="text" class="form-control"
                                        ref={nameRef}
                                        onClick={nameClick}
                                        onChange={(e) => nameHandler(e.target.value)}
                                           onKeyDown={nameKeyDownHandler}
                                    />
                                </div>
                                {nameErrorMessage && (
                                    <div className="error-message">
                                        {nameErrorMessage}
                                    </div>
                                )}
                            </div>

                            <div class="frm_row">
                                <label for="" class="form-label frm_label">아이디</label>
                                <div class="frm_field">
                                    <input placeholder="아이디를 입력해주세요." type="text" class="form-control"
                                    ref={userIdRef}
                                   onClick={userIdClick}
                                           disabled={userTypeCheck}
                                   onChange={(e)=>userIdHandler(e.target.value)}
                                   onKeyDown={userIdKeyDownHandler}
                                           value={userId}
                                    />
                                    { !userTypeCheck &&(
                                    <>
                                    <button className="btn btn-secondary"
                                    onClick={userIdFindCheck}
                                    >중복확인</button>
                                    </> )}
                                </div>
                                {userIdErrorMessage && (
                                    <div className={userIdCheck ? "success-message" : "error-message"}>
                                        {userIdErrorMessage}
                                    </div>
                                )}
                            </div>


                            {!userTypeCheck && (
                                <>
                            <div class="frm_row">
                                <label for="" class="form-label frm_label">비밀번호</label>
                                <div class="frm_field">
                                    <input placeholder="8~16자의 영문, 숫자, 특수문자를 조합하여 사용하세요" type="password" class="form-control"
                                    ref={passwordRef}
                                   onClick={passwordClick}
                                           onKeyDown={passwordKeyDownHandler}
                                           onChange={(e) => passwordHandler(e.target.value)}
                                           value={valuePassword}
                                           disabled={userTypeCheck}
                                    />
                                </div>
                                {passwordErrorMessage && (
                                    <div className="error-message">
                                        {passwordErrorMessage}
                                    </div>
                                )}
                            </div>


                            <div class="frm_row">
                                <label for="" class="form-label frm_label">비밀번호 확인</label>
                                <div class="frm_field">
                                    <input placeholder="8~16자의 영문, 숫자, 특수문자를 조합하여 사용하세요" type="password" class="form-control"
                                    ref={passwordCheckRef}
                                   onClick={passwordCheckClick}
                                           onKeyDown={passwordCheckKeyDownHandler}
                                   onChange={(e) => passwordCheckHandler(e.target.value)}
                                   value={valuePasswordCheck}
                                           disabled={userTypeCheck}
                                    />
                                </div>
                                {passwordCheckErrorMessage && (
                                    <div className="error-message">
                                        {passwordCheckErrorMessage}
                                    </div>
                                )}
                            </div>
                                </>
                            )}


                            <div class="frm_row">
                                <label for="" class="form-label frm_label">휴대폰</label>
                                <div class="frm_field input_phone">
                                    <input placeholder="" type="text" class="form-control"
                                    ref={cellphoneRef}
                                   onChange={(e)=>cellphoneHandler(e.target.value)}
                                           onClick={cellphoneClick}
                                           value={cellphone}
                                           maxLength={3}
                                    />
                                    <span className="txt_range">-</span>
                                    <input placeholder="" type="text" class="form-control"
                                           ref={cellphoneRef2}
                                           onChange={(e)=>cellphoneHandler2(e.target.value)}
                                           onClick={cellphone2Click}
                                           value={cellphone2}
                                           maxLength={4}

                                    />
                                    <span className="txt_range">-</span>
                                    <input placeholder="" type="text" class="form-control"
                                           ref={cellphoneRef3}
                                           onChange={(e)=>cellphoneHandler3(e.target.value)}
                                           onClick={cellphone3Click}
                                           value={cellphone3}
                                           maxLength={4}
                                           onKeyDown={cellphoneCheckKeyDownHandler}
                                    />
                                </div>
                                {cellphoneErrorMessage && (
                                    <div className="error-message">
                                        {cellphoneErrorMessage}
                                    </div>
                                )}
                            </div>

                            <div class="frm_row">
                                <label for="" class="form-label frm_label">이메일</label>
                                <div class="frm_field input_email">
                                    <input placeholder="" type="text" class="form-control"
                                           ref={emailFirstRef}
                                           onClick={emailFirstClick}
                                           onChange={(e)=>emailFirstHandler(e.target.value)}
                                           // disabled={emailFirstDisabled}
                                           onKeyDown={emailFirstKeyDownHandler}
                                           disabled={userTypeCheck || emailFirstDisabled}
                                           value={emailFirstReq}
                                           // value="test123"

                                    />
                                    <span className="txt_range">@</span>
                                    <input placeholder="" type="text"
                                           class="form-control"
                                           ref={emailSecondRef}
                                           value={emailSecondReq}
                                           onClick={emailSecondClick}
                                           onChange={(e) => emailSecondHandler(e.target.value)}
                                           disabled={userTypeCheck || !isDirectInput}

                                    />
                                    <select className="form-select"
                                            onChange={handleSelectChange}
                                            disabled={userTypeCheck || selectEmailDisabled}
                                    >
                                        <option value="naver.com">naver.com</option>
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="hanmail.net">hanmail.net</option>
                                        <option value="yahoo.com">yahoo.com</option>
                                        <option value="daum.net">daum.net</option>
                                        <option>직접입력</option>
                                    </select>
                                </div>
                                {emailErrorMessage && (
                                    <div className="error-message">
                                        {emailErrorMessage}
                                    </div>
                                )}
                            </div>

                            <div class="frm_row">
                                <label for="" class="form-label frm_label">인증코드</label>
                                <div class="frm_field">
                                    <input placeholder="인증코드를 입력해주세요." type="text" class="form-control"
                                           maxLength={6}
                                           ref={authCodeRef}
                                           onClick={authCodeClick}
                                           onKeyDown={authCodeKeyDownHandler}
                                           onChange={(e) => authCodeHandler(e.target.value)}
                                           disabled={authCodeDisabled && !userTypeCheck}
                                           value={authCode}
                                    />
                                    <button className="btn btn-secondary"
                                            onClick={handleAuthCodeSend}
                                            disabled={isTimerActive}
                                    >인증코드전송</button>

                                </div>
                                {authCodeErrorMessage && (
                                    <div className="error-message">
                                        {authCodeErrorMessage}
                                    </div>
                                )}
                            </div>

                            <div class="frm_row">
                                <label for="" class="form-label frm_label">업체종류</label>
                                <div class="frm_field">
                                    <select className="form-select"
                                            value={kindReq}
                                            onChange={handleSelectKindChange}
                                            onClick={kindClick}
                                            ref={kindRef}
                                    >
                                        <option value="Handler/Transporter">운반/처리</option>
                                        <option value="Transporter">운반</option>
                                        <option value="Handler">처리</option>
                                        <option value="Generator">배출</option>
                                    </select>
                                </div>
                            </div>

                            <div class="frm_row">
                                <label for="" class="form-label frm_label">사업자등록번호</label>
                                <div class="frm_field">
                                    <input placeholder="" type="text" class="form-control"
                                           ref={companyNumberRef}
                                           onChange={(e)=>companyNumberFirstHandler(e.target.value)}
                                           onClick={companyNumberClick}
                                           value={companyNumber}
                                           maxLength={3}
                                    />
                                    <span className="txt_range">-</span>
                                    <input placeholder="" type="text" class="form-control"
                                           ref={companyNumberRef2}
                                           onChange={(e)=>companyNumberSecondHandler(e.target.value)}
                                           onClick={companyNumberClick2}
                                           maxLength={2}
                                    />
                                    <span className="txt_range">-</span>
                                    <input placeholder="" type="text" class="form-control"
                                           ref={companyNumberRef3}
                                           onChange={(e)=>companyNumberThirdHandler(e.target.value)}
                                           onClick={companyNumberClick3}
                                           maxLength={5}
                                    />
                                    <button className="btn btn-secondary"
                                    onClick={companyNumberFindCheck}
                                    >중복확인</button>
                                </div>
                                {companyNumberErrorMessage && (
                                    <div className={companyNumberCheck ?"success-message" : "error-message"}>
                                        {companyNumberErrorMessage}
                                    </div>
                                )}
                            </div>

                            <div class="frm_row">
                                <label for="" class="form-label frm_label">사업장등록증</label>
                                <div class="frm_field">
                                    <input placeholder="" type="file" class="form-control"
                                           ref={companyNumberFileRef}
                                    onChange={(e)=>companyNumberFileHandler(e)}
                                    />
                                    <button className="btn btn-secondary"
                                            onClick={companyNumberFileClick}
                                    >파일첨부</button>
                                </div>
                                {companyNumberFileErrorMessage && (
                                    <div className="error-message">
                                        {companyNumberFileErrorMessage}
                                    </div>
                                )}
                            </div>

                            {/*<div class="frm_row">*/}
                            {/*    <label for="" class="form-label frm_label">허가증</label>*/}
                            {/*    <div class="frm_field">*/}
                            {/*        <input placeholder="" type="file" class="form-control"*/}
                            {/*            ref={permitFileRef}*/}
                            {/*               // onChange={(e)=>permitFileHandler(e)}*/}
                            {/*        />*/}
                            {/*        <button className="btn btn-secondary"*/}
                            {/*        // onClick={permitFileClick}*/}
                            {/*        >파일첨부</button>*/}
                            {/*    </div>*/}
                            {/*    {permitFileErrorMessage && (*/}
                            {/*        <div className="error-message">*/}
                            {/*            {permitFileErrorMessage}*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-secondary"
                        onClick={handleGoBack}
                        >돌아가기</button>
                        <button className="btn btn-primary ms-10px"
                        onClick={handleGoNext}
                        >가입하기</button>
                    </div>
                </div>
            </div>
            <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>인증 성공</h5>
                    <p>{showSuccessMessage}</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>회원가입완료</h5>
                    <p>회원가입이 완료되었습니다.<br />
                        로그인버튼을 눌러 가입하신 아이디로 로그인하세요.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleClose}>확인</button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5>인증 실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>
            <PublicFooter />
        </>
    );
}