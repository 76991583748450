import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import {
    postArriveCompanyNumberSearch, postArriveDetailSave, postArriveUserInfo,
    postRegistration,
    postWasteKindSearch,
    postWasteSearch
} from "../../../api/information";
import AddressSearchPopup from "../../../api/utils/AddressPopup";
import { MobileHeader } from "../../common/MobileHeader";
import { Sidebar } from "../../common/Sidebar";
import { PcHeader } from "../../common/PcHeader";
import { Navigate } from 'react-router-dom';

export const InformationSendCheckEditSecond = ({ data, goBack,approveCheck }) => {
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");
    const [showCompanyNumberMessage, setShowCompanyNumberMessage] = useState("");
    const [showCompanyNumber, setShowCompanyNumber] = useState("");
    const [wasteKindOptions, setWasteKindOptions] = useState([]); //폐기물 종류
    const handleClose = () => setShow(false);
    const handleCompanyNumber = () => setShowCompanyNumber(false)
    const handleShow = () => setShow(true);
    const orderNameRef = useRef(null)
    const orderCeoNameRef = useRef(null)
    const orderCompanyAddressRef = useRef(null)
    const constructionNameRef = useRef(null)
    const constructionCeoNameRef = useRef(null)
    const constructionAddressRef = useRef(null)
    const constructionTextRef = useRef(null)
    const conStructionSiteAddressDetailRef = useRef(null)
    const [wasteKinds, setWasteKinds] = useState([]);
    const [userInfo, setUserInfo] = useState(null)

    const wasteKindSearch = async () => {
        try {
            const response = await postWasteKindSearch()
            console.log(response)
            setWasteKindOptions(response.result);
        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }


    const [registerReq, setRegisterReq] = useState({
        informationSendIdx: "",
        name: "",
        ceoName: "",
        companyKind: "",
        ceoCellphone: "",
        taxEmail: "",
        companyAddress: "",
        allbaroName: "",
        allbaroCellphone: "",
        siteManagerCellphone: "",
        siteManagerName: "",
        companyNumber: "",
        orderName: "",
        orderCeoName: "",
        orderCompanyAddress: "",
        constructionName: "",
        constructionCeoName: "",
        constructionAddress: "",
        constructionText: "", //공사명
        constructionStartDate: "",
        constructionEndDate: "",
        constructionSiteAddress: "",
        constructionSiteAddressDetail: "",
        wasteKinds: [],
        addCompanyNumber: "",
    })

    const onKeyDownHandler = (e, part) => {
        if (e.key === 'Enter') {
            e.preventDefault();   // 기본 동작 (예: 폼 제출)을 취소합니다.

            console.log("쳌")
            console.log(part)

            if (part === 'orderName') {
                orderCeoNameRef.current.focus()
            } else if (part === 'orderCeoName') {
                orderCompanyAddressRef.current.focus();
            } else if (part === 'orderCompanyAddress') {

                constructionTextRef.current.focus();
            }
            // else if (part === 'constructionName') {
            //     constructionCeoNameRef.current.focus();
            // } else if (part === 'constructionCeoName') {
            //     constructionAddressRef.current.focus();
            // } else if (part === 'constructionAddress') {
            //     constructionTextRef.current.focus();
            // }
            else if (part === 'constructionText') {
                conStructionSiteAddressDetailRef.current.focus();
            }


        }
    }

    useEffect(() => {
        scrollToTop()
        console.log("Received data:", data);
        wasteKindSearch()
        userInfoSearch()
        // 오늘 날짜를 시작 날짜로 설정
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1); // 내일 날짜 설정

        // yyyy-mm-dd 형식으로 날짜 변환
        const formattedToday = today.toISOString().split("T")[0];
        const formattedTomorrow = tomorrow.toISOString().split("T")[0];

        // 시작 날짜는 오늘, 종료 날짜는 내일로 설정 (최초 로드 시에만)

        setStartDate(formattedToday);
        setEndDate(formattedTomorrow);

        setRegisterReq((prev) => ({
            ...prev,
            constructionStartDate: formattedToday, // 선택된 모든 유효한 파일을 배열로 설정
            constructionEndDate: formattedTomorrow,
        }));
    }, []);

    useEffect(() => {
        // 데이터가 제대로 로드되지 않으면 아무 작업도 하지 않음
        if (!userInfo) return;

        setRows((prevRows) => {
            return prevRows.map((row) => {
                let updatedRow = { ...row };

                // kind에 따라 분기하여 arriveCompany와 handlerCompany를 설정
                if (userInfo.kind === "Handler/Transporter") {
                    updatedRow.arriveCompany = userInfo.companyName || "";
                    updatedRow.handlerCompany = userInfo.companyName || "";
                } else if (userInfo.kind === "Handler") {
                    updatedRow.handlerCompany = userInfo.companyName || "";
                } else if (userInfo.kind === "Transporter") {
                    updatedRow.arriveCompany = userInfo.companyName || "";
                }

                return updatedRow;
            });
        });

    }, [userInfo]);




    const userInfoSearch= async ()  => {

        try{
            const response = await postArriveUserInfo()
            setUserInfo(response.result.result)
        }
        catch (e){
            setShowErrorMessage("시스템 오류가 발생하였습니다.")
            setShowError(true)
        }
    }

    // 시작 날짜가 변경될 때
    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        console.log(newStartDate)
        setStartDate(newStartDate);
        setRegisterReq((prev) => ({
            ...prev,
            constructionStartDate: newStartDate,
        }));

    };

    // 종료 날짜 변경 시, 시작 날짜보다 하루 이상 차이나지 않도록 제한
    const handleEndDateChange = (e) => {
        const selectedEndDate = new Date(e.target.value);
        const selectedStartDate = new Date(startDate);

        if (selectedEndDate >= selectedStartDate) {
            setEndDate(e.target.value);
        } else {
            setShowError(true)
            setShowErrorMessage("종료날짜는 시작 날짜 이전일 수없습니다.")
        }

        setRegisterReq((prev) => ({
            ...prev,
            constructionEndDate: e.target.value,
        }));
    };


    // 주소 검색 팝업 열기
    const handlePostcode = () => {
        setIsPostcodeOpen(true);
    };

    // 주소 선택 시 호출되는 함수
    const handleCompletePost = (data) => {
        const fullAddress = data.address; // 전체 주소

        console.log(fullAddress)
        setAddress(fullAddress);
        setRegisterReq((prev) => ({
            ...prev,
            constructionSiteAddress: fullAddress,
        }));
        setIsPostcodeOpen(false); // 팝업 닫기
    };

    const handleClosePopup = () => {
        setIsPostcodeOpen(false); // 팝업 닫기
        conStructionSiteAddressDetailRef.current.focus()
    };


    const handleSubmit = () => {
        // formData를 부모 컴포넌트로 넘기면서 goBack 호출
        goBack(registerReq);
    };


    useEffect(() => {
        scrollToTop();

        // 데이터가 정의되어 있을 때만 업데이트
        if (data && data.updatedRegisterReq) {
            const updatedData = data.updatedRegisterReq;
            setRegisterReq(prevState => ({
                ...prevState,
                informationSendIdx: updatedData.informationSendIdx || prevState.informationSendIdx,
                name: updatedData.name || prevState.name,
                ceoName: updatedData.ceoName || prevState.ceoName,
                companyKind: updatedData.companyKind || prevState.companyKind,
                ceoCellphone: updatedData.ceoCellphone || prevState.ceoCellphone,
                taxEmail: updatedData.taxEmail || prevState.taxEmail,
                companyAddress: updatedData.companyAddress || prevState.companyAddress,
                allbaroName: updatedData.allbaroName || prevState.allbaroName,
                allbaroCellphone: updatedData.allbaroCellphone || prevState.allbaroCellphone,
                siteManagerCellphone: updatedData.siteManagerCellphone || prevState.siteManagerCellphone,
                siteManagerName: updatedData.siteManagerName || prevState.siteManagerName,
                companyNumber: updatedData.companyNumber || prevState.companyNumber,
                // 추가 필드에 대해서도 동일하게 처리
                orderName: updatedData.orderName || prevState.orderName,
                orderCeoName: updatedData.orderCeoName || prevState.orderCeoName,
                orderCompanyAddress: updatedData.orderCompanyAddress || prevState.orderCompanyAddress,
                constructionName: updatedData.constructionName || prevState.constructionName,
                constructionCeoName: updatedData.constructionCeoName || prevState.constructionCeoName,
                constructionAddress: updatedData.constructionAddress || prevState.constructionAddress,
                constructionText: updatedData.constructionText || prevState.constructionText,
                constructionTextDetail: updatedData.constructionTextDetail || prevState.constructionTextDetail,
                constructionStartDate: updatedData.constructionStartDate || prevState.constructionStartDate,
                constructionEndDate: updatedData.constructionEndDate || prevState.constructionEndDate,
                constructionSiteAddress: updatedData.constructionSiteAddress || prevState.constructionSiteAddress,
                constructionSiteAddressDetail: updatedData.constructionSiteAddressDetail || prevState.constructionSiteAddressDetail,
                // wasteKinds: updatedData.wasteKinds || prevState.wasteKinds,
            }));
            if (Array.isArray(updatedData.wasteKinds) && updatedData.wasteKinds.length > 0) {
                setRows(updatedData.wasteKinds.map((item, index) => {
                    const arrivePay = item.arrivePay ? parseFloat(item.arrivePay) : 0; // 기본값 설정
                    const anotherPay = item.anotherPay ? parseFloat(item.anotherPay) : 0; // 기본값 설정

                    const sum = arrivePay + anotherPay; // sum 계산

                    // arriveCompany와 handlerCompany 조건 처리
                    let arriveCompany = item.arriveCompany || "";
                    let handlerCompany = item.handlerCompany || "";

                    if (userInfo) {
                        if (userInfo.kind === "Handler/Transporter") {
                            arriveCompany = userInfo.companyName;
                            handlerCompany = userInfo.companyName;
                        } else if (userInfo.kind === "Handler") {
                            handlerCompany = userInfo.companyName;
                        } else if (userInfo.kind === "Transporter") {
                            arriveCompany = userInfo.companyName;
                        }
                    }


                    return {
                        id: index + 1, // id는 1부터 시작
                        idx: item.idx,
                        informationSendIdx: item.informationSendIdx,
                        kind: item.kindName || "폐콘크리트", // kindName을 kind로
                        unit: item.unit || "톤", // unit 그대로 사용
                        kindCount: item.kindCount || "0", // unit 그대로 사용
                        tonCount: item.tonCount || "0", // kindCount 그대로
                        arrivePay: arrivePay,
                        anotherPay: anotherPay,
                        remark: item.remark || "",
                        sum: sum, // NaN 방지
                        arriveCompany: arriveCompany,
                        handlerCompany: handlerCompany,
                        checked: false // 체크박스는 초기값 false로 설정
                    };
                }));

                // wasteKinds 배열도 업데이트
                setWasteKinds(updatedData.wasteKinds);
            } else {
                // wasteKinds가 비어있거나 정의되지 않은 경우 기본값 설정
                setRows([]);
                setWasteKinds([]);
            }
        }

        wasteKindSearch();
    }, [data]); // data가 바뀔 때마다 useEffect가 실행됨



    const [companyNumber, setCompanyNumber] = useState("")
    const companyNumberRef = useRef(null)

    const [companyNumber2, setCompanyNumber2] = useState("")
    const companyNumberRef2 = useRef(null)


    const [companyNumber3, setCompanyNumber3] = useState("")
    const companyNumberRef3 = useRef(null)

    const handleCompanyNumberChange = (field, value) => {

        value = value.replace(/\D/g, '');
        // 선택한 필드에 따라 업데이트
        switch (field) {
            case "first":
                setCompanyNumber(value)
                if (value.length === 3) {
                    companyNumberRef2.current.focus();
                }
                break;
            case "second":
                setCompanyNumber2(value)
                if (value.length === 2) {
                    companyNumberRef3.current.focus();
                }
                break;
            case "third":
                setCompanyNumber3(value)
                break;
            default:
                break;
        }
    }

    const handleCompanyNameSearch = async () => {
        try {

            const mergedCompanyNumber = `${companyNumber}${companyNumber2}${companyNumber3}`;

            const response = await postArriveCompanyNumberSearch({
                companyNumber: mergedCompanyNumber
            })

            if (response.code === "0000") {
                const selectedRowIndex = rows.findIndex(row => row.checked); // 체크된 행의 인덱스 찾기

                if (selectedRowIndex === -1) return;

                // 선택한 행의 데이터를 복사
                const selectedRow = rows[selectedRowIndex];

                const kind = response.result.result.kind;
                const companyName = response.result.result.companyName;

                let newRow = { ...selectedRow, id: selectedRow.id + 1 };

                console.log("뉴체크" + newRow)
                if (kind === "Transporter") {
                    newRow.arriveCompany = companyName;
                } else if (kind === "Handler") {
                    newRow.handlerCompany = companyName;
                } else if (kind === "Handler/Transporter") {
                    newRow.arriveCompany = companyName;
                    newRow.handlerCompany = companyName;
                }

                // 새로운 행을 선택된 인덱스 바로 아래에 삽입
                const updatedRows = [...rows];
                updatedRows.splice(selectedRowIndex + 1, 0, newRow);

                // 모든 행의 id를 재정렬 (행 번호가 중복되지 않도록)
                const reindexedRows = updatedRows.map((row, idx) => ({
                    ...row,
                    id: idx + 1, // 1부터 차례대로 재정렬
                    checked: false,
                }));
                console.log("Updated rows:", reindexedRows);
                setRows(reindexedRows); // 상태 업데이트
                setShowCompanyNumber(false)
                setRegisterReq(prevState => ({
                    ...prevState,
                    addCompanyNumber: mergedCompanyNumber,
                }));
            }

            console.log(response)

        }
        catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }

    // "업체추가" 버튼 클릭 시 실행되는 함수
    const handleCompanyAddRow = () => {


        // // 체크된 행이 여러 개인지 확인
        const selectedRows = rows.filter(row => row.checked);

        if (selectedRows.length !== 1) {
            setShowErrorMessage("복사할 한 행만 선택 해주세요.")
            setShowError(true)
            return;
        }

        setShowCompanyNumber(true)
        //

    };

    useEffect(() => {
        if (showCompanyNumber && companyNumberRef.current) {
            companyNumberRef.current.focus();
        }
    }, [showCompanyNumber]);

    const handleDeleteRow = () => {
        const updatedRows = rows.filter((row) => !row.checked); // 체크된 행 삭제
        const updatedWasteKinds = wasteKinds.filter((_, index) => !rows[index].checked); // wasteKinds에서 체크된 행 삭제

        // 인덱스 재설정
        const reindexedRows = updatedRows.map((row, index) => ({
            ...row,
            id: index + 1, // 인덱스를 1부터 시작하도록 설정
        }));
        // 상태 업데이트
        setRows(reindexedRows);
        setWasteKinds(updatedWasteKinds);
    };

    const formatNumberWithCommas = (num) => {
        if (!num) return "";
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleChange = (index, field, value) => {
        setWasteKinds((prevWasteKinds) => {
            const updatedWasteKinds = [...prevWasteKinds];

            // 선택한 필드에 따라 업데이트
            switch (field) {
                case "kindName":
                    updatedWasteKinds[index].kindName = value;
                    break;
                case "unit":
                    updatedWasteKinds[index].unit = value;
                    break;
                case "kindCount":
                    value = value.replace(/\D/g, '');
                    updatedWasteKinds[index].kindCount = value;
                    break;
                case "tonCount":
                    value = value.replace(/\D/g, '');
                    updatedWasteKinds[index].tonCount = value;
                    break;
                case "arrivePay":
                    value = value.replace(/\D/g, '');
                    updatedWasteKinds[index].arrivePay = value; // 포맷팅 제거
                    break;
                case "anotherPay":
                    value = value.replace(/\D/g, '');
                    updatedWasteKinds[index].anotherPay = value; // 포맷팅 제거
                    break;
                case "remark":
                    updatedWasteKinds[index].remark = value;
                    break;
                case "arriveCompany":
                    updatedWasteKinds[index].arriveCompany = value;
                    break;
                case "handlerCompany":
                    updatedWasteKinds[index].handlerCompany = value;
                    break;
                default:
                    break;
            }

            // arrivePay와 anotherPay의 합계를 계산하여 sum에 업데이트
            const arrivePay = parseFloat(updatedWasteKinds[index].arrivePay) || 0; // 숫자로 변환
            const anotherPay = parseFloat(updatedWasteKinds[index].anotherPay) || 0; // 숫자로 변환
            const totalSum = arrivePay + anotherPay; // sum 계산

            // sum을 업데이트
            updatedWasteKinds[index].sum = totalSum; // 포맷팅 제거

            // rows 업데이트
            setRows((prevRows) => {
                const updatedRows = [...prevRows];
                updatedRows[index] = {
                    ...updatedRows[index],
                    arrivePay: updatedWasteKinds[index].arrivePay,
                    anotherPay: updatedWasteKinds[index].anotherPay,
                    sum: updatedWasteKinds[index].sum,
                    kind: updatedWasteKinds[index].kindName,
                    unit: updatedWasteKinds[index].unit,
                    kindCount: updatedWasteKinds[index].kindCount,
                    tonCount: updatedWasteKinds[index].tonCount,
                    remark: updatedWasteKinds[index].remark,
                    arriveCompany: updatedWasteKinds[index].arriveCompany,
                    handlerCompany: updatedWasteKinds[index].handlerCompany,
                };
                return updatedRows;
            });

            return updatedWasteKinds;
        });
    };


    const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);
    const [address, setAddress] = useState('');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const handleErrorClose = () => {
        setShowError(false)
    }

    const handleSuccessClose = () => {
        navigate('/information/sendCheck')
    }

    const [rows, setRows] = useState([
        {
            id: 1,
            kind: "폐콘크리트",
            unit: "톤",
            kindCount: "0",
            tonCount: "0",
            arrivePay: "0",
            anotherPay: "0",
            sum: "0",
            remark: "",
            arriveCompany: "",
            handlerCompany: "",
        }
    ]);
    // 선택된 행의 인덱스를 추적하기 위한 상태
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);


    const handleCheckboxChange = (index) => {
        setSelectedRowIndex(index);
        setRows((prevRows) => {
            return prevRows.map((row, idx) => {
                // 선택된 행의 체크 상태만 반전시키고 나머지 데이터는 그대로 유지
                if (idx === index) {
                    return { ...row, checked: !row.checked }; // 체크 상태 반전
                }
                return row; // 나머지 행은 그대로 반환
            });
        });
    };
    
    const handleAddRow = () => {

        let arriveCompany = "";
        let handlerCompany = "";

        // kind 값에 따라 분기 처리
        if (userInfo.kind === "Handler/Transporter") {
            arriveCompany = userInfo.companyName || ""; // arriveCompany에 데이터 설정
            handlerCompany = userInfo.companyName || ""; // handlerCompany에 데이터 설정
        } else if (userInfo.kind === "Handler") {
            handlerCompany = userInfo.companyName || ""; // handlerCompany에만 설정
        } else if (userInfo.kind === "Transporter") {
            arriveCompany = userInfo.companyName || ""; // arriveCompany에만 설정
        }

        const newRow = {
            id: rows.length + 1,
            kind: "폐콘크리트",
            unit: "톤",
            kindCount: 0,
            tonCount: 0,
            arrivePay: 0,
            anotherPay: 0,
            sum: 0,
            remark: "",
            arriveCompany, // 분기 처리된 결과값 할당
            handlerCompany, // 분기 처리된 결과값 할당
            kindNameEnabled: true,
            unitEnabled: true,
        };

        // rows에 새 행 추가
        setRows((prevRows) => [...prevRows, newRow]);
    };
    useEffect(() => {
        const newWasteKinds = rows.map((row, index) => {
            console.log(row.arrivePay)
            console.log(row.anotherPay)

            return {
                kindName: row.kind || wasteKinds[index]?.kindName || "",
                unit: row.unit || wasteKinds[index]?.unit || "",
                kindCount: row.kindCount !== undefined ? row.kindCount : (wasteKinds[index]?.kindCount || 0),
                tonCount: row.tonCount !== undefined ? row.tonCount : (wasteKinds[index]?.tonCount || 0),
                arrivePay: row.arrivePay !== undefined ? row.arrivePay : (wasteKinds[index]?.arrivePay || 0),
                anotherPay: row.anotherPay !== undefined ? row.anotherPay : (wasteKinds[index]?.anotherPay || 0),
                sum: (parseInt(row.arrivePay, 10) + parseInt(row.anotherPay, 10)),
                remark: row.remark || wasteKinds[index]?.remark || "",
                arriveCompany: row.arriveCompany || wasteKinds[index]?.arriveCompany || "",
                handlerCompany: row.handlerCompany || wasteKinds[index]?.handlerCompany || "",
            };
        });
        setWasteKinds(newWasteKinds);
    }, [rows]);



    useEffect(() => {
        // wasteKinds가 변경될 때마다 registerReq 업데이트
        setRegisterReq((prevRegisterReq) => ({
            ...prevRegisterReq,
            wasteKinds: wasteKinds, // wasteKinds 배열 추가
        }));
    }, [wasteKinds]); // wasteKinds가 변경될 때마다 실행


    const handleSave = async () => {
        try {

            const errorMessages = {
                orderName: "발주처 상호를 입력해주세요.",
                orderCeoName: "발주처 대표자를 입력해주세요.",
                orderCompanyAddress: "발주처 주소를 입력해주세요.",
                // constructionName: "공사내용 상호를 입력해주세요.",
                // constructionCeoName: "공사내용 대표자를 입력해주세요.",
                // constructionAddress: "공사내용 주소(사업자등록증상 주소)를 입력해주세요.",
                constructionText: "공사명을 입력해주세요.",
                constructionStartDate: "공사기간을 입력해주세요.",
                constructionEndDate: "공사기간을 입력해주세요.",
                constructionSiteAddress: "현장 주소를 입력해주세요.",
            };

            for (const [key, value] of Object.entries(registerReq)) {
                if (key in errorMessages && value === "") {
                    const errorMessage = errorMessages[key];
                    if (errorMessage) {
                        setShowErrorMessage(errorMessage);
                        setShowError(true);
                    }
                    return;
                }
            }

            const { constructionStartDate, constructionEndDate } = registerReq;
            // 날짜 비교 로직 추가
            if (new Date(constructionStartDate) > new Date(constructionEndDate)) {
                setShowErrorMessage("공사 시작일이 종료일보다 늦을 수 없습니다.");
                setShowError(true);
                return;
            }

            const wasteKindMessages = {
                kindCount: "기재수량을 입력해주세요.",
                tonCount: "신고물량(톤을) 입력해주세요.",
                arrivePay: "운반단가를 입력해주세요.",
                anotherPay: "처리단가를 입력해주세요.",
                arriveCompany: "운반업체 이름을 입력해주세요.",
                handlerCompany: "처리업체 이름을 입력해주세요",
            };

            for (let i = 0; i < wasteKinds.length; i++) { // 인덱스 0부터 시작
                const wasteKind = wasteKinds[i]; // 각 wasteKind 객체

                for (const [key, value] of Object.entries(wasteKind)) {
                    if (key in wasteKindMessages && (value === "" || value === "0" || value === 0)) {
                        const wasteKindMessage = wasteKindMessages[key];
                        if (wasteKindMessage) {
                            setShowErrorMessage(wasteKindMessage);
                            setShowError(true);
                            return;
                        }
                    }
                }
            }
            const response = await postArriveDetailSave(registerReq)

            if (response.code === "0000") {
                setShowSuccess(true)
            }

        } catch (e) {
            setShowErrorMessage(e.message)
            setShowError(true)
            navigate('/information/sendCheck')
        }
    };
    return (
        <React.Fragment>


            <Modal className="modal_alert3" show={showCompanyNumber} onHide={handleClose2} centered>
                <Modal.Body>
                    <div className="flex-center-between">
                        <label className="form-check-label">사업자등록번호</label>
                        <div class="frm_field input_phone input_phone_tbl">
                            <input placeholder="" type="text" class="form-control"
                                maxLength={3}
                                ref={companyNumberRef}
                                onChange={(e) => handleCompanyNumberChange("first", e.target.value)}
                                disabled={approveCheck}
                            />
                            <span className="txt_range">-</span>
                            <input placeholder="" type="text" class="form-control"
                                maxLength={2}
                                ref={companyNumberRef2}
                                onChange={(e) => handleCompanyNumberChange("second", e.target.value)}
                                disabled={approveCheck}
                            />
                            <span className="txt_range">-</span>
                            <input placeholder="" type="text" class="form-control"
                                maxLength={5}
                                ref={companyNumberRef3}
                                onChange={(e) => handleCompanyNumberChange("third", e.target.value)}
                                disabled={approveCheck}
                            />
                            <button className="btn btn-secondary"
                                onClick={handleCompanyNameSearch}
                            >추가</button>
                        </div>
                    </div>
                    <p className="mb-40px"></p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleCompanyNumber}>닫기</button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showSuccess} onHide={handleClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5 className="singleLine">확인완료</h5>
                    <p className="mb-40px">전달받은 기재사항내용을 <span className="text-primary">“최종확인완료”</span> 하였습니다.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인</button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                    <h5>실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_main">
                                <div className="card-header">
                                    <span>기재사항 확인 및 수정 &nbsp;&gt; &nbsp;</span> 기재사항 상세정보
                                </div>
                                <div className="card-body">
                                    <div className="stepper_wrapper lg">
                                        <div className="stepper_line"></div>
                                        <ul>
                                            <li className="active">
                                                <div className="stepper_dot dot_1"></div>
                                                <div className="stepper_text">배출업체정보</div>
                                            </li>
                                            <li className="active">
                                                <div className="stepper_dot dot_2"></div>
                                                <div className="stepper_text">발주처 및 공사내용</div>
                                            </li>
                                            <li>
                                                <div className="stepper_dot dot_3"></div>
                                                <div className="stepper_text">확인완료</div>
                                            </li>
                                        </ul>
                                    </div>

                                    <h3 className="table_heading">3. 발주처 정보(공사계약 업체 또는 관공서)</h3>
                                    <div className="table_scroll">
                                        <table className="table table-bordered card_table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "12.82%" }}>상호</th>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={registerReq.orderName || ""} // 값이 없을 때 빈 문자열로 처리
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    orderName: e.target.value,
                                                                }))
                                                            }
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'orderName')}
                                                            ref={orderNameRef}
                                                            disabled={approveCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>대표자</th>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={registerReq.orderCeoName}
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    orderCeoName: e.target.value,
                                                                }))
                                                            }
                                                            ref={orderCeoNameRef}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'orderCeoName')}
                                                            disabled={approveCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>주소</th>
                                                    <td>
                                                        <input type="text" className="form-control" placeholder=""
                                                            value={registerReq.orderCompanyAddress}
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    orderCompanyAddress: e.target.value,
                                                                }))
                                                            }
                                                            ref={orderCompanyAddressRef}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'orderCompanyAddress')}
                                                            disabled={approveCheck}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3 className="table_heading">4. 공사내용(발주처와 공사계약서상 내용기재)</h3>
                                    <div className="table_scroll">
                                        <table className="table table-bordered card_table">
                                            <tbody>
                                                {/*<tr>*/}
                                                {/*    <th style={{ width: "12.82%" }}>상호</th>*/}
                                                {/*    <td colSpan={3}>*/}
                                                {/*        <input type="text" className="form-control"*/}
                                                {/*            value={registerReq.constructionName}*/}
                                                {/*            onChange={(e) =>*/}
                                                {/*                setRegisterReq((prevState) => ({*/}
                                                {/*                    ...prevState,*/}
                                                {/*                    constructionName: e.target.value,*/}
                                                {/*                }))*/}
                                                {/*            }*/}
                                                {/*            ref={constructionNameRef}*/}
                                                {/*            onKeyDown={(e) => onKeyDownHandler(e, 'constructionName')}*/}
                                                {/*            disabled={approveCheck}*/}
                                                {/*        />*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                {/*    <th>대표자</th>*/}
                                                {/*    <td colSpan={3}>*/}
                                                {/*        <input type="text" className="form-control"*/}
                                                {/*            value={registerReq.constructionCeoName}*/}
                                                {/*            onChange={(e) =>*/}
                                                {/*                setRegisterReq((prevState) => ({*/}
                                                {/*                    ...prevState,*/}
                                                {/*                    constructionCeoName: e.target.value,*/}
                                                {/*                }))*/}
                                                {/*            }*/}
                                                {/*            ref={constructionCeoNameRef}*/}
                                                {/*            onKeyDown={(e) => onKeyDownHandler(e, 'constructionCeoName')}*/}
                                                {/*            disabled={approveCheck}*/}
                                                {/*        />*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                {/*    <th>주소<br />*/}
                                                {/*        (사업자등록증상 주소)</th>*/}
                                                {/*    <td colSpan={3}>*/}
                                                {/*        <input type="text" className="form-control"*/}
                                                {/*            value={registerReq.constructionAddress}*/}
                                                {/*            onChange={(e) =>*/}
                                                {/*                setRegisterReq((prevState) => ({*/}
                                                {/*                    ...prevState,*/}
                                                {/*                    constructionAddress: e.target.value,*/}
                                                {/*                }))*/}
                                                {/*            }*/}
                                                {/*            ref={constructionAddressRef}*/}
                                                {/*            onKeyDown={(e) => onKeyDownHandler(e, 'constructionAddress')}*/}
                                                {/*            disabled={approveCheck}*/}
                                                {/*        />*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                <tr>
                                                    <th>공사명</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                            value={registerReq.constructionText}
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    constructionText: e.target.value,
                                                                }))
                                                            }
                                                            ref={constructionTextRef}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'constructionText')}
                                                            disabled={approveCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>공사기간</th>
                                                    <td colSpan={3}>
                                                        <div className="flex-center-between">
                                                            <div className="frm_field input_date input_phone_tbl">
                                                                <input placeholder="" type="date" className="form-control"
                                                                    value={startDate}
                                                                    onChange={handleStartDateChange}
                                                                    disabled={approveCheck}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="date" className="form-control"
                                                                    value={endDate}
                                                                    onChange={handleEndDateChange}
                                                                    disabled={approveCheck}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>현장주소</th>
                                                    <td colSpan={3}>
                                                        <div className="flex-center-between">
                                                            {
                                                                !approveCheck &&
                                                                <button className="btn btn-secondary me-10px"
                                                                    onClick={() => handlePostcode}
                                                                >주소찾기</button>
                                                            }
                                                            <input placeholder="" type="text" className="form-control"
                                                                value={registerReq.constructionSiteAddress}
                                                                disabled={true}
                                                                onChange={(e) =>
                                                                    setRegisterReq((prevState) => ({
                                                                        ...prevState,
                                                                        constructionSiteAddress: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                        </div>
                                                        <input placeholder="" type="text" className="form-control mt-6px"
                                                            value={registerReq.constructionSiteAddressDetail}
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    constructionSiteAddressDetail: e.target.value,
                                                                }))
                                                            }
                                                            ref={conStructionSiteAddressDetailRef}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'conStructionSiteAddressDetail')}
                                                            disabled={approveCheck}
                                                        />
                                                    </td>
                                                </tr>

                                            </tbody>
                                            {isPostcodeOpen && (
                                                <AddressSearchPopup
                                                    onComplete={handleCompletePost}
                                                    onClose={handleClosePopup}
                                                />
                                            )}
                                        </table>
                                    </div>

                                    <div className="card_top_with_btns mb-13px">
                                        <h3 className="table_heading mb-0">5. 폐기물 목록</h3>
                                        <div className="card_table_btntop">
                                            {!approveCheck && (
                                            <button className="btn btn-secondary"
                                                onClick={handleCompanyAddRow}
                                            >업체추가</button>
                                                )}
                                            {!approveCheck && (
                                            <button className="btn btn-dark ms-10px"
                                                onClick={handleAddRow}
                                            >성상추가</button>
                                            )}
                                            {!approveCheck && (
                                            <button className="btn btn-outline-danger ms-10px"
                                                onClick={handleDeleteRow}
                                            >삭제</button>
                                                )}
                                        </div>
                                    </div>
                                    <div className="table_scroll mb-13px">
                                        <table className="table my_table mb-0">
                                            <thead>
                                                <tr>
                                                    {!approveCheck ? <th style={{ width: "4.57%" }}>선택</th> : ""}
                                                    <th style={{ width: "4.57%" }}>번호</th>
                                                    <th style={{ width: "13.09%" }}>폐기물 종류</th>
                                                    <th style={{ width: "8.71%" }}>기재단위</th>
                                                    <th style={{ width: "6.71%" }}>기재수량</th>
                                                    <th style={{ width: "6.71%" }}>신고물량(톤)</th>
                                                    <th style={{ width: "6.71%" }}>운반단가</th>
                                                    <th style={{ width: "6.71%" }}>처리단가</th>
                                                    <th style={{ width: "8.71%" }}>합계</th>
                                                    <th style={{ width: "12.33%" }}>특이사항</th>
                                                    <th style={{ width: "7.33%" }}>운반</th>
                                                    <th style={{ width: "7.33%" }}>처리</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => (
                                                    <tr key={index}>
                                                        { !approveCheck ?
                                                        <td className="text-center">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={row.checked || false}
                                                                onChange={() => handleCheckboxChange(index)} // 체크박스 변경 시 처리
                                                            />
                                                        </td> : ""
                                                        }
                                                        <td className="text-center">{row.id}</td>
                                                        <td>
                                                            <select
                                                                className="form-select"
                                                                value={wasteKinds[index]?.kindName || ""} // 해당 행의 kindName을 선택된 값으로 설정
                                                                disabled={!row.kindNameEnabled}
                                                                onChange={(e) => handleChange(index, "kindName", e.target.value)} // 선택 변경 시 처리
                                                            >
                                                                {wasteKindOptions.map((kind, idx) => (
                                                                    <option key={idx} value={kind.kindName}>
                                                                        {kind.kindName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                className="form-select"
                                                                value={wasteKinds[index]?.unit || 0} // 해당 행의 unit을 선택된 값으로 설정
                                                                // disabled={!row.unitEnabled}
                                                                onChange={(e) => handleChange(index, "unit", e.target.value)}
                                                            >
                                                                <option value="톤">톤</option>
                                                                <option value="루베">루베</option>
                                                                <option value="대">대</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                // value={wasteKinds[index]?.kindCount !== undefined ? wasteKinds[index].kindCount : 0} // kindCount가 undefined인 경우 0을 표시
                                                                value={wasteKinds[index]?.kindCount !== undefined ? Number(wasteKinds[index].kindCount).toLocaleString('ko') : 0} // kindCount가 undefined인 경우 0을 표시
                                                                onChange={(e) => handleChange(index, "kindCount", e.target.value)}
                                                                disabled={approveCheck}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={wasteKinds[index]?.tonCount !== undefined ? Number(wasteKinds[index].tonCount).toLocaleString('ko') : 0}
                                                                onChange={(e) => handleChange(index, "tonCount", e.target.value)}
                                                                disabled={approveCheck}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={wasteKinds[index]?.arrivePay !== undefined ? Number(wasteKinds[index].arrivePay).toLocaleString('ko') : 0}
                                                                onChange={(e) => handleChange(index, "arrivePay", e.target.value)}
                                                                disabled={approveCheck}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={wasteKinds[index]?.anotherPay !== undefined ? Number(wasteKinds[index].anotherPay).toLocaleString('ko') : 0}
                                                                onChange={(e) => handleChange(index, "anotherPay", e.target.value)}
                                                                disabled={approveCheck}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                disabled={true}
                                                                value={(row.sum).toLocaleString('ko')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={wasteKinds[index]?.remark !== undefined ? wasteKinds[index].remark : ""}
                                                                onChange={(e) => handleChange(index, "remark", e.target.value)}
                                                                disabled={approveCheck}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={wasteKinds[index]?.arriveCompany !== undefined ? wasteKinds[index].arriveCompany : ""}
                                                                onChange={(e) => handleChange(index, "arriveCompany", e.target.value)}
                                                                disabled={approveCheck}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={wasteKinds[index]?.handlerCompany !== undefined ? wasteKinds[index].handlerCompany : ""}
                                                                onChange={(e) => handleChange(index, "handlerCompany", e.target.value)}
                                                                disabled={approveCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer card_footer_btngrp_end">
                                    <button className="btn btn-outline-secondary btn_footer"
                                        onClick={()=>navigate('/information/sendCheck')}
                                    >목록</button>
                                    <button className="btn btn-outline-secondary btn_footer"
                                        onClick={handleSubmit}
                                    >뒤로가기</button>
                                    {!approveCheck && (
                                    <button className="btn btn-primary btn_footer"
                                        onClick={handleSave}
                                    >확인완료</button>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}