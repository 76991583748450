const baseUrl = "/api/information"
const wasteUrl = "/api/waste"
const arriveUrl = "/api/arrive"
const contractUrl = "/api/contract"
const mypageUrl = "/api/mypage"
const completeUrl = "/api/complete"
export const POST_INFORMATION_SEND_SEARCH = baseUrl+"/informationSendSearch" //기재사항 발송 조회
export const POST_WASTE_SEARCH = wasteUrl+"/wasteSearch" // 폐기물 사업자 등록번호 데이터 조회
export const POST_WASTE_REGISTRATION = wasteUrl+"/registration" // 폐기물 기재사항 등록

export const POST_WASTE_KIND_SEARCH  = wasteUrl+"/wasteKindSearch" // 폐기물 종류 조회
export const POST_ARRIVE_SEARCH = arriveUrl+"/arriveSearch" // 폐기물 기재사항 확인 및 수정 조회
export const POST_ARRIVE_DELETE = arriveUrl+"/arriveDelete" // 폐기물 기재사항 확인 및 수정 삭제

export const POST_ARRIVE_SEARCH_DETAIL = arriveUrl+"/arriveSearchDetail" // 폐기물 기재사항 상세조회
export const POST_ARRIVE_COMPANY_NUMBER_SEARCH = arriveUrl+"/arriveCompanyNumberSearch" //기재사항 확인 및 수정 업체추가 버튼 클릭 시 사업자 등록번호 조회
export const POST_ARRIVE_DETAIL_SAVE = arriveUrl+"/arriveDetailSave" //기재사항 확인 및 수정 업체추가 버튼 클릭 시 사업자 등록번호 조회
export const POST_ARRIVE_USER_INFO = arriveUrl+"/arriveUserInfo" //기재사항 확인 및 수정 기본정보


export const POST_CONTRACT_SEARCH = contractUrl+"/search" // 폐기물 계약진행사항 조회

export const POST_CONTRACT_COATING_PRE_VIEW = contractUrl+"/coatingPreView" // PDF 문서 미리보기용도
export const POST_CONTRACT_COATING_SEARCH = contractUrl+"/coatingSearch" // 도장 유무 찾기
export const POST_CONTRACT_INFO = contractUrl+"/info" // 계약정보
export const POST_CONTRACT_EMAIL_SEND = contractUrl+"/emailSend" // 도장 유무 찾기

export const POST_CONTRACT_NO_MEMBER_EMAIL_SEND_CHECK = contractUrl+"/contractNoMemberEmailSendCheck" // 비회원 이메일 인증

export const POST_CONTRACT_NO_MEMBER_COATING_CREATE = contractUrl+"/noMemberCoatingCreate" // 비회원 도장 생성
export const POST_CONTRACT_NO_MEMBER_COATING_UPLOAD = contractUrl+"/noMemberCoatingUpload" // 비회원 도장 업로드
export const POST_CONTRACT_NO_MEMBER_SAVE = contractUrl+"/noMemberSave" // 비회원 전자승인
export const POST_MYPAGE_COATING_CREATE = mypageUrl+"/coatingCreate" // 도장 생성
export const POST_MYPAGE_COATING_UPLOAD = mypageUrl+"/coatingUpload" // 도장 업로드
export const POST_COMPLETE_WASTE_SEARCH = completeUrl+"/wasteSearch" // 완료 계약서 조회
export const POST_COMPLETE_WASTE_DELETE = completeUrl+"/wasteDelete" // 완료 계약서 삭제
export const POST_COMPLETE_WASTE_UPDATE = completeUrl+"/wasteUpdate" // 완료 계약서 수정
export const POST_COMPLETE_WASTE_SAVE = completeUrl+"/wasteSave" // 신고서 제출하기 버튼
export const POST_COMPLETE_DOCUMENT_SEARCH = completeUrl+"/documentSearch" // 신고서 조회
export const POST_COMPLETE_DOCUMENT_UPDATE = completeUrl+"/documentUpdate" // 신고서 저장
export const POST_COMPLETE_DOCUMENT_PERMIT_DOWNLOAD = completeUrl+"/documentPermitDownload" // 허가증 다운로드
export const POST_COMPLETE_DOCUMENT_MEMBER_PRE_VIEW = completeUrl+"/documentMemberPreView" // 계약서 전체 미리보기
export const POST_COMPLETE_DOCUMENT_CONTRIBUTION_DOWNLOAD = completeUrl+"/documentContributionDownload" // 분담금 다운로드




