import React from "react";
import { Spinner } from "react-bootstrap"; // react-bootstrap에서 Spinner 가져오기

const LoadingSpinner = ({ isLoading, text }) => {
  if (!isLoading) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.spinnerContainer}>
        <Spinner animation="border" role="status" variant="primary" />
        {text && <p style={styles.loadingText}>{text}</p>} {/* 텍스트 추가 */}
      </div>
    </div>
  );
};

// 스타일 추가
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // 반투명 검정 배경
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // 다른 콘텐츠보다 위에 표시
  },
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column', // 세로로 스피너와 텍스트 배치
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    marginTop: '10px', // 스피너와 텍스트 사이 간격
    color: 'white',
    fontSize: '18px',  // 텍스트 크기 조정
  },
};

export default LoadingSpinner;
