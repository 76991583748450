import { PublicFooter } from "./base/PublicFooter";
import { PublicHeader } from "./base/PublicHeader";
import {findIsPay, postLogin} from "../api/auth";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { scrollToTop } from "../api/utils/scrollToTop";
import {postOauth2CallKaKao} from "../api/oauth2";

export const Login = () => {

    const navigate = useNavigate();
    const [loginReq, setLoginReq] = useState({
        userId: localStorage.getItem("oneclick_remember_id") ? localStorage.getItem("oneclick_remember_id") : "",
        password: ""
    })
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const [naverInitialized, setNaverInitialized] = useState(false);
    const [password, setPassword] = useState("")

    const [rememberId, setRememberId] = useState(localStorage.getItem("oneclick_remember_id") ? true : false)

    const passwordRef = useRef(null)
    const [idErrorMessage, setIdErrorMessage] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");



    const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_APP_KEY; // 발급받은 클라이언트 아이디

    const REDIRECT_URI = process.env.REACT_APP_SERVER_URL+"/oauth2/call/naver"; // 리다이렉트 URI
    const generateRandomState = () => {
        return Math.random().toString(36).substring(2); // 간단한 랜덤 문자열 생성
    }
    const STATE = generateRandomState();
    const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;
// 매번 새로운 state 값을 생성

    const [checkLogin,setCheckLogin] = useState(false)

    const NaverLogin =  () => {
        // 팝업 창의 크기와 위치를 설정
        const width = 500;
        const height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        // 팝업 창 열기
        const popup = window.open(
            NAVER_AUTH_URL,
            "NaverLoginPopup", // 팝업 창의 이름
            `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
        )

        return;

        // 팝업 창이 닫히면 프로필을 가져오는 함수 실행
        /*const checkPopupClosed = setInterval(async () => {
            if (popup.closed) {
                clearInterval(checkPopupClosed);
                // 팝업이 닫힌 후 profile 데이터를 부모 창에서 처리
                const profile = window.profileData; // 팝업에서 전달된 데이터
                if (profile) {

                    // 추가 처리 (예: 로그인 상태 업데이트 등)
                    const userInfo = {
                        type: "naver",
                        id: profile.response.id,
                        nickname: profile.response.name,
                        email: profile.response.email,
                    };
                    try {
                        const result = await postOauth2CallKaKao(userInfo);
                        if (result.result.result === true) {
                            sessionStorage.setItem("oneclick_userName", result.result.userName);
                            Cookies.set("accToken", result.result.accToken);
                            navigate("/dashboard/main");
                        } else {
                            console.log(result)
                            console.log("티티티")
                            navigate("/memberShipAgree", {state: {userInfo}});
                        }
                        setErrorMessage("");
                    } catch (e) {
                        setErrorMessage(e.message);
                    }
                } else {
                    alert("로그인에 실패했습니다.")
                }
            }
        }, 500);*/
    };

    window.addEventListener('profileData', (event) => {
        const profile = event.detail; // 전달받은 데이터
        console.log(profile)
        naverlogin(profile)

        // 데이터 처리 로직 추가
    });

    const naverlogin = async (profile) => {

        const userInfo = {
            type: "naver",
            id: profile.response.id,
            nickname: profile.response.name,
            email: profile.response.email,
        };

        try {
            const result = await postOauth2CallKaKao(userInfo);

            if (result.result?.result) {
                const { id } = profile.response;
                const isPay = (await findIsPay({ userId: id }))?.result?.isPay ?? false;

                sessionStorage.setItem("oneclick_userName", result.result.userName);
                Cookies.set("accToken", result.result.accToken);
                sessionStorage.setItem("loginId", id);

                navigate(isPay ? "/dashboard/main" : "/feeIntroduce");
            } else {
                navigate("/memberShipAgree", { state: { userInfo } });
            }

            setErrorMessage("");
        } catch (e) {
            setErrorMessage(e.message);
        }
    };


    // const checkPopupClosed = async () => {
    //     console.log(popup)
    //     if (popup.closed) {
    //         console.log("2")
    //         // clearInterval(checkPopupClosed);
    //         // 팝업이 닫힌 후 profile 데이터를 부모 창에서 처리
    //         const profile = window.profileData; // 팝업에서 전달된 데이터
    //         console.log(profile)
    //         if (profile) {
    //             // 추가 처리 (예: 로그인 상태 업데이트 등)
    //             const userInfo = {
    //                 type: "naver",
    //                 id: profile.response.id,
    //                 nickname: profile.response.name,
    //                 email: profile.response.email,
    //             };
    //             try {
    //                 const result = await postOauth2CallKaKao(userInfo);
    //                 if (result.result.result === true) {
    //                     sessionStorage.setItem("oneclick_userName", result.result.userName);
    //                     Cookies.set("accToken", result.result.accToken);
    //                     navigate("/dashboard/main");
    //                 } else {
    //                     console.log(result)
    //                     console.log("티티티")
    //                     navigate("/memberShipAgree", {state: {userInfo}});
    //                 }
    //                 setErrorMessage("");
    //             } catch (e) {
    //                 setErrorMessage(e.message);
    //             }
    //         } else {
    //             alert("로그인에 실패했습니다.")
    //         }
    //     }
    //     else{
    //         console.log("?")
    //     }
    // }

    // useEffect(()=>{
    //     if(popup !== null){
    //         console.log(popup)
    //             checkPopupClosed();
    //
    //     }
    // },[popup])

//     /// 팝업에서 부모 창으로 데이터를 전달하는 함수
//     const sendDataToParent = (data) => {
//         if (window.opener) {
//             // 부모 창에 데이터를 전송
//             window.opener.postMessage(data, "http://localhost:3000"); // 부모 창 URL로 전송
//             window.close(); // 팝업 창 닫기
//         }
//     };
//
// // 네이버 인증 후 받은 code와 state를 부모 창에 전달
//     const handleRedirectData = () => {
//         const urlParams = new URLSearchParams(window.location.search);
//         const code = urlParams.get('code');
//         const state = urlParams.get('state');
//
//         if (code && state) {
//             // 서버로 인증을 위한 요청을 보낸 후 받은 데이터 처리
//             fetch(`/oneclick/oauth2/call/naver?code=${code}&state=${state}`)
//                 .then(response => response.json())
//                 .then(data => {
//                     sendDataToParent(data); // 부모 창으로 데이터 전송
//                 })
//                 .catch(error => {
//                     console.error("Error during fetching data:", error);
//                 });
//         }
//     };
//
// // 네이버 로그인 후 리다이렉트된 후 호출
//     handleRedirectData();
//
//     window.addEventListener("message", (event) => {
//         // 부모 창의 URL 확인
//         if (event.origin === "http://localhost:8080") { // 네이버 인증 서버 URL
//             const profileData = event.data; // 전달받은 데이터
//             console.log("받은 프로필 데이터:", profileData);
//             // 받은 데이터를 사용하여 부모 창에서 필요한 작업 처리
//         } else {
//             console.error("유효하지 않은 메시지 출처:", event.origin);
//         }
//     });

    const [naverSDKCheck, setNaverSDKCheck] = useState(false)

    // 페이지 로딩 시 네이버 SDK 스크립트 로드


    // 컴포넌트가 마운트될 때 스크립트 로드 함수 호출
    useEffect(() => {
        if(!naverSDKCheck){
            loadNaverSDK();
        }

    }, [naverSDKCheck]);
    const loadNaverSDK = () => {

        if (setNaverSDKCheck) {
            return; // SDK가 이미 로드되었으면 더 이상 로드하지 않음
        }

        const script = document.createElement('script');
        script.src = 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js';
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
            setNaverSDKCheck(true);
        };

        script.onerror = () => {
        };

    };
    //


    // const [user, setUser] = useState(null);
    // const [status, setStatus] = useState(false);
    //
    // const getUser = async () => {
    //     const { naver } = window;
    //
    //     if (naver) {
    //         const naverLogin = new naver.LoginWithNaverId({
    //             clientId: NAVER_CLIENT_ID,
    //             callbackUrl: "http://localhost:8080/oneclick/oauth2/call/naver",
    //             isPopup: true,
    //         });
    //
    //         // 로그인 상태 확인
    //         await naverLogin.getLoginStatus((status) => {
    //             console.log(`로그인?: ${status}`);
    //             setStatus(status);
    //
    //             if (status) {
    //                 // 로그인 상태일 때 사용자 정보 가져오기
    //                 setUser({ ...naverLogin.user });
    //
    //                 // 부모 페이지 리디렉션과 팝업창 닫기
    //                 window.opener.location.href = "http://localhost:3000"; // 부모 페이지로 리디렉션
    //                 window.close(); // 팝업창 닫기
    //             }
    //         });
    //     }
    // };
    //
    // // 사용자 정보가 있을 때 렌더링
    // useEffect(() => {
    //     if (status) {
    //         getUser(); // 로그인 상태 확인 후 사용자 정보 가져오기
    //     }
    // }, [status]);


// 카카오 SDK 로드 함수
    const loadKakaoScript = () => {
        return new Promise((resolve, reject) => {
            if (window.Kakao) {
                resolve();
                return;
            }
            const script = document.createElement("script");
            script.src = "https://developers.kakao.com/sdk/js/kakao.js";
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error("Kakao SDK loading failed"));
            document.head.appendChild(script);
        });
    };

    // 카카오 SDK 초기화 함수
    const initializeKakao = async () => {
        try {
            await loadKakaoScript(); // 카카오 SDK 로드
            if (window.Kakao) {
                window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY); // 카카오 앱 키
                setKakaoInitialized(true);
            }
        } catch (error) {

        }
    };

    const unlinkKakao = async () => {
        try {
            // 카카오 연결 끊기 요청
            await window.Kakao.API.request({
                url: "/v1/user/unlink",
            });
        } catch (error) {
        }
    };

// 카카오 로그인 버튼 클릭 시 호출되는 함수
    const kakaoLoginButton = async () => {
        try {

            // 카카오 SDK가 초기화되지 않은 경우 초기화
            if (!kakaoInitialized) {
                await initializeKakao(); // 카카오 SDK 초기화 확인
            }

            // 연결 끊기 수행
            await unlinkKakao(); // 연결 끊기 요청 후 로그인 팝업 띄우기

            // 로그인 팝업 호출
            window.Kakao.Auth.login({
                success: function (authObj) {
                    getUserInfo(); // 로그인 후 사용자 정보 처리
                },
                fail: function (err) {
                },
            });
        } catch (e) {

            alert("로그인 오류가 발생했습니다. " + e);
        }
    };

    // 카카오 사용자 정보 가져오기
    const getUserInfo = async () => {
        try {
            window.Kakao.API.request({
                url: "/v2/user/me",
                data: {
                    property_keys: [
                        "properties.nickname",
                        "kakao_account.email",
                        "kakao_account.gender",
                        "kakao_account.openid",
                    ],
                },
                success: async function (response) {
                    // 사용자 정보 처리
                    const userInfo = {
                        type: "kakao",
                        id: response.id,
                        nickname: response.properties.nickname,
                        email: response.kakao_account.email,
                    };
                    setKakaoInitialized(false);
                    try {
                        const result = await postOauth2CallKaKao(userInfo);

                        console.log(result)

                        if (result.result?.result) {
                            const isPay = (await findIsPay({ userId: userInfo.id }))?.result?.isPay ?? false;

                            sessionStorage.setItem("oneclick_userName", userInfo.nickname);
                            Cookies.set("accToken", result.result.accToken);
                            sessionStorage.setItem("loginId", userInfo.id);

                            navigate(isPay ? "/dashboard/main" : "/feeIntroduce");
                        } else {
                            navigate("/memberShipAgree", { state: { userInfo } });
                        }
                        // if (result.result.result === true) {
                        //     sessionStorage.setItem("oneclick_userName", result.result.userName);
                        //     Cookies.set("accToken", result.result.accToken);
                        //     navigate("/dashboard/main");
                        // } else {
                        //     navigate("/memberShipAgree", { state: { userInfo } });
                        // }
                        setErrorMessage("");
                    } catch (e) {
                        setErrorMessage(e.message);
                    }
                },
                fail: function (error) {
                    setKakaoInitialized(false);
                },
            });
        } catch (e) {
            setKakaoInitialized(false);
            alert("사용자 정보 요청 오류: " + e);
        }
    };






    // useEffect(() => {
    //     initializeKakao();
    // }, []);
    //
    //
    //
    //
    //
    // const loadKakaoScript = () => {
    //     return new Promise((resolve, reject) => {
    //         if (window.Kakao) {
    //             resolve();
    //             return;
    //         }
    //         const script = document.createElement("script");
    //         script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    //         script.async = true;
    //         script.onload = () => resolve();
    //         script.onerror = () => reject(new Error("Kakao SDK loading failed"));
    //         document.head.appendChild(script);
    //     });
    // };
    //
    // const initializeKakao = async () => {
    //     try {
    //         await loadKakaoScript(); // 카카오 SDK 로드
    //         if (window.Kakao) {
    //             console.log("테스트")
    //             window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY); // 카카오 앱 키
    //             setKakaoInitialized(true);  // 초기화 완료 상태 업데이트
    //         }
    //     } catch (error) {
    //         // alert("카카오 로그인 오류 발생")
    //     }
    // };
    //
    // const kakaoLoginButton = async () => {
    //     try {
    //         window.Kakao.Auth.login({
    //             success: function (authObj) {
    //                 window.Kakao.API.request({
    //                     url: '/v2/user/me',
    //                     data: {
    //                         property_keys: [
    //                             'properties.nickname',
    //                             'kakao_account.email',
    //                             'kakao_account.gender',
    //                             'kakao_account.openid'
    //                         ]
    //                     },
    //                     success: async function (response) {
    //                         // 여기에 필요한 로직 추가 (ex. 상태 업데이트, 서버로 전송)
    //                         const userInfo = {
    //                             type: 'kakao',
    //                             id: response.id,
    //                             nickname: response.properties.nickname,
    //                             email: response.kakao_account.email,
    //                         };
    //                         if(kakaoInitialized){
    //                             try{
    //                                 const result = await postOauth2CallKaKao(userInfo)
    //                                 const resultData = result.result
    //                                 console.log("체크")
    //                                 if(resultData.result===true){
    //
    //                                     if (resultData.userName != null) {
    //                                         localStorage.setItem('oneclick_userName', resultData.userName);
    //                                     }
    //                                     Cookies.set('accToken', resultData.accToken);
    //                                     navigate("/dashboard/main")
    //                                 }
    //                                 else{
    //                                     console.log("티티티티")
    //                                     console.log(result.result.result)
    //                                     navigate('/memberShipAgree', { state: { userInfo } });
    //                                 }
    //                                 setErrorMessage("")
    //                             }
    //                             catch(e){
    //                                 setErrorMessage(e.message)
    //
    //                             }
    //                         }
    //                     },
    //                     fail: function (error) {
    //                         console.log(error);
    //                     }
    //                 });
    //             },
    //             fail: function (err) {
    //                 console.log(err);
    //             },
    //         });
    //     } catch (e) {
    //         alert(e);
    //     }
    // }


    const loginButton = async () => {

        try {

            const response = await postLogin(loginReq)

            if (response.userName != null) {
                sessionStorage.setItem('oneclick_userName', response.userName);
            }
            rememberId ? localStorage.setItem("oneclick_remember_id", loginReq.userId) : localStorage.removeItem("oneclick_remember_id");

            Cookies.set('accToken', response.accToken);
            sessionStorage.setItem('loginId', loginReq.userId)
            
            if(!response.isPay) 
                navigate("/feeIntroduce")
            else 
                navigate("/dashboard/main")

        }
        catch (e) {
                setPasswordErrorMessage(e.message);


        }
    }

    const idHandler = (e) => {
        const inputValue = e.target.value;

        setErrorMessage("")
        if (inputValue === "") {
            setIdErrorMessage("아이디를 입력해주세요.");
        } else {

            setIdErrorMessage("");
        }
        setLoginReq({ ...loginReq, userId: inputValue });
    };

    const passwordHandler = (e) => {

        if (e === "") {
            setPasswordErrorMessage("비밀번호를 입력해주세요.")
            setPassword(e)
        }
        else {
            setPassword(e)
            setPasswordErrorMessage("");
        }
        setLoginReq({ ...loginReq, password: e })

    }

    const userIdKeyDownHandler = (e) => {
        if (e.key === "Enter" || e.key === '13') {
            passwordRef.current.focus();
        }
    }

    const passwordKeyDownHandler = (e) => {
        if(e.key === "Enter" || e.key === '13') {
            loginButton();
        }
    }

    const idRememberHandler = (e) => {
        setRememberId(e)
    }

    return (
        <>
            <PublicHeader />
            <div className="login_content">
                <div className="login_box">
                    <img src={process.env.PUBLIC_URL + "/images/login_logo.png"} alt="" />
                    <div className="login_text">원클릭 플랫폼에 오신 것을 환영합니다.</div>
                    <div className="frm_login_row">
                        <label className="form-label">아이디</label>
                        <div className="input-group">
                            <span className="input-group-text">
                                <img src={process.env.PUBLIC_URL + "/images/icon_login_input1.png"} alt="" />
                            </span>
                            <input type="text"
                                className="form-control"
                                placeholder="아이디를 입력 해주세요."
                                onChange={(e) => idHandler(e)}
                                onKeyDown={userIdKeyDownHandler}
                                value={loginReq.userId}
                            />
                        </div>
                        {idErrorMessage && (
                            <div className="error-messageLogin">
                                {idErrorMessage}
                            </div>
                        )}
                    </div>
                    <div className="frm_login_row mb-0">
                        <label className="form-label">비밀번호</label>
                        <div className="input-group">
                            <span className="input-group-text">
                                <img src={process.env.PUBLIC_URL + "/images/icon_login_input2.png"} alt="" />
                            </span>
                            <input type="password" className="form-control" placeholder="비밀번호를 입력 해주세요."
                                onChange={(e) => passwordHandler(e.target.value)}
                                ref={passwordRef}
                                value={password}
                                onKeyDown={passwordKeyDownHandler}
                            />
                        </div>
                        {passwordErrorMessage && (
                            <div className="error-messageLogin">
                                {passwordErrorMessage}
                            </div>
                        )}
                    </div>
                    {errorMessage && (
                        <div className="error-messageLogin">
                            {errorMessage}
                        </div>
                    )}
                    <div className="form-check remember_outer">
                        <input className="form-check-input" type="checkbox" id="chk_remember"
                            checked={rememberId}
                            onChange={(e) => idRememberHandler(e.target.checked)}
                        />
                        <label className="form-check-label" for="chk_remember">
                            ID 기억하기
                        </label>
                    </div>
                    <ul className="login_links">
                        <li><a href="/memberShipAgree">회원가입</a></li>
                        <li><a href="/findId">ID 찾기</a></li>
                        <li><a href="/findPassword">비밀번호 찾기</a></li>
                    </ul>
                    <button className="btn btn-primary btn-lg btn_login"
                        onClick={loginButton}
                    >로그인</button>
                    <button className="btn btn-light btn-lg btn_kakao"
                        onClick={kakaoLoginButton}
                    ></button>
                    <button className="btn btn-light btn-lg btn_naver"
                    id='naverIdLogin'
                            onClick={NaverLogin}
                    >
                    </button>
                </div>
            </div>
            <PublicFooter />
        </>
    );
}