import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postWasteSearch } from "../../../api/information";
import { scrollToRef } from "../../../api/utils/scrollToRef";
import Modal from "react-bootstrap/Modal";
import { scrollToTop } from "../../../api/utils/scrollToTop";
import { fileUpload } from "../../../api/auth";
import { InformationWasteManageSecond } from "./InformationWasteManageSecond";
import {VerifySidebar} from "../sendCheck/base/VerifySidebar";
import {VerifyContractMobileHeader} from "../request/base/VerifyContractMobileHeader";
import {VerifyContractHeader} from "../request/base/VerifyContractHeader";
import { MobileHeader } from "../../common/MobileHeader";
import { Sidebar } from "../../common/Sidebar";
import { PcHeader } from "../../common/PcHeader";

export const InformationWasteManageFirst = (SecondData) => {

    const location = useLocation();
    const navigate = useNavigate()
    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("");

    const [showError2, setShowError2] = useState(false);
    const [showErrorMessage2, setShowErrorMessage2] = useState("");

    const [fileName, setFileName] = useState(""); // 서버에서 가져온 파일 이름
    const [fileBytes, setFileBytes] = useState(""); // 서버에서 가져온 파일 바이트
    const [uploadedFileNames, setUploadedFileNames] = useState(""); // 업로드된 파일 이름


    // 전달된 상태를 가져옵니다.
    const { state } = location;


    const [registerReq, setRegisterReq] = useState({
        informationSendIdx: "",
        name: "",
        ceoName: "",
        companyKind: "",
        ceoCellphone: "",
        taxEmail: "",
        companyAddress: "",
        allbaroName: "",
        allbaroCellphone: "",
        siteManagerCellphone: "",
        siteManagerName: "",
        companyNumber: "",
        orderName: "",
        orderCeoName: "",
        orderCompanyAddress: "",
        constructionName: "",
        constructionCeoName: "",
        constructionAddress: "",
        constructionText: "", //공사명
        constructionTextDetail: "", //공사명 상세 삭제
        constructionStartDate: "",
        constructionEndDate: "",
        constructionSiteAddress: "",
        constructionSiteAddressDetail: "",
        wasteKinds: [],
    })


    //페이지 진입
    useEffect(() => {

        scrollToTop()
        if (state) {
            // console.log("Received state:", state); // 콘솔에 상태를 출력
            // 필요에 따라 상태를 활용하는 로직 추가
            const serialNum = state.serialNumber;
            const informationSendIdx = state.informationSendIdx;
            console.log(serialNum)
            console.log(informationSendIdx)
            // registerReq의 informationSendIdx 업데이트
            setRegisterReq(prevState => ({
                ...prevState,
                informationSendIdx: informationSendIdx || prevState.informationSendIdx, // informationSendIdx가 있으면 업데이트
            }));
        }
    }, [])

    const [companyNumber, setCompanyNumber] = useState("")
    const companyNumberRef = useRef(null)

    const [companyNumber2, setCompanyNumber2] = useState("")
    const companyNumberRef2 = useRef(null)

    const [companyNumber3, setCompanyNumber3] = useState("")
    const companyNumberRef3 = useRef(null)

    const nameRef = useRef(null)
    const ceoNameRef = useRef(null)
    const companyKindRef = useRef(null)
    const companyAddressRef = useRef(null)
    const allbaroNameRef = useRef(null)
    const siteManagerNameRef = useRef(null)

    const [ceoCellphonePart1, setCeoCellphonePart1] = useState("");
    const [ceoCellphonePart2, setCeoCellphonePart2] = useState("");
    const [ceoCellphonePart3, setCeoCellphonePart3] = useState("");

    const [allbarophonePart1, setAllbarophonePart1] = useState("");
    const [allbarophonePart2, setAllbarophonePart2] = useState("");
    const [allbarophonePart3, setAllbarophonePart3] = useState("");

    const [siteManagerphonePart1, setSiteManagerPhonePart1] = useState("");
    const [siteManagerphonePart2, setSiteManagerPhonePart2] = useState("");
    const [siteManagerphonePart3, setSiteManagerPhonePart3] = useState("");

    const part1Ref = useRef(null) //연락처 앞
    const part2Ref = useRef(null); //중간
    const part3Ref = useRef(null); //끝

    const allbaroPart1Ref = useRef(null) //올바로 연락처 앞
    const allbaroPart2Ref = useRef(null); //중간
    const allbaroPart3Ref = useRef(null); //끝

    const siteManagePart1Ref = useRef(null) //현장 연락처 앞
    const siteManagePart2Ref = useRef(null); //중간
    const siteManagePart3Ref = useRef(null); //끝

    const [taxEmailLocalPart, setTaxEmailLocalPart] = useState('');
    const [taxEmailDomainPart, setTaxEmailDomainPart] = useState('');

    const taxEmailRef1 = useRef(null)
    const taxEmailRef2 = useRef(null);


    const [registerFileReq, setRegisterFileReq] = useState({
        companyNumberFile: [],
        fileName: "",
    })

    const companyNumberFileRef = useRef(null)
    const [companyFileCheck, setCompanyFileCheck] = useState(false)


    const companyNumberFirstHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        setCompanyNumber(e);
        if (e.length === 3) {
            companyNumberRef2.current.focus()
        }

    }

    const companyNumberSecondHandler = (e) => {

        // 숫자가 아닌 문자는 제거
        e = e.replace(/\D/g, '');
        if (e.length === 2) {
            companyNumberRef3.current.focus()
        }
        setCompanyNumber2(e);
    }

    const companyNumberThirdHandler = (e) => {

        e = e.replace(/\D/g, '');
        setCompanyNumber3(e);
    }

    const handleTaxEmailLocalPartChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^a-zA-Z0-9]/g, '');

        setTaxEmailLocalPart(value);
    };

    const handleTaxEmailDomainPartChange = (e) => {
        let value = e.target.value;

        // 알파벳과 숫자가 아닌 문자는 제거합니다.
        value = value.replace(/[^a-zA-Z0-9.]/g, '');
        if (value === "") {
            setTimeout(() => {
                taxEmailRef2.current.focus();
            }, 0);
        }
        setTaxEmailDomainPart(value);
    };

    const companyNumberFindCheck = async () => {

        try {

            const fullCompanyNumber = companyNumber + companyNumber2 + companyNumber3

            if (fullCompanyNumber.length !== 10) {
                setShowErrorMessage("사업자 등록번호 형식이 아닙니다.")
                setShowError(true)
                return
            }

            const updatedRegisterReq = {
                ...registerReq, //기존 필드 유지
                companyNumber: fullCompanyNumber
            };

            const response = await postWasteSearch(updatedRegisterReq)
            console.log(response)
            if (response.code === "0000") {
                const result = response.result.result;
                const fileNameArray = response.result.fileName;
                const fileByte = response.result.fileByte; // 서버에서 받은 Base64 데이터
                setRegisterReq(prevState => ({
                    informationSendIdx: prevState.informationSendIdx, // 기존 값 유지
                    name: result.name || "",
                    ceoName: result.ceoName || "",
                    companyKind: result.companyKind || "",
                    ceoCellphone: result.ceoCellphone || "",
                    taxEmail: result.taxEmail || "",
                    companyAddress: result.companyAddress || "",
                    allbaroName: result.allbaroName || "",
                    allbaroCellphone: result.allbaroCellphone || "",
                    siteManagerCellphone: result.siteManagerCellphone || "",
                    siteManagerName: result.siteManagerName || "",
                    companyNumber: prevState.companyNumber, // 기존 값 유지
                    orderName: prevState.orderName, // 기존 값 유지
                    orderCeoName: prevState.orderCeoName, // 기존 값 유지
                    orderCompanyAddress: prevState.orderCompanyAddress, // 기존 값 유지
                    constructionName: prevState.constructionName, // 기존 값 유지
                    constructionCeoName: prevState.constructionCeoName, // 기존 값 유지
                    constructionAddress: prevState.constructionAddress, // 기존 값 유지
                    constructionText: prevState.constructionText, // 기존 값 유지
                    constructionTextDetail: prevState.constructionTextDetail, // 기존 값 유지
                    constructionStartDate: prevState.constructionStartDate, // 기존 값 유지
                    constructionEndDate: prevState.constructionEndDate, // 기존 값 유지
                    constructionSiteAddress: prevState.constructionSiteAddress, // 기존 값 유지
                    constructionSiteAddressDetail: prevState.constructionSiteAddressDetail, // 기존 값 유지
                }));

                if(fileNameArray&& fileByte){
                    const fileName = fileNameArray[0]; // 첫 번째 파일 이름 가져오기
                    const base64ToFile = (base64String, fileName) => {
                        const byteCharacters = atob(base64String);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);

                        const blob = new Blob([byteArray]); // Blob 생성
                        return new File([blob], fileName); // File 객체 생성
                    };
                    // Base64 데이터를 File 객체로 변환
                    const decodedFile = base64ToFile(fileByte, fileName);
                    setRegisterFileReq((prev) => ({
                        ...prev,
                        companyNumberFile: [decodedFile], // File 객체 배열로 저장
                        fileName: fileName, // 파일 이름 저장
                    }));
                    setCompanyFileCheck(true)
                }
                const ceoCellphone = result.ceoCellphone || "";
                const allbaroCellphone = result.allbaroCellphone || "";
                const siteManagerCellphone = result.siteManagerCellphone || "";

                if (result.ceoCellphone.length === 11) {

                    setCeoCellphonePart1(ceoCellphone.slice(0, 3));
                    setCeoCellphonePart2(ceoCellphone.slice(3, 7));
                    setCeoCellphonePart3(ceoCellphone.slice(7));

                }
                else {
                    setCeoCellphonePart1(ceoCellphone.slice(0, 3));
                    setCeoCellphonePart2(ceoCellphone.slice(3, 6));
                    setCeoCellphonePart3(ceoCellphone.slice(6));
                }

                if (result.allbaroCellphone.length === 11) {
                    setAllbarophonePart1(allbaroCellphone.slice(0, 3));
                    setAllbarophonePart2(allbaroCellphone.slice(3, 7));
                    setAllbarophonePart3(allbaroCellphone.slice(7));
                }
                else {
                    setAllbarophonePart1(allbaroCellphone.slice(0, 3));
                    setAllbarophonePart2(allbaroCellphone.slice(3, 6));
                    setAllbarophonePart3(allbaroCellphone.slice(6));
                }

                if (result.siteManagerCellphone.length === 11) {
                    setSiteManagerPhonePart1(siteManagerCellphone.slice(0, 3));
                    setSiteManagerPhonePart2(siteManagerCellphone.slice(3, 7));
                    setSiteManagerPhonePart3(siteManagerCellphone.slice(7));
                }
                else {
                    setSiteManagerPhonePart1(siteManagerCellphone.slice(0, 3));
                    setSiteManagerPhonePart2(siteManagerCellphone.slice(3, 6));
                    setSiteManagerPhonePart3(siteManagerCellphone.slice(6));
                }
                const emailParts = result.taxEmail.split('@');
                setTaxEmailLocalPart(emailParts[0] || '');
                setTaxEmailDomainPart(emailParts[1] || '');
            }
        }
        catch (e) {
            companyNumberClick()
            setShowErrorMessage2(e.message)
            setShowError2(true)
        }
    }

    const companyNumberClick = () => {
        scrollToRef(companyNumberRef)
    }

    const handleErrorClose = () => {
        setShowError(false)
    }

    const handleErrorClose2 = () => {
        setShowError2(false)
    }

    const ceoCellPhoneHandler = (e, part) => {
        let value = e.target.value;

        // 숫자가 아닌 문자는 제거
        value = value.replace(/\D/g, '');

        if (part === 'part1') {
            setCeoCellphonePart1(value);
            if (value.length === 3) {
                if (value === '010') {
                    part2Ref.current.maxLength = 4;
                } else {
                    part2Ref.current.maxLength = 3;
                }
                part2Ref.current.focus();
            }
        } else if (part === 'part2') {
            setCeoCellphonePart2(value);
            if ((ceoCellphonePart1 === '010' && value.length === 4) ||
                (ceoCellphonePart1 !== '010' && value.length === 3)) {
                part3Ref.current.focus();
            }
        } else if (part === 'part3') {
            setCeoCellphonePart3(value);
        }
        else if (part === 'allbaroPart1') {
            setAllbarophonePart1(value)
            if (value.length === 3) {
                if (value === '010') {
                    allbaroPart2Ref.current.maxLength = 4;
                } else {
                    allbaroPart2Ref.current.maxLength = 3;
                }
                allbaroPart2Ref.current.focus();
            }
        }
        else if (part === 'allbaroPart2') {
            setAllbarophonePart2(value)
            if ((allbarophonePart1 === '010' && value.length === 4) ||
                (allbarophonePart1 !== '010' && value.length === 3)) {
                allbaroPart3Ref.current.focus();
            }
        }
        else if (part === 'allbaroPart3') {
            setAllbarophonePart3(value)
        }
        else if (part === 'siteManagerPart1') {
            setSiteManagerPhonePart1(value)
            if (value.length === 3) {
                if (value === '010') {
                    siteManagePart2Ref.current.maxLength = 4;
                } else {
                    siteManagePart2Ref.current.maxLength = 3;
                }
                siteManagePart2Ref.current.focus();
            }
        }
        else if (part === 'siteManagerPart2') {
            setSiteManagerPhonePart2(value)
            if ((siteManagerphonePart1 === '010' && value.length === 4) ||
                (siteManagerphonePart1 !== '010' && value.length === 3)) {
                siteManagePart3Ref.current.focus();
            }
        }
        else if (part === 'siteManagerPart3') {
            setSiteManagerPhonePart3(value)
        }
    };

    const onKeyDownHandler = (e, part) => {
        if (e.key === 'Enter') {
            e.preventDefault();   // 기본 동작 (예: 폼 제출)을 취소합니다.

            if (part === 'companyNumber') {
                nameRef.current.focus()
            }
            else if (part === 'name') {
                ceoNameRef.current.focus();
            } else if (part === 'ceoName') {
                companyKindRef.current.focus();
            }
            else if (part === 'companyKind') {
                part1Ref.current.focus()
            }
            else if (part === 'part1') {
                part2Ref.current.focus()
            }
            else if (part === 'part2') {
                part3Ref.current.focus()
            }
            else if (part === 'part3') {
                companyAddressRef.current.focus();
            }
            else if (part === 'companyAddress') {
                taxEmailRef1.current.focus();
            }
            else if (part === 'taxEmailLocal') {
                taxEmailRef2.current.focus();
            }
            else if (part === 'taxEmailDomain') {
                allbaroNameRef.current.focus();
            }
            else if (part === 'allbaroName') {
                allbaroPart1Ref.current.focus();
            }
            else if (part === 'allbaroPart1') {
                allbaroPart2Ref.current.focus();
            }
            else if (part === 'allbaroPart2') {
                allbaroPart3Ref.current.focus();
            }
            else if (part === 'allbaroPart3') {
                siteManagerNameRef.current.focus();
            }
            else if (part === 'siteManagerName') {
                siteManagePart1Ref.current.focus();
            }
            else if (part === 'siteManagerPart1') {
                siteManagePart2Ref.current.focus();
            }
            else if (part === 'siteManagerPart2') {
                siteManagePart3Ref.current.focus();
            }

        }
    };

    const [currentComponent, setCurrentComponent] = useState('first'); // 현재 컴포넌트를 제어하는 상태
    const [dataToPass, setDataToPass] = useState(null); // 두 번째 컴포넌트로 전달할 데이터

    const nextHandlerClick = () => {

        // 현재 registerReq 상태 확인
        console.log("현재 registerReq:", registerReq);

        const mergedCompanyNumber = `${companyNumber}${companyNumber2}${companyNumber3}`;
        const mergedCeoCellphone = `${ceoCellphonePart1}${ceoCellphonePart2}${ceoCellphonePart3}`;
        const mergedEmail = `${taxEmailLocalPart}@${taxEmailDomainPart}`;
        const mergedAllbarophone = `${allbarophonePart1}${allbarophonePart2}${allbarophonePart3}`;
        const mergedSiteManagerphone = `${siteManagerphonePart1}${siteManagerphonePart2}${siteManagerphonePart3}`;

        console.log("머지체크" + mergedCompanyNumber)

        const updatedRegisterReq = {
            ...registerReq, //기존 필드 유지
            ceoCellphone: mergedCeoCellphone,
            taxEmail: mergedEmail,
            allbaroCellphone: mergedAllbarophone,
            siteManagerCellphone: mergedSiteManagerphone,
            companyNumber: mergedCompanyNumber,
        };


        console.log("업데이트" + updatedRegisterReq)

        const errorMessages = {
            companyNumber: "배출업체 사업자 등록번호를 입력해주세요.",
            name: "배출업체 상호를 입력해주세요.",
            ceoName: "배출업체 대표자를 입력해주세요.",
            companyKind: "배출업체 업종을 입력해주세요.",
            ceoCellphone: "배출업체 연락처를 입력해주세요.",
            taxEmail: "세금계산서발행 이메일 주소를 입력해주세요.",
            companyAddress: "주소(사업자등록증상 주소)를 입력해주세요.",
            allbaroName: "올바로 담당자 성함을 입력해주세요.",
            allbaroCellphone: "올바로 담당자 연락처를 입력해주세요.",
            siteManagerCellphone: "현장 담당자 연락처를 입력해주세요.",
            siteManagerName: "현장 담당자 성함을 입력해주세요.",
        };

        for (const [key, value] of Object.entries(updatedRegisterReq)) {
            // errorMessages에 해당 key가 존재할 때만 검사
            if (key in errorMessages && value === "") {
                // key에 해당하는 에러 메시지를 errorMessages 객체에서 가져옴
                const errorMessage = errorMessages[key];
                if (errorMessage) {
                    setShowErrorMessage(errorMessage);
                    setShowError(true);
                }
                return;
            }
        }

        console.log("파일체크" + companyFileCheck)

        if (!companyFileCheck) {
            setShowErrorMessage("사업자등록증은 필수입니다.")
            setShowError(true)
            return
        }

        register(mergedCompanyNumber)

        setDataToPass({
            updatedRegisterReq,
            registerFileReq
        });
        // / 첫 번째 컴포넌트에서 두 번째 컴포넌트로 전환
        setCurrentComponent('second');
    };

    const [secondComponentData, setSecondComponentData] = useState({}); // 두 번째 컴포넌트의 데이터를 저장

    // 두 번째 컴포넌트에서 첫 번째 컴포넌트로 돌아가면서 데이터를 넘기는 함수
    const goBackToFirst = (data) => {

        console.log("데이터체크" + data)
        setSecondComponentData(data); // 두 번째 컴포넌트로부터 받은 데이터를 저장
        setCurrentComponent('first'); // 첫 번째 컴포넌트로 전환
    };

    // useEffect를 통해 secondComponentData가 변경될 때 registerReq 업데이트
    useEffect(() => {
        console.log("전달받은데이터" + secondComponentData)
        if (secondComponentData) {
            setRegisterReq(prevRegisterReq => ({
                ...prevRegisterReq, // 기존 필드 유지
                ...secondComponentData, // 새로운 데이터로 업데이트
            }));
        }
    }, [secondComponentData]);

    const companyNumberFileHandler = (event) => {
        const files = event?.target?.files; // 선택된 파일들


        if (files == null) {
            setShowErrorMessage("사업자등록번호증은 필수입니다.")
            setShowError(true)
            return
        }

        const validFiles = Array.from(files).filter(isValidFileType); // 유효한 파일만 필터링
        if (files && files.length > 0) {

        }


        if (validFiles.length === files.length) {
            const fileName = files[0].name; // 첫 번째 파일의 이름만 가져옴

            console.log("밸리드파일"+validFiles)

            console.log(fileName); // 'company.png'만 출력
            setRegisterFileReq((prev) => ({
                ...prev,
                companyNumberFile: validFiles, // 선택된 모든 유효한 파일을 배열로 설정
                fileName: fileName,
            }));
            console.log("files" + files)
            console.log("밸리드용" + validFiles)
            setCompanyFileCheck(true)
        } else {
            setShowErrorMessage("이미지 파일 및 PDF만 가능합니다.")
            setShowError(true)
            setCompanyFileCheck(false)
        }

    };

    const isValidFileType = (file) => {
        const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i; // 허용할 파일 확장자
        return allowedExtensions.test(file.name); // 파일 이름으로 확장자 검사
    };


    const companyNumberFileClick = () => {
        if (companyNumberFileRef.current) {
            companyNumberFileRef.current.click();
        }
    };


    const register = async (mergedCompanyNumber) => {

        const { companyNumberFile } = registerFileReq;


        console.log(mergedCompanyNumber);

        // fileId 배열 생성
        const fileIds = [
            ...Array(companyNumberFile.length).fill(1), // companyNumberFile의 각 파일에 대해 1로 설정
        ];

        // data 객체 생성
        const data = {
            files: [...companyNumberFile],
            userId: state.serialNumber,
            filesId: fileIds, // fileId 배열 설정
            companyNumber: mergedCompanyNumber,
            type: 'send',
        };

        console.log(data)
        try {
            const response = await fileUpload(data); // data 객체 전송
        } catch (e) {
            setShowErrorMessage(e.message);
            setShowError(true)
        }
    };

    const removeFile = () => {
        // 파일 이름을 비우는 로직
        setRegisterFileReq(prevState => ({
            ...prevState,
            fileName: '' // 파일 이름을 제거
        }));
        setCompanyFileCheck(false)
    };


    return (
        <div>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                    <h5>인증 실패</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인</button>
                </Modal.Body>
            </Modal>

            <Modal className="modal_alert1" show={showError2} onHide={handleErrorClose2} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt="" />
                    <h5>정보 없음</h5>
                    <p>{showErrorMessage2}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose2}>확인</button>
                </Modal.Body>
            </Modal>
            <MobileHeader />
            {currentComponent === 'first' ? (
                <div className="main-sidebar">
                    <Sidebar />
                    <div className="main-container">
                        <PcHeader title="폐기물 관리" icon="/images/icon_header2.png" />
                        <div className="body-box">
                            <div className="body-container">
                                <div className="card card_main">
                                    <div className="card-header">
                                        기재사항 등록
                                    </div>
                                    <div className="card-body">
                                        <div className="stepper_wrapper lg">
                                            <div className="stepper_line"></div>
                                            <ul>
                                                <li className="active">
                                                    <div className="stepper_dot dot_1"></div>
                                                    <div className="stepper_text">배출업체정보 입력</div>
                                                </li>
                                                <li className="">
                                                    <div className="stepper_dot dot_2"></div>
                                                    <div className="stepper_text">발주처 및 공사내용 입력</div>
                                                </li>
                                                <li>
                                                    <div className="stepper_dot dot_3"></div>
                                                    <div className="stepper_text">전송완료</div>
                                                </li>
                                            </ul>
                                        </div>

                                        <h3 className="table_heading">1. 사업자등록번호 검색</h3>
                                        <table className="table table-bordered card_table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "12.82%" }}>사업자 등록번호</th>
                                                    <td>
                                                        <div className="flex-center-between">
                                                            <div class="frm_field input_phone input_phone_tbl">
                                                                <input placeholder="" type="text" class="form-control"
                                                                    ref={companyNumberRef}
                                                                    onChange={(e) => companyNumberFirstHandler(e.target.value)}
                                                                    // onClick={companyNumberClick}
                                                                    value={companyNumber}
                                                                    maxLength={3}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text" class="form-control"
                                                                    ref={companyNumberRef2}
                                                                    onChange={(e) => companyNumberSecondHandler(e.target.value)}
                                                                    // onClick={companyNumberClick2}
                                                                    maxLength={2}
                                                                    value={companyNumber2}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text" class="form-control"
                                                                    ref={companyNumberRef3}
                                                                    onChange={(e) => companyNumberThirdHandler(e.target.value)}
                                                                    // onClick={companyNumberClick3}
                                                                    maxLength={5}
                                                                    value={companyNumber3}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'companyNumber')}
                                                                />
                                                            </div>
                                                            <button className="btn btn-secondary"
                                                                onClick={companyNumberFindCheck}
                                                            >검색</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h3 className="table_heading">2. 배출업체 정보입력(시공사, 발주처에서 의뢰 받은 업체)</h3>
                                        <table className="table table-bordered card_table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "12.82%" }}>상호</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                            value={registerReq.name || ""} // 값이 없을 때 빈 문자열로 처리
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    name: e.target.value,
                                                                }))
                                                            }
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'name')}
                                                            ref={nameRef}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>대표자</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                            value={registerReq.ceoName}
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    ceoName: e.target.value,
                                                                }))
                                                            }
                                                            ref={ceoNameRef}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'ceoName')}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>업종 (대표업종1개)</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control" placeholder=""
                                                            value={registerReq.companyKind}
                                                            onChange={(e) =>
                                                                setRegisterReq((prevState) => ({
                                                                    ...prevState,
                                                                    companyKind: e.target.value,
                                                                }))
                                                            }
                                                            ref={companyKindRef}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'companyKind')}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>연락처</th>
                                                    <td colSpan={3}>
                                                        <div className="flex-center-between">
                                                            <div className="frm_field input_phone input_phone_tbl">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="part1"
                                                                    value={ceoCellphonePart1}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'part1')}
                                                                    maxLength={3}
                                                                    ref={part1Ref}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="part2"
                                                                    value={ceoCellphonePart2}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'part2')}
                                                                    maxLength={ceoCellphonePart1 === '010' ? 4 : 3}
                                                                    ref={part2Ref}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="part3"
                                                                    value={ceoCellphonePart3}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'part3')}
                                                                    maxLength={4}
                                                                    ref={part3Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'part3')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>주소<br />
                                                        (사업자등록증상 주소)</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control" placeholder=""
                                                            value={registerReq.companyAddress}
                                                            onChange={(e) => setRegisterReq({ ...registerReq, companyAddress: e.target.value })}
                                                            ref={companyAddressRef}
                                                            onKeyDown={(e) => onKeyDownHandler(e, 'companyAddress')}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>세금계산서 발행<br />이메일 주소</th>
                                                    <td colSpan={3}>
                                                        <div className="frm_field input_email input_email_tbl">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={taxEmailLocalPart}
                                                                onChange={handleTaxEmailLocalPartChange}
                                                                ref={taxEmailRef1}
                                                                onKeyDown={(e) => onKeyDownHandler(e, 'taxEmailLocal')}
                                                            />
                                                            <span className="txt_range">@</span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={taxEmailDomainPart}
                                                                onChange={handleTaxEmailDomainPartChange}
                                                                ref={taxEmailRef2}
                                                                onKeyDown={(e) => onKeyDownHandler(e, 'taxEmailDomain')}
                                                            />
                                                            <select
                                                                className="form-select"
                                                                onChange={handleTaxEmailDomainPartChange}
                                                                value={taxEmailDomainPart}
                                                            >
                                                                <option value="">직접입력</option>
                                                                <option value="naver.com">naver.com</option>
                                                                <option value="gmail.com">gmail.com</option>
                                                                <option value="hanmail.net">hanmail.net</option>
                                                                <option value="yahoo.com">yahoo.com</option>
                                                                <option value="daum.net">daum.net</option>
                                                            </select>
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <th>올바로 담당자 성함</th>
                                                    <td style={{ width: "37.18%" }}>
                                                        <input type="text" className="form-control" placeholder=""
                                                            value={registerReq.allbaroName}
                                                            onChange={(e) => setRegisterReq({ ...registerReq, allbaroName: e.target.value })}
                                                        /></td>
                                                    <th style={{ width: "12.82%" }}>올바로 담당자 연락처</th>
                                                    <td>
                                                        <div className="flex-center-between">
                                                            <div class="frm_field input_phone input_phone_tbl">
                                                                <input placeholder="" type="text" className="form-control"
                                                                    value={allbarophonePart1}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart1')}
                                                                    maxLength={3}
                                                                    ref={allbaroPart1Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart1')}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text" class="form-control"
                                                                    value={allbarophonePart2}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart2')}
                                                                    maxLength={allbarophonePart1 === '010' ? 4 : 3}
                                                                    ref={allbaroPart2Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart2')}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text" class="form-control"
                                                                    value={allbarophonePart3}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'allbaroPart3')}
                                                                    maxLength={4}
                                                                    ref={allbaroPart3Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'allbaroPart3')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>현장 담당자 성함</th>
                                                    <td>
                                                        <input type="text" className="form-control" placeholder=""
                                                            value={registerReq.siteManagerName}
                                                            onChange={(e) => setRegisterReq({ ...registerReq, siteManagerName: e.target.value })}
                                                        /></td>
                                                    <th>현장 담당자 연락처</th>
                                                    <td>
                                                        <div className="flex-center-between">
                                                            <div class="frm_field input_phone input_phone_tbl">
                                                                <input placeholder="" type="text" className="form-control"
                                                                    value={siteManagerphonePart1}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart1')}
                                                                    maxLength={3}
                                                                    ref={siteManagePart1Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart1')}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text" class="form-control"
                                                                    value={siteManagerphonePart2}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart2')}
                                                                    maxLength={siteManagerphonePart1 === '010' ? 4 : 3}
                                                                    ref={siteManagePart2Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart2')}
                                                                />
                                                                <span className="txt_range">-</span>
                                                                <input placeholder="" type="text" class="form-control"
                                                                    value={siteManagerphonePart3}
                                                                    onChange={(e) => ceoCellPhoneHandler(e, 'siteManagerPart3')}
                                                                    maxLength={4}
                                                                    ref={siteManagePart3Ref}
                                                                    onKeyDown={(e) => onKeyDownHandler(e, 'siteManagerPart3')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>사업자 등록증 사본 첨부</th>
                                                    <td colSpan={3}>
                                                        <div className="tbl_files_outer">
                                                            <input placeholder="" type="file" className="form-control d-none"
                                                                ref={companyNumberFileRef}
                                                                id={"input-file"}
                                                                onChange={(e) => companyNumberFileHandler(e)}
                                                            />
                                                            <ul className="list_files ">
                                                                {registerFileReq.fileName &&
                                                                    <li>{registerFileReq.fileName}
                                                                        <img src={process.env.PUBLIC_URL + "/images/icon_fileclose.png"} alt="" onClick={removeFile}
                                                                            />
                                                                    </li>
                                                                }
                                                            </ul>
                                                            <button className="btn btn-secondary">
                                                                <label htmlFor={"input-file"}>
                                                                    파일첨부
                                                                </label>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer card_footer_btngrp_end">
                                        <button className="btn btn-primary btn_footer"
                                            onClick={nextHandlerClick}
                                        >다음으로</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <InformationWasteManageSecond data={dataToPass} goBack={goBackToFirst} />
            )}
        </div>
    );
}