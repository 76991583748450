import { MobileHeader } from "../common/MobileHeader";
import { PcHeader } from "../common/PcHeader";
import { Sidebar } from "../common/Sidebar";
import React, {useEffect, useRef, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {
    postMypageHandlerDetailSave,
    postMypageHandlerDetailSearch
} from "../../api/mypage";
import {scrollToTop} from "../../api/utils/scrollToTop";

export const MyPageHandler = () => {

    const [showError, setShowError] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");


    const [responseData,setResponseData] = useState([])


    const kindPermitRef = useRef(null);
    const permitNumberRef = useRef(null);
    const permitOrganRef = useRef(null);
    const permitQuantityRef = useRef(null);
    const currentWasteQuantityRef = useRef(null);
    const permitRegistrationRef = useRef(null);
    const targetWasteRef = useRef(null);
    const todayAverageWasteHandlerQuantityRef = useRef(null);
    const todayAverageWasteQuantityRef = useRef(null);
    const wasteDriveQuantityRef = useRef(null);
    const wasteHandlerMiddleKindRef = useRef(null);
    const wasteHandlerMiddleScaleRef = useRef(null);
    const wasteHandlerProblemRef = useRef(null);
    const wasteTotalQuantityRef = useRef(null);

    const onKeyDownHandler = (e,part) => {

        if (e.key === 'Enter') {
            if(part==="kindPermit"){
                permitNumberRef.current.focus();
            }else if(part==="permitNumber"){
                permitRegistrationRef.current.focus();
            }else if(part==="permitRegistration"){
                permitOrganRef.current.focus();
            }else if(part==="permitOrgan"){
                targetWasteRef.current.focus();
            }

        }
    }

    const handleErrorClose = () => {
        setShowError(false)
        scrollToTop()
    }

    const handleSuccessClose= () => {
        setShowSuccess(false)
        mypageLoading()
        scrollToTop()
    }

    const [register,setRegister] = useState({
        idx : "",
        userIdx : "",
        companyNumber:"",
        kindPermit:"",
        permitNumber : "",
        permitOrgan : "",
        permitQuantity : "",
        currentWasteQuantity:"",
        permitRegistration : "",
        targetWaste :"",
        todayAverageWasteHandlerQuantity :"",
        todayAverageWasteQuantity :"",
        wasteDriveQuantity :"",
        wasteTotalQuantity :"",
        myPageHandlerKinds : []
    })





    const mypageLoading= async () => {
        try{

            const response = await postMypageHandlerDetailSearch()

            if(response.code==="0000"){
                const result = response.result
                setResponseData(result)

                setRegister(prevState => ({
                    idx : result.idx,
                    userIdx : result.userIdx,
                    companyNumber: result.companyNumber,
                    kindPermit: result.kindPermit,
                    permitNumber : result.permitNumber,
                    permitOrgan : result.permitOrgan,
                    permitRegistration : result.permitRegistration,
                    permitQuantity : result.permitQuantity,
                    currentWasteQuantity : result.currentWasteQuantity,
                    targetWaste : result.targetWaste,
                    todayAverageWasteHandlerQuantity : result.todayAverageWasteHandlerQuantity,
                    todayAverageWasteQuantity : result.todayAverageWasteQuantity,
                    wasteDriveQuantity : result.wasteDriveQuantity,
                    wasteTotalQuantity : result.wasteTotalQuantity,

                }))

                const transformedRows = (result.myPageHandlerKinds || []).map(item => ({
                    wasteHandlerProblem: item.wasteHandlerProblem || "",
                    wasteHandlerMiddleKind: item.wasteHandlerMiddleKind || "",
                    wasteHandlerMiddleScale: item.wasteHandlerMiddleScale || "",
                    disabled: true,
                    checked: false,
                }));

                // rows 상태 업데이트
                setRows(transformedRows);
            }
        }
        catch (e){
            setShowErrorMessage("관리자에게 문의바랍니다.")
            setShowError(true)
        }
    }


    const handleChange = (e, field, allowDot = false, format = false) => {
        let { value } = e.target;

        // 숫자만 허용하고 allowDot이 true일 때만 소수점 허용
        const regex = allowDot ? /[^0-9.]/g : /[^0-9]/g;
        value = value.replace(regex, "");

        if (allowDot && value.endsWith(".")) {
            value += "0"; // 소수점이 끝나면 0 추가
        }

        // 숫자 형식 포맷 적용 여부
        const formattedValue = format ? new Intl.NumberFormat().format(value) : value;

        setRegister({
            ...register,
            [field]: formattedValue
        });
    };

    const handlerSave = async () => {

        try{
            const updatedRegister = {
                ...register,
                myPageHandlerKinds: rows,
            };
            const response = await postMypageHandlerDetailSave(updatedRegister)

            if(response.code==="0000"){

                setShowSuccessMessage("저장 되었습니다.")
                setShowSuccess(true)
            }
        }
        catch (e){
            setShowErrorMessage(e.message)
            setShowError(true)
        }
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToTop()
        }, 100);
        mypageLoading()

    }, []);

    const [selectAll, setSelectAll] = useState(false);
    const [rows, setRows] = useState([
        { wasteHandlerProblem: "", wasteHandlerMiddleKind: "",wasteHandlerMiddleScale:"",
            disabled: true, checked: false },
    ]);
    const handleAddRow = () => {
        if (rows.length < 10) {
            setRows([
                ...rows,
                { wasteHandlerProblem: "", wasteHandlerMiddleKind: "", wasteHandlerMiddleScale: "", disabled: true, checked: false },
            ]);
        } else {
            // 10행을 초과하지 않도록 처리
           setShowErrorMessage("최대 10행까지 가능합니다.")
            setShowError(true)
        }
    };

    // 입력값 변경 핸들러 (숫자만 입력 및 콤마 추가)
    const handleInputChange = (index, field, value) => {

        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    // 행 체크박스 선택 핸들러
    const handleRowCheckboxChange = (index) => {
        const updatedRows = [...rows];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRows(updatedRows);

        // 전체 선택 상태 업데이트
        const allChecked = updatedRows.every((row) => row.checked);
        setSelectAll(allChecked);
    };

    // 전체 체크박스 선택 핸들러
    const handleSelectAllChange = () => {
        const updatedRows = rows.map((row) => ({
            ...row,
            checked: !selectAll,
        }));
        setRows(updatedRows);
        setSelectAll(!selectAll);
    };

    // 선택된 행 삭제
    const handleDeleteSelected = () => {
        const updatedRows = rows.filter((row) => !row.checked);
        setRows(updatedRows);
        setSelectAll(false);
    };


    return (
        <React.Fragment >
            <Modal className="modal_alert1" show={showSuccess} onHide={handleSuccessClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/alert_icon1.png"} alt="" />
                    <h5 className="singleLine">저장 성공</h5>
                    <p>수정 되었습니다.</p>
                    <button className="btn btn-primary btn-lg btn_alert1 h-48px w-100" onClick={handleSuccessClose}>확인
                    </button>
                </Modal.Body>
            </Modal>
            <Modal className="modal_alert1" show={showError} onHide={handleErrorClose} centered>
                <Modal.Body>
                    <img className="alert_icon1" src={process.env.PUBLIC_URL + "/images/icon_red_alert.png"} alt=""/>
                    <h5>오류</h5>
                    <p>{showErrorMessage}</p>
                    <button className="btn btn-secondary btn-lg btn_alert1 h-48px w-100" onClick={handleErrorClose}>확인
                    </button>
                </Modal.Body>
            </Modal>
            <MobileHeader />
            <div className="main-sidebar">
                <Sidebar />
                <div className="main-container">
                    <PcHeader title="마이페이지" icon="/images/icon_square.png" />
                    <div className="body-box">
                        <div className="body-container">
                            <div className="card card_main">
                                <div className="card-header">
                                    수탁능력확인서
                                </div>
                                <div className="card-body">
                                    <h3 className="card_body_heading">처리업체 확인서 정보</h3>

                                    <div className="table_scroll">
                                        <table className="table table-bordered card_table mb-4">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "12.82%" }}>업종(신고서)</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={register.kindPermit || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       kindPermit: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={kindPermitRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'kindPermit')}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>허가(신고)번호</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={register.permitNumber || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       permitNumber: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={permitNumberRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'permitNumber')}
                                                        />

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>허가(신고)일<br />
                                                        (최종변경일)</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={register.permitRegistration || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       permitRegistration: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={permitRegistrationRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'permitRegistration')}
                                                        />

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>허가(신고수리)기관</th>
                                                    <td colSpan={3}>
                                                        <input type="text" className="form-control"
                                                               value={register.permitOrgan || ""}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       permitOrgan: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={permitOrganRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'permitOrgan')}
                                                        />

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>영업대상폐기물</th>
                                                    <td colSpan={3}>
                                                              <textarea className="form-control" rows={4}
                                                                        value={register.targetWaste || ""}
                                                                        onChange={(e) => {
                                                                            setRegister((prevState) => ({
                                                                                ...prevState,
                                                                                targetWaste: e.target.value,
                                                                            }));
                                                                        }}
                                                                        ref={targetWasteRef}
                                                                        onKeyDown={(e) => onKeyDownHandler(e, 'targetWaste')}
                                                              ></textarea>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="table_scroll">
                                        <div className="card_top_with_btns mb-13px">
                                            <h3 className="table_heading mb-0"></h3>
                                            <div className="card_table_btntop">
                                                <button className="btn btn-outline-secondary"
                                                        onClick={handleAddRow}
                                                >추가</button>
                                                <button className="btn btn-outline-danger ms-10px"
                                                        onClick={handleDeleteSelected}
                                                >삭제</button>
                                            </div>
                                        </div>
                                        <table className="table table-bordered tbody_noborder my_table">
                                            <thead>
                                            <tr>
                                                <th style={{ width: "100%" }} colSpan={10}> {/* colSpan을 9에서 10으로 변경 */}
                                                    수탁자의 허가 또는 신고사항<br />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "2.82%" }} rowSpan={2}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAllChange}
                                                    /></th>
                                                <th style={{ width: "6.49%" }} rowSpan={2}>허가<br/>신고<br/>또는<br/>업종</th>
                                                <th style={{ width: "8.49%" }} rowSpan={2}>영업대상폐기물</th>
                                                <th style={{ width: "12.49%" }} rowSpan={2}>
                                                    폐기물 처분<br />
                                                    또는 재활용 방법
                                                </th>
                                                <th style={{ width: "50.03%" }} colSpan={2}>
                                                    폐기물 중간 처분 또는 재활용 시설<br />
                                                    폐기물 처분 또는 재활용 방법
                                                </th>
                                                <th style={{ width: "12.49%" }} colSpan={2}>폐기물<br />
                                                    최종처분시설</th>
                                                <th style={{ width: "12.49%" }} colSpan={3}>수집/운반차량</th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "33.33%" }}>종류</th>
                                                <th>용량(톤/시간)</th>
                                                <th style={{ width: "6.24%" }}>용량(m³)</th>
                                                <th>매립기간</th>
                                                <th style={{ width: "6.24%" }}>규모(톤)</th>
                                                <th>수량(대)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td className="p-3px text-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={row.checked}
                                                        onChange={() => handleRowCheckboxChange(index)}
                                                    />
                                                    </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled={row.disabled}
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled={row.disabled}
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.wasteHandlerProblem}
                                                        maxLength={50}
                                                        onChange={(e) =>
                                                            handleInputChange(index, "wasteHandlerProblem", e.target.value)
                                                        }
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.wasteHandlerMiddleKind}
                                                        maxLength={50}
                                                        onChange={(e) =>
                                                            handleInputChange(index, "wasteHandlerMiddleKind", e.target.value)
                                                        }
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.wasteHandlerMiddleScale}
                                                        maxLength={50}
                                                        onChange={(e) =>
                                                            handleInputChange(index, "wasteHandlerMiddleScale", e.target.value)
                                                        }
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled={row.disabled}
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled={row.disabled}
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled={row.disabled}
                                                    />
                                                </td>
                                                <td className="p-3px">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled={row.disabled}
                                                    />
                                                </td>
                                            </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="table_scroll">
                                        <table className="table table-bordered tbody_noborder my_table">
                                            <thead>
                                                <tr>
                                                    <th colSpan={6}>수탁자(최종처분업자 외의 자)의 처리능력(단위 : 톤)</th>
                                                </tr>
                                                <tr>
                                                    <th>허용보관량</th>
                                                    <th>현재 폐기물 보관량</th>
                                                    <th>1일 평균 폐기물 반입량</th>
                                                    <th>금회 폐기물 수탁(운반)량</th>
                                                    <th>전체 폐기물 보관량</th>
                                                    <th>1일 폐기물 처 (운반)량</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control"
                                                               value={register.permitQuantity || ""}
                                                               maxLength={20}
                                                               onChange={(e) => handleChange(e, "permitQuantity",false,true)}
                                                               ref={permitQuantityRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'permitQuantity')}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control"
                                                               value={register.currentWasteQuantity || ""}
                                                               maxLength={20}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       currentWasteQuantity: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={currentWasteQuantityRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'currentWasteQuantity')}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control"
                                                               value={register.todayAverageWasteQuantity || ""}
                                                               maxLength={20}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       todayAverageWasteQuantity: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={todayAverageWasteQuantityRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'todayAverageWasteQuantity')}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control"
                                                               value={register.wasteDriveQuantity || ""}
                                                               maxLength={20}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       wasteDriveQuantity: e.target.value,
                                                                   }));
                                                               }}
                                                               // onChange={(e) => handleChange(e, "wasteDriveQuantity",true)}
                                                               ref={wasteDriveQuantityRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'wasteDriveQuantity')}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control"
                                                               value={register.wasteTotalQuantity || ""}
                                                               maxLength={20}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       wasteTotalQuantity: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={wasteTotalQuantityRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'wasteTotalQuantity')}
                                                        />
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control"
                                                               value={register.todayAverageWasteHandlerQuantity || ""}
                                                               maxLength={20}
                                                               onChange={(e) => {
                                                                   setRegister((prevState) => ({
                                                                       ...prevState,
                                                                       todayAverageWasteHandlerQuantity: e.target.value,
                                                                   }));
                                                               }}
                                                               ref={todayAverageWasteHandlerQuantityRef}
                                                               onKeyDown={(e) => onKeyDownHandler(e, 'todayAverageWasteHandlerQuantity')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th colSpan={5}>수탁자(최종처분업자)의 처분능력(단위 : m³)</th>
                                                    <th rowSpan={2}>수탁가능여부</th>
                                                </tr>
                                                <tr>
                                                    <th>매립 허가용량</th>
                                                    <th>1일 평균 폐기물 반입량</th>
                                                    <th>금회 폐기물 수탁량</th>
                                                    <th colSpan={2}>남은매립 용량</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control" disabled/>
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control" disabled/>
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control" disabled/>
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control" disabled/>
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control" disabled/>
                                                    </td>
                                                    <td className="p-3px">
                                                        <input type="text" className="form-control" value="가능" style={{ textAlign: "center" }} disabled/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer card_footer_btngrp_end">
                                    <button className="btn btn-primary btn_footer"
                                    onClick={handlerSave}
                                    >저장</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}

