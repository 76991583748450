import Modal from "react-bootstrap/Modal";
import React from "react";

export default function InformationSendCheckEditFirstImagePreviewModal(props) {
    const { show, handleClose, previewUrl, download } = props;

    const handleDownload = () => {
        // 다운로드 링크 생성
        const link = document.createElement('a');
        link.href = previewUrl;
        link.download = download; // 파일 이름 설정
        document.body.appendChild(link); // 링크를 DOM에 추가
        link.click(); // 링크 클릭
        document.body.removeChild(link); // 링크 제거
    };

    return (
        <Modal show={show} onHide={handleClose} centered size={"xl"}>
            <Modal.Header>
                <div className="header_title">미리보기</div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
                <img
                    src={previewUrl}
                    alt="모달 미리보기"
                    style={{ width: "100%", height: "auto" }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={handleDownload}>
                    다운로드
                </button>
                <button className="btn btn-secondary" onClick={handleClose}>
                    닫기
                </button>
            </Modal.Footer>
        </Modal>
    );
}
