import Modal from "react-bootstrap/Modal";
import React from "react";

export default function DeliveryCheckPreviewModal(props) {
    const { show, handleClose, previewUrl, handlePrint  } = props;


    return (
        <Modal show={show} onHide={handleClose} centered size={"xl"}>
            <Modal.Header>
                <div className="header_title">미리보기</div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
                    <iframe
                        src={previewUrl}
                        title="PDF 미리보기"
                        width="100%"
                        height="600px"
                        style={{ border: "none" }}
                    />
                {/*) : (*/}
                {/*    <p>PDF 파일을 불러오는 중입니다...</p>*/}
                {/*)}*/}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={handlePrint}>
                    다운로드
                </button>
                <button className="btn btn-secondary" onClick={handleClose}>
                    닫기
                </button>
            </Modal.Footer>
        </Modal>
    );
}
