import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';

export const PublicHeader = () => {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isLogin, setIsLogin] = useState(sessionStorage.getItem('loginId'))

    const handleLogout = () => {
        Cookies.remove('accToken'); // accToken 쿠키 삭제
        const userName = sessionStorage.getItem('oneclick_userName');
        if (userName) {
            sessionStorage.removeItem('oneclick_userName');
            sessionStorage.removeItem('loginId');
        }
        navigate("/login")
    };

    return (
        <header>
            <div className="public_header_wrapper">
                <div className="public_header_outer">
                    <button className="btn btn-link btn_menu_mobile mobile_view" onClick={handleShow}>
                        <img src={process.env.PUBLIC_URL + "/images/icon_hamburger_light.png"} alt="" />
                    </button>
                    <a href={process.env.PUBLIC_URL + '/'} className="logo_holder pc_view"></a>
                    <a href={process.env.PUBLIC_URL + '/'} className="logo_holder mobile_view"></a>
                    <div className="header_menu">
                        <ul className="left_menu">
                            {/*<li><a href="/#">서비스 소개</a></li>*/}
                            <li><a href={process.env.PUBLIC_URL + '/feeIntroduce'}>요금안내</a></li>
                            {/*<li><a href="/#">법적효력</a></li>*/}
                            {/*<li><a href="/#">도입문의</a></li>*/}
                        </ul>
                        <ul className="right_menu">
                            {isLogin === null? (
                                <>
                                    <li onClick={() => navigate("/login")}>
                                        <a href="">로그인</a>
                                    </li>
                                    <li onClick={() => navigate("/memberShipAgree")}><a href="">회원가입</a></li>
                                </>
                            ) : (
                                <li onClick={() => handleLogout()}><a href="#">로그아웃</a></li>
                            )}
                        </ul>

                    </div>
                </div>
            </div>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="offcanvas_menu">
                        <li><a href={process.env.PUBLIC_URL + "/feeIntroduce"}>서비스 소개</a></li>
                        {/* <li><a href="/feeIntroduce">요금안내</a></li>
                        <li><a href="/feeIntroduce">법적효력</a></li>
                        <li><a href="/feeIntroduce">도입문의</a></li> */}
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </header>
    );
}